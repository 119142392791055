import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyFetchSelfServeDetailsQuery } from "@api/selfServe";
import { Stack, Typography, Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { RootState } from "@stores/store";
import ImageCarousel from "./components/ImageCarousel"
import UserAuth from "@services/auth";

const imageBucketUrl = `${process.env.REACT_APP_BUCKET_DOMAIN}`;
const images = [
  `${imageBucketUrl}/demo-dashboards/financial_projections_1.png`,
  `${imageBucketUrl}/demo-dashboards/financial_projections_2.png`,
  `${imageBucketUrl}/demo-dashboards/financial_projections_3.png`,
];

const GettingStartedROASProjection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const modulesState = useSelector((state: RootState) => state?.ModulesData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selfServeData, setSelfServeData] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [requestStatus, setRequestStatus] = useState<0 | 1 | 2>(0);
  const [requestedFeature, setRequestedFeature] = useState<boolean>(false);

  const [fetchSelfServeDetails] = useLazyFetchSelfServeDetailsQuery();

  useEffect(() => {
    if (modulesState.loadCount === 0) return;

    // if modules has length, navigate back to dashboards.
    if (UserAuth.demoToken) {
      navigate('/dashboard');
      return;
    }

    // if modules doesn't have length, fetch self serve api.
    fetchDataAccessStatus();

    const polling = setInterval(() => {
      fetchDataAccessStatus();
    }, 60000);

    return () => {
      clearInterval(polling);
    }
  }, [modulesState]);

  const fetchDataAccessStatus = async (): Promise<void> => {
    try {
      const response = await fetchSelfServeDetails().unwrap();

      if (response?.data?.unrequestedDashboards?.length) {
        const unRequestedDashboards = response.data.unrequestedDashboards;
        setSelfServeData(response.data);
        setRequestStatus(unRequestedDashboards.includes("FINANCIAL_PROJECTIONS") ? 1 : 2);
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  return (
    <Stack
      gap={"1.75rem"}
      padding={"2rem"}
      sx={{
        overflowY: 'auto',
        maxHeight: '100vh'
      }}
    >
      <Stack>
        <Typography
          fontSize={"2.25rem"}
          lineHeight={"2.5rem"}
          fontWeight={700}
          color={"#162936"}
        >
          Access ROAS Projections,
        </Typography>
        <Typography
          fontSize={"2.25rem"}
          lineHeight={"2.5rem"}
          fontWeight={700}
          color={"#B4B4B4"}
        >
          for smarter financial planning
        </Typography>
        <Typography
          marginTop={'0.75rem'}
          fontSize={'1rem'}
          lineHeight={'1.25rem'}
          fontWeight={500}
          color={'#6C6C6C'}
          sx={{ width: isMobile ? "100%" : "60%" }}
        >
          Use our projections model trained on your data and fine-tuned with category insights to forecast cohort-level ROAS, helping you plan your cash flow
        </Typography>
      </Stack>

      <Stack gap={"0.75rem"} direction={"row"}>
        {!requestedFeature && (<Button
          variant="contained"
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          // disabled={[0, 2].includes(requestStatus)}
          sx={{
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            fontWeight: 700,
            color: '#F6F8F9',
            padding: '0.625rem 1.5rem',
            borderRadius: '100px',
            textTransform: 'none'
          }}
          onClick={(() => setRequestedFeature(true))}
        >
          Request Feature
        </Button>)}
        {requestedFeature && (<Button
          variant="text"
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          sx={{
            pointerEvents: "none",
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            fontWeight: 500,
            color: '#6C6C6C',
            padding: '0.625rem 0',
            borderRadius: '100px',
            textTransform: 'none',
            ":hover": {
              background: 'none'
            }
          }}
        >
          {"You have been added to the waitlist!"}
        </Button>)}
      </Stack>

      <Box>
        <ImageCarousel images={images} />
      </Box>
    </Stack>
  );
};

export default GettingStartedROASProjection;