import { FC, useRef } from "react";
import { Paper, Box, InputBase, useTheme, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import useDebounce from "@hooks/useDebounce";

export interface SearchProps {
  handleSearch: (search: string) => void;
  search: string;
  setSearch: (val: string) => void;
  placeholder?: string;
}

const Search: FC<SearchProps> = (props: SearchProps) => {
  const { handleSearch, search, setSearch, placeholder = "Search" } = props;

  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down("md"));

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const debounceSearch = useDebounce((searchString: string) => {
    handleSearch(searchString);
  }, 800);

  const handleSearchChange = (searchString: string) => {
    setSearch(searchString);
    debounceSearch(searchString);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper
        component="form"
        sx={{
          height: 45,
          px: "19px",
          display: "flex",
          alignItems: "center",
          borderRadius: "28px",
          backgroundColor: theme.palette.custom_field_background,
          width: "100%",
          boxShadow: "none",
        }}
      >
        <InputBase
          inputRef={searchInputRef}
          placeholder={placeholder}
          value={search}
          onChange={(e) => handleSearchChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            flex: 1
          }}
        />
        {search ? (
          <Box
            sx={{ mt: 1, cursor: "pointer" }}
            onClick={(e) => handleSearchChange("")}
          >
            <CloseIcon />
          </Box>
        ) : (
          <Box
            sx={{ mt: 1 }}
          >
            <SearchIcon />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default Search;
