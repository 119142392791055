import dayjs from 'dayjs';
import { SideNavOptions } from 'src/components/SideNav/types';
import { ChartDataSet } from 'src/components/Charts/types';
import { Module, Submodule, SubmoduleFilter } from '../stores/Modules/types/modules';
import { User } from '../stores/User/types/user';
import {
  DEFAULT_PVX_FINANCING,
  MULTIPLICATION_FACTOR_DEFAULT_MONTHLY_REVENUE,
  MULTIPLICATION_FACTOR_DEFAULT_OPERATING_EXPENSES,
  MULTIPLICATION_FACTOR_DEFAULT_PREVIOUS_MONTH_BALANCE,
  USER_ROLES,
  USER_STATUS
} from '../constants/constants';
import {
  RequestedSubModule,
  UserSubModulesFilterApiMap
} from '../api/userModules';
import { UserSubmoduleMap } from '../api/company';
import { CalculatorChartData, FundingCalculations } from '../stores/FundingCalculator/types/fundingCalculator';
import { MODULES_REQUEST_STATUS } from './types/RequestedDashboards';

export const formatDate = (dateString: string) => {
  if (!dateString) {
    return '-';
  }
  return dayjs(dateString).format('MMM D, YYYY');
};

const createUrlForRequestedSubModule = (module: Module): string => {
  const path = module.name.replace(/([A-Z])|\s/g, (match) =>
    match === " " ? "_" : match.toLowerCase()
  );
  return `dashboard/${path}`;
}

const createSubModuleTypeForRequestedSubModule = (module: Module): string => {
  return module.name.replace(/\s+/g, '_').toUpperCase();
}

export const convertModulesToOptions = (modules: Module[]): SideNavOptions[] => {
  return modules.map((module) => {
    const subOptions = module.submodules?.map((submodule: Submodule) => ({
      label: submodule.name,
      path: submodule.url,
      type: submodule.submoduleType,
    }));

    const metadata = module.metadata ? JSON.parse(module.metadata) : undefined;
    let url = '';
    if (module.requestStatus === "REQUESTABLE" || module.requestStatus === "REQUESTED") {
      url = createUrlForRequestedSubModule(module);
    } else if (module.requestStatus === "APPROVED") {
      url = module.submodules?.[0]?.url || '';
    }
    return {
      label: module.name,
      path: url,
      icon: metadata ? metadata.icon : '',
      metadata: metadata,
      subOptions: subOptions,
      requestStatus: module.requestStatus
    };
  }) || [];
};

export const formatUserList = (users: User[]): User[] => {
  if (users?.length) {
    return users.map((user) => {
      return {
        id: user.id || '',
        email: user.email || '',
        status: USER_STATUS[user.status as keyof typeof USER_STATUS] || '',
        role: USER_ROLES[user.role as keyof typeof USER_ROLES] || '',
        joiningDate: formatDate(user.joiningDate || ''),
        companyId: user.companyId || ''
      };
    });
  }
  return [];
};

export const formatUserSubmoduleFilters = (filtersFromApi: UserSubModulesFilterApiMap[]): SubmoduleFilter[] => {
  if (filtersFromApi?.length) {
    const submoduleFilters: SubmoduleFilter[] = [];

    filtersFromApi.forEach((submoduleFilter) =>
      submoduleFilter.submoduleFilterRoles.forEach((filterOption) => {
        submoduleFilters.push({
          id: filterOption.id,
          filterValue: filterOption.filterValue,
          submoduleFilterName: submoduleFilter.name,
          submoduleFilterId: submoduleFilter.id,
          isSelected: true,
        });
      })
    );

    return submoduleFilters;
  }
  return [];
};

export const formatModulesToSubmoduleList = (modules: Module[]): UserSubmoduleMap[] => {
  const submoduleFilters: UserSubmoduleMap[] = [];

  if (modules?.length) {
    modules.forEach((module) => {
      if (module.submodules?.length) {
        module.submodules.forEach((submodule) => {
          submoduleFilters.push({
            moduleId: module.id,
            submoduleId: submodule.id,
            filterIds: submodule.filters?.map((filter) => filter.id) || []
          });
        });
      }
    });
  }

  return submoduleFilters;
};

export const getSelectedFilterIds = (modules: Module[], submoduleId: string): string[] => {
  const selectedFilterIds: string[] = [];

  for (const module of modules) {
    if (module.submodules) {
      for (const submodule of module.submodules) {
        if (submodule.id === submoduleId) {
          if (submodule.filters) {
            const selectedFilters = submodule.filters.filter(filter => filter.isSelected);
            selectedFilterIds.push(...selectedFilters.map(filter => filter.id));
          }
        }
      }
    }
  }

  return selectedFilterIds;
}

export const getDashboardDataBasedOnUrl = (modules: Module[], url: string): {
  id: string;
  name: string;
  embedId: string;
  submoduleType?: string;
  filterQuery: string;
  requestStatus: MODULES_REQUEST_STATUS;
  requestId?: string;
} | null => {
  for (const module of modules) {
    if (module.submodules && module.submodules.length > 0) {
      for (const submodule of module.submodules) {
        if (submodule.url === url) {
          return {
            id: submodule.id || '',
            name: submodule.name,
            embedId: submodule.embedId,
            submoduleType: submodule.submoduleType,
            requestStatus: "APPROVED",
            filterQuery: submodule.defaultFilter
              ? `(${submodule.nativeFilterId || 'NATIVE_FILTER-Knb6wV2-t'}:(__cache:(label:'${submodule.defaultFilter}',validateStatus:!f,value:!('${submodule.defaultFilter}')),extraFormData:(filters:!((col:'game_id',op:IN,val:!('${submodule.defaultFilter}')))),filterState:(label:'${submodule.defaultFilter}',validateStatus:!f,value:!('${submodule.defaultFilter}')),id:${submodule.nativeFilterId || 'NATIVE_FILTER-Knb6wV2-t'},ownState:()))`
              : ''
          };
        }
      }
    } else if ((module.requestStatus === "REQUESTABLE" || module.requestStatus === "REQUESTED") && url.slice(1) === createUrlForRequestedSubModule(module)) {
      return {
        id: module.id || "",
        name: module.name,
        embedId: "",
        submoduleType: createSubModuleTypeForRequestedSubModule(module),
        requestStatus: module.requestStatus,
        requestId: module.requestId,
        filterQuery: "",
      };
    }
  }
  return null;
}
export const formatCurrency = (
  value: number | string,
  isAbbreviated: boolean = false,
  millionsFractionDigitsCount: number = 1,
  thousandsFractionDigitsCount: number = 1
): string => {
  if (!value || Number(value) === 0) return '';
  const numericValue = Number(value);

  if (isAbbreviated) {
    if (numericValue >= 1000000) {
      // Value in millions
      const res = Math.floor(numericValue / 100000) / 10;
      return `$${res.toLocaleString('en-US', {
        minimumFractionDigits: millionsFractionDigitsCount,
        maximumFractionDigits: millionsFractionDigitsCount,
      })}M`;
    } else if (numericValue >= 1000) {
      // Value in thousands
      const res = Math.floor(numericValue / 100) / 10;
      return `$${res.toLocaleString('en-US', {
        minimumFractionDigits: thousandsFractionDigitsCount,
        maximumFractionDigits: thousandsFractionDigitsCount,
      })}k`;
    }
  }

  // Return full value with comma-separated formatting
  return `$${numericValue.toLocaleString('en-US')}`;
}

export const calculateInterpolatedChartData = (monthlyExpenditureData: CalculatorChartData): ChartDataSet => {
  if (monthlyExpenditureData.length) {
    const result: CalculatorChartData = [...monthlyExpenditureData];
    const ln = Math.log;

    for (let i = 0; i < monthlyExpenditureData.length - 1; i++) {
      const current = monthlyExpenditureData[i];
      const next = monthlyExpenditureData[i + 1];

      const x = current.value;
      const y = next.value;
      const m = current.month;
      const n = next.month;

      const C = (y - x) / ln(n - m + 1);

      for (let month = m; month <= n; month++) {
        if (!result.some(item => item.month === month)) {
          const D = month - m + 1;
          const value = x + C * ln(D);
          result.push({ month, value: parseFloat(value.toFixed(4)) });
        }
      }
    }

    result.sort((a, b) => a.month - b.month);

    return [{ label: 'Net Cumulative ROAS', data: result.map((res) => ({ x: res.month, y: res.value })) }];
  }
  return [];
}

export const convertToFundingCalculations = (data: any): FundingCalculations => {
  const monthlyFinancials = Object.values(data.leadMagnetFinancials.monthlyFinancials);
  const monthlyRoasAndPayback = Object.values(data.leadMagnetRoasAndPayback.monthlyRoasAndPayback);
  const { avgRevenue, avgMarketingSpend, operatingExpenses, latestMonthCashBalance } = data.leadMagnetFinancials;

  const calculation = {
    id: data.id || 'playground',
    email: data.email || '',
    requestId: data.requestId || '',
    variables: {
      pvxFinancing: data.leadMagnetFinancials.investorFundingPercentage,
      paybackPeriodInMonths: data.leadMagnetFinancials.marketingSpends.totalMonths || monthlyRoasAndPayback.length,
      latestMonthFinancials: {
        netRevenue: avgRevenue,
        marketingSpends: avgMarketingSpend,
        latestMonthCashBalance: latestMonthCashBalance,
        operatingExpenses: operatingExpenses,
        profitBeforeTaxes: avgRevenue - (avgMarketingSpend + operatingExpenses),
      },
      revenueChurnPerMonth: data.leadMagnetFinancials.monthlyRevenueChurn,
      uaIncreasePerMonth: data.leadMagnetFinancials.monthlySpendIncrease,
    },
    summary: {
      cashCost: data.leadMagnetFinancials.cashCost,
      eligibleFund: data.leadMagnetFinancials.marketingSpends.totalInvestorSpend,
      annualFacilitySize: data.leadMagnetFinancials.marketingSpends.totalMarketingSpend,
      totalInvestmentPaybackAmount: monthlyRoasAndPayback.reduce((sum: number, i: any) => sum + i.firstInvestmentPaybackAmount, 0),
      totalInvestmentInterestPaid: monthlyRoasAndPayback.reduce((sum: number, i: any) => sum + i.firstInvestmentInterestPaid, 0),
    },
    charts: {
      cumulative_roas: {
        datasets: [
          {
            label: "Net Cumulative ROAS",
            data: monthlyRoasAndPayback.map((i: any, index) => ({ x: i.month, y: i.cumulativeRoas * 100 })),
          }
        ],
        title: "Cumulative ROAS",
        description: "Net Cumulative Return on Advertising Spend over time."
      },
      revenue: {
        datasets: [
          {
            label: "With PvX Funding",
            data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: i.netRevenueWithInvestment })),
          },
          // {
          //   label: "Current net revenue",
          //   data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: 0 })),
          // }
        ],
        title: "Revenue Growth",
        description: "Comparison of revenue growth with PvX Funding and Current Net Revenue."
      },
      cumulative_revenue: {
        datasets: [
          {
            label: "With PvX Funding",
            data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: i.cumulativeRevenueWithInvestment })),
          },
          // {
          //   label: "Current Net Revenue",
          //   data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: 0 })),
          // }
        ],
        title: "Revenue Growth",
        description: "Comparison of revenue growth with PvX Funding and Current Net Revenue."
      },
      monthly_marketing_spends: {
        datasets: [
          {
            label: "Company Funding",
            data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: i.companySpend })),
          },
          {
            label: "PvX Funding",
            data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: i.investorSpend })),
          },
        ],
        title: "Monthly Marketing Spends",
        description: "Allocation of marketing spends between PvX Funding and Company Funding."
      },
      profit_before_taxes: {
        datasets: [
          {
            label: "With PvX Funding",
            data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: i.profitWithInvestment })),
          },
          {
            label: "Without PvX Funding",
            data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: i.profitWithoutInvestment })),
          }
        ],
        title: "Profit Before Taxes",
        description: "Profit comparison before taxes with PvX Funding and Current UA Spends."
      },
      cumulative_profit_before_taxes: {
        datasets: [
          {
            label: "With PvX Funding",
            data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: i.cumulativeProfitWithInvestment })),
          },
          {
            label: "Without PvX Funding",
            data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: i.cumulativeProfitWithoutInvestment })),
          }
        ],
        title: "Profit Before Taxes",
        description: "Profit comparison before taxes with PvX Funding and Current UA Spends."
      },
      ending_cash_balance: {
        datasets: [
          {
            label: "With PvX Funding",
            data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: i.cashBalanceWithInvestment })),
          },
          {
            label: "Without PvX Funding",
            data: monthlyFinancials.map((i: any, index) => ({ x: index + 1, y: i.cashBalanceWithoutInvestment })),
          }
        ],
        title: "Cash Balance",
        description: "Comparison of ending cash balance with PvX Funding and Status Quo."
      },
      interest_paid: {
        datasets: [
          {
            label: "Principal Repayment",
            data: monthlyRoasAndPayback.map((i: any, index) => ({ x: i.month, y: i.firstInvestmentPaybackAmount })),
          },
          {
            label: "Cash cost",
            data: monthlyRoasAndPayback.map((i: any, index) => ({ x: i.month, y: i.firstInvestmentInterestPaid })),
          }
        ],
        title: 'Interest Paid',
        description: 'Comparison of interest paid'
      }
    }
  };

  return calculation;
};

export const getFundingValue = (avgMonthlyMarketingSpend: number, paybackPeriod: number = 12) => {
  // avgMarketingSpend * pvxFundingPercentage * ((1 + monthlyRevenueIncrease)^12 - 1) / monthlyRevenueIncrease
  const value = avgMonthlyMarketingSpend * DEFAULT_PVX_FINANCING * (Math.pow((1 + 0.025), 12) - 1) / 0.025;
  return `${formatCurrency(value, true)}`;
};

export const percentageDifference = (a: number, b: number) => {
  if (b === 0) {
    return a;
  }
  return ((a - b) / Math.abs(b)) * 100;
}

export const roundToNthPlace = (number: number, place: number) => {
  const factor = Math.pow(10, place);
  return Math.round(number / factor) * factor;
}

export const getDefaultAvgRevenue = (avgMonthlyMarketingSpend: number) => {
  const avgRevenue = MULTIPLICATION_FACTOR_DEFAULT_MONTHLY_REVENUE * avgMonthlyMarketingSpend;
  return roundToNthPlace(avgRevenue, 2); // rounding to nearest hundreds
}

export const getDefaultOperatingExpenses = (avgMonthlyMarketingSpend: number) => {
  const operatingExpenses = MULTIPLICATION_FACTOR_DEFAULT_OPERATING_EXPENSES * avgMonthlyMarketingSpend;
  return roundToNthPlace(operatingExpenses, 2); // rounding to nearest hundreds
}

export const getDefaultPreviousMonthBalance = (avgMonthlyMarketingSpend: number) => {
  const previousMonthBalance = MULTIPLICATION_FACTOR_DEFAULT_PREVIOUS_MONTH_BALANCE * avgMonthlyMarketingSpend;
  return roundToNthPlace(previousMonthBalance, 2); // rounding to nearest hundreds
}

export const processRatingString = (ratingString: string | null | undefined): number => {
  if (!ratingString) {
    return 0;
  }
  // Remove any non-numeric characters except dot
  const cleanedRating = ratingString.replace(/[^0-9.]/g, "");

  // Convert to a floating-point number
  const rating = parseFloat(cleanedRating);

  // Ensure valid number and round to one decimal place
  return isNaN(rating) ? 0 : parseFloat(rating.toFixed(1));
};

// If the modules have a metadata property with a sortOrder array, sort the submodules based on the order
export const updateSubmoduleOrdering = (modules: Module[]): void => {
  modules.forEach((module) => {
    if (!module.metadata || !module.submodules) return;

    const metadata = JSON.parse(module.metadata);
    if (!metadata.sortOrder) return;

    const order: Record<string, number> = {};
    for (const [index, subModuleType] of metadata.sortOrder.entries()) {
      order[subModuleType] = index;
    }

    module.submodules?.sort((a, b) => {
      const orderA = order[a.submoduleType || ""];
      const orderB = order[b.submoduleType || ""];

      return orderA - orderB;
    });
  });
};

export const convertRequestedSubModulesToModules = (requestedSubModules: RequestedSubModule[]): Module[] => {
  if (!requestedSubModules || requestedSubModules.length === 0) {
    return [];
  }

  return requestedSubModules.map((requestedSubModule) => ({
    id: requestedSubModule.submoduleCatalogId, // Using requestId as the module ID
    name: requestedSubModule.moduleName, // Using submoduleName as the module name
    requestStatus: requestedSubModule.status,
    requestId: requestedSubModule.requestId,
    sortOrder: '', // Placeholder for sortOrder, adjust as needed
    metadata: requestedSubModule.moduleMetadata, // Placeholder for metadata, adjust as needed
  }));
};