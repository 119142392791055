import React from "react";
import { Box, TextField, Typography, MenuItem } from "@mui/material";

interface Option {
  value: string | number;
  label: string;
  icon?: string;
}

interface CustomSelectProps {
  label: string;
  value: string | number;
  onChange: (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: boolean;
  onBlur?: () => void;
  disabled?: boolean;
  options: Option[];
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  value,
  onChange,
  onBlur,
  error = true,
  disabled = false,
  options,
}) => {
  return (
    <Box
      sx={{
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        border: error ? "1px solid #F6465D" : "1px solid #989CA24D",
        background: "white",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Manrope, sans-serif",
          fontSize: "11px",
          lineHeight: "12px",
          padding: '22px 22px 0 22px',
          fontWeight: 700,
          color: error ? "#F6465D" : "#162936",
        }}
      >
        {label}
      </Typography>
      <TextField
        select
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        SelectProps={{
            MenuProps: {
                style: {
                    marginTop: '2px'
                }
            }
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            fontSize: "15px",
            color: "#162936",
            fontWeight: 550,
            lineHeight: "18px",
            letterSpacing: "-0.6px",
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
            "& .MuiSelect-select": {
              padding: '0 40px 22px 22px',
              // display: 'flex',
              // alignItems: 'center',
              // gap: '0.25rem'
            },
            "& .MuiSvgIcon-root": {
                top: 0,
                right: 14
            }
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{
            padding: '1rem',
          }}>
            <Box sx={{
              display: 'flex',
              gap: '0.25rem',
              alignItems: 'center'
            }}>
              <Typography fontSize={14} fontWeight={600} color={"#162936"} lineHeight={'1rem'}>{option.label}</Typography>

              {option.icon && <>
                <img src={option.icon} alt={option.label} />
              </>}
            </Box>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default CustomSelect;