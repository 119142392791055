import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Button,
  useTheme,
  useMediaQuery,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { genericTexts, titles } from "@utils/translations/en";
import ModulesSubModules from "./components/ModulesSubModules";
import { Popover, RadioGroup, SelectWithLabel } from "@components";
import { RootState } from "@stores/store";
import { setToastMessage } from "@stores/App/slice";
import { TOAST_TYPES } from "@constants/constants";
import { NAV_ROUTES } from "@constants/navRoutes";
import useUserModulesById from "@hooks/api/useUserModulesById";
import { UserPayload } from "@api/company";
import useCompany from "@hooks/api/useCompany";
import useUserById from "@hooks/api/useUserById";
import useSubmodulesFilters from "@hooks/api/useSubmodulesFilters";
import useUpdateUser from "@hooks/api/useUpdateUser";
import useInviteUser from "@hooks/api/useInviteUser";
import useDeleteUser from "@hooks/api/useDeleteUser";
import { inputFieldStyles, textFieldStyles } from "@utils/styles";

export type USER_ROLES = "VIEWER" | "ADMIN";

const USER_ROLE_OPTIONS: Array<{ value: USER_ROLES; label: string }> = [
  { value: "VIEWER", label: "Viewer" },
  { value: "ADMIN", label: "Admin" },
];

interface UserSubmoduleMap {
  moduleId?: string;
  submoduleId: string;
  filterIds: string[];
}

interface UserPayloadForm {
  email: string;
  companyId: string;
  username: string;
  userRole: USER_ROLES;
}

const UserDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { id: urlParamUserId } = useParams();
  const dispatch = useDispatch();

  // Redux Store
  const companies = useSelector((state: RootState) => state?.CompanyListData);
  const modules = useSelector((state: RootState) => state?.ModulesData);

  // APIS
  const { getUserModuleDetailsById } = useUserModulesById();
  const { getUserDetailsById } = useUserById();
  const { getSubModulesFilters } = useSubmodulesFilters();
  const { getCompanyList } = useCompany();
  const { editUser } = useUpdateUser();
  const { createUser } = useInviteUser();
  const { deleteUserById } = useDeleteUser();

  // State
  const [mode, setMode] = useState<"CREATE" | "EDIT">("CREATE");
  const [form, setForm] = useState<UserPayloadForm>({
    email: "",
    companyId: "",
    username: "",
    userRole: "VIEWER",
  });
  const [isUserUpdating, setIsUserUpdating] = useState<boolean>(false);
  const [submoduleList, setSubmoduleList] = useState<UserSubmoduleMap[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  // useEffect Hooks
  useEffect(() => {
    if (!companies?.companyList?.length) {
      getCompanyList();
    }
  }, []);

  useEffect(() => {
    if (!urlParamUserId) {
      // CREATE/INVITE MODE
      setMode("CREATE");
    } else {
      // EDIT MODE
      fetchUserDetails(urlParamUserId);
      fetchUserModules(urlParamUserId);
      setMode("EDIT");
    }
  }, [urlParamUserId]);

  useEffect(() => {
    if (modules.loadCount === 0) return;
    if (modules.filtersFetched) return;

    getSubModulesFilters(modules.modules);
  }, [modules]);

  const fetchUserDetails = async (id: string) => {
    const user = await getUserDetailsById(id);

    if (user) {
      const _form = {
        ...form,
        email: user.email,
        companyId: user.companyId,
        username: user.username,
        userRole: user.role,
      };
      setForm(_form);
    }
  };

  const fetchUserModules = async (id: string) => {
    const response = await getUserModuleDetailsById(id);

    if (response && response.data) {
      const { data: selectedModules } = response;

      const submoduleList: UserSubmoduleMap[] = [];
      for (const module of selectedModules) {
        for (const submodule of module.submoduleCatalogs) {
          const selectedFilters = submodule.submoduleFilters.flatMap(
            (filterType) =>
              filterType.submoduleFilterRoles.map((filter) => filter.id)
          );

          submoduleList.push({
            moduleId: module.id,
            submoduleId: submodule.id,
            filterIds: selectedFilters,
          });
        }
      }

      setSubmoduleList(submoduleList);
    }
  };

  // Form Functions
  const handleFormChange = (field: string, value: string) => {
    const updatedValue = {
      ...form,
      [field]: value,
    };

    if (field === "userRole" && value === "ADMIN") {
      setSubmoduleList([]);
    }

    setForm(updatedValue);
  };

  // CTA Handlers
  const deleteUser = async (id: string | undefined) => {
    if (!id) return;

    const res = await deleteUserById({ id });
    dispatch(
      setToastMessage({
        type: Boolean(res?.code) ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR,
        message: Boolean(res?.code)
          ? `${form.email} deleted !`
          : `Failed to delete ${form.email}, please try again !`,
      })
    );
    setShowDeleteConfirmation(false);
    navigate(NAV_ROUTES.USER_MANAGEMENT);
  };

  const handleSaveClick = async () => {
    setIsUserUpdating(true);

    const payload: UserPayload = {
      ...form,
      submoduleList,
    };

    if (mode === "CREATE") {
      const res = await createUser(payload);
      dispatch(
        setToastMessage({
          type: res?.code ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR,
          message: res?.code
            ? `${payload.email} added !`
            : `Failed to add ${payload.email}, please try again !`,
        })
      );
      navigate(NAV_ROUTES.USER_MANAGEMENT);
    } else {
      payload["id"] = urlParamUserId;
      const res = await editUser(payload);
      dispatch(
        setToastMessage({
          type: Boolean(res?.code) ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR,
          message: Boolean(res?.code)
            ? `${payload.email} updated !`
            : `Failed to update ${payload.email}, please try again !`,
        })
      );
      navigate(NAV_ROUTES.USER_MANAGEMENT);
    }

    setIsUserUpdating(false);
  };

  return (
    <>
      <Box
        id="user_details_container"
        sx={{
          height: "100%",
          width: "100%",
          p: 0,
          m: 0,
        }}
      >
        <Box
          sx={{
            height: `calc(100vh - ${isMobile ? "190" : "170"}px)`,
            overflow: "auto",
            pb: 3,
            pt: "1rem",
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"} mb={"1rem"}>
            <Typography
              sx={{
                color: "#162936",
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              {titles.USER_DETAILS}
            </Typography>

            {mode === "EDIT" && (
              <Button
                onClick={() => setShowDeleteConfirmation(true)}
                startIcon={
                  <DeleteOutlineIcon
                    htmlColor={theme.palette.error.main}
                    sx={{
                      height: "1rem",
                      width: "1rem",
                      cursor: "pointer",
                      backgroundColor: "#F7F7F7",
                      borderRadius: isMobile ? 3 : 20,
                    }}
                  />
                }
              >
                <Typography
                  sx={{
                    textTransform: "none",
                  }}
                  color={theme.palette.error.main}
                  fontSize={"0.875rem"}
                  fontWeight={700}
                >
                  Delete User
                </Typography>
              </Button>
            )}
          </Stack>

          <Box
            sx={{
              display: "grid",
              gap: "0.875rem",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "auto auto",
            }}
          >
            <Box sx={{ gridColumn: isMobile ? "span 2" : "span 1" }}>
              <SelectWithLabel
                label="Company ."
                labelId="company-select-label"
                menuItems={companies.companyList.map((company) => ({
                  label: company.name,
                  value: company.companyId,
                }))}
                onChange={(value) => handleFormChange("companyId", value)}
                isAddNew={true}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400,
                    },
                  },
                }}
                value={form.companyId}
              />
            </Box>

            <Box sx={{ gridColumn: isMobile ? "span 2" : "span 1" }}>
              <TextField
                sx={{
                  ...textFieldStyles,
                  "input": {
                    ...inputFieldStyles,
                  },
                  width: "100%"
                }}
                value={form.email}
                onChange={(e) => handleFormChange("email", e.target.value)}
                label="Email"
                placeholder="Email"
                disabled={mode === "EDIT"}
              />
            </Box>

            <Box sx={{ gridColumn: isMobile ? "span 2" : "span 1" }}>
              <TextField
                sx={{
                  ...textFieldStyles,
                  "input": {
                    ...inputFieldStyles,
                  },
                  width: "100%"
                }}
                value={form.username}
                onChange={(e) => handleFormChange("username", e.target.value)}
                label="Username"
                placeholder="Username"
              />
            </Box>

            <Box
              sx={{
                gridColumn: "span 2",
                ...(isMobile
                  ? {
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }
                  : {}),
              }}
            >
              <RadioGroup
                value={form.userRole}
                options={USER_ROLE_OPTIONS}
                name="admin_viewer_radio"
                row
                disabled={mode === "EDIT"}
                handleChange={(value: string) =>
                  handleFormChange("userRole", value)
                }
              />
            </Box>
          </Box>

          <ModulesSubModules
            userRole={form.userRole}
            setSubmoduleList={setSubmoduleList}
            submoduleList={submoduleList}
          />
        </Box>

        {/* Form Buttons */}
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            height: 73,
            width: "calc(100% + 24px)",
            left: "-24px",
            border: "2px solid #2F736E1F",
            px: 4,
            py: 2,
            display: "flex",
            flexDirection: "row-reverse",
            gap: 2,
            background: theme.palette.common.white,
          }}
        >
          <Button
            variant="contained"
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{
              background: theme.palette.primary.main,
              borderRadius: "100px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: 700,
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              padding: "10px 24px",
              color: "#F6F8F9",
              "&:hover": {
                background: theme.palette.primary.main,
              },
              textTransform: "none",
              display: "flex",
              gap: "0.5rem",
              width: "5.063rem",
            }}
            disabled={
              isUserUpdating ||
              form.email === "" ||
              form.username === "" ||
              form.companyId === "" ||
              submoduleList.filter((item) => !!!item.submoduleId).length > 0
            }
            onClick={handleSaveClick}
          >
            {isUserUpdating ? (
              <CircularProgress
                style={{
                  width: "1rem",
                  height: "1rem",
                  color: theme.palette.primary.main,
                }}
              />
            ) : (
              genericTexts.Save
            )}
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate(NAV_ROUTES.USER_MANAGEMENT)}
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: 700,
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              padding: "10px 24px",
              border: "1px solid #2E4A4F",
              borderRadius: "100px",
              color: "#6C6C6C",
              "&:hover": {
                borderColor: theme.palette.secondary.main,
              },
              textTransform: "none",
            }}
          >
            {genericTexts.Cancel}
          </Button>
        </Box>
      </Box>

      {showDeleteConfirmation && (
        <Popover
          title="Delete user"
          subTitle="Are you sure you want to delete this user ?"
          primaryButton="Delete"
          onClose={() => setShowDeleteConfirmation(false)}
          onAction={() => deleteUser(urlParamUserId)}
        />
      )}
    </>
  );
};

export default UserDetails;
