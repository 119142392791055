import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { RootState } from "@stores/store";
import { genericTexts } from "@utils/translations/en";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DrawerComponent from "../../../../components/Drawer/Drawer";
import FilterSection from "./FilterSection";
import { SubmoduleFilterMap } from "@stores/Modules/types/modules";
import { UserSubmoduleMap } from "@api/company";
import { USER_ROLES } from "../UserDetails";
import { inputFieldStyles, menuItemStyles, selectStyles } from "@utils/styles";

export interface ModulesSubModulesProps {
  submoduleList: UserSubmoduleMap[];
  setSubmoduleList: (value: UserSubmoduleMap[]) => void;
  userRole: USER_ROLES;
}

export interface FilterOptions {
  filterOptions: string[];
  selectedFilters: Set<string>;
  filterMap: SubmoduleFilterMap | null;
}

export interface SubModuleData {
  moduleName: string;
  subModuleOptions: Array<{ label: string; value: string }>;
  selectedSubModules: Set<string>;
  filters: Record<string, FilterOptions>;
}

export interface ModuleData {
  moduleOptions: Array<{ label: string; value: string }>;
  selectedModules: Set<string>;
  modules: Record<string, SubModuleData>;
}

const ModulesSubModules: FC<ModulesSubModulesProps> = ({
  submoduleList,
  setSubmoduleList,
  userRole,
}) => {
  // Theme
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Redux
  const modulesState = useSelector((state: RootState) => state?.ModulesData);

  // State
  const [moduleData, setModuleData] = useState<ModuleData>({
    modules: {},
    moduleOptions: [],
    selectedModules: new Set(),
  });
  const [openDrawer, toggleOpenDrawer] = useState(false);
  const [selectedModule, setSelectedModule] = useState<string>("");
  const [selectedModuleName, setSelectedModuleName] = useState<string>("");
  const [selectedSubModule, setSelectedSubModule] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  // Use Effects
  useEffect(() => {
    if (modulesState.loadCount === 0 || !modulesState.filtersFetched) return;

    const modulesMap: Record<string, SubModuleData> = {};

    const moduleOptions: { label: string; value: string }[] =
      modulesState.modules.map((module) => {
        const subModuleOptions = (module.submodules || []).map((subModule) => ({
          label: subModule.name,
          value: subModule.id,
        }));

        const filters: Record<string, FilterOptions> = (
          module.submodules || []
        ).reduce((acc, subModule) => {
          let subModuleFilters: SubmoduleFilterMap = {};
          if (subModule.filters?.length) {
            for (const filter of subModule.filters) {
              const { submoduleFilterName } = filter;
              if (!subModuleFilters[submoduleFilterName]) {
                subModuleFilters[submoduleFilterName] = [];
              }

              subModuleFilters[submoduleFilterName].push(filter);
            }
          } else {
            subModuleFilters = {};
          }

          acc[subModule.id] = {
            filterOptions: (subModule.filters || []).map(
              (filter) => filter.filterValue
            ),
            selectedFilters: new Set(),
            filterMap: subModuleFilters,
          };
          return acc;
        }, {} as Record<string, FilterOptions>);

        modulesMap[module.id] = {
          moduleName: module.name,
          subModuleOptions,
          selectedSubModules: new Set(),
          filters,
        };
        return { label: module.name, value: module.id };
      });

    const selecteModules = new Set<string>();

    for (const submodule of submoduleList) {
      const { moduleId, submoduleId, filterIds } = submodule;

      if (moduleId) {
        selecteModules.add(moduleId);
        modulesMap[moduleId].selectedSubModules.add(submoduleId);
        modulesMap[moduleId].filters[submoduleId].selectedFilters = new Set(
          filterIds
        );
      }
    }

    setModuleData({
      moduleOptions,
      selectedModules: new Set(),
      modules: modulesMap,
    });
  }, [modulesState]);

  const handleModuleSelection = (moduleId: string, index: number) => {
    const _submoduleList = [...submoduleList];
    const { moduleId: previousModuleId, submoduleId: previousSubModuleId } =
      _submoduleList[index];
    const _moduleData = { ...moduleData };

    if (previousModuleId) _moduleData.selectedModules.delete(previousModuleId);
    if (previousSubModuleId) {
      _moduleData.modules[previousModuleId as string].selectedSubModules.delete(
        previousSubModuleId
      );
    }

    if (previousSubModuleId) {
      _moduleData.modules[previousModuleId as string].selectedSubModules.delete(
        previousSubModuleId
      );
    }

    _submoduleList[index].moduleId = moduleId;
    _submoduleList[index].submoduleId = "";
    _submoduleList[index].filterIds = [];

    setSubmoduleList(_submoduleList);
    setModuleData({
      ..._moduleData,
      selectedModules: new Set([..._moduleData.selectedModules, moduleId]),
    });
  };

  const handleSubModuleSelection = (subModuleId: string, index: number) => {
    const _submoduleList = [...submoduleList];
    const _moduleData = { ...moduleData };

    const { moduleId, submoduleId: previousSubModuleId } =
      _submoduleList[index];
    if (previousSubModuleId)
      _moduleData.modules[moduleId as string].selectedSubModules.delete(
        previousSubModuleId
      );

    _submoduleList[index].submoduleId = subModuleId;
    _submoduleList[index].filterIds = [];

    setSubmoduleList(_submoduleList);
    setModuleData({
      ..._moduleData,
      modules: {
        ..._moduleData.modules,
        [moduleId as string]: {
          ..._moduleData.modules[moduleId as string],
          selectedSubModules: new Set([
            ..._moduleData.modules[moduleId as string].selectedSubModules,
            subModuleId,
          ]),
        },
      },
    });
  };

  const handleDeleteRow = (index: number) => {
    const _submoduleList = [...submoduleList];
    const _moduleData = { ...moduleData };

    // Remove the module and sub-module from the sets
    const { moduleId, submoduleId } = _submoduleList[index];
    if (moduleId) _moduleData.selectedModules.delete(moduleId);
    if (submoduleId)
      _moduleData.modules[moduleId as string].selectedSubModules.delete(
        submoduleId
      );

    // Remove the module and sub-module from the selectedModules array
    _submoduleList.splice(index, 1);
    setSubmoduleList(_submoduleList);
    setModuleData(_moduleData);
  };

  const handleFiltersClick = (index: number) => {
    setSelectedModule(submoduleList[index].moduleId as string);
    setSelectedModuleName(
      moduleData.modules[submoduleList[index].moduleId as string]?.moduleName
    );
    setSelectedSubModule(submoduleList[index].submoduleId);
    setSelectedFilters(submoduleList[index].filterIds);
    setSelectedIndex(index);
    toggleOpenDrawer(true);
  };

  const handleFilterSave = (
    data: {
      type: string;
      options: { id: string; name: string; position: number }[];
    }[]
  ) => {
    const _submoduleList = [...submoduleList];

    if (selectedIndex !== -1 && data.length) {
      _submoduleList[selectedIndex].filterIds = data[0].options.map(
        (filter: { id: string; name: string; position: number }) => filter.id
      );
    }

    setSubmoduleList(_submoduleList);
  };

  const addNewModuleSelection = () => {
    setSubmoduleList([
      ...submoduleList,
      { moduleId: "", submoduleId: "", filterIds: [] },
    ]);
  };

  return (
    <Stack gap={"1rem"} mt={"1rem"}>
      <Typography fontSize={"1rem"} fontWeight={500} color={"#6B7280"}>
        Module Details
      </Typography>

      {submoduleList.map(({ moduleId, submoduleId }, index) => (
        <Box key={`${index}--${moduleId}--${submoduleId}`}>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr 40px" // 1fr for each Select, 40px for IconButton
            gap="1rem"
            alignItems="center"
          >
            <FormControl sx={{
              ...(isMobile
                ? {
                    gridColumn: "span 4",
                  }
                : {}),
            }} fullWidth>
              <InputLabel sx={{
                fontSize: "14px",
              }} id={"module-label-" + index}>
                Module
              </InputLabel>
              <Select
                labelId={"module-label-" + index}
                label="Module"
                value={moduleId}
                onChange={(e) => handleModuleSelection(e.target.value, index)}
                fullWidth
                sx={{
                  ...selectStyles,
                  height: "56px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {moduleData.moduleOptions
                  .filter(
                    (option) =>
                      option.value === moduleId ||
                      moduleData.modules[option.value]?.subModuleOptions
                        .length !==
                        moduleData.modules[option.value]?.selectedSubModules.size
                  )
                  .map((option) => (
                    <MenuItem
                      key={`${index}--${option.value}`}
                      value={option.value}
                      sx={{
                        ...menuItemStyles,
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{
              ...(isMobile
                ? {
                    gridColumn: "span 4",
                  }
                : {}),
            }}>
              <InputLabel sx={{
                fontSize: "14px",
              }} id={"submodule-label-" + index}>Sub Module</InputLabel>

              <Select
                value={submoduleId}
                onChange={(e) => handleSubModuleSelection(e.target.value, index)}
                fullWidth
                labelId={"submodule-label-" + index}
                label="Sub Module"
                disabled={
                  !moduleId ||
                  moduleData.modules[moduleId as string]?.subModuleOptions
                    .length === 0
                }
                sx={{
                  ...selectStyles,
                  height: "56px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {moduleData.modules[moduleId as string]?.subModuleOptions
                  .filter(
                    (option) =>
                      option.value === submoduleId ||
                      !moduleData.modules[
                        moduleId as string
                      ].selectedSubModules.has(option.value)
                  )
                  .map((option) => (
                    <MenuItem
                      key={`${index}--${option.value}`}
                      value={option.value}
                      sx={{
                        ...menuItemStyles
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Box
              display="flex"
              justifyContent="space-between"
              onClick={() =>
                moduleData.modules[submoduleList[index].moduleId as string]
                  ?.filters[submoduleList[index].submoduleId]?.filterOptions
                  .length && handleFiltersClick(index)
              }
              sx={{
                ...selectStyles,
                border: "1px solid rgba(0, 0, 0, 0.23)",
                height: "56px",
                alignItems: "center",
                color: "#00000080",
                ...(isMobile
                  ? {
                      gridColumn: "span 3",
                    }
                  : {}),
                opacity: moduleData.modules[
                  submoduleList[index].moduleId as string
                ]?.filters[submoduleList[index].submoduleId]?.filterOptions
                  .length
                  ? "1"
                  : "0.5",
                cursor: moduleData.modules[
                  submoduleList[index].moduleId as string
                ]?.filters[submoduleList[index].submoduleId]?.filterOptions
                  .length
                  ? "pointer"
                  : "not-allowed",
              }}
            >
              <Typography
                sx={{
                  ...inputFieldStyles
                }}
              >
                {submoduleList[index].filterIds.length
                  ? `${submoduleList[index].filterIds.length} Selected`
                  : "Select Filters"}
              </Typography>

              <ArrowRightIcon sx={{ color: "#49454F" }} />
            </Box>

            <IconButton
              onClick={() => handleDeleteRow(index)}
              sx={{
                width: "40px",
                ":hover": {
                  background: "none",
                },
              }}
            >
              <DeleteOutlineIcon
                sx={{ height: "1.5rem", width: "1.5rem" }}
                htmlColor={theme.palette.error.main}
              />
            </IconButton>
          </Box>

          {isMobile && submoduleList.length !== index + 1 && (
            <Divider sx={{ mt: "1rem" }} />
          )}
        </Box>
      ))}

      <Box>
        <Button
          onClick={addNewModuleSelection}
          variant="contained"
          disabled={
            userRole === "ADMIN" ||
            submoduleList.filter((item) => !!!item.submoduleId).length > 0
          }
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "#F6F8F9",
            borderRadius: "100px",
            textTransform: "capitalize",
            px: 4,
            py: 1,
            "&:hover": { backgroundColor: theme.palette.primary.main },
          }}
          startIcon={<AddIcon />}
        >
          {genericTexts.addNewModule}
        </Button>
      </Box>

      {openDrawer && (
        <DrawerComponent
          open={openDrawer}
          anchor="right"
          close={(e) => {
            toggleOpenDrawer(false);
          }}
          height="98vh"
          zIndex={9999}
        >
          <FilterSection
            moduleName={selectedModuleName || ""}
            moduleId={selectedModule || ""}
            subModuleId={selectedSubModule || ""}
            filterMap={
              moduleData.modules[selectedModule]?.filters[selectedSubModule]
                ?.filterMap
            }
            close={() => toggleOpenDrawer(false)}
            handleFilterSave={(data) => handleFilterSave(data)}
            selectedFilters={selectedFilters}
          />
        </DrawerComponent>
      )}
    </Stack>
  );
};

export default ModulesSubModules;
