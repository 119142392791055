import { FC, MouseEventHandler, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import { FormControl, InputLabel, MenuItem, Select, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, SelectProps, useTheme } from "@mui/material";
import useCreateCompany from "../../hooks/api/useCreateCompany";
import { inputFieldStyles, menuItemStyles } from "@utils/styles";

type CustomSelectProps = {
  label: string;
  labelId: string;
  menuItems?: Array<{ label: string; value: string }>;
  Icon?: JSX.Element;
  defaultValue?: string;
  onClick?: MouseEventHandler;
  onChange?: (value: any) => void;
  isAddNew?: boolean;
  disabled?: boolean;
};

const SelectWithLabel: FC<CustomSelectProps & SelectProps> = (props) => {
  const {
    label,
    labelId,
    menuItems,
    Icon = <ArrowDropDownIcon />,
    defaultValue,
    onClick = () => { },
    onChange = () => { },
    isAddNew,
    value,
    disabled = false,
    ...rest
  } = props;

  const theme = useTheme();
  const { addCompany } = useCreateCompany();

  const [openDialog, setOpenDialog] = useState(false);
  const [newValue, setNewValue] = useState("");

  const handleAddNewClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogSubmit = async () => {
    if (newValue) {
      const res = await addCompany({ name: newValue });
      onChange(res.id);
      setNewValue(res.name);
    }
    setOpenDialog(false);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel
          id={labelId}
          sx={{
            color: '#6C6C6C',
            '&.Mui-focused': {
              color: theme.palette.primary.main,
            },
          }}
        >
          {label}
        </InputLabel>
        <Select
          {...rest}
          label={label}
          labelId={labelId}
          onClick={onClick}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          sx={{
            borderRadius: "8px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#d6d6d6", // Default border color
                borderRadius: "8px",
              },
              "&:hover fieldset": {
                borderColor: "#d6d6d6", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#d6d6d6", // Border color when focused
              },
            },
            ".MuiSvgIcon-root ": {
              fill: "#2F736E !important",
            },
            ...inputFieldStyles
          }}
          inputProps={{
            MenuProps: {
              sx: {
                maxHeight: '20rem',
                '& .MuiMenu-list': {
                  backgroundColor: '#F0F0F1',
                  color: '#162936',
                  padding: 0
                },
                '& .Mui-selected': {
                  backgroundColor: '#51B8B026 !important',
                  '&:hover': {
                    backgroundColor: '#51B8B04D !important',
                  },
                },
              }
            },
          }}
          {...((Icon && { IconComponent: () => Icon }) || {})}
        >

          {isAddNew && (
              <MenuItem
                onClick={handleAddNewClick}
                sx={{
                  ...menuItemStyles,
                  color: '#51B8B0',
                }}
                >
                <AddIcon sx={{width: '18px', height: '18px'}}/>
                Create New
              </MenuItem>
          )}
          {menuItems?.map(({ label, value }) => (
            <MenuItem
              key={value}
              value={value}
              onClick={() => onChange(value)}
              sx={{
                ...menuItemStyles,
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '35%',
            padding: 3,
            borderRadius: '28px'
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: 700, color: '#162936', fontSize: '1.5rem', padding: 0 }}>
          Add Company
        </DialogTitle>
        <DialogContent sx={{padding: 0}}>
          <TextField
            autoFocus
            margin="dense"
            label="Company Name"
            type="text"
            fullWidth
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            sx={{ 
              marginTop: '1rem', 
              marginBottom: '1.5rem',
              "& .MuiInputLabel-root.Mui-focused": {
                    color: theme.palette.primary.main,
              },
              "& .MuiInputBase-root": {
                    color: "#162936",
                    fontSize: "1rem",
                    fontWeight: 550,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: theme.palette.primary.main,
                 },
                "&:hover fieldset": {
                  borderColor: theme.palette.primary.main,
                 },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary.main,
                 },
                },
             }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            px: 2
          }}
        >
          <Button
            variant="outlined"
            sx={{ 
              fontFamily: 'Manrope, sans-serif',
              fontWeight: 700,
              fontSize: '0.875rem',
              lineHeight: '1.25rem',
              padding: '10px 24px',
              border: 'none',
              borderRadius: "100px",
              color: '#51B8B0',
              '&:hover': {
                border: 'none',
              },
              textTransform: 'none'
            }}
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              background: theme.palette.primary.main,
              borderRadius: "100px",
              fontFamily: 'Manrope, sans-serif',
              fontWeight: 700,
              fontSize: '0.875rem',
              lineHeight: '1.25rem',
              padding: '10px 24px',
              color: '#F6F8F9',
              '&:hover': {
                background: theme.palette.primary.main,
              },
              textTransform: 'none'
            }}
            onClick={handleDialogSubmit}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectWithLabel;
