import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@stores/store";
import { FullPageLoader, GenericTable } from "@components";
import {
    Box,
    Button,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import FundingAllocation from "../components/FundingDetails/FundingAllocation";
import FundingDetailsAdminFilters from "../components/FundingDetails/FundingDetailsAdminFilters";
import FundingRequestAdminSidePanel from "../components/FundingDetails/FundingRequestAdminSidePanel";
import { processRowDataWithMetadata } from "../../../components/GenericTable/tableDataUtil";
import { formatCurrency } from "@utils/common";
import {
    FUNDING_TYPES,
    INVESTMENT_STATUS,
    INVESTMENT_STATUS_VALUES,
    useLazyGetInvestmentAdminRequestByIdQuery,
    useLazyGetInvestmentRequestAllocationByGameIdQuery,
    useLazyGetInvestmentRequestGameFiltersQuery,
    useLazyGetInvestmentRequestsQuery
} from "@api/investments";
import useCharts from "@hooks/api/useCharts";
import useChartById from "@hooks/api/useChartById";
import Pagination from "../components/Pagination";
import TransactionTypesSidePanel from "../components/InvestmentPerformance/TransactionTypesSidePanel";
import { setDashboardFilter } from "@stores/DashboardFilters/slice";
import { PaginationConfig } from "@utils/types/PaginationConfig";

export interface Filter {
    key: string;
    label: string;
    values: { id: string; label: string }[];
}

const requestsTableMetadata = {
    "chartType": "TABLE",
    "columns": [
        {
            "id": "settlementDate",
            "name": "Settlement Date",
            "type": "string"
        },
        {
            "id": "cohort",
            "name": "Cohort",
            "type": "string"
        },
        {
            "id": "gameName",
            "name": "Deal Name",
            "type": "string"
        },
        {
            "id": "expectedSpend",
            "name": "Growth Spend",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "requestedFund",
            "name": "Investment Amt.",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "adjGrowthSpend",
            "name": "Adj. Growth Spend",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "newInvAmount",
            "name": "New Inv. Amount",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "fundingPercentage",
            "name": "Funding %",
            "type": "percentage"
        },
        {
            "id": "allocatedFund",
            "name": "Allocated Amount",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        },
        {
            "id": "allocationType",
            "name": "Request Type",
            "type": "string",
        },
        {
            "id": "status",
            "name": "Status",
            "type": "string"
        },
    ]
};

const requestsTableMappedColumns = [
    {
        "key": "settlementDate",
        "label": "Settlement Date"
    },
    {
        "key": "cohort",
        "label": "Cohort"
    },
    {
        "key": "gameName",
        "label": "Deal Name"
    },
    {
        "key": "expectedSpend",
        "label": "Growth Spend"
    },
    {
        "key": "requestedFund",
        "label": "Investment Amt."
    },
    {
        "key": "adjGrowthSpend",
        "label": "Adj. Growth Spend"
    },
    {
        "key": "newInvAmount",
        "label": "New Inv. Amount"
    },
    {
        "key": "fundingPercentage",
        "label": "Funding %"
    },
    {
        "key": "allocatedFund",
        "label": "Allocated Amount"
    },
    {
        "key": "allocationType",
        "label": "Request Type"
    },
    {
        "key": "status",
        "label": "Status"
    }
];

const allocatonTableMetadata = {
    "chartType": "TABLE",
    "columns": [
        {
            "id": "settlementDate",
            "name": "Settlement Date",
            "type": "string"
        },
        {
            "id": "investors",
            "name": "Investor",
            "type": "string"
        },
        {
            "id": "allocationType",
            "name": "Type",
            "type": "string",
            "icons": [
                {
                    "type": "conditional",
                    "position": "left",
                    "condition": "row.amount>0",
                    "location": "yellow_icon.svg"
                }
            ]
        },
        {
            "id": "cohort",
            "name": "Cohort",
            "type": "string"
        },
        {
            "id": "amount",
            "name": "Amount",
            "type": "currency",
            "currencyFormat": {
                "currency": "dollar",
                "scale": 2
            }
        }
    ]
};

const allocationTableMappedColumns = [
    { key: "settlementDate", label: "Settlement Date" },
    { key: "investors", label: "Investor" },
    { key: "allocationType", label: "Type" },
    { key: "cohort", label: "Cohort" },
    { key: "amount", label: "Amount" },
];

const PAGE_SIZE = 25;

const FundingDetailsAdmin: FC<{ id: string }> = ({ id }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const dashboardFilters = useSelector((state: RootState) => state?.DashboardFiltersData);
    const fundingDetailsAdminSelections = dashboardFilters.dashboards["FundingDetailsAdmin" as any];

    const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);
    const [isDataLoading, setDataLoading] = useState<boolean>(true);
    const [ledgerLoader, setLedgerLoader] = useState<boolean>(true);
    const [showFundingRequestPanel, setShowFundingRequestPanel] = useState(false);
    const [selections, setSelections] = useState<Record<string, string>>(fundingDetailsAdminSelections || { gameId: "All" });
    const [filters, setFilters] = useState<Filter[]>([]);
    const [isOpenRequests, setOpenRequests] = useState<boolean>(false);
    const [selectedGameReq, setSelectedGameReq] = useState<any | null>(null);
    const [selectedRequest, setSelectedRequest] = useState<string>("");
    const [investmentRequests, setInvestmentRequests] = useState([]);
    const [investmentRequestById, setInvestmentRequestIById] = useState([]);
    const [allocationsByGameId, setAllocationsByGameId] = useState([]);
    const [showEditAllocations, setShowEditAllocations] = useState(false);
    const [dashboardData, setDashboardData] = useState<
        { id: string; name: string; chartType: string, metadata: string }[]
    >([]);
    const [chartData, setChartData] = useState<
        { id: string; name: string; columns: any[]; rows: any[]; meta: any }[]
    >([]);
    const [pageState, setPageState] = useState<Record<string, number>>({});
    const [showTransactionTypesSidePanel, setShowTransactionTypesSidePanel] = useState(false);
    const [fundingRequestsPagination, setFundingRequestsPagination] = useState<PaginationConfig>({
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        total: 0
    });

    const [getInvestmentRequests] = useLazyGetInvestmentRequestsQuery();
    const [getInvestmentAdminRequestById] = useLazyGetInvestmentAdminRequestByIdQuery();
    const [getInvestmentRequestGameFilters] = useLazyGetInvestmentRequestGameFiltersQuery();
    const [getInvestmentRequestAllocationByGameId] = useLazyGetInvestmentRequestAllocationByGameIdQuery();
    const { getChartData } = useCharts();
    const { getChartDataById } = useChartById();

    const chart = chartData.find((chart) => chart.name === "Revenue Ledger");
    const gameObject: Record<string, string> = { gameId: selectedGameReq?.gameId || "" };
    const currentPage = pageState["Revenue Ledger"] || 1;
    const paginatedRows = chart?.rows?.slice(
        (currentPage - 1) * PAGE_SIZE,
        currentPage * PAGE_SIZE
    ) || [];

    // Hook to fetch Ledger chart data
    useEffect(() => {
        if (id) {
            getChartData(id).then((response) => {
                if (response) {
                    setDashboardData(response);
                }
            });
        }
    }, [id]);

    // Hook to fetch dashboard data
    useEffect(() => {
        if (selectedGameReq?.gameId) {
            fetchDashboardData({ gameId: selectedGameReq.gameId || "", settlementDate: selectedGameReq.settlementDate || "" });
        }
    }, [dashboardData, id, dashboardLoader, selectedGameReq]);

    // Hook to fetch dashboard data based on filter changes
    useEffect(() => {
        if (Object.keys(selections).length > 0 && Object.keys(filters).length && !selectedGameReq?.gameId) {
            getAllRequests();
        }
    }, [filters, selections, isOpenRequests, selectedGameReq]);

    // Hook to update filter options on load
    useEffect(() => {
        const getFilters = async () => {
            const gameResponse = await getInvestmentRequestGameFilters({}).unwrap();

            const gameValues = [
                { id: "All", label: "All Deals" },
                ...gameResponse.data.map((game: any, index: number) => ({
                    id: game.gameId,
                    label: game.gameName,
                }))
            ];

            setFilters([{
                key: "gameId",
                label: "Games",
                values: gameValues,
            }]);
        };

        getFilters();
    }, []);

    // Hook to fetch all investment requests by gameId
    useEffect(() => {
        if (selectedGameReq?.gameId) {
            getRequestById();
        } else {
            setInvestmentRequestIById([]);
        }
    }, [selectedGameReq, isOpenRequests]);

    // Hook to fetch allocation for selectedGameReq
    useEffect(() => {
        if (
            selectedGameReq?.id
            && (
                selectedGameReq?.fundingAdjustmentReported
                || (
                    !selectedGameReq?.fundingAdjustmentReported
                    && ![INVESTMENT_STATUS.REJECTED, INVESTMENT_STATUS.PENDING_CONTRACT_CREATION, INVESTMENT_STATUS.PENDING_SIGNATURE].includes(selectedGameReq?.status)
                )
            )
        ) {
            getAllocationData();
        }
    }, [selectedGameReq]);

    useEffect(() => {
        if (Boolean(selectedRequest)) {
            setShowFundingRequestPanel(true)
        } else {
            setShowFundingRequestPanel(false)
        }
    }, [selectedRequest]);

    useEffect(() => {
        if (!showFundingRequestPanel) {
            setSelectedRequest("");
        }
    }, [showFundingRequestPanel]);

    const getRequestTypeIndicatorColor = (row: any) => {
        return row.fundingAdjustmentReported
            ? (Number(row.allocatedAdjustment) > 0 ? "#601410" : "#FFA726")
            : "#2E736E"
    };

    const conditionallyMappedRequestsTableColumns = requestsTableMappedColumns.map((col: any) => ({
        ...col,
        ...(col.key === 'gameName' && {
            render: (key: string, row: any) => {
                const val = selectedGameReq?.gameName || row.gameName || row.gameId;

                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "left"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {`${val}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'expectedSpend' && {
            render: (key: string, row: any) => {
                const val = row.expectedSpend || 0;

                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'requestedFund' && {
            render: (key: string, row: any) => {
                const val = row.requestedFund || 0;

                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'adjGrowthSpend' && {
            render: (key: string, row: any) => {
                const val = row.actualSpend || 0;

                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'newInvAmount' && {
            render: (key: string, row: any) => {
                const val = (row?.requestedAdjustment ? (row.allocatedFund + row.requestedAdjustment) : row.allocatedFund) || 0;

                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'allocatedFund' && {
            render: (key: string, row: any) => {
                const val = (row?.allocatedAdjustment ? (row.allocatedFund + row.allocatedAdjustment) : row.allocatedFund) || 0;
                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "right"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {`${val ? formatCurrency(val) : "-"}`}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'allocationType' && {
            render: (key: string, row: any) => {
                return (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            textAlign: "left"
                        }}>
                        <Box
                            sx={{
                                height: '0.85rem',
                                width: '0.85rem',
                                borderRadius: '0.25rem',
                                background: getRequestTypeIndicatorColor(row),
                                m: "0.5rem"
                            }}
                        />
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {row.fundingAdjustmentReported
                                ? (Number(row.allocatedAdjustment) > 0 ? "Overspend" : "Underspend")
                                : "New"}
                        </Typography>
                    </Box>
                );
            }
        }),
        ...(col.key === 'status' && {
            render: (key: string, row: any) => {
                return (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "left"
                        }}>
                        <Typography
                            fontSize={"0.875rem"}
                            fontWeight={550}
                        >
                            {INVESTMENT_STATUS_VALUES[row.status as keyof typeof INVESTMENT_STATUS_VALUES]}
                        </Typography>
                    </Box>
                );
            }
        })
    }));

    const getRequestById = async (requestId?: string) => {
        setDataLoading(true);
        const queryParams = `?sort=cohort&sortOrder=DESC`;

        const res = await getInvestmentAdminRequestById({ id: requestId || selectedGameReq?.id || "", queryParams }).unwrap();

        if (res?.data?.id) {
            const row: any = [res.data];
            setInvestmentRequestIById(row);
        } else {
            setInvestmentRequestIById([]);
        }
        setDataLoading(false);
    };

    const getAllRequests = async (pageNumber: number = 1) => {
        pageNumber = pageNumber - 1;
        let params: string[] = [];
        params.push(`openRequests=${isOpenRequests}`);

        if (filters.length && selections?.gameId && selections.gameId !== "All") {
            params.push(`gameId=${selections.gameId}`);
        }

        const queryParams = params.length ? `?${params.join('&')}&sort=cohort&sortOrder=DESC&pageNumber=${pageNumber}&pageSize=${fundingRequestsPagination.pageSize}` : `?sort=cohort&sortOrder=DESC&pageNumber=${pageNumber}&pageSize=${fundingRequestsPagination.pageSize}`;

        const res = await getInvestmentRequests({ queryParams }).unwrap();

        setFundingRequestsPagination({
            ...fundingRequestsPagination,
            pageNumber: pageNumber + 1,
            total: res.data.totalElements,
        });

        setDashboardLoader(false);
        if (res?.data?.items?.length) {
            setInvestmentRequests(res.data.items);
        } else {
            setInvestmentRequests([]);
        }
    };

    const fetchDashboardData = async (selections?: Record<string, string>) => {
        if (id && dashboardData?.length) {
            const chartsById: any[] = [];
            setLedgerLoader(true);
            try {
                await Promise.all(
                    dashboardData.map(
                        async (chart: {
                            id: string;
                            name: string;
                            chartType: string;
                            metadata: string;
                        }) => {
                            const parsedMetadata = JSON.parse(chart.metadata);
                            const response = await getChartDataById(id, chart.id, selections, parsedMetadata?.sort?.fields, parsedMetadata?.sort?.order);
                            if (response) {
                                const { rows, columns, metadata } = formatChartResponseData(response);
                                chartsById.push({
                                    columns,
                                    rows,
                                    id: chart.id,
                                    name: response.name,
                                    meta: metadata,
                                });
                            }
                        }
                    )
                );
                setChartData(chartsById);
            } catch (error) {
                console.error("Error fetching chart data:", error);
            } finally {
                setLedgerLoader(false);
                setDashboardLoader(false);
            }
        }
    };

    const formatChartResponseData = (response: any) => {
        let columns = [];
        let rows = [];
        const metadata = JSON.parse(response.metadata);
        const { mappedColumns, mappedData } =
            processRowDataWithMetadata(
                response.name,
                metadata,
                response.result.data
            );
        columns = mappedColumns;
        rows = mappedData.map((row: any) => ({
            ...row,
            type: FUNDING_TYPES[row.type] || row.type || "-"
        }));

        return { rows, columns, metadata };
    };

    const handleSelectGame = (id: string) => {
        const request: any = investmentRequests.find((req: any) => req.id === id);
        setSelectedGameReq(request || null);
    };

    const handleFilterChange = (key: string, selectedValue: string) => {
        const updatedSelections = { ...selections, [key]: selectedValue };
        setSelections(updatedSelections);
        dispatch(setDashboardFilter({ "FundingDetailsAdmin": updatedSelections }));
    };

    const handleRequestsRowClick = async (selectedRequestId: string) => {
        await getRequestById(selectedRequestId);
        setSelectedRequest(selectedRequestId);
    }

    const getFundingRequestDetailsById = (selectedRequestId: string) => {
        return investmentRequestById.find((req: any) => req.id === selectedRequestId);
    };

    const handlePageChange = (chartName: string, newPage: number) => {
        setPageState((prev) => ({ ...prev, [chartName]: newPage }));
    };

    const getAllocationData = async () => {
        if (!selectedGameReq?.id) return [];

        const { id } = selectedGameReq;
        const res = await getInvestmentRequestAllocationByGameId({ requestId: id }).unwrap();

        if (!res?.data?.length) setAllocationsByGameId([]);

        // Filter out computed values in allocations
        const allocationData = res.data.filter((itm: any) => !itm.computed).map((item: any, index: number) => ({
            id: `${id}_child_${index}`,
            investors: item.investor,
            allocationType: FUNDING_TYPES[item.investmentType],
            amount: item.amount,
            settlementDate: item.settlementDate,
            cohort: selectedGameReq?.cohort || "",
        }));

        setAllocationsByGameId(allocationData);
    };

    const refreshAllData = (gameId: string, settlementDate: any) => {
        getAllocationData();
        getRequestById();
        fetchDashboardData({ gameId, settlementDate });
    };

    return (
        <>
            {dashboardLoader && <FullPageLoader hasSideNav={true} />}
            <div
                id="pvx-dashboard-container"
                style={{
                    width: "100%",
                    height: "100vh",
                    border: "none",
                    overflow: "auto",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        position: "relative"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Typography
                            fontSize={"2rem"}
                            fontWeight={600}
                            sx={{
                                color: Boolean(selectedGameReq?.gameId) ? '#6C6C6C' : "#1B1C17",
                                mt: "0.5rem",
                                mb: "1.5rem",
                                cursor: Boolean(selectedGameReq?.gameId) ? "pointer" : "auto",
                                width: isMobile && Boolean(selectedGameReq?.gameId) ? "8rem" : "auto"
                            }}
                            onClick={() => setSelectedGameReq(null)}
                        >
                            Funding Requests
                        </Typography>
                        {Boolean(selectedGameReq?.gameId) && (
                            <>
                                <Typography
                                    fontSize={"2rem"}
                                    fontWeight={600}
                                    sx={{ color: "#5A5D72", mt: "0.5rem", mb: "1.5rem", mx: "1rem" }}
                                >
                                    {`>`}
                                </Typography>
                                <Typography
                                    fontSize={"2rem"}
                                    fontWeight={600}
                                    sx={{ color: "#1B1C17", mt: "0.5rem", mb: "1.5rem" }}
                                >
                                    {`${selectedGameReq?.gameName || selectedGameReq.gameId} (${selectedGameReq.cohort})`}
                                </Typography>
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "1.5rem",
                            right: "0rem"
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Manrope, sans-serif",
                                fontWeight: 700,
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                color: "#6C6C6C"
                            }}
                        >
                            $ = USD
                        </Typography>
                    </Box>
                </Box>
                {!Boolean(selectedGameReq?.gameId) ? (
                    <>
                        <FundingDetailsAdminFilters
                            filters={filters}
                            selections={selections}
                            handleFilterChange={handleFilterChange}
                            isOpenRequests={isOpenRequests}
                            setOpenRequests={(val: boolean) => setOpenRequests(val)}
                        />
                        <Box
                            id={"Funding Requests"}
                            key={"Funding Requests"}
                            sx={{
                                width: "100%",
                                height: "fit-content",
                                backgroundColor: "white",
                                borderRadius: "12px",
                                padding: "0.75rem",
                                marginY: "1rem",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                    justifyContent: "space-between",
                                    // marginBottom: "1rem",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        lineHeight: "1.375rem",
                                        fontWeight: 700,
                                        color: "#162936",
                                    }}
                                >
                                    Funding Requests
                                </Typography>
                                <Stack direction={"row"} gap={"0.5rem"}>
                                    <Pagination
                                        page={fundingRequestsPagination.pageNumber}
                                        size={fundingRequestsPagination.pageSize}
                                        total={fundingRequestsPagination.total}
                                        handleChange={(pageNumber) => getAllRequests(pageNumber)}
                                        fileName={""}
                                        chartId={""}
                                        submoduleId={""}
                                        filterSelection={{}}
                                    />

                                    <IconButton onClick={(e: any) => getAllRequests()} sx={{ color: "#2E736E" }}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Stack>
                            </Box>
                            <GenericTable
                                data={
                                    isOpenRequests
                                        ? investmentRequests.filter(
                                            (investmentRequests: any) => [
                                                INVESTMENT_STATUS.PENDING_CONTRACT_CREATION,
                                                INVESTMENT_STATUS.PENDING_SIGNATURE,
                                                INVESTMENT_STATUS.PENDING_ALLOCATION,
                                                INVESTMENT_STATUS.PENDING_APPROVAL,
                                            ].includes(investmentRequests.status)
                                        )
                                        : investmentRequests
                                }
                                columns={conditionallyMappedRequestsTableColumns}
                                meta={requestsTableMetadata}
                                actions={[
                                    {
                                        icon: "table-row-next.svg",
                                        action: (id) => handleSelectGame(id),
                                    }
                                ]}
                                onViewRow={(row: any) => {
                                    handleRequestsRowClick(row.id);
                                }}
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <Box
                            id={"Funding Request"}
                            key={"Funding Request"}
                            sx={{
                                width: "100%",
                                height: "fit-content",
                                backgroundColor: "white",
                                borderRadius: "12px",
                                padding: "0.75rem",
                                marginY: "1rem",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                    justifyContent: "space-between",
                                    marginBottom: "1rem",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        lineHeight: "1.375rem",
                                        fontWeight: 700,
                                        color: "#162936",
                                    }}
                                >
                                    Funding Request
                                </Typography>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <IconButton
                                            onClick={(e: any) => {
                                                getRequestById();
                                                getAllocationData();
                                            }}
                                            sx={{ color: "#2E736E" }}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                        {/* <Pagination
                                        page={currentPage}
                                        size={PAGE_SIZE}
                                        total={chart.rows.length}
                                        handleChange={(newPage) => handlePageChange(name, newPage)}
                                        fileName={chart.name}
                                        chartId={chart.id}
                                        submoduleId={id}
                                        filterSelection={filterSelection}
                                    /> */}
                                    </Box>
                                </Box>
                            </Box>
                            <GenericTable
                                data={investmentRequestById.filter((req: any) => req.gameId === selectedGameReq.gameId) || []}
                                columns={conditionallyMappedRequestsTableColumns}
                                meta={requestsTableMetadata}
                                isLoading={isDataLoading}
                                onViewRow={(row: any) => setSelectedRequest(row.id)}
                            />
                        </Box>
                        {(
                            selectedGameReq?.fundingAdjustmentReported
                            || (
                                !selectedGameReq?.fundingAdjustmentReported
                                && ![INVESTMENT_STATUS.REJECTED, INVESTMENT_STATUS.PENDING_CONTRACT_CREATION, INVESTMENT_STATUS.PENDING_SIGNATURE].includes(selectedGameReq?.status)
                            )
                        ) ? (
                            <Box
                                id={"Funding Request Allocations"}
                                key={"Funding Request Allocations"}
                                sx={{
                                    width: "100%",
                                    height: "fit-content",
                                    backgroundColor: "white",
                                    borderRadius: "12px",
                                    padding: "0.75rem",
                                    marginY: "1rem",
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "1rem",
                                            lineHeight: "1.375rem",
                                            fontWeight: 700,
                                            color: "#162936",
                                        }}
                                    >
                                        Allocations
                                    </Typography>
                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                        {(!([
                                            INVESTMENT_STATUS.REJECTED,
                                            INVESTMENT_STATUS.REJECTED_ADJUSTMENT,
                                            INVESTMENT_STATUS.CLOSED,
                                            INVESTMENT_STATUS.APPROVED,
                                            INVESTMENT_STATUS.PENDING_CONTRACT_CREATION,
                                            INVESTMENT_STATUS.PENDING_SIGNATURE].includes(selectedGameReq?.status))
                                            && !showEditAllocations
                                        ) ? (
                                            <Button
                                                sx={{
                                                    textTransform: "none",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}
                                                onClick={() => setShowEditAllocations(true)}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "#51B8B0",
                                                        fontFamily: "Manrope, sans-serif",
                                                        fontWeight: 800,
                                                        fontSize: "0.85rem",
                                                        lineHeight: "1rem"
                                                    }}
                                                >
                                                    {`${(
                                                        !selectedGameReq?.fundingAdjustmentReported
                                                        && ([INVESTMENT_STATUS.PENDING_ALLOCATION, INVESTMENT_STATUS.PENDING_APPROVAL].includes(selectedGameReq?.status))
                                                    )
                                                        ? "Allocate"
                                                        : "Edit Allocation"}`}
                                                </Typography>
                                                <KeyboardArrowRightOutlinedIcon
                                                    sx={{
                                                        color: "#51B8B0",
                                                        fontWeight: 700,
                                                        lineHeight: "1rem",
                                                        fontSize: "1.25rem"
                                                    }}
                                                />
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                    </Box>
                                </Box>
                                <Box>
                                    {showEditAllocations ? (
                                        <FundingAllocation
                                            fundingRequestId={selectedGameReq?.id}
                                            setCloseUpdate={() => setShowEditAllocations(false)}
                                            gameId={selectedGameReq?.gameId}
                                            refreshData={() => refreshAllData(selectedGameReq.gameId, selectedGameReq.settlementDate)}
                                        />
                                    ) : (
                                        <>
                                            {(allocationsByGameId?.length === 0 && !selectedGameReq?.fundingAdjustmentReported) ?
                                            (<Box sx={{ height: '100px', width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                No data
                                            </Box>
                                            ) : (<GenericTable
                                                    data={allocationsByGameId || []}
                                                    columns={allocationTableMappedColumns}
                                                    meta={allocatonTableMetadata}
                                                    isLoading={isDataLoading}
                                                />
                                            )}
                                        </>
                                    )}
                                </Box>
                            </Box>
                        ) : (
                            <></>
                        )}
                        <Box
                            id={"Revenue Ledger"}
                            key={"Revenue Ledger"}
                            sx={{
                                width: "100%",
                                height: "fit-content",
                                backgroundColor: "white",
                                borderRadius: "12px",
                                padding: "0.75rem",
                                marginY: "1rem",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                    justifyContent: "space-between",
                                    marginBottom: "1rem",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "1rem",
                                        lineHeight: "1.375rem",
                                        fontWeight: 700,
                                        color: "#162936",
                                    }}
                                >
                                    Ledger
                                </Typography>
                                <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center", alignItems: "center" }}>
                                    <Button
                                        sx={{
                                            textTransform: "none",
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                        onClick={() => { setShowTransactionTypesSidePanel(true) }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#51B8B0",
                                                fontFamily: "Manrope, sans-serif",
                                                fontWeight: 700,
                                                fontSize: "0.75rem",
                                                lineHeight: "1rem"
                                            }}
                                        >
                                            Transaction Types
                                        </Typography>
                                        <KeyboardArrowRightOutlinedIcon
                                            sx={{
                                                color: "#51B8B0",
                                                fontWeight: 700,
                                                lineHeight: "1rem",
                                                fontSize: "1rem"
                                            }}
                                        />
                                    </Button>
                                    {chart &&
                                        <Pagination
                                            page={currentPage}
                                            size={PAGE_SIZE}
                                            total={chart.rows.length}
                                            handleChange={(newPage) => handlePageChange("Revenue Ledger", newPage)}
                                            fileName={chart.name}
                                            chartId={chart.id}
                                            submoduleId={id}
                                            filterSelection={gameObject}
                                        />
                                    }
                                    <IconButton onClick={(e: any) => fetchDashboardData({ gameId: selectedGameReq.gameId, settlementDate: selectedGameReq.settlementDate })} sx={{ color: "#2E736E" }}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <GenericTable
                                data={paginatedRows || []}
                                columns={chartData[0]?.columns || []}
                                meta={chartData[0]?.meta || null}
                                isLoading={ledgerLoader}
                            />
                        </Box>
                        <TransactionTypesSidePanel
                            showTransactionTypesSidePanel={showTransactionTypesSidePanel}
                            setShowTransactionTypesSidePanel={setShowTransactionTypesSidePanel}
                        />
                    </>
                )}
                <FundingRequestAdminSidePanel
                    showFundingRequestPanel={showFundingRequestPanel}
                    setShowFundingRequestPanel={setShowFundingRequestPanel}
                    fundingRequestDetails={getFundingRequestDetailsById(selectedRequest)}
                />
            </div >
        </>
    );
};

export default FundingDetailsAdmin;
