import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material"
import { Loader } from "@components";
import useChartById from "@hooks/api/useChartById";
import { setDashboardFilter } from "@stores/DashboardFilters/slice";
import { useEditProjectionsMutation, useLazyGetPredictionsQuery } from "@api/projections";
import FinancialProjectionsTable from "./FinancialProjectionsTable/FinancialProjectionsTable"
import { processCombinedCohortData } from "./FinancialProjectionsTable/tableDataUtil"
import { FinancialProjectionChartTypes, PredictionDetails, ProjectionValueUpdateType } from "./types";
import DownloadChart from "../DownloadChart";

interface CombinedCohortProjectionsProps {
	submoduleId: string;
	selectedGame: { id: string, label: string };
	setDashboardLoader: React.Dispatch<React.SetStateAction<boolean>>
	dashboardCharts: { id: string; name: string; chartType: string, metadata: string }[]
}


const CombinedCohortsProjections: React.FC<CombinedCohortProjectionsProps> = ({
	submoduleId,
	selectedGame,
	setDashboardLoader,
	dashboardCharts
}) => {
	const dispatch = useDispatch()

	const [chartData, setChartData] = useState<
		{ id: string; name: string; columns: any[]; rows: any[]; meta: any }[]
	>([]);
	const [componentLoader, setComponentLoader] = useState<boolean>(true)
	const [predictionDetails, setPredictionDetails] = useState<PredictionDetails | undefined>(undefined)

	const { getChartDataById } = useChartById()
	const [getPredictions] = useLazyGetPredictionsQuery();
	const [editProjections, { isSuccess: isEditProjectionsSuccess }] = useEditProjectionsMutation()

	const chartsList = [FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_COMBINED_VIEW]
	const tabsChartsList = dashboardCharts.filter((chart: any) => chartsList.includes(chart.chartType))

	const fetchDashboardData = async (filters?: Record<string, string>) => {
		if (submoduleId && tabsChartsList?.length) {
			setComponentLoader(true)
			const chartsById: any[] = [];
			try {
				await Promise.all(
					tabsChartsList.map(
						async (chart: {
							id: string;
							name: string;
							chartType: string;
							metadata: string;
						}) => {
							const parsedMetadata = JSON.parse(chart.metadata);
							const response = await getChartDataById(submoduleId, chart.id, filters, parsedMetadata?.sort?.fields, parsedMetadata?.sort?.order);
							if (response) {
								const { rows, columns, metadata } = formatChartResponseData(response);
								chartsById.push({
									columns,
									rows,
									id: chart.id,
									name: response.name,
									meta: metadata,
								});
							}
						}
					)
				);

				setChartData(chartsById);
			} catch (error) {
				console.error("Error fetching chart data:", error);
			} finally {
				setDashboardLoader(false);
				setComponentLoader(false);
			}
		}
	};

	const formatChartResponseData = (response: any) => {
		let columns = [];
		let rows = [];
		const metadata = response.metadata;
		const { mappedColumns, mappedData } = processCombinedCohortData(
			response.name,
			metadata,
			response.result
		);
		columns = mappedColumns;
		rows = mappedData

		return { rows, columns, metadata }
	}

	const fetchPredictionsData = async () => {
		if (selectedGame?.id)
			await getPredictions({ gameId: selectedGame?.id, submoduleId: submoduleId }).then((response) => {
				if (response)
					setPredictionDetails(response?.data?.data[0])
			})
	}

	useEffect(() => {
		setComponentLoader(true)

		if (selectedGame?.id && predictionDetails?.requestId) {
			fetchDashboardData({
				game_id: selectedGame?.id,
				predictionRequestId: predictionDetails?.requestId || '',
			});
		}
	}, [predictionDetails]);

	useEffect(() => {
		setComponentLoader(true);
		fetchPredictionsData();
		dispatch(setDashboardFilter({ "FinancialProjections": selectedGame }));
	}, [selectedGame]);

	useEffect(() => {
		if (selectedGame?.id && predictionDetails?.requestId) {
			fetchDashboardData({
				game_id: selectedGame?.id,
				predictionRequestId: predictionDetails?.requestId || '',
			});
		}
	}, [isEditProjectionsSuccess])

	const handleFieldEditSave = (updatedValues: ProjectionValueUpdateType[]) => {
		if (updatedValues.length > 0 && predictionDetails?.requestId)
			editProjections({
				submoduleId: submoduleId,
				predictionId: predictionDetails?.requestId,
				body: updatedValues
			})
	}

	return (
		<Box
			sx={{
				backgroundColor: "#FFFFFF",
				padding: "1rem 1rem 0.5rem",
				borderRadius: "0.75rem",
				marginTop: '18px',
				minHeight: '30rem'
			}}
		>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: "1rem"
				}}>
				<Typography
					sx={{
						color: "#162936",
						fontFamily: "Manrope, sans-serif",
						fontWeight: 700,
						fontSize: "1rem",
					}}
				>
					Financial Projections
				</Typography>
				<DownloadChart
					submoduleId={submoduleId}
					chartId={chartData[0]?.id}
					filterSelection={{ gameId: selectedGame?.id, predictionRequestId: predictionDetails?.requestId || '' }}
					fileName={`${chartData[0]?.name}`}
				/>
			</Box>
			{componentLoader ?
				<Box
					sx={{
						height: '26rem',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}>
					<Loader />
				</Box>
				:
				<FinancialProjectionsTable
					data={chartData[0]?.rows || []}
					columns={chartData[0]?.columns || []}
					meta={chartData[0]?.meta}
					handleFieldSave={handleFieldEditSave}
				/>
			}
		</Box>
	)
}

export default CombinedCohortsProjections;