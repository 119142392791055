import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@stores/store";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { CustomDropdown } from "@components";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter_icon.svg";
import useCustomDashboardFilters from "@hooks/api/useCustomDashboardFilters";
import { setDashboardFilter } from "@stores/DashboardFilters/slice";

interface Filter {
  key: string;
  label: string;
  values: { id: string; label: string }[];
}

interface InvestmentPerformanceFiltersProps {
  id: string;
  onFilterChange: (selections: Record<string, string>) => void;
}

const InvestmentPerformanceFilters: FC<InvestmentPerformanceFiltersProps> = ({ id, onFilterChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const dashboardFilters = useSelector((state: RootState) => state?.DashboardFiltersData);

  const { getDashboardFilterValues } = useCustomDashboardFilters();

  const [filters, setFilters] = useState<Filter[]>([]);
  const [selections, setSelections] = useState<Record<string, string>>({});

  // Hook to fetch dashboard data on load
  useEffect(() => {
    if (id) {
      getDashboardFilterValues(id).then((response) => {
        if (response) {
          const filterData = Object.values(response).map((res: any) => ({
            label: res.displayName,
            key: res.name,
            values: [
              { id: "All", label: `All ${res.displayName}s` },
              ...res.values.map((v: string) => ({
                id: v,
                label: v,
              })),
            ],
          }));

          setFilters(filterData);

          if (
            Object.keys(dashboardFilters?.dashboards).length
            && dashboardFilters.dashboards["InvestmentPerformance" as any]
            && Object.keys(dashboardFilters.dashboards["InvestmentPerformance" as any]).length) {
            setSelections(dashboardFilters.dashboards["InvestmentPerformance" as any]);
            onFilterChange(dashboardFilters.dashboards["InvestmentPerformance" as any]);
          } else if (Object.keys(selections).length === 0) {
            // Initialize selections to "All" for each filter
            const initialSelections = filterData.reduce((acc, filter) => {
              acc[filter.key] = "All";
              return acc;
            }, {} as Record<string, string>);
            setSelections(initialSelections);
            onFilterChange(initialSelections);
          }
        }
      });
    }
  }, [id]);

  // Hook to update dashboard filter values to/from store
  useEffect(() => {
    if (selections) {
      dispatch(setDashboardFilter({ "InvestmentPerformance": selections }));
    }
  }, [selections]);

  // Handle filter selection changes
  const handleFilterChange = (key: string, selectedValue: string) => {
    const updatedSelections = { ...selections, [key]: selectedValue };
    setSelections(updatedSelections);
    onFilterChange(updatedSelections);
  };

  return (
    <Box
      sx={{
        padding: "0.8rem",
        backgroundColor: "white",
        borderRadius: "0.8rem",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          color: "#162936",
          fontWeight: 700,
          fontSize: "1rem",
          lineHeight: "1.375rem",
          paddingY: "0.5rem",
        }}
      >
        <FilterIcon />
        Filters
      </Box>
      <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "0.8rem" }}>
        {filters.map((filter) => (
          <CustomDropdown
            key={filter.key}
            selectedOption={selections[filter.key]}
            dropdownOptions={filter.values}
            handleChange={(event) => handleFilterChange(filter.key, event.target.value)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default InvestmentPerformanceFilters;
