import React from 'react';
import { Box, Skeleton } from '@mui/material';

const DoughnutChartSkelton: React.FC = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', maxHeight: '18rem' }}>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                py: '1rem',
                position: 'relative',
            }}
        >
            {/* Main doughnut chart */}
            <Box
                sx={{
                    position: 'relative',
                    width: '11rem',
                    height: '11rem',
                    borderRadius: '50%',
                    background: 'linear-gradient(90deg, #00000008 0%, #00000010 25%, #00000014 50%, #00000010 75%, #00000008 100%)',
                    mask: 'radial-gradient(circle, transparent 40%, black 41%)',
                    backgroundSize: '200% 100%',
                    animation: 'loading 3s infinite linear',
                    '@keyframes loading': {
                        '0%': {
                            backgroundPosition: '200% 0',
                        },
                        '100%': {
                            backgroundPosition: '-200% 0',
                        },
                    },
                }}
            />
        </Box>
        {/* Legend */}
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                py: '1rem'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    py: '0.5rem'
                }}
            >
                <Skeleton
                    variant="circular"
                    width="1rem"
                    height="1rem"
                    sx={{ borderRadius: '50%', mr: '1rem' }}
                />
                <Skeleton
                    variant="rectangular"
                    width="5rem"
                    height="1rem"
                    sx={{ borderRadius: '8px' }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    py: '0.5rem'
                }}
            >
                <Skeleton
                    variant="circular"
                    width="1rem"
                    height="1rem"
                    sx={{ borderRadius: '50%', ml: '1rem', mr: '1rem' }}
                />
                <Skeleton
                    variant="rectangular"
                    width="5rem"
                    height="1rem"
                    sx={{ borderRadius: '8px' }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    py: '0.5rem'
                }}
            >
                <Skeleton
                    variant="circular"
                    width="1rem"
                    height="1rem"
                    sx={{ borderRadius: '50%', ml: '1rem', mr: '1rem' }}
                />
                <Skeleton
                    variant="rectangular"
                    width="5rem"
                    height="1rem"
                    sx={{ borderRadius: '8px' }}
                />
            </Box>
        </Box>
    </Box>
);

export default DoughnutChartSkelton;
