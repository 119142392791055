import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@stores/store";
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CustomDropdown } from "@components";
import { useLazyGetUserModuleGamesQuery } from "@api/userModules";
import useCustomDashboardFilters from "@hooks/api/useCustomDashboardFilters";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Filter, Game } from "./types";

interface FundingEligibilityProps {
  selectedGame: Game | undefined,
  submoduleId: string;
  setSelectedGame: React.Dispatch<Game | undefined>
  setComponentLoader: React.Dispatch<React.SetStateAction<boolean>>
  downloadPDF: () => void;
  isDownloading: boolean;
}
const FundingEligibilityHeader: FC<FundingEligibilityProps> = ({
  selectedGame,
  submoduleId,
  setSelectedGame,
  setComponentLoader,
  downloadPDF,
  isDownloading
}) => {
  const theme = useTheme();
  const dashboardFilters = useSelector((state: RootState) => state?.DashboardFiltersData);
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const [filters, setFilters] = useState<Filter[]>([]);


  const { getDashboardFilterValues } = useCustomDashboardFilters();
  const [getUserModuleGames] = useLazyGetUserModuleGamesQuery();

  const fundingEligibilitySelections = dashboardFilters.dashboards["FundingEligibility" as any] as Game;

  useEffect(() => {
    if (submoduleId) {
      getDashboardFilterValues(submoduleId).then(async (response) => {
        if (response) {
          const gamesParam = response?.game_id?.values || [];
          const gamesDetails = await getUserModuleGames({ submoduleId, gamesParam }).unwrap();
          const filterData: any = Object.values(response).map((res: any) => ({
            label: res.displayName,
            key: res.name,
            values: res.values.map((v: string) => ({
              id: v,
              label: gamesDetails?.data.find((d: any) => d.gameId === v)?.name || v,
            })).sort((a: any, b: any) => a.label.localeCompare(b.label)),
          }))

          setFilters(filterData)

          if (fundingEligibilitySelections) {
            setSelectedGame(fundingEligibilitySelections)
          }
          else {
            const filteredGame = filterData.find((f: any) => f.key === "gameId")?.values?.[0];
            setSelectedGame({
              id: filteredGame?.id,
              name: filteredGame?.label
            })
          }
        }
      })
    }
  }, [submoduleId]);

  const handleFilterChange = (value: any) => {
    const filteredGame = filters.find((f: any) => f.key === "gameId")?.values?.find((game: any) => game.id === value);
    if (filteredGame) {
      setComponentLoader(true)
      setSelectedGame({
        id: filteredGame?.id,
        name: filteredGame?.label
      })
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "1.25rem",
          gap: "0.75rem",
          borderRadius: "1rem",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: '0.5rem',
              flexDirection: isSmallDevice ? "column" : "row",
            }}
          >
            <Typography
              fontSize={isSmallDevice ? "1.5rem" : "2rem"}
              fontWeight={600}
              color={"#162936"}
            >
              Funding Eligibility: {filters[0]?.values?.length === 1 ? filters[0]?.values[0]?.label : ""}
            </Typography>
            {filters.map((filter) => (
              filter.values.length > 1 ? <CustomDropdown
                key={filter.key}
                selectedOption={selectedGame?.id || ''}
                dropdownOptions={filter.values}
                handleChange={(event) =>
                  handleFilterChange(event.target.value)
                }
                wrapperSx={{
                  color: "#162936",
                  fontWeight: 600,
                  fontSize: isSmallDevice ? "1rem" : "1.5rem",
                  display: "flex",
                  alignItem: "center",
                  paddingTop: isSmallDevice ? "0.375rem" : "1rem",
                }}
              /> : <></>
            ))}
          </Box>
          {isDownloading ?
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CircularProgress
                style={{
                  height: '24px',
                  width: '24px',
                  color: '#2F736E'
                }}
              />
            </Box> :
            <button
              onClick={downloadPDF}
              style={{
                all: "unset",
                cursor: "pointer",
                height: '30px',
                width: '30px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <FileDownloadOutlinedIcon style={{
                height: '30px',
                width: '30px'
              }} />
            </button>}

        </Box>
      </Box>
    </>
  );
};

export default FundingEligibilityHeader;
