import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/store';
import { DEMO_NAV_ROUTES, NAV_ROUTES } from '../../constants/navRoutes';
import { getDashboardDataBasedOnUrl } from '../../utils/common';
import EmptyDashboard from './EmptyDashboard';
import { FullPageLoader } from '@components';
import SupersetDashboard from './SupersetDashboard';
import CustomDashboardWrapper from './CustomDashboardWrapper';
import { useLazyFetchSelfServeDetailsQuery } from '@api/selfServe';
import UserAuth from '@services/auth';
import { DEMO_USER_EMAILS } from '@constants/constants';
import { MODULES_REQUEST_STATUS } from '@utils/types/RequestedDashboards';


const Dashboard: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { module, submodule: subModule } = useParams<{ module?: string; submodule?: string }>();
  const userData = useSelector((state: RootState) => state?.UserData);
  const modulesInStore = useSelector((state: RootState) => state?.ModulesData);
  const [isEmptyDashboard, setEmptyDashboard] = useState(false);
  const [dashboardData, setDashboardData] = useState<{ 
    id: string; 
    embedId: string; 
    submoduleType?: string; 
    filterQuery: string; 
    requestStatus: MODULES_REQUEST_STATUS;
    requestId?: string;
  } | null>(null);
  const [fetchSelfServeDetails] = useLazyFetchSelfServeDetailsQuery();

  // TODO: REMOVE AFTER DEMO 
  // Hook to handle demo flow
  useEffect(() => {
    if (userData?.user?.email && DEMO_USER_EMAILS.includes(userData.user.email) && !UserAuth.demoToken) {
      navigate(DEMO_NAV_ROUTES.GETTING_STARTED);
    }
  }, [userData, module, modulesInStore, subModule]);

  // Hook to set dashboard redirection
  // if no permission then redirect to home page
  // set native filter value for superset dashboard if admin user
  // set dashboard id
  useEffect(() => {
    if (modulesInStore.loadCount === 0) return;

    if (modulesInStore?.modules?.filter((module) => module.requestStatus === 'APPROVED').length === 0) {
      fetchSelfServeDetails().unwrap().then(({ data }) => {
        if (data && data.requestStatus && data.requestStatus !== 'PUBLISHED') {
          navigateToGettingStarted();
        } else {
          setEmptyDashboard(true);
        }
      });
    } else {
      if (!module) {
        redirectOnEmptyDashboard();
      }
    }

    if (subModule) {
      setDashboardData(getDashboardDataBasedOnUrl(modulesInStore.modules, subModule));
    } else {
      const data = getDashboardDataBasedOnUrl(modulesInStore.modules, location.pathname);
      setDashboardData(data);
    }
  }, [module, modulesInStore, subModule]);
  
  const navigateToGettingStarted = () => {
    navigate(NAV_ROUTES.GETTING_STARTED);
  }

  // Function to redirect to first dashboard in access of user
  const redirectOnEmptyDashboard = () => {
    const redirectModule = modulesInStore?.modules?.[0];
    const redirectSubmodule = redirectModule ? (redirectModule?.submodules?.[0] || null) : null;
    if (redirectModule && redirectSubmodule && !module) {
      const urlModuleParam = redirectModule.name.toLowerCase()
        .replace(/ /g, "_")
        .replace(/\(|\)/g, "");
      navigate(`${NAV_ROUTES.DASHBOARD}/${urlModuleParam}/${redirectSubmodule.url}`);
    }
  };

  // If no dashboards present for user display empty dashboard view
  if (isEmptyDashboard) {
    return (
      <EmptyDashboard />
    );
  }

  return (
    <>
      {dashboardData?.id ? (
        dashboardData?.embedId ? (
          <SupersetDashboard data={dashboardData} />
        ) : (
          <CustomDashboardWrapper data={dashboardData} />
        )
      ) : (
        <FullPageLoader hasSideNav={true} />
      )
      }
    </>
  );
}

export default Dashboard;
