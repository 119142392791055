import { Stack, Typography } from "@mui/material";

const GettingStartedHeader = () => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      paddingBottom={"1.25rem"}
    >
      <Typography
        fontSize={"2.25rem"}
        lineHeight={"2.75rem"}
        fontWeight={700}
        color={"#162936"}
      >
        Getting Started
      </Typography>
    </Stack>
  );
};

export default GettingStartedHeader;
