import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { GenericTable } from "@components";
import useDashboardRequests from "@hooks/api/useDashboardRequests";
import useDebounce from "@hooks/useDebounce";
import { setToastMessage } from "@stores/App/slice";
import { TOAST_TYPES } from "@constants/constants";
import { AdminRegisterUserPayload, useAdminRegisterUserMutation } from "@api/register";
import { Column, Row } from "../../../components/GenericTable/GenericTable";
import DashboardRequestsSidePanel from "./components/DashboardRequestsSidePanel";
import NewGameSidePanel from "./components/NewGameSidePanel";
import { formatCurrency } from "@utils/common";

type GameData = {
  companyName: string;
  gameId: string;
  email: string;
  status: string;
  roasProjections: string;
  createdAt: string;
  action?: JSX.Element;
  attachmentId?: string;
  monthlyMarketingSpends: string | null;
};
const columns: Column<GameData>[] = [
  { key: "companyName", label: "Deal Name" },
  { key: "gameId", label: "Game ID" },
  { key: "email", label: "Email ID" },
  { key: "monthlyMarketingSpends", label: "Monthly Marketting Spends" },
  { key: "status", label: "Data Status" },
  { key: "roasProjections", label: "ROAS Projections" },
  { key: "createdAt", label: "Time" },
];

const metadata = {
  chartType: "TABLE",
  columns: [
    { id: "companyName", type: "string" },
    { id: "gameId", type: "string" },
    { id: "email", type: "string" },
    { id: "monthlyMarketingSpends", type: "string" },
    { id: "status", type: "string" },
    { id: "roasProjections", type: "string" },
    { id: "createdAt", type: "string" },
    { id: "action", type: "action" },
  ],
};

const SELFSERVE_STATUS_COLORS: Record<string, string> = {
  NOT_REQUESTED: "#D3D3D3",
  PUBLISHED: "#5E8700",
  DATA_FETCH_FAILED: "#FF0000",
  FAILED: "#FF0000",
  READY_TO_PROCESS: "#FFA726",
  READY_TO_PUBLISH: "#FFA726",
  REQUESTED: "#FFA726",
  PENDING: "#FFA726",
  PENDING_DATA_PULL: "#FFA726",
  DATA_PULL_INITIATED: "#FFA726",

  PENDING_USER_CREATION: "#FFA726",
  PENDING_ACCESS: "#FFA726",
  ACCESS_GRANTED: "#FFA726",
  DATA_FETCH_SUCCESSFUL: "#FFA726",
  DASHBOARD_CREATION_FAILED: "#FF0000",
};

const DASHBOARD_STATUS_COLORS: Record<string, string> = {
  REQUESTABLE: "#FFA726",
  REQUESTED: "#5E8700",
  NO_DATA: "#D3D3D3"
};

enum DashboardStatus {
  REQUESTABLE = 'Requestable',
  NOT_REQUESTED = 'Not Requested',
  REQUESTED = 'Requested',
  PENDING = 'Pending',
  DATA_FETCH_FAILED = 'Data Fetch Failed',
  READY_TO_PROCESS = 'Ready to Process',
  PENDING_DATA_PULL = 'Pending Data Pull',
  DATA_PULL_INITIATED = 'Data Pull Initiated',
  FAILED = 'Failed',
  READY_TO_PUBLISH = 'Ready to Publish',
  PUBLISHED = 'Published'
}

enum SelfServeRequestStatus {
  PENDING_USER_CREATION = 'Pending User Creation',
  PENDING_NDA_CREATION = 'Pending NDA Creation',
  PENDING_NDA_SIGNATURE = 'Pending NDA Signature',
  PENDING_ACCESS = 'Pending Access',
  ACCESS_GRANTED = 'Access Granted',
  DATA_FETCH_SUCCESSFUL = 'Data Fetch Successful',
  DATA_FETCH_FAILED = 'Data Fetch Failed',
  PUBLISHED = 'Published',
  DASHBOARD_CREATION_FAILED = 'Dashboard Creation Failed'
}

const DashboardRequest = () => {
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [search, setSearch] = useState<string>("");
  const [showDashboardRequestsSidePanel, setShowDashboardRequestsSidePanel] = useState(false);
  const [showNewGameSidePanel, setShowNewGameSidePanel] = useState(false);
  const [selectedRow, setSelectedRow] = useState<GameData | null>(null);
  const [dashboardData, setDashboardData] = useState<Row<GameData>[] | null>(
    null
  );

  const { getDashboardRequestsData } = useDashboardRequests();
  const [adminRegisterUserApi] = useAdminRegisterUserMutation();

  useEffect(() => {
    fetchDashboardRequestData();
  }, []);

  const fetchDashboardRequestData = async (
    search: string | undefined = undefined,
    pageNumber: number | undefined = undefined,
    pageSize: number | undefined = undefined) => {
    try {
      const response = await getDashboardRequestsData(
        search,
        pageNumber,
        pageSize
      );

      if (response) {
        const formattedData = response.map((data: any) => {
          const financialProjection = data.requestedDashboards.find(
            (dashboard: any) => dashboard.moduleName === "Financial Projections"
          );

          return {
            ...data,
            roasProjections: data.requestedDashboards?.length > 0 ? financialProjection.status : "No Data",
          };
        });

        setDashboardData(formattedData);
      }
    } catch (error) {
      console.error(error);
      dispatch(setToastMessage({
        type: TOAST_TYPES.ERROR,
        message: "Failed to fetch dashboard requests data"
      }))
    }
  }

  const debouncedSearch = useDebounce((value: string) => fetchDashboardRequestData(value), 800);

  const handleSearchFocus = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleEditClick = (rowId: string) => {
    if (dashboardData) {
      const rowData = dashboardData?.find((row: any) => row.id === rowId);
      if (rowData) {
        setSelectedRow(rowData);
        setShowDashboardRequestsSidePanel(true);
      }
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    debouncedSearch(value);
  }

  const handleReload = () => {
    setSearch('');
    fetchDashboardRequestData();
  }

  const conditionallyMappedColumn = columns.map((col: any) => {
    if (col.key === "status") {
      return {
        ...col,
        render: (key: string, row: any) => {
          const value = row[col.key];
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {value !== "NOT_REQUESTED" && (
                <Box
                  sx={{
                    width: "0.75rem",
                    minWidth: "0.75rem",
                    height: "0.75rem",
                    borderRadius: "0.25rem",
                    backgroundColor: SELFSERVE_STATUS_COLORS[value] || "#D3D3D3",
                    marginRight: "0.5rem",
                  }}
                />
              )}
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  color: "#1B1C17",
                }}
              >
                {SelfServeRequestStatus[value as keyof typeof SelfServeRequestStatus]}
              </Typography>
            </Box>
          );
        },
        renderHeader: (column: any) => col.label,
      };
    }
    if (col.key === 'roasProjections') {
      return {
        ...col,
        render: (key: string, row: any) => {
          const value = row[col.key];
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {value !== "NOT_REQUESTED" && (
                <Box
                  sx={{
                    width: "0.75rem",
                    minWidth: "0.75rem",
                    height: "0.75rem",
                    borderRadius: "0.25rem",
                    backgroundColor: DASHBOARD_STATUS_COLORS[value] || "#D3D3D3",
                    marginRight: "0.5rem",
                  }}
                />
              )}
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  color: "#1B1C17",
                }}
              >
                {DashboardStatus[value as keyof typeof DashboardStatus] || "No Data"}
              </Typography>
            </Box>
          );
        },
      };

    }
    if (col.key === "createdAt") {
      return {
        ...col,
        render: (key: string, row: any) => {
          const value = row[col.key];
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  color: "#1B1C17",
                }}
              >
                {new Date(value).toUTCString()}
              </Typography>
            </Box>
          );
        }
      };
    }

    if (col.key === "monthlyMarketingSpends") {
      return {
        ...col,
        render: (key: string, row: any) => {
          const columnValue = row[col.key];
        
          // Return a default placeholder if the column value is missing
          if (!columnValue) {
            return <Box>-</Box>;
          }
        
          const rangeParts = columnValue.split(" - ");
          let formattedValue = "";
        
          if (rangeParts.length === 2) {
            // Handle range values (e.g., "$1,000 - $2,000")
            const [from, to] = rangeParts;
            const formattedFrom = formatCurrency(from.slice(1).replaceAll(",", ""), true);
            const formattedTo = formatCurrency(to.slice(1).replaceAll(",", ""), true);
            formattedValue = `${formattedFrom} - ${formattedTo}`;
          } else if (rangeParts.length === 1) {
            // Handle single currency values (e.g., "> $1,000")
            const [currencySymbol, amount] = rangeParts[0].split(" ");
            const formattedAmount = formatCurrency(amount.slice(1).replaceAll(",", ""), true);
            formattedValue = `${currencySymbol} ${formattedAmount}`;
          }
        
          return (
            <Box sx={{ textWrap: "nowrap" }}>
              {formattedValue}
            </Box>
          );
        }
      }
    }
    return col;
  });

  const handleCreateNewGame = async (payload: AdminRegisterUserPayload) => {
    try {
      await adminRegisterUserApi(payload).unwrap();
    } catch (error: any) {
      const { data } = error;
      if (data) {
        const { message } = data;

        dispatch(setToastMessage({
          type: TOAST_TYPES.ERROR,
          message: message || "Unable to register user, please try again later."
        }));
      } else {
        dispatch(setToastMessage({
          type: TOAST_TYPES.ERROR,
          message: error?.error?.message || "Unable to register user, please try again later."
        }));
      }
    } finally {
      setShowNewGameSidePanel(false);
      fetchDashboardRequestData();
    }
  };

  return (
    <div
      style={{
        padding: "1rem 2rem",
        paddingBottom: isMediumView ? "4rem" : "2rem",
        overflow: "scroll",
        maxHeight: "100%"
      }}
    >
      <Box
        id="header_wrapper"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "2rem",
          mt: "1rem"
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            fontSize: "2rem",
            lineHeight: 1.5,
            fontFamliy: "Manrope, sans-serif",
            marginTop: "0.5rem"
          }}
        >
          Self Serve Requests
        </Typography>
        <Button
          variant="contained"
          onClick={() => setShowNewGameSidePanel(true)}
          sx={{
            borderRadius: '100px',
            fontWeight: '700',
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: '#F6F8F9',
            fontFamily: 'Manrope, sans-serif',
            '&:hover': {
              backgroundColor: theme.palette.primary.main
            },
            padding: '12px 16px'
          }}
        >

          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem" }}>
            <AddIcon
              htmlColor={theme.palette.common.white}
              sx={{
                height: '1rem',
                width: '1rem',
                cursor: "pointer",
                backgroundColor: "transparent",
              }}
            />
            <Typography
              sx={{
                color: theme.palette.common.white,
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              New Game
            </Typography>
          </Box>
        </Button>
      </Box>
      <Box
        sx={{
          marginBottom: "1.5rem",
        }}
      >
        <Paper
          component="form"
          sx={{
            height: 45,
            px: "19px",
            display: "flex",
            alignItems: "center",
            borderRadius: "28px",
            mb: "10px",
            backgroundColor: theme.palette.custom_field_background,
            width: isMediumView ? "100%" : "60%",
            boxShadow: "none",
          }}
        >
          <InputBase
            inputRef={searchInputRef}
            placeholder={isMediumView ? `Search ...` : `Search Email / Game ID`}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              flex: 1
            }}
          />
          {search ? (
            <Box
              sx={{ mt: 1, cursor: "pointer" }}
              onClick={() => setSearch("")}
            >
              <CloseIcon />
            </Box>
          ) : (
            <Box sx={{ mt: 1, cursor: "pointer" }} onClick={handleSearchFocus}>
              <SearchIcon />
            </Box>
          )}
        </Paper>
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          borderRadius: "0.5rem",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontSize: "1rem",
              fontWeight: 700,
            }}
          >
            Data Co-op
          </Typography>

          <Box>
            <IconButton onClick={handleReload} sx={{
              color: "#2E736E",
              padding: "4px"
            }}>
              <RefreshIcon />
            </IconButton>
          </Box>
        </Stack>
        {dashboardData && (
          <GenericTable
            data={dashboardData}
            columns={conditionallyMappedColumn}
            meta={metadata}
            actions={[
              {
                label: "",
                icon: "table-row-next.svg",
                action: handleEditClick,
              },
            ]}
          />
        )}
      </Box>
      {
        selectedRow && (
          <DashboardRequestsSidePanel
            showDashboardRequestsSidePanel={showDashboardRequestsSidePanel}
            setShowDashboardRequestsSidePanel={setShowDashboardRequestsSidePanel}
            selectedRow={selectedRow}
          />
        )
      }
      {showNewGameSidePanel && (
        <NewGameSidePanel
          showNewGameSidePanel={true}
          setShowNewGameSidePanel={setShowNewGameSidePanel}
          createNewGameAction={handleCreateNewGame}
        />
      )}
    </div >
  );
};

export default DashboardRequest;
