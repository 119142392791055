import apiRoutes from "@constants/apiRoutes";
import baseApi from "@services/api";

export interface SelfServeDetails {
  accessEmail?: string;
  category: string | null;
  gameId: string;
  genre: string | null;
  id: string;
  metadata: string;
  requestStatus: string;
  unrequestedDashboards?: any;
  appUrl: string;
  companyName?: string;
  companyAddress?: string;
  signingAuthorityName?: string;
  signingAuthorityEmail?: string;
  signingAuthorityTitle?: string;
}

export interface SelfServeDetailsApiMap {
  code: number;
  message: string;
  data: SelfServeDetails;
}

export interface SelfServeCategoriesAndGenreOptionsApiMap {
  code: number;
  message: string;
  data: {
    categories: {
      name: string;
      genres: string[];
    }[];
  };
}

export interface SelfServeUpdateCategoryAndGenrePayload {
  category: string;
  genre: string;
}

export interface SelfServeUpdateApplicationDetailsPayload {
  appStoreUrl?: string;
  playStoreUrl?: string;
}

export interface SelfServeUpdateNDADetailsPayload {
  companyName: string;
  companyAddress: string;
  signingAuthorityName: string;
  signingAuthorityTitle: string;
  signingAuthorityEmail: string;
}

const selfServe = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSelfServeDetails: builder.query<SelfServeDetailsApiMap, void>({
      query: () => apiRoutes.selfServeDetails,
    }),
    fetchCategoryAndGenreOptions: builder.query<
      SelfServeCategoriesAndGenreOptionsApiMap,
      void
    >({
      query: () => apiRoutes.selfServeCategoryAndGenreOptions,
    }),
    updateCategoryAndGenre: builder.mutation<
      any,
      { selfServeId: string; payload: SelfServeUpdateCategoryAndGenrePayload }
    >({
      query: ({ selfServeId, payload }) => ({
        url: apiRoutes.selfServeUpdateCategoryAndGenre(selfServeId),
        method: "PUT",
        body: payload,
      }),
    }),
    updateApplicationDetails: builder.mutation<
      any,
      { selfServeId: string; payload: SelfServeUpdateApplicationDetailsPayload }
    >({
      query: ({ selfServeId, payload }) => ({
        url: apiRoutes.selfServeUpdateApplicationDetails(selfServeId),
        method: "PUT",
        body: payload,
      }),
    }),
    fetchDashboardRequests: builder.query<any, string>({
      query: (qps: string) =>
        qps
          ? `${apiRoutes.selfServeDashboardRequests}?${qps}`
          : apiRoutes.selfServeDashboardRequests,
    }),
    fetchSelfServeAtachments: builder.query<any, { selfServeId: string, attachmentId: string }>({
      query: ({ selfServeId, attachmentId }) => apiRoutes.selfServeAtachments(selfServeId, attachmentId),
    }),
    selfServeDataAccess: builder.mutation<
      any,
      { accountName: string; selfServeId: string }
    >({
      query: ({ accountName, selfServeId }) => ({
        url: apiRoutes.selfServeDataAccess(selfServeId),
        method: "POST",
        body: { accountName },
      }),
    }),
    selfServeRequestDashboard: builder.mutation<
      any,
      { selfServeId: string; dashboardId: string }
    >({
      query: ({ selfServeId, dashboardId }) => ({
        url: apiRoutes.selfServeRequestDashboard(selfServeId, dashboardId),
        method: "POST",
      }),
    }),
    selfServeNdaDetails: builder.mutation<void, { selfServeId: string, payload: SelfServeUpdateNDADetailsPayload }>({
      query: ({ selfServeId, payload }) => ({
        url: apiRoutes.selfServeNDADetails(selfServeId),
        method: "POST",
        body: payload
      })
    }),
    fetchSelfServeRefreshConfig: builder.query<any, { gameId: string }>({
      query: ({ gameId }) => apiRoutes.selfServeRefreshConfig(gameId)
    }),
    selfServeRefreshConfig: builder.mutation<any, { gameId: string, refreshEnabled: boolean }>({
      query: ({ gameId, refreshEnabled }) => ({
        url: apiRoutes.selfServeRefreshConfig(gameId),
        method: 'PUT',
        body: { refreshEnabled }
      }),
    }),
  }),
});

export const {
  useLazyFetchSelfServeDetailsQuery,
  useLazyFetchCategoryAndGenreOptionsQuery,
  useUpdateCategoryAndGenreMutation,
  useUpdateApplicationDetailsMutation,
  useLazyFetchDashboardRequestsQuery,
  useFetchDashboardRequestsQuery,
  useSelfServeDataAccessMutation,
  useSelfServeRequestDashboardMutation,
  useSelfServeNdaDetailsMutation,
  useFetchSelfServeRefreshConfigQuery,
  useSelfServeRefreshConfigMutation,
  useLazyFetchSelfServeAtachmentsQuery,
} = selfServe;
