import { FC } from "react";
import { Box, Button, Stack } from "@mui/material";
import menuBgTransparent from "../../../assets/images/menu-bg-transparent.svg";
import pvxLogo from "../../../assets/icons/pvx-logo.svg";

const SignupSideNav: FC = () => {
  const socials = [
    {
      label: "LinkedIn",
      href: "https://www.linkedin.com/company/pvx-partners"
    }
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#989CA21A",
        width: "18.75rem",
        borderRadius: "1rem",
        backgroundImage: `url(${menuBgTransparent})`,
        backgroundPosition: "1rem 1rem",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "4.625rem",
          width: "4.625rem",
          borderRadius: "1rem",
          backgroundColor: "#101112",
          padding: "0.875rem",
          margin: "0.6875rem",
        }}
      >
        <img height="100%" src={pvxLogo} alt="pvx-logo" />
      </Box>

      <Stack direction="column" spacing="2.625rem">
        {socials.map(({ label, href }, index) => (
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            href={href}
            key={index}
            sx={{
              textTransform: "none",
              padding: 0,
              fontSize: "1.0625rem",
              lineHeight: "1.375rem",
              fontWeight: 550,
              color: "#101112",
              justifyContent: "flex-start",
              ":hover": { backgroundColor: "rgba(0,0,0,0)" },
            }}>
            {label}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default SignupSideNav;
