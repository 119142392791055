import { FC, useMemo } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import DoughnutChartSkelton from "../../../../../components/Charts/DoughnutChart/DoughnutChartSkelton";
import {
  getFundingEligibiltyChartData,
  getFundingEligibiltyChartOptions,
  getFundingEligibiltyChartPlugins,
} from "../utils";
import { FundingEligibilityChartResponse } from "../types";

interface FundingEligibilityDoughnutChartProps {
  chartData: FundingEligibilityChartResponse | undefined;
}

const FundingEligibilityDoughnutChart: FC<
  FundingEligibilityDoughnutChartProps
> = ({
  chartData,
}) => {
  const theme = useTheme();
  const isMediumDevice = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));

  const reducedWidth = isMediumDevice ? "100%" : "40rem";
  const reducedHeight = isMediumDevice ? "17.5rem" : "20rem";

  const borderlineOffset = isSmallDevice ? 50 : isMediumDevice ? 45 : 60;
  const textOffsets = isMediumDevice ? 1.5 : 1.4;

  const memoizedChart = useMemo(() => {
    if (!chartData?.overallScore) return <DoughnutChartSkelton />;

    return (
      <Doughnut
        data={getFundingEligibiltyChartData(chartData.overallScore)}
        options={getFundingEligibiltyChartOptions()}
        plugins={getFundingEligibiltyChartPlugins(
          chartData.overallScore,
          chartData.overallScoreType,
          borderlineOffset,
          textOffsets
        )}
        updateMode="hide"
        redraw
        style={{
          display: "block",
        }}
      />
    );
  }, [chartData, isSmallDevice]);

  return (
    <Box
      sx={{
        maxWidth: isMediumDevice ? "none" : "500px",
        background: "linear-gradient(69.42deg, #181A1C 9.31%, #222D2E 88.54%)",
        borderRadius: "1rem",
        display: "flex",
        justifyContent: "center",
        px: "0.5rem",
        position: "relative",
        width: reducedWidth,
        height: reducedHeight,
      }}
    >
      {memoizedChart}
    </Box>
  );
};

export default FundingEligibilityDoughnutChart;
