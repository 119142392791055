import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DrawerComponent from "../../../../components/Drawer/Drawer";
import { useDispatch } from "react-redux";
import { TOAST_TYPES } from "@constants/constants";
import { setToastMessage } from "@stores/App/slice";

interface SignNDAFormProps {
  open: boolean;
  close: () => void;
  save: (payload: any) => void;
  details: any;
}

const SignNDAForm: FC<SignNDAFormProps> = ({ open, close, save, details }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  const [isFormValid, setIsFormValid] = useState(false);
  const [form, setForm] = useState({
    companyName: details?.companyName || "PvX Puzzler Pro LLC",
    signingAuthorityName: details?.signingAuthorityName || "PvX Demo",
    companyAddress: details?.companyAddress || "12310 Gaming Ave CA 95070",
    signingAuthorityEmail: details?.signingAuthorityEmail || "demo@pvxpartners.com",
    signingAuthorityTitle: details?.signingAuthorityTitle || "CEO",
  });
  const [errorState, setErrorState] = useState({
    companyName: false,
    signingAuthorityName: false,
    companyAddress: false,
    signingAuthorityEmail: false,
    signingAuthorityTitle: false,
  });
  const [touched, setTouched] = useState({
    companyName: false,
    signingAuthorityName: false,
    companyAddress: false,
    signingAuthorityEmail: false,
    signingAuthorityTitle: false,
  });

  useEffect(() => {
    validate(form);
  }, []);

  const validate = (updatedValue: any) => {
    let formValid = true;
    const newErrorState = { ...errorState };
    for (const field in updatedValue) {
      if (updatedValue[field as keyof typeof form].length === 0) {
        newErrorState[field as keyof typeof form] = true;
      } else {
        newErrorState[field as keyof typeof form] = false;
      }

      // Special validation for the "signingAuthorityEmail" field
      if (field === "signingAuthorityEmail") {
        const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
        newErrorState[field as keyof typeof form] = !emailRegex.test(
          updatedValue[field as keyof typeof form]
        ); // Set error if email is invalid
      }
    }

    // Check if any field has an error; if so, mark the form as invalid
    for (const field in newErrorState) {
      if (newErrorState[field as keyof typeof form]) {
        formValid = false;
        break;
      }
    }

    // Update state variables
    setErrorState(newErrorState);
    setIsFormValid(formValid);
  };

  const handleFormChange = (field: keyof typeof form, value: string) => {
    const updatedValue = {
      ...form,
      [field]: value,
    };

    validate(updatedValue);
    setForm(updatedValue);
  };

  const handleFormTouched = (field: keyof typeof form) => {
    const updatedTouched = {
      ...touched,
      [field]: true,
    };

    if (form[field].length === 0) {
      setErrorState({
        ...errorState,
        [field]: true,
      });
    }

    setTouched(updatedTouched);
  };

  const handleSendNDA = () => {
    save(form);
    dispatch(
      setToastMessage({
        type: TOAST_TYPES.DEFAULT,
        heading: "Mail Sent to the Signing Authority through DocuSign.",
        message: "Please ask the signing authority to complete the process.",
      })
    );
  };

  return (
    <DrawerComponent
      height="98vh"
      zIndex={1203}
      backgroundColor="#F0F0F1"
      open={open}
      anchor="right"
      close={close}
    >
      <Stack
        width={"400px"}
        maxWidth={isMobile ? "min(450px, 90vw)" : "450px"}
        height="100%"
        justifyContent={"space-between"}
      >
        <Box>
          {/* Header */}
          <Stack
            padding="1.5rem 1rem"
            pl="1.5rem"
            direction="row"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" gap="0.75rem">
              <Typography
                fontSize="1.375rem"
                lineHeight="1.875rem"
                fontWeight={700}
                color="#6C6C6C"
              >
                Fill Details
              </Typography>
            </Stack>
            <IconButton
              onClick={close}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              <CloseIcon sx={{ color: "#162C36" }} />
            </IconButton>
          </Stack>

          {/* Body */}
          <Stack padding={"0rem 1.5rem 1rem 1.5rem"} gap={"2rem"}>
            <Stack
              sx={{
                padding: "0rem",
                borderRadius: "0.5rem",
              }}
            >
              <Stack>
                <Typography
                  fontSize={"0.875rem"}
                  lineHeight={"1.25rem"}
                  fontWeight={500}
                  color={"#6C6C6C"}
                >
                  Please fill in the following details to send the Non Disclosure Agreement
                </Typography>
              </Stack>
            </Stack>

            <TextField
              onChange={(e) => handleFormChange("companyName", e.target.value)}
              onBlur={(e) => handleFormTouched("companyName")}
              value={form.companyName}
              error={touched["companyName"] && errorState["companyName"]}
              label="Company Name"
              placeholder="Company Name"
            />
            <TextField
              onChange={(e) =>
                handleFormChange("signingAuthorityName", e.target.value)
              }
              onBlur={(e) => handleFormTouched("signingAuthorityName")}
              value={form.signingAuthorityName}
              error={
                touched["signingAuthorityName"] &&
                errorState["signingAuthorityName"]
              }
              label="Signing Authority’s Name"
              placeholder="Signing Authority’s Name"
            />
            <TextField
              onChange={(e) =>
                handleFormChange("companyAddress", e.target.value)
              }
              onBlur={(e) => handleFormTouched("companyAddress")}
              value={form.companyAddress}
              error={touched["companyAddress"] && errorState["companyAddress"]}
              label="Company Address"
              placeholder="Company Address"
            />
            <TextField
              onChange={(e) =>
                handleFormChange("signingAuthorityEmail", e.target.value)
              }
              onBlur={(e) => handleFormTouched("signingAuthorityEmail")}
              value={form.signingAuthorityEmail}
              error={
                touched["signingAuthorityEmail"] &&
                errorState["signingAuthorityEmail"]
              }
              label="Signing Authority’s Email"
              placeholder="Signing Authority’s Email"
            />
            <TextField
              onChange={(e) =>
                handleFormChange("signingAuthorityTitle", e.target.value)
              }
              onBlur={(e) => handleFormTouched("signingAuthorityTitle")}
              value={form.signingAuthorityTitle}
              error={
                touched["signingAuthorityTitle"] &&
                errorState["signingAuthorityTitle"]
              }
              label="Signing Authority’s Title"
              placeholder="Signing Authority’s Title"
            />
          </Stack>
        </Box>

        {/* Footer Section */}
        <Stack
          padding={"1rem"}
          borderTop={"1px solid #E8EAEB"}
          direction={"row"}
        >
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            variant="contained"
            onClick={handleSendNDA}
            disabled={!isFormValid}
            sx={{
              padding: "0.625rem 1.5rem",
              borderRadius: "100px",
              textTransform: "none",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              fontWeight: 700,
              background: "#2F736E",
              color: "#fff",
              display: "flex",
              alignItems: "center",
            }}
          >
            Send NDA
          </Button>

          <Button
            onClick={close}
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              padding: "0.625rem 1.5rem",
              textTransform: "none",
              fontWeight: 700,
              color: "#51B8B0",
              ":hover": {
                background: "none",
              },
            }}
          >
            Close
          </Button>
        </Stack>
      </Stack>
    </DrawerComponent>
  );
};

export default SignNDAForm;
