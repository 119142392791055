import { FC, useEffect } from "react";
import {
  Stack,
  Typography,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import guideImagePath from '../../../../assets/images/appsflyer_accountname_guide.png'

const stepsToGrantAccess = [
  {
    title: "Log in to Your Appsflyer Dashboard",
    details: "Go to Appsflyer and log in to your account.",
  },
  {
    title: "Navigate to Team Members Settings",
    details: "In the left menu, go to ‘Account Settings’ > ‘Team Members’.",
  },
  {
    title: "Add a New Team Member",
    details: "Click ‘Invite New User’ and enter the mentioned email.",
  },
  {
    title: "Assign the Marketing Lead Role",
    details: [
      "Set the role as ‘Marketing Lead’ to allow us to pull the necessary data.",
      "Ensure permissions cover reporting and analytics access.",
    ],
  },
  {
    title: "Confirm and Send the Invitation",
    details: "Click ‘Invite’ to grant access.",
  },
  {
    title: "Notify Us",
    details:
      "Once access is granted, let us know by clicking on the ‘Access Granted’ button, so we can proceed with the data evaluation.",
  },
];

const securityAndPrivacyDetails = [
  "Your data is only used for eligibility assessment and is never shared.",
  "Access is read-only, ensuring no modifications can be made to your account.",
  "Once access is granted, we will begin analyzing your app’s performance and update you on its funding eligibility within a few days.",
];

interface GrantAccessToAppsFlyerProps {
  close: (cancel: boolean) => void;
  scrollTo: string;
}

const GrantAccessToAppsFlyer: FC<GrantAccessToAppsFlyerProps> = ({ close, scrollTo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (scrollTo) {
      const element: HTMLElement | null = document.getElementById(scrollTo);
      if (!element) return;

      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [])

  return (
    <Stack
      width={"450px"}
      maxWidth={isMobile ? "min(450px, 80vw)" : "450px"}
      height="100%"
    >
      {/* Header Section */}
      <Stack
        padding="1.5rem 1rem"
        direction="row"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" gap="0.75rem">
          <Typography
            fontSize="1.375rem"
            lineHeight="1.875rem"
            fontWeight={700}
            color="#6C6C6C"
          >
            Grant Access to Appsflyer
          </Typography>
        </Stack>
        <IconButton 
          onClick={() => close && close(true)}
          disableFocusRipple 
          disableRipple 
          disableTouchRipple>
          <CloseIcon sx={{ color: "#162C36" }} />
        </IconButton>
      </Stack>

      {/* Content Section */}
      <Stack
        padding="0.5rem 1.5rem"
        paddingBottom="0.75rem"
        gap="0.75rem"
        flex={1}
        overflow="scroll"
      >
        {/* Steps to Grant Access */}
        <Stack
          padding="1rem"
          gap="1rem"
          sx={{ background: "#fff", borderRadius: "0.5rem" }}
        >
          <Typography
            fontSize="1.375rem"
            lineHeight="1.875rem"
            fontWeight={700}
            color="#162936"
          >
            Grant Access to Evaluate Your App’s Funding Eligibility
          </Typography>
          <Typography
            fontSize="0.75rem"
            lineHeight="1rem"
            fontWeight={500}
            color="#6C6C6C"
          >
            To assess your app’s eligibility for funding, we need access to its
            performance data on Appsflyer.
          </Typography>

          <Typography
            fontSize="0.75rem"
            lineHeight="1rem"
            fontWeight={500}
            color="#6C6C6C"
          >
            Our platform analyses key performance metrics such as ROAS,
            retention, and user acquisition efficiency to determine funding
            eligibility. By granting access, we can securely fetch the required
            data and provide an accurate evaluation.
          </Typography>

          <Typography
            fontSize="1rem"
            lineHeight="1.375rem"
            fontWeight={700}
            color="#162936"
          >
            Steps to Grant Access on Appsflyer
          </Typography>
          <ol
            style={{
              margin: 0,
              paddingLeft: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {stepsToGrantAccess.map(({ title, details }, index) => (
              <Typography
                key={index}
                component="li"
                fontSize="0.875rem"
                fontWeight={700}
                lineHeight="1.25rem"
                color="#162936"
              >
                {title}
                <ul
                  style={{
                    margin: 0,
                    paddingLeft: "0.5rem",
                    listStyleType: "disc",
                  }}
                >
                  {Array.isArray(details) ? (
                    details.map((detail, idx) => (
                      <Typography
                        key={idx}
                        component="li"
                        fontSize="0.75rem"
                        lineHeight="1rem"
                        fontWeight={500}
                        color="#6C6C6C"
                      >
                        {detail}
                      </Typography>
                    ))
                  ) : (
                    <Typography
                      component="li"
                      fontSize="0.75rem"
                      lineHeight="1rem"
                      fontWeight={500}
                      color="#6C6C6C"
                    >
                      {details}
                    </Typography>
                  )}
                </ul>
              </Typography>
            ))}
          </ol>
        </Stack>

        {/* AppsFlyer Account Name */}
        <Stack
          id="appsflyer-account-name"
          padding="1rem"
          gap="1rem"
          sx={{ background: "#fff", borderRadius: "0.5rem" }}>
          <Typography
            fontSize="1.375rem"
            lineHeight="1.875rem"
            fontWeight={700}
            color="#162936"
          >
            Apps Flyer Account name
          </Typography>

          <Typography
            fontSize="0.875rem"
            lineHeight="1.25rem"
            fontWeight={500}
            color="#6C6C6C">
              Find the Account Name on the top right corner of your Appsflyer account.
          </Typography>
          <img src={guideImagePath} alt="img"/>
        </Stack>

        {/* Data we collect */}
        <Stack
          id="data-we-connect"
          padding="1rem"
          gap="1rem"
          sx={{ background: "#fff", borderRadius: "0.5rem" }}>
          <Typography
            fontSize="1.375rem"
            lineHeight="1.875rem"
            fontWeight={700}
            color="#162936"
          >
            Data we collect
          </Typography>

          <Typography
            fontSize="0.875rem"
            lineHeight="1.25rem"
            fontWeight={500}
            color="#6C6C6C">
              Aggregated Cohort data of the following metrics
          </Typography>

          <ul style={{ margin: 0, paddingLeft: "1rem" }}>
            <Typography
              component="li"
              fontSize="0.875rem"
              lineHeight="1.25rem"
              fontWeight={500}
              color="#6C6C6C">
                Revenue & Marketing Spend - IAA & IAP
            </Typography>

            <Typography
              component="li"
              fontSize="0.875rem"
              lineHeight="1.25rem"
              fontWeight={500}
              color="#6C6C6C">
                Payer Conversion & Retention
            </Typography>

            <Typography
              component="li"
              fontSize="0.875rem"
              lineHeight="1.25rem"
              fontWeight={500}
              color="#6C6C6C">
                Revenue Split by Marketing Channel, Platform & Geo
            </Typography>

            <Typography
              component="li"
              fontSize="0.875rem"
              lineHeight="1.25rem"
              fontWeight={500}
              color="#6C6C6C">
                Marketing Spend by Marketing Channel, Platform & Geo
            </Typography>
          </ul>

          <Typography
            fontSize="0.875rem"
            lineHeight="1.25rem"
            fontWeight={500}
            color="#6C6C6C">
            Note: We do not collect any PII data such as device id, GAID or IDFA for the purpose of our analysis.
          </Typography>
        </Stack>

        {/* Security & Privacy */}
        <Stack
          padding="1rem"
          gap="1rem"
          sx={{ background: "#fff", borderRadius: "0.5rem" }}
        >
          <Typography
            fontSize="1.375rem"
            lineHeight="1.875rem"
            fontWeight={700}
            color="#162936"
          >
            Security & Privacy
          </Typography>
          <ul style={{ margin: 0, paddingLeft: "1rem" }}>
            {securityAndPrivacyDetails.map((text, idx) => (
              <Typography
                key={idx}
                component="li"
                fontSize="0.875rem"
                lineHeight="1.25rem"
                fontWeight={500}
                color="#6C6C6C"
              >
                {text}
              </Typography>
            ))}
          </ul>
        </Stack>
      </Stack>

      {/* Footer Section */}
      <Stack padding={"1rem"} borderTop={"1px solid #E8EAEB"} direction={"row"}>
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          variant="contained"
          onClick={() => close && close(true)}
          sx={{
            padding: "0.625rem 1.5rem",
            borderRadius: "100px",
            textTransform: "none",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            fontWeight: 500,
            background: "#2F736E",
            color: "#fff",
          }}
        >
          Close
        </Button>
      </Stack>
    </Stack>
  );
};

export default GrantAccessToAppsFlyer;
