import { FC } from "react";
import { Button, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface CustomButtonProps {
  color: string;
  name: string;
  backgroundColor?: string;
  borderColor?: string;
  onClick: () => void;
  disabled?: boolean;
  startIcon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  height?: string;
}

const CustomButton: FC<CustomButtonProps> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        backgroundColor: props.disabled ? `${props.backgroundColor}5A` : props.backgroundColor,
        borderRadius: "2rem",
        padding: "0.625rem 1.5rem",
        alignItems: "center",
        textTransform: "none",
        border: `1px solid ${props.borderColor}`,
        "&:hover": {
          backgroundColor: props.backgroundColor,
        },
        height: props.height,
        display: "flex",
        gap: "0.2rem"
      }}
      disabled={props.disabled}
    >
      {props.startIcon && <props.startIcon sx={{ color: props.color, height: "1rem" }} />}
      <Typography
        fontSize={"0.875rem"}
        fontWeight={500}
        sx={{ color: props.color }}
      >
        {props.name}
      </Typography>
    </Button>
  );
};

export default CustomButton;
