import { Box, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { FC } from "react";
import { AttributesData } from "../../CustomDashboards/Benchmarks";

const BenchmarkingAttributesField: FC<{ boxData: AttributesData[], title: string, minValue: number, maxValue: number }> = ({ boxData, title, minValue, maxValue }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const getNormalisedValue = (value: number, minValue: number, maxValue: number) => {
    if (value <= minValue) return "calc(0% - 0.656rem)";
    if (value >= maxValue) return "calc(100% - 0.656rem)";

    let normalized = ((value - minValue) / (maxValue - minValue)) * 100;
    return `calc(${normalized}% - 0.656rem)`;
  };

  const getTooltipText = () => {
    const dataPoints = [
      { label: "Lowest", value: minValue, isPercentile: false },
      ...[
        ...(parseFloat(boxData?.[3]?.value) > 0
          ? [{ label: `P${parseFloat(boxData?.[3]?.value).toFixed(0)}`, value: boxData?.[3]?.tooltipValue || "-", sortValue: parseFloat(boxData?.[3]?.value) || 0, isPercentile: true }]
          : []),
        { label: "P25", value: boxData?.[0]?.tooltipValue, sortValue: parseFloat(boxData?.[0]?.value) || 0, isPercentile: false },
        { label: "P50", value: boxData?.[1]?.tooltipValue, sortValue: parseFloat(boxData?.[1]?.value) || 0, isPercentile: false },
        { label: "P75", value: boxData?.[2]?.tooltipValue, sortValue: parseFloat(boxData?.[2]?.value) || 0, isPercentile: false }
      ].sort((a, b) => a.sortValue - b.sortValue),
      { label: "Highest", value: maxValue },
    ];

    return dataPoints;
  };


  return (
    <Box
      sx={{
        height: "4.375rem",
        width: "100%",
        border: "2px solid #E8EAEB",
        borderRadius: "0.5rem",
        backgroundColor: "#F0F0F1",
        padding: "1rem 1.5rem",
      }}
    >
      <Tooltip
        followCursor
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#FFFFFF",
              border: "1px solid #737F86",
              fontSize: "0.875rem",
              padding: "8px 12px",
              borderRadius: "0.5rem",
              width: "100%",
              maxWidth: "none",
            },
          },
        }}
        title={isMobile
          ? boxData.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
                width: "10rem",
              }}
            >
              {
                getTooltipText().map((item: any, index: number) => (
                  <Typography key={index} sx={{ fontWeight: 700, fontSize: "0.625rem", color: "#6C6C6C", display: "flex", justifyContent: "space-between" }}>
                    {item.label}<span>{item.value}</span>
                  </Typography>
                ))
              }
            </Box>
          ) : boxData.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                width: "100%",
                minWidth: "max-content",
                flexGrow: 1,
              }}
            >
              {
                getTooltipText().map((item: any, index: number) => (
                  <Typography
                    key={index}
                    sx={{
                      color: item.isPercentile ? boxData?.[3]?.color : "#6C6C6C",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <span style={{ fontSize: "0.625rem", fontWeight: 800 }}>{item.label}</span><span style={{ fontSize: "0.75rem", fontWeight: 800 }}>{item.value}</span>
                  </Typography>
                ))
              }
            </Box>
          )}
      >
        <Box
          sx={{
            height: "100%",
            borderRadius: "0.5rem",
            background: "repeating-linear-gradient(135deg, #F0F0F1, #F0F0F1 1px, #E0E0E0 5px, #E0E0E0 1px)",
            position: "relative",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          {boxData?.map((data: AttributesData, index) => (
            (data.value !== null && data.tooltipValue !== "-") &&
              <Box
                key={data.label || index}
                sx={{
                  width: "1.313rem",
                  height: "106%",
                  backgroundColor: data.color,
                  border: `1px solid ${data.border}`,
                  borderRadius: "0.25rem",
                  position: "absolute",
                  transform: "translateY(-1px)",
                  left: getNormalisedValue(parseFloat(data.value), 0, 100),
                  zIndex: data.color === "#2F736E14" ? 1 : 0
                }}
              >
                <Typography
                  sx={{
                    width: "1.313rem",
                    fontFamily: "Monrope, sans-serif",
                    fontWeight: 700,
                    fontSize: "0.625rem",
                    color: "#B4B4B4",
                    transform: "translateY(2.125rem)"
                  }}
                >
                  {data.label}
                </Typography>
              </Box>
            )
          )}
        </Box>
      </Tooltip>
    </Box>
  )
}

export default BenchmarkingAttributesField;