import { useLazyGetSubModuleFiltersQuery } from "../../api/userModules";
import { useDispatch } from "react-redux";
import { Module } from "@stores/Modules/types/modules";
import { setAuthorisedModule, setFiltersFetched } from "@stores/Modules/slice";
import { useState } from "react";

const useSubmodulesFilters = () => {
  const dispatch = useDispatch();
  const [getSubModuleFilters, { isError }] = useLazyGetSubModuleFiltersQuery();
  const [isFetching, setIsFetching] = useState(false);

  const getSubModulesFilters = async (modules: Module[]) => {
    try {
      const clonedModules: Module[] = structuredClone(modules);

      const futures = [];

      for (const module of clonedModules) {
        if (module.submodules) {
          for (const subModule of module.submodules) {
            const promise = getSubModuleFilters(subModule.id)
              .unwrap()
              .then((filtersResponse) => {
                const { submoduleFilterValues: filters } = filtersResponse.data;
                subModule.filters = filters || [];
              })
              .catch((error) => {
                console.error(
                  `Failed to fetch filters for Submodule ${subModule.id}:`,
                  error
                );
              });

            futures.push(promise);
          }
        }
      }

      setIsFetching(true);
      await Promise.all(futures);

      dispatch(setAuthorisedModule(clonedModules));
      dispatch(setFiltersFetched());
    } catch (error) {
      console.error("Fetching filters failed: ", error);
    } finally {
      setIsFetching(false);
    }
  };

  return { getSubModulesFilters, isLoading: isFetching, isError };
};

export default useSubmodulesFilters;
