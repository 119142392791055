import { FC, memo, useState, useEffect, ChangeEvent } from "react";
import {
  useTheme,
  useMediaQuery,
  Stack,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { setToastMessage } from "@stores/App/slice";
import { useDispatch } from "react-redux";
import {
  PLAY_STORE_LINK_REGEX,
  APP_STORE_LINK_REGEX,
  APP_STORE_LINK_REGEX_2,
} from "@constants/constants";
import { IGameDetails } from "./GameDetails";
import CustomInputField from "../../../FundingCalculator/components/CustomInputField";
import { TOAST_TYPES } from "@constants/constants";

interface EditGameProps {
  playStoreApp?: IGameDetails | null;
  appStoreApp?: IGameDetails | null;
  selfServeId: string;
  close?: (cancel?: boolean) => void;
}

const EditGame: FC<EditGameProps> = memo(
  ({ playStoreApp, appStoreApp, selfServeId, close }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();

    const playStoreLink = playStoreApp?.appUrl || "";
    const appStoreLink = appStoreApp?.appUrl || "";

    const [linkToPlayStore, setLinkToPlayStore] =
      useState<string>(playStoreLink);
    const [linkToAppStore, setLinkToAppStore] = useState<string>(appStoreLink);
    const [touchedPlayStoreLink, setTouchedPlayStoreLink] =
      useState<boolean>(false);
    const [touchedAppStoreLink, setTouchedAppStoreLink] =
      useState<boolean>(false);
    const [errorState, setErrorState] = useState({
      linkToPlayStore: false,
      linkToAppStore: false,
    });
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const handleSaveApplicationDetails = async (): Promise<void> => {
      try {
        !!close && close();
      } catch (error: any) {
        dispatch(setToastMessage({
          type: TOAST_TYPES.ERROR,
          message: error?.error?.message || null
        }));
      }
    };

    useEffect(() => {
      let newErrorState = {
        ...errorState,
      };
    
      const isPlayStoreLinkValid = PLAY_STORE_LINK_REGEX.test(linkToPlayStore);
      const isAppStoreLinkValid = APP_STORE_LINK_REGEX.test(linkToAppStore) || APP_STORE_LINK_REGEX_2.test(linkToAppStore);
    
      if (!linkToPlayStore && !linkToAppStore) {
        // If both are empty, set errors and invalidate form
        newErrorState = {
          linkToPlayStore: true,
          linkToAppStore: true,
        };
        setIsFormValid(false);
      } else {
        newErrorState = {
          linkToPlayStore: linkToPlayStore.length > 0 && !isPlayStoreLinkValid,
          linkToAppStore: linkToAppStore.length > 0 && !isAppStoreLinkValid,
        };

        const isValidForm =
          (isPlayStoreLinkValid && isAppStoreLinkValid) ||
          (isPlayStoreLinkValid && !linkToAppStore) ||
          (isAppStoreLinkValid && !linkToPlayStore);
    
        setIsFormValid(isValidForm);
      }
    
      setErrorState(newErrorState);
    }, [linkToPlayStore, linkToAppStore]);    

    return (
      <Stack
        width={"390px"}
        maxWidth={isMobile ? "min(390px, 80vw)" : "390px"}
        height={"100%"}
      >
        <Stack flex={1}>
          <Stack
            padding={"1.5rem 1rem"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} gap={"0.75rem"}>
              <Typography
                fontSize={"1.375rem"}
                lineHeight={"1.875rem"}
                fontWeight={700}
                color={"#6C6C6C"}
              >
                Change Game
              </Typography>
            </Stack>

            <Stack>
              <IconButton 
                onClick={() => close && close(true)} 
                disableFocusRipple 
                disableRipple 
                disableTouchRipple>
                <CloseIcon
                  sx={{
                    color: "#162C36",
                  }}
                />
              </IconButton>
            </Stack>
          </Stack>
          <Stack flex={1} gap={"0.75rem"} padding={"0.5rem 1.5rem"}>
            <CustomInputField
              label="LINK TO PLAY STORE"
              value={linkToPlayStore}
              error={touchedPlayStoreLink && errorState.linkToPlayStore}
              onBlur={() => setTouchedPlayStoreLink(true)}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setLinkToPlayStore(e.target.value)}
            ></CustomInputField>

            <CustomInputField
              label="LINK TO APP STORE"
              value={linkToAppStore}
              error={touchedAppStoreLink && errorState.linkToAppStore}
              onBlur={() => setTouchedAppStoreLink(true)}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setLinkToAppStore(e.target.value)}
            ></CustomInputField>
          </Stack>
        </Stack>

        <Stack
          padding={"1rem 1.5rem"}
          direction={"row"}
          borderTop={"1px solid #E8EAEB"}
        >
          <Button
            onClick={handleSaveApplicationDetails}
            disabled={ !isFormValid }
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            variant="contained"
            sx={{
              padding: "0.625rem 1.5rem",
              borderRadius: "100px",
              textTransform: "none",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              fontWeight: 500,
              background: "#2F736E",
              color: "#fff"
            }}
          >
            Save
          </Button>
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={() => close && close(true)}
            sx={{
              padding: "0.625rem 1.5rem",
              borderRadius: "100px",
              textTransform: "none",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              fontWeight: 500,
              color: "#51B8B0",
              ":hover": {
                background: "none",
              },
            }}
          >
            Close
          </Button>
        </Stack>
      </Stack>
    );
  }
);

export default EditGame;