import { useLazyGetChartsQuery } from "@api/charts";

const useCharts = () => {
    const [getCharts, { isLoading, isError }] = useLazyGetChartsQuery();

    const getChartData = async (submoduleId: string) => {
        try {
            let response = await getCharts(submoduleId).unwrap();
            if (response?.data) {
                return response.data;
            }

            return null;

        } catch (err) {
            console.error(err);
        }
    };

    return { getChartData, isLoading, isError };
};

export default useCharts;
