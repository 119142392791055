import { FC } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { RootState } from "@stores/store";

const GettingStartedDashboards: FC = () => {
  const modulesState = useSelector((state: RootState) => state?.ModulesData);
  const navigate = useNavigate();

  if (modulesState?.modules.filter((module) => module.requestStatus === "APPROVED").length) {
    navigate('/dashboard');
    return null;
  }
  
  return <Outlet />;
};

export default GettingStartedDashboards;
