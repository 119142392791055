import { FC, memo } from "react";
import { useMediaQuery, useTheme, Box, Stack, Typography, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { APPLICATION_STORES } from "@constants/constants";
import playStoreIcon from "../../../../assets/icons/play-store.png";
import appStoreIcon from "../../../../assets/icons/app-store.png";
import starIcon from "../../../../assets/icons/star.svg";

export interface IGameDetails {
  appUrl: string;
  developerName: string;
  downloads: string;
  icon: string;
  marketPlace: (typeof APPLICATION_STORES)[keyof typeof APPLICATION_STORES];
  name: string;
  rating: string | number;
  reviews: string;
}

interface GameDetailsProps {
  gameDetails: IGameDetails;
  setIsEditGameOpen: () => void;
  category?: string | null;
  genre?: string | null;
}

const GameDetails: FC<GameDetailsProps> = memo(
  ({ gameDetails, setIsEditGameOpen, category, genre }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "1rem",
          justifyContent: isMobile ? "start": "space-between",
          backgroundColor: "#2F736E14",
          border: "1px solid #2F736E1F",
          borderRadius: "0.25rem",
          padding: "0.75rem",
          flex: "1",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              height: "52px",
              minWidth: "52px",
              backgroundColor: "black",
              borderRadius: "0.375rem",
              overflow: "hidden",
            }}
          >
            <img alt="game-icon" src={gameDetails.icon} height="100%" width="100%" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.25rem",
            }}
          >
            <Stack direction={"row"} alignItems={'flex-start'} gap={"0.5rem"}>
              <Typography
                fontSize={"1rem"}
                lineHeight={"1.375rem"}
                fontWeight={700}
                color={"#162936"}
                sx={{
                  display: "-webkit-box",
                  "-webkit-box-orient": "vertical",
                  "-webkit-line-clamp": "2",
                  overflow: "hidden",
                }}
              >
                {gameDetails.name}
              </Typography>

              {
                !(!!category && !!genre) && 
                <IconButton
                  onClick={setIsEditGameOpen}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  sx={{
                    fontSize: "0.875rem",
                    color: "#51B8B0",
                    lineHeight: "1.25rem",
                    fontWeight: "700",
                    textDecoration: "none",
                    padding: 0,
                    paddingTop: "0.25rem",
                    textTransform: "none",
                    ":hover": {
                      background: "none",
                    },
                  }}
                >
                  <EditIcon sx={{
                    fontSize: "1rem"
                  }}/>
                </IconButton>
              }
            </Stack>
            <Typography
              fontSize={"0.85rem"}
              lineHeight={"1rem"}
              fontWeight={700}
              color={"#6C6C6C"}
            >
              {gameDetails.developerName}

              {category && ` • ${category}`}

              {genre && ` • ${genre}`}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "1.5rem",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                ...(!isMobile && {
                  justifyContent: "flex-end"
                }),
              }}
            >
              <img
                src={
                  gameDetails.marketPlace === APPLICATION_STORES.PLAY_STORE
                    ? playStoreIcon
                    : appStoreIcon
                }
                alt="play-store-icon"
                height={24}
                width={24}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.25rem",
                }}
              >
                <Typography
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  fontWeight={700}
                  color={"#162936"}
                >
                  { gameDetails.rating }
                </Typography>
                <img height={16} width={16} src={starIcon} alt="rating-star" />
              </Box>
            </Box>

            {
              gameDetails.marketPlace === APPLICATION_STORES.PLAY_STORE &&
              <Box>
                <Typography
                  fontSize={"0.85rem"}
                  lineHeight={"1rem"}
                  fontWeight={700}
                  color={"#6C6C6C"}
                  textAlign={!isMobile ? "right" : "left"}
                >
                  {gameDetails.downloads} Downloads
                </Typography>
              </Box>
            }
          </Box>
        </Box>
      </Box>
    );
  }
);

export default GameDetails;