import { LocalStorage } from '../utils/storage';

const ACCESS_TOKEN = 'access';
const REFRESH_TOKEN = 'refresh';
const DEMO_TOKEN = 'demo';

interface UserToken {
  access_token: string;
  refresh_token: string;
}

class UserAuth {
  static clearTokens(): void {
    LocalStorage.remove(ACCESS_TOKEN);
    LocalStorage.remove(REFRESH_TOKEN);
    LocalStorage.remove(DEMO_TOKEN);
  }

  static setTokens(token: UserToken): void {
    LocalStorage.setItem(ACCESS_TOKEN, token.access_token);
    LocalStorage.setItem(REFRESH_TOKEN, token.refresh_token);
  }

  static get refreshToken(): string | null {
    return LocalStorage.getItem(REFRESH_TOKEN);
  }

  static get accessToken(): string | null {
    return LocalStorage.getItem(ACCESS_TOKEN);
  }

  static get isAuthenticated(): boolean {
    return Boolean(UserAuth.accessToken);
  }

  static get demoToken(): string | null {
    return LocalStorage.getItem(DEMO_TOKEN);
  }

  static setDemoToken(demoToken: string): void {
    LocalStorage.setItem(DEMO_TOKEN, demoToken);
  }
}

export default UserAuth;
