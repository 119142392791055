import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NAV_ROUTES } from "../../constants/navRoutes";
import { SideNavProps } from "./types";
import MobileNavBar from "./MobileNavBar";
import DesktopNavBar from "./DesktopNavBar";
import { DEMO_GETTING_STARTED_FILTER_OPTIONS, DEMO_USER_EMAILS, GETTING_STARTED_FILTER_OPTIONS } from "@constants/constants";
import UserAuth from "@services/auth";
import { RootState } from "@stores/store";
import { useSelector } from "react-redux";

const SideNav: React.FC<SideNavProps> = ({
  customWidth,
  options,
  isAdmin,
  username,
  onClickLogout,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModule, setOpenModule] = useState<number | null>(null);
  const [selectedSubmodule, setSelectedSubmodule] = useState<string | null>(
    null
  );

  const userData = useSelector((state: RootState) => state?.UserData);

  let filteredOptions = isAdmin ? options.filter((option) => option?.subOptions?.[0]?.type !== "FUNDING_REQUEST") : options;

  // TODO: REMOVE AFTER DEMO 
  if (userData.user.email && DEMO_USER_EMAILS.includes(userData.user.email) && !UserAuth.demoToken) {
    filteredOptions = DEMO_GETTING_STARTED_FILTER_OPTIONS;
  } else if (filteredOptions.filter(option => option.requestStatus === "APPROVED").length === 0 && location.pathname.includes(NAV_ROUTES.GETTING_STARTED)) {
    filteredOptions = GETTING_STARTED_FILTER_OPTIONS;
  }

  const handleModuleClick = (index: number, subOptions?: { label: string; path: string }[]) => {
    const isSubmoduleSelected = subOptions?.map((option) => option.label).includes(selectedSubmodule || '')
    if (isSubmoduleSelected && openModule !== null) {
      setOpenModule(null);
      return;
    }
    
    if (subOptions && subOptions.length) {
      setOpenModule(openModule === index ? null : index);
      if (subOptions.length > 0 && !isSubmoduleSelected) {
        const urlModuleParam = filteredOptions[index].label.toLowerCase()
          .replace(/ /g, "_")
          .replace(/\(|\)/g, "");
        setSelectedSubmodule(subOptions[0].label);
        navigate(
          `${NAV_ROUTES.DASHBOARD}/${urlModuleParam}/${subOptions[0].path}`
        );
      }
    } else {
      setSelectedSubmodule(null);
      navigate(filteredOptions[index].path);
    }
  };

  const handleSubModuleClick = (path: string, label: string) => {
    setSelectedSubmodule(label)
    navigate(
      `${NAV_ROUTES.DASHBOARD}/${path
        .toLowerCase()
        .replace(/ /g, "_")
        .replace(/\(|\)/g, "")}`
    );
  };

  const isCurrentRoute = (path: string | string[]): boolean => {
    if (Array.isArray(path)) {
      const id = location.pathname.split("/").pop() || "";
      return id ? path.includes(id) : false;
    }

    // For getting started the absolute path is checked.
    if (path.includes(NAV_ROUTES.GETTING_STARTED)) {
      return location.pathname === path;
    }

    return location.pathname.includes(path);
  };

  return (
    <>
      {/* Mobile View */}
      <MobileNavBar
        filteredOptions={filteredOptions}
        handleModuleClick={handleModuleClick}
        handleSubModuleClick={handleSubModuleClick}
        isCurrentRoute={isCurrentRoute}
        openModule={openModule}
        setOpenModule={setOpenModule}
        setSelectedSubmodule={setSelectedSubmodule}
        userName={username}
        isAdmin={isAdmin}
        customWidth={customWidth}
        onClickLogout={onClickLogout}
      />
      {/* Desktop View */}
      <DesktopNavBar
        filteredOptions={filteredOptions}
        handleModuleClick={handleModuleClick}
        handleSubModuleClick={handleSubModuleClick}
        isCurrentRoute={isCurrentRoute}
        openModule={openModule}
        setOpenModule={setOpenModule}
        setSelectedSubmodule={setSelectedSubmodule}
        userName={username}
        isAdmin={isAdmin}
        customWidth={customWidth}
        onClickLogout={onClickLogout}
      />
    </>
  );
};

export default SideNav;
