import { createSlice } from '@reduxjs/toolkit';
import { ApiError } from './types/api';
import { AppState, Auth, ToastTypes } from './types/app';
import { TOAST_TYPES } from '@constants/constants';

const initialState: AppState = {
  apiErrorLog: {},
  auth: {
    access_token: '',
    refresh_token: ''
  },
  toast: {
    type: TOAST_TYPES.DEFAULT,
    toast_message: '',
    open: false,
    heading: ''
  }
};

export const AppData = createSlice({
  name: 'AppData',
  initialState,
  reducers: {
    setApiErrorLog: (state: AppState, { payload }: { payload: ApiError }) => {
      const data = state?.apiErrorLog
        ? { ...state.apiErrorLog, [payload.endpoint]: payload }
        : { [payload.endpoint]: payload };

      return { ...state, apiErrorLog: data };
    },
    unsetApiErrorLog: (state) => ({ ...state, apiErrorLog: {} }),
    setAuthToken: (state: AppState, { payload }: { payload: Auth }) => {
      return { ...state, auth: { ...payload } }
    },
    resetAppData: () => initialState,
    setToastMessage: (state: AppState, { payload }: {
      payload: {
        type?: ToastTypes,
        heading?: string,
        message: string | undefined | null,
      }
    }) => {
      return {
        ...state,
        toast: {
          type: payload.type || TOAST_TYPES.DEFAULT,
          toast_message: payload.message || 'Something went wrong',
          open: true,
          heading: payload.heading || ''
        }
      };
    },
    resetToastMessage: (state: AppState) => {
      return { ...state, toast: { ...state.toast, open: false } };
    },
  }
});

export const {
  setAuthToken,
  setApiErrorLog,
  unsetApiErrorLog,
  resetAppData,
  setToastMessage,
  resetToastMessage
} = AppData.actions;

export default AppData.reducer;
