const NAV_ROUTES = {
    AUTH: '/auth',
    AUTH_REDIRECT: '/callback',
    USER_MANAGEMENT: '/user-management',
    USER_MANAGEMENT_INVITE: '/user-management/user-invite',
    USER_MANAGEMENT_DETAILS: '/user-management/user-details/:id',
    DASHBOARD_REQUESTS: '/dashboard-requests',
    DASHBOARD: '/dashboard',
    DASHBOARD_MODULE: '/dashboard/:module',
    DASHBOARD_MODULE_SUBMODULE: '/dashboard/:module/:submodule',
    HOME: '/home',
    ERROR: '/server-error',
    PAGE_NOT_FOUND: '/page-not-found',
    FUNDING_CALCULATOR: '/estimate-facility-size',
    FUNDING_CALCULATOR_PLAYGROUND: '/funding-calculator',
    FUNDING_CALCULATOR_DETAILS: '/funding-calculator/details',
    FUNDING_CALCULATOR_DETAILS_ID: '/funding-calculator/details/:calculationId',
    SIGNUP: '/sign-up',
    GETTING_STARTED: '/dashboard/getting-started',
    GETTING_STARTED_UNDERWRITING: '/dashboard/getting-started/underwriting',
    GETTING_STARTED_BENCHMARKS: '/dashboard/getting-started/benchmarks',
    GETTING_STARTED_FINANCIAL_PROJECTIONS: '/dashboard/getting-started/financial-projections',
    GETTING_STARTED_FUNDING_ELIGIBILITY: '/dashboard/getting-started/funding-eligibility',
};

const PAGE_TITLES = {
    AUTH: 'PvX | Login',
    AUTH_REDIRECT: 'PvX | Login',
    USER_MANAGEMENT: 'PvX | Users',
    USER_MANAGEMENT_INVITE: 'PvX | Users',
    USER_MANAGEMENT_DETAILS: 'PvX | Users',
    DASHBOARD: 'PvX | Dashboards',
    DASHBOARD_MODULE: 'PvX | Dashboards',
    DASHBOARD_MODULE_SUBMODULE: 'PvX | Dashboards',
    HOME: 'PvX | Home',
    ERROR: 'PvX | Error',
    PAGE_NOT_FOUND: 'PvX | Page Not Found',
    FUNDING_CALCULATOR: 'PvX | Funding Calculator',
    FUNDING_CALCULATOR_PLAYGROUND: 'PvX | Funding Calculator',
    FUNDING_CALCULATOR_DETAILS: 'PvX | Funding Calculator',
    FUNDING_CALCULATOR_DETAILS_ID: 'PvX | Funding Calculator',
};

// TODO: REMOVE AFTER DEMO 
const DEMO_NAV_ROUTES = {
    SIGNUP: '/demo/sign-up',
    GETTING_STARTED: '/demo/dashboard/getting-started',
    GETTING_STARTED_UNDERWRITING: '/demo/dashboard/getting-started/underwriting',
    GETTING_STARTED_BENCHMARKS: '/demo/dashboard/getting-started/benchmarks',
    GETTING_STARTED_FINANCIAL_PROJECTIONS: '/demo/dashboard/getting-started/financial-projections',
    GETTING_STARTED_FUNDING_ELIGIBILITY: '/demo/dashboard/getting-started/funding-eligibility',
};

export { NAV_ROUTES, PAGE_TITLES, DEMO_NAV_ROUTES };
