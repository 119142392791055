import apiRoutes from '../constants/apiRoutes';
import baseApi from '../services/api';

export const INVESTMENT_STATUS: Record<string, string> = {
  PENDING_CONTRACT_CREATION: "PENDING_CONTRACT_CREATION", // Funding request is waiting for contract creation
  PENDING_SIGNATURE: "PENDING_SIGNATURE", // Funding request is waiting for signature from client
  PENDING_ALLOCATION: "PENDING_ALLOCATION", // Funding request is waiting for allocation
  PENDING_APPROVAL: "PENDING_APPROVAL", // Allocation confirmed, is waiting to be counter-signed
  APPROVED: "APPROVED", // Funding request is approved
  CLOSED: "CLOSED", // Funding request is closed
  REJECTED_ADJUSTMENT: "REJECTED_ADJUSTMENT", // Adjusted Funding request is rejected allotment
  REJECTED: "REJECTED" // Funding request is rejected
};

export const INVESTMENT_STATUS_VALUES = {
  PENDING_CONTRACT_CREATION: "Pending Creation",
  PENDING_SIGNATURE: "Pending Signature (Client)",
  PENDING_ALLOCATION: "Pending Allocation",
  PENDING_APPROVAL: "Pending Signature",
  APPROVED: "Allocated",
  CLOSED: "Closed",
  REJECTED_ADJUSTMENT: "Rejected",
  REJECTED: "Rejected"
};

export const FUNDING_TYPES: Record<string, string> = {
  "Revenue Share": "Collection",
  FINANCING_FUNDING_REFUND: 'Financing (Funding Refund)',
  FINANCING_PAYMENTS: 'Financing (Payments)',
  FRESH_FINANCING: 'Fresh Financing',
  RE_FINANCING: 'Re-Financing',
  FUNDING_REFUND: 'Funding Refund',
  FUNDING_TOP_UP: 'Funding Top Up',
  PAYMENTS: 'Payments',
  ADJUSTMENTS: 'Adjustments',
  FINANCING_ADJUSTMENTS: 'Financing (Adjustments)'
};

export const ALLOCATION_TYPES: Record<string, string> = {
  ALLOCATION: "ALLOCATION",
  ADJUSTMENT: "ADJUSTMENT"
};

export interface InvestmentRequestPayload {
  requestId?: string,
  gameId: string;
  cohort: string,
  expectedSpend: number,
  requestedFund: number,
  nextExpectedSpend: number,
  experimentalSpend: number
}

export interface AdjustmentRequestPayload {
  requestId: string,
  actualSpend: string
}

export interface AllocationRequests {
  investor: string,
  investmentType: string,
  amount: number,
  settlementDate: string
}

export interface CreateAllocationRequestPayload {
  requestId: string;
  allocations: AllocationRequests[]
}

const investments = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvestmentRequestByGameId: builder.query<any, any>({
      query: ({ gameId, queryParams }: { gameId: string, queryParams: any }) => (queryParams ? `${apiRoutes.investmentRequests}/games/${gameId}${queryParams}` : `${apiRoutes.investmentRequests}/games/${gameId}`)
    }),
    getInvestmentRequests: builder.query<any, any>({
      query: ({ queryParams }: { queryParams: any }) => (queryParams ? `${apiRoutes.investmentAdminRequests}${queryParams}` : apiRoutes.investmentAdminRequests)
    }),
    getInvestmentRequestAllocationByGameId: builder.query<any, any>({
      query: ({ requestId }: { requestId: string }) => `${apiRoutes.investmentAdminRequests}/${requestId}/allocation`
    }),
    getInvestmentRequestGameFilters: builder.query<any, any>({
      query: () => `${apiRoutes.investmentRequests}/games`
    }),
    getInvestmentRequestCohortFilters: builder.query<any, any>({
      query: () => `${apiRoutes.investmentAdminRequests}/cohorts`
    }),
    getInvestmentRequestInvestorFilters: builder.query<any, any>({
      query: ({ gameId }: { gameId: string }) => `${apiRoutes.investmentAdminRequests}/games/${gameId}/investors`
    }),
    getInvestmentAdminRequestById: builder.query<any, any>({
      query: ({ id, queryParams }: { id: string, queryParams: any }) => (queryParams ? `${apiRoutes.investmentAdminRequests}/${id}${queryParams}` : `${apiRoutes.investmentAdminRequests}/${id}`)
    }),
    getInvestmentAttachmentsByRequestId: builder.query<any, any>({
      query: ({ requestId }: { requestId: string }) => `${apiRoutes.investmentRequests}/${requestId}/attachments`
    }),
    downloadInvestmentAttachmentsByRequestId: builder.query<any, any>({
      query: ({ requestId, attachmentId }: { requestId: string, attachmentId: string }) => `${apiRoutes.investmentRequests}/${requestId}/attachments/${attachmentId}/download`
    }),
    createInvestmentRequest: builder.mutation<any, any>({
      query: (payload: InvestmentRequestPayload) => ({
        url: `${apiRoutes.investmentRequests}/games/${payload.gameId}`,
        method: 'POST',
        body: payload
      }),
    }),
    createAdjustmentRequest: builder.mutation<any, any>({
      query: (payload: AdjustmentRequestPayload) => ({
        url: `${apiRoutes.investmentRequests}/${payload.requestId}/adjustment`,
        method: 'POST',
        body: payload
      }),
    }),
    createAllocationRequest: builder.mutation<any, any>({
      query: (payload: CreateAllocationRequestPayload) => ({
        url: `${apiRoutes.investmentAdminRequests}/${payload.requestId}/allocate`,
        method: 'POST',
        body: payload.allocations
      }),
    }),
    modifyInvestmentRequest: builder.mutation<any, any>({
      query: (payload: InvestmentRequestPayload) => ({
        url: `${apiRoutes.investmentRequests}/${payload.requestId}`,
        method: 'PUT',
        body: payload
      }),
    }),
    modifyAdjustmentRequest: builder.mutation<any, any>({
      query: (payload: AdjustmentRequestPayload) => ({
        url: `${apiRoutes.investmentRequests}/${payload.requestId}/adjustment`,
        method: 'PUT',
        body: payload
      }),
    }),
    rejectInvestmentRequest: builder.mutation<any, any>({
      query: ({ requestId }: { requestId: string }) => ({
        url: `${apiRoutes.investmentAdminRequests}/${requestId}/reject`,
        method: 'PUT',
      }),
    }),
    getGameDealStructureById: builder.query<any, any>({
      query: ({ gameId }: { gameId: string }) => `${apiRoutes.investmentsGamesById(gameId)}/deal`
    })
  })
});

export const {
  useLazyGetInvestmentRequestByGameIdQuery,
  useLazyGetInvestmentAdminRequestByIdQuery,
  useLazyGetInvestmentRequestAllocationByGameIdQuery,
  useLazyGetInvestmentRequestCohortFiltersQuery,
  useLazyGetInvestmentRequestGameFiltersQuery,
  useLazyGetInvestmentAttachmentsByRequestIdQuery,
  useLazyDownloadInvestmentAttachmentsByRequestIdQuery,
  useLazyGetInvestmentRequestInvestorFiltersQuery,
  useLazyGetInvestmentRequestsQuery,
  useCreateAdjustmentRequestMutation,
  useCreateAllocationRequestMutation,
  useCreateInvestmentRequestMutation,
  useModifyAdjustmentRequestMutation,
  useModifyInvestmentRequestMutation,
  useRejectInvestmentRequestMutation,
  useLazyGetGameDealStructureByIdQuery
} = investments;
