import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@stores/store";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CustomTooltip, GenericTable, Loader } from "@components";
import Pagination from "../Pagination";
import { Circle, InfoOutlined, TrendingUp } from "@mui/icons-material";
import { isProjectedPayback } from "./utils";

const ProjectionsTable: FC<{
  selectedGameId: string,
  subModuleId: string,
  chartId: string,
  chartName: string,
  handleRowClick?: (cohortDetails: { cohort: string, marketingSpends: string }) => void,
  filterSelection: Record<string, string>;
  isLoading: boolean,
  isSimulation: boolean
}> = ({ selectedGameId, handleRowClick, chartId, chartName, subModuleId, filterSelection, isLoading, isSimulation }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [pageNumber, setPageNumber] = useState<number>(1)

  const projectionsTableData = useSelector((state: RootState) =>
    isSimulation ?
      state?.FinancialProjectionsData?.financialProjections?.simulationsTableData
      : state?.FinancialProjectionsData?.financialProjections?.tableData
  )

  const selectedGameData = projectionsTableData?.rows
  const metadata = projectionsTableData?.meta ? projectionsTableData.meta : undefined
  const pricingDetails = projectionsTableData?.pricingDetails
  const PAGE_SIZE = 50;
  const rowSliceStartIndex = (pageNumber - 1) * PAGE_SIZE

  const paginatedRows = selectedGameData.slice(rowSliceStartIndex, rowSliceStartIndex + PAGE_SIZE)

  useEffect(() => {
    setPageNumber(1)
  }, [selectedGameId])

  const formattedPricingDetails = pricingDetails.map(item => {
    const regex = /:\s*(-?\d+(\.\d+)?)/; // Matches ": number" pattern
    const match = item.match(regex);

    if (match) {
      const numericValue = parseFloat(match[1]);
      const percentage = (numericValue * 100).toFixed(1) + "%";

      // Replace the original numeric value with the percentage in the string
      return item.replace(regex, `: ${percentage}`);
    }

    return item;
  });

  const getConfidenceIndicatorColor = (confidence: string) => {
    switch (confidence) {
      case 'Low':
        return '#601410';
      case 'Moderate':
        return '#FFA726';
      case 'High':
        return '#2F736E';
      default:
        return '#F0F01'
    }
  }

  const conditionallyMappedColumns = projectionsTableData?.columns?.map((col: any) => ({
    ...col,
    ...(col.key === 'paybackPeriod' && {
      render: (key: string, row: any) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}>
            {isProjectedPayback(row.cohort, row.paybackPeriod) ?
              <TrendingUp
                sx={{
                  color: "#7FBCAD",
                  height: '20px',
                  width: '20px',
                  marginRight: '8px'
                }} />
              : <></>
            }
            {row.paybackPeriod || '-'}
          </Box>
        );
      },
      renderHeader: (column: any) => {
        return (
          <Box
            sx={{
              width: "100%",
              textAlign: 'right',
            }}>
            {column.label}
          </Box>
        )
      }
    }),
    ...((col.key === 'roas' || col.key === 'revenue') && {
      renderHeader: (column: any) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '12px'
            }}>

            <CustomTooltip
              content={
                <>

                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                      color: "#6C6C6C",
                    }}
                  >
                    {col.key === 'roas' ? "Cumulative ROAS" : 'Cumulative Revenue'} projected <br /> at the end of next 12 months
                  </div>
                </>
              }
              customStyle={{
                "& .MuiTooltip-tooltip": {
                  borderRadius: "0.75rem",
                  bgcolor: "#FFFFFF",
                  backdropFilter: "blur(5px)",
                  boxShadow: 2,
                  paddingX: "1rem",
                  paddingY: "0.75rem",
                  position: "relative",
                  left: "80px",
                  top: "-10px",
                },
              }}
            >
              <InfoOutlined
                sx={{ color: 'white', height: '18px', width: '18px' }}
              />
            </CustomTooltip>
            {column.label}
          </Box>
        );
      }
    }),
    ...(col.key === 'cashCost' && {
      render: (key: string, row: any) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}>
            {Number(row.cashCost) ? `${(row.cashCost * 100).toFixed(1)}%` : '-'}
          </Box>
        );
      },
    }),
    ...(col.key === 'confidence' && {
      render: (key: string, row: any) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '8px'
            }}>
            <Box
              sx={{
                height: '10px',
                width: '10px',
                borderRadius: '2px',
                background: getConfidenceIndicatorColor(row.confidence)
              }} />
            {row.confidence}
          </Box>
        );
      },
    })
  }));

  return (
    <Box
      id="roas-projection-table-wrapper"
      key="roas-projection-table-wrapper"
      sx={{
        width: "100%",
        height: "fit-content",
        backgroundColor: "white",
        borderRadius: "12px",
        padding: "0.75rem",
        marginTop: "1rem",
        marginBottom: isMobile ? '3rem' : '1rem'
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            lineHeight: "1.375rem",
            fontWeight: 700,
            color: "#162936",
          }}
        >
          Next 12 Month Projections
        </Typography>
        <Pagination
          page={pageNumber}
          size={PAGE_SIZE}
          total={selectedGameData.length}
          handleChange={(newPage) => setPageNumber(newPage)}
          fileName={chartName}
          chartId={chartId}
          submoduleId={subModuleId}
          filterSelection={filterSelection}
        />
      </Box>
      {isLoading ?
        <Box>
          <Loader />
        </Box> :
        <>
          <GenericTable
            data={paginatedRows as any}
            columns={conditionallyMappedColumns}
            meta={metadata}
            onViewRow={(row: any) =>
              handleRowClick &&
              handleRowClick(
                { cohort: row?.cohort, marketingSpends: row?.marketingSpend }
              )}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              width: '100%',
              gap: isMobile ? '8px' : '0px'
            }}>
            <Box
              sx={{
                color: '#989CA2',
                fontSize: '0.75rem',
                lineHeight: '1rem',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                width: isMobile ? "100%" : 'fit-content'
              }}>
              {formattedPricingDetails.map((detail, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                  <Box>{detail}</Box>
                  {index !== formattedPricingDetails.length - 1 ? <Circle sx={{ height: '6px', width: '8px' }} /> : <></>}
                </Box>
              )
              )}
            </Box>
            <Box sx={{
              display: 'flex', alignItems: 'center', borderRadius: '4px', border: '1px solid #D8DBDD',
              color: '#6C6C6C', fontWeight: '500', fontSize: '0.75rem', lineHeight: '1rem', paddingX: '6px', paddingY: '2px',
              width: 'fit-content'
            }}>
              <TrendingUp
                sx={{
                  color: "#7FBCAD",
                }}
              />
              : Based on our Projections
            </Box>
          </Box>
        </>}
    </Box>
  );
};

export default ProjectionsTable;