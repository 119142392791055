const attributeInMonths = ["Payback Period (in months)"];

const colors: any = {
    category: { primary: "#2F736E14", border: "#AECBD0", highlightBorder: "#2E4A4F" },
    genre: { primary: "#2F736E14", border: "#AECBD0", highlightBorder: "#2E4A4F" }
};

export const boxColors: any = {
    category: ["#FF6646", "#FF9E2B", "#9AD6D1", "#34746D"],
    genre: ["#34746D", "#9AD6D1", "#FF9E2B", "#FF6646"]
};

const getBoxColors = (percentile: any) => {
    if (percentile < 25) return "#FF6646";
    else if (percentile < 50) return "#FF9E2B";
    else if (percentile < 75) return "#9AD6D1";
    else return "#34746D";
}

const mapData = (attribute: string, type: any, values: any, referenceValue: any, index: number) => {
    const roasValue = referenceValue !== null ? `${attributeInMonths.includes(attribute) ? (parseFloat(referenceValue)).toFixed(2) : (parseFloat(referenceValue) * 100).toFixed(2)}${attributeInMonths.includes(attribute) ? "" : "%"}` : null;

    if (!values.p25Value || !values.p50Value || !values.p75Value) return [];
    return [
        { label: "P 25", value: `25%`, tooltipValue: `${attributeInMonths.includes(attribute) ? (parseFloat(values.p25Value)).toFixed(2) : (parseFloat(values.p25Value) * 100).toFixed(2)}${attributeInMonths.includes(attribute) ? "" : "%"}`, color: colors[type].primary, border: colors[type].border },
        { label: "P 50", value: `50%`, tooltipValue: `${attributeInMonths.includes(attribute) ? (parseFloat(values.p50Value)).toFixed(2) : (parseFloat(values.p50Value) * 100).toFixed(2)}${attributeInMonths.includes(attribute) ? "" : "%"}`, color: colors[type].primary, border: colors[type].border },
        { label: "P 75", value: `75%`, tooltipValue: `${attributeInMonths.includes(attribute) ? (parseFloat(values.p75Value)).toFixed(2) : (parseFloat(values.p75Value) * 100).toFixed(2)}${attributeInMonths.includes(attribute) ? "" : "%"}`, color: colors[type].primary, border: colors[type].border },
        {
            label: "",
            value: (roasValue !== null && values.percentile !== null) ? `${(parseFloat(values.percentile) * 100).toFixed(2)}${attributeInMonths.includes(attribute) ? "" : "%"}` : null,
            tooltipValue:
                roasValue === null || parseFloat(roasValue) < 0.01
                    ? null
                    : roasValue,
            color: getBoxColors((parseFloat(values.percentile) * 100).toFixed(2)),
            border: "#2E4A4F",
        },
    ];
}

const getMinMaxValues = (inputItem: any) => {
    const formatValue = (value: any, isMonth: boolean) =>
        `${isMonth ? parseFloat(value).toFixed(2) : (parseFloat(value) * 100).toFixed(2)}${isMonth ? "" : "%"}`;

    const isMonth = attributeInMonths.includes(inputItem.attribute);

    return {
        minValue: {
            genre: formatValue(inputItem.genre?.minValue || 0, isMonth),
            category: formatValue(inputItem.category?.minValue || 0, isMonth)
        },
        maxValue: {
            genre: formatValue(inputItem.genre?.maxValue || 0, isMonth),
            category: formatValue(inputItem.category?.maxValue || 0, isMonth)
        }
    };
};

export const transformData = (name: string, inputData: any) => {
    return {
        header: name,
        data: inputData.data.map((inputItem: any, index: number) => ({
            label: inputItem.attribute,
            value: inputItem.referenceValue !== null ? `${attributeInMonths.includes(inputItem.attribute) ? (parseFloat(inputItem.referenceValue)).toFixed(2) : (parseFloat(inputItem.referenceValue) * 100).toFixed(2)}${attributeInMonths.includes(inputItem.attribute) ? "" : "%"}` : null,
            vsCategory: mapData(inputItem.attribute, "category", inputItem.category, inputItem.referenceValue, index),
            vsGenre: mapData(inputItem.attribute, "genre", inputItem.genre, inputItem.referenceValue, index),
            ...getMinMaxValues(inputItem)
        }))
    };
};
