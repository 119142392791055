import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import useChartById from "@hooks/api/useChartById";
import useCharts from "@hooks/api/useCharts";
import { FullPageLoader } from "@components";
import BenchmarkingTable from "../components/Benchmarks/BenchmarkingTable";
import BenchmarkingFilters from "../components/Benchmarks/BenchmarkingFilters";
import { transformData } from "../components/Benchmarks/benchmarkTableUtil";
import DefinitionsSidePanel from "../components/Benchmarks/DefinitionsSidePanel";

export type AttributesData = {
  label: string;
  value: string;
  color: string;
  border: string;
  tooltipValue: string;
};

export type RowDataItem = {
  label: string;
  value: string | null;
  icon?: string;
  minValue: { genre: number, category: number };
  maxValue: { genre: number, category: number };
  vsCategory: AttributesData[];
  vsGenre: AttributesData[];
};

export type RowData = {
  id: string;
  header: string;
  data: RowDataItem[];
};

const Benchmarks: React.FC<{ id: string }> = ({ id }) => {
  const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);
  const [componentLoader, setComponentLoader] = useState<Record<string, boolean>>({});
  const [dashboardData, setDashboardData] = useState<{ id: string; name: string; chartType: string; metadata: string }[]>([]);
  const [chartData, setChartData] = useState<{ id: string; header: string; data: any; rowOrder: number; enableToggleRoas: boolean }[]>([]);
  const [filterSelection, setFilterSelection] = useState<Record<string, string>>({});
  const [selectedRoas, setSelectedRoas] = useState<Record<string, string>>({});
  const [showDefinitionSidePanel, setShowDefinitionSidePanel] = useState(-1);

  const { getChartData } = useCharts();
  const { getChartDataById } = useChartById();

  // Fetch dashboard data on component mount
  useEffect(() => {
    if (id) {
      getChartData(id).then((response) => {
        if (response) {
          setDashboardData(response);
        }
      });
    }
  }, [id]);

  // Fetch all charts when filters change
  useEffect(() => {
    if (Object.keys(filterSelection).length > 0) {
      fetchDashboardData(filterSelection);
    }
  }, [filterSelection]);

  // Fetch all charts initially
  const fetchDashboardData = async (filters?: Record<string, string>) => {
    if (id && dashboardData?.length) {
      const chartsById: any[] = [];
      setComponentLoader(() => Object.fromEntries(dashboardData.map((chart) => [chart.id, true])));
      try {
        let selectedRoasValues: any = {};
        await Promise.all(
          dashboardData.map(async (chart) => {
            const parsedMetadata = JSON.parse(chart.metadata);
            const hasFilters = parsedMetadata?.filters && parsedMetadata.filters.length > 0;

            const updatedFilters: Record<string, string> = {
              ...filters,
              ...(hasFilters ? { period: selectedRoas[chart.id] || "M1" } : {}),
            };

            if (hasFilters) {
              selectedRoasValues[chart.id] = selectedRoas[chart.id] || "M1";
            }

            const response = await getChartDataById(id, chart.id, updatedFilters, parsedMetadata?.sort?.fields, parsedMetadata?.sort?.order);

            if (response?.name) {
              const mappedData = transformData(response?.name, response?.result);

              chartsById.push({
                id: chart.id,
                header: mappedData.header,
                data: mappedData.data,
                enableToggleRoas: hasFilters,
                rowOrder: response.rowOrder,
              });
            }
          })
        );

        setSelectedRoas({ ...selectedRoas, ...selectedRoasValues });
        setChartData(chartsById);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        setDashboardLoader(false);
        setComponentLoader(() => Object.fromEntries(dashboardData.map((chart) => [chart.id, false])));
      }
    }
  };


  // Fetch single chart when ROAS changes
  const fetchChartDataById = async (chartId: string, newRoas: string) => {
    if (id && dashboardData?.length) {
      try {
        const chart = dashboardData.find((c) => c.id === chartId);
        setComponentLoader((prev) => ({ ...prev, [chartId]: true }));
        if (!chart) return;

        const parsedMetadata = JSON.parse(chart.metadata);
        const hasFilters = parsedMetadata?.filters && parsedMetadata.filters.length > 0;

        const updatedFilters: Record<string, string> = {
          ...filterSelection,
          period: newRoas,
        };

        const response = await getChartDataById(id, chartId, updatedFilters, parsedMetadata?.sort?.fields, parsedMetadata?.sort?.order);

        if (response?.name) {
          const mappedData = transformData(response?.name, response?.result);

          setChartData((prevCharts) =>
            prevCharts.map((chartData) =>
              chartData.id === chartId
                ? {
                  ...chartData,
                  header: mappedData.header,
                  data: mappedData.data,
                  enableToggleRoas: hasFilters,
                  rowOrder: response.rowOrder,
                }
                : chartData
            )
          );
        }
      } catch (error) {
        console.error(`Error fetching chart ${chartId} data:`, error);
      } finally {
        setDashboardLoader(false);
        setComponentLoader((prev) => ({ ...prev, [chartId]: false }));
      }
    }
  };

  // Handle ROAS selection per chart
  const handleRoasChange = (chartId: string, newRoas: string) => {
    setSelectedRoas((prevRoas) => {
      if (prevRoas[chartId] === newRoas) {
        return prevRoas;
      }
      const updatedRoas = {
        ...prevRoas,
        [chartId]: newRoas,
      };
      fetchChartDataById(chartId, newRoas);
      return updatedRoas;
    });
  };

  return (
    <>
      {dashboardLoader && <FullPageLoader hasSideNav={true} />}
      <div
        id="pvx-benchmarks-container"
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "auto",
          padding: "1rem 2rem 5rem",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "1.125rem",
        }}
      >
        <Box sx={{
          minWidth: "700px",
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}>
          <BenchmarkingFilters
            id={id}
            showDefinitionSidePanel={() => setShowDefinitionSidePanel(0)}
            onFilterChange={(selections) => setFilterSelection(selections)}
          />
          {chartData
            .sort((a, b) => a.rowOrder - b.rowOrder)
            .map((chart, index) => (
              <BenchmarkingTable
                key={chart.id}
                rowData={chart}
                enableToggleRoas={chart.enableToggleRoas}
                setSelectedRoas={(newRoas: any) => handleRoasChange(chart.id, newRoas)}
                showDefinitionSidePanel={() => setShowDefinitionSidePanel(index + 1)}
                subModuleId={id}
                isLoading={componentLoader[chart.id] as any}
                gameId={filterSelection.gameId}
                selectedRoas={selectedRoas[chart.id]}
              />
            ))}
        </Box>
        <DefinitionsSidePanel
          scrollToDefinitionSidePanel={showDefinitionSidePanel}
          hideDefinitionSidePanel={() => setShowDefinitionSidePanel(-1)}
        />
      </div>
    </>
  );
};

export default Benchmarks;
