import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography, useMediaQuery, useTheme, Select, MenuItem, FormControl, InputLabel, CircularProgress, Link, TextField, Radio } from "@mui/material";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { RootState } from "@stores/store";
import { 
  SelfServeDetails,
  SelfServeUpdateCategoryAndGenrePayload, 
  useLazyFetchCategoryAndGenreOptionsQuery, 
  useLazyFetchSelfServeDetailsQuery, 
  useSelfServeDataAccessMutation, 
  useUpdateCategoryAndGenreMutation 
} from "@api/selfServe";
import { APPLICATION_STORES, SCHEDULE_CALL_LINK, TOAST_TYPES } from "@constants/constants";
import { CustomAccordion } from "../../../components";
import appsflyerLogo from "../../../assets/icons/appsflyer-logo.png";
import adjustLogo from "../../../assets/icons/adjust-logo.png";
import singularLogo from "../../../assets/icons/signular-logo.png";
import snowFlakeLogo from "../../../assets/icons/snowflake-logo.png";
import bigQueryLogo from "../../../assets/icons/big-query-logo.png";
import DrawerComponent from "../../../components/Drawer/Drawer";
import GrantAccessToAppsFlyer from "./components/GrantAccessToAppsFlyer";
import ProcessingAppsFlyerDataAccess from "./components/ProcessingAppsFlyerDataAccess";
import EditGame from "./components/EditGame";
import GameDetails, { IGameDetails } from "./components/GameDetails";
import { setToastMessage } from "@stores/App/slice";
import { processRatingString } from "@utils/common";
import { resetLoadCount } from "@stores/Modules/slice";
import GettingStartedHeader from "./components/GettingStartedHeader";
import SignNDA from "./components/SignNDA";
import EditSignNDA from "./components/EditSignNDA";

const GettingStarted: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const modulesState = useSelector((state: RootState) => state?.ModulesData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [applicationDetailsResponse, setApplicationDetailsResponse] = useState<{ 
    category: string | null; 
    genre: string | null; 
    selfServeId: string;
    requestStatus: string;
    accessEmail: string;
  } | null>(null);
  const [accordionConfig, setAccordionConfig] = useState<{ isSuccess: boolean, open: boolean }[] | null>(null);
  const [playStoreApp, setPlayStoreApp] = useState<null | IGameDetails>(null);
  const [appStoreApp, setAppStoreApp] = useState<null | IGameDetails>(null);
  const [category, setCategory] = useState<string>("");
  const [genre, setGenre] = useState<string>("");
  const [categoryOptions, setCategoryOptions] = useState<{ name: string; genres: string[]}[]>([]);
  const [genreOptions, setGenreOptions] = useState<string[]>([]);
  const [isEditGameOpen, setIsEditGameOpen] = useState<boolean>(false);
  const [isGrantAccessToAppsFlyerLearnMoreOpen, setIsGrantAccessToAppsFlyerLearnMoreOpen] = useState<boolean>(false);
  const [accessData, setAccessData] = useState<number>(1);
  const [accessGrantScrollTo, setAccessGrantScrollTo] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  const [openEditSignNDAConfirmation, setOpenEditSignNDAConfirmation] = useState(false);
  const [ndaDetails, setNDADetails] = useState<any>(null);
  const [openNDAForm, setOpenNDAForm] = useState<boolean>(false);

  const pollingRef = useRef<NodeJS.Timeout | null>(null);

  const [fetchSelfServeDetails, { isLoading: isSelfServeDetailsFetching }] = useLazyFetchSelfServeDetailsQuery();
  const [fetchCategoryAndGenreOptionsApi] = useLazyFetchCategoryAndGenreOptionsQuery();
  const [updateCategoryAndGenreApi] = useUpdateCategoryAndGenreMutation();
  const [selfServeDataAccessApi, { isLoading: isDataAccessUpdating }] = useSelfServeDataAccessMutation();

  const processingDataAccessStatus = new Set<string>(["ACCESS_GRANTED", "DATA_FETCH_SUCCESSFUL", "DATA_FETCH_FAILED", "DATA_FETCH_PARTIAL", "DASHBOARD_CREATION_FAILED"]);
  const pendingNDASignatureStatus = new Set<string>(["PENDING_NDA_CREATION", "PENDING_NDA_SIGNATURE"]);
  const processedDataAccessStatus = new Set<string>(["PUBLISHED"]);
  const pendingDataAccessStatus = new Set<string>(["PENDING_ACCESS"]);

  // Hook for calling the self serve details.
  // Hook is executed only after the modules are fetched.
  useEffect(() => {
    if (modulesState.loadCount === 0) return;
    
    // if modules has length, navigate back to dashboards.
    if (modulesState?.modules.filter((module) => module.requestStatus === "APPROVED").length) {
      navigate('/dashboard');
      return;
    }

    // if modules doesn't have length, fetch self serve api.
    fetchApplicationDetails(true);
  }, [modulesState]);

  // Hook for fetching the category and genre options.
  useEffect(() => {
    fetchCategoryAndGenreOptions();
  }, []);

  // Hook for updating genre and genre options when category is changed.
  useEffect(() => {
    setGenre("");
    if (category === null) return;

    let genres: string[] = [];
    for (const cat of categoryOptions) {
      if (cat.name !== category) continue;

      genres = cat.genres;
      break;
    }

    setGenreOptions(genres);
  }, [category]);

  useEffect(() => {
    return () => {
      stopPolling(); // Cleanup on unmount
    };
  }, []);

  const stopPolling = () => {
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }
  };

  const startPolling = () => {
    if (pollingRef.current) return; // Prevent multiple intervals

    pollingRef.current = setInterval(() => {
      fetchApplicationDetails();
    }, 60000);
  }

  const updateApplicationDetails = (data: SelfServeDetails) => {
    const { metadata } = data;
    const parsedMetadata = JSON.parse(metadata);
    
    const playStoreApp: IGameDetails = parsedMetadata[APPLICATION_STORES.PLAY_STORE] || null;
    const appStoreApp: IGameDetails = parsedMetadata[APPLICATION_STORES.APP_STORE] || null;

    if (playStoreApp?.rating) {
      playStoreApp["rating"] = processRatingString(playStoreApp["rating"] as string);
    }

    if (appStoreApp?.rating) {
      appStoreApp["rating"] = processRatingString(appStoreApp["rating"] as string);
    }

    setPlayStoreApp(playStoreApp);
    setAppStoreApp(appStoreApp);
  }

  const updateAccordionConfig = (category: string | null, genre: string | null, requestStatus: string) => {
    const accordionConfig = [
      {
        isSuccess: false,
        open: false
      },
      {
        isSuccess: false,
        open: false
      },
      {
        isSuccess: false,
        open: false
      }
    ];

    if (category === null || genre === null) {
      accordionConfig[0].open = true;
    } else {
      accordionConfig[0].isSuccess = true;
      if (pendingNDASignatureStatus.has(requestStatus)) {
        accordionConfig[1].open = true;
      } else {
        accordionConfig[1].isSuccess = true;
        if (pendingDataAccessStatus.has(requestStatus) || processingDataAccessStatus.has(requestStatus)) {
          accordionConfig[2].open = true;
        }
      }
    }

    setAccordionConfig(accordionConfig);
  }

  const updateNDADetails = (
    companyName: string | undefined, 
    companyAddress: string | undefined, 
    signingAuthorityName: string | undefined, 
    signingAuthorityEmail: string | undefined,
    signingAuthorityTitle: string | undefined
  ) => {
      if (
        !companyName ||
        !companyAddress ||
        !signingAuthorityName ||
        !signingAuthorityEmail ||
        !signingAuthorityTitle
      ) {
        setNDADetails(null);
      } else {
        setNDADetails({
          companyName,
          companyAddress,
          signingAuthorityName,
          signingAuthorityEmail,
          signingAuthorityTitle
        });
      }
  }

  const fetchApplicationDetails = async (polling = false): Promise<void> => {
    try {
      const response = await fetchSelfServeDetails().unwrap();
      const { data } = response;

      if (data === null || !data.id) {
        // If self serve details not present.
        dispatch(setToastMessage({
          message: "Self serve id not present.",
          type: TOAST_TYPES.ERROR
        }));

        navigate('/dashboard');
        return;
      }

      if (data.requestStatus && processedDataAccessStatus.has(data.requestStatus)) {
        // If self serve details processed.
        dispatch(resetLoadCount());
        navigate('/dashboard');
        return;
      }
      
      setApplicationDetailsResponse({
        selfServeId: data.id,
        category: data.category,
        genre: data.genre,
        requestStatus: data.requestStatus,
        accessEmail: data.accessEmail || ""
      });

      updateNDADetails(
        data.companyName,
        data.companyAddress,
        data.signingAuthorityName,
        data.signingAuthorityEmail,
        data.signingAuthorityTitle
      );
      updateAccordionConfig(data.category, data.genre, data.requestStatus);
      updateApplicationDetails(data);

      if (polling && processingDataAccessStatus.has(data.requestStatus)) {
        startPolling();
      }
    } catch (error: any) {
      dispatch(setToastMessage({
        type: TOAST_TYPES.ERROR,
        message: error?.error?.message || null
      }));
    }
  }

  const fetchCategoryAndGenreOptions = async (): Promise<void> => {
    try {
      const response = await fetchCategoryAndGenreOptionsApi().unwrap();
      const { data } = response;

      setCategoryOptions(data.categories);
    } catch (error: any) {
      dispatch(setToastMessage({
        type: TOAST_TYPES.ERROR,
        message: error?.error?.message || null
      }));
    }
  }

  const handleSubmitCategoryAndGenre = async (): Promise<void> => {
    // Validations done for submitting category and genre submit.
    if (category === null || genre === null) {
      return;
    }
    
    if (!!!applicationDetailsResponse || !applicationDetailsResponse.selfServeId) {
      return;
    }
    
    const payload: SelfServeUpdateCategoryAndGenrePayload = { category, genre };
    const selfServeId = applicationDetailsResponse.selfServeId as string;

    try {
      await updateCategoryAndGenreApi({
        selfServeId, payload
      }).unwrap();

      fetchApplicationDetails();
    } catch (error: any) {
      dispatch(setToastMessage({
        type: TOAST_TYPES.ERROR,
        message: error?.error?.message || null
      }));
    }
  }

  const handleEditGameClose = useCallback((cancel: boolean = false) => {
    if (!cancel) fetchApplicationDetails();

    setIsEditGameOpen(false);
  }, []);

  const handleGrantAccessClose = useCallback((cancel: boolean = false) => {
    setAccessGrantScrollTo("");
    setIsGrantAccessToAppsFlyerLearnMoreOpen(false)
  }, []);

  const handleEditGameOpen = useCallback(() => setIsEditGameOpen(true), []);

  const handleCopyToClipboard = (text: string) => {
    dispatch(setToastMessage({
      type: TOAST_TYPES.SUCCESS,
      message: "Copied"
    }));
    navigator.clipboard.writeText(text)
  }

  const handleClickData = (sidePanelId: string) => {
    setIsGrantAccessToAppsFlyerLearnMoreOpen(true);
    setAccessGrantScrollTo(sidePanelId);
  }

  const handleLearnHowClicked = () => {
    setAccessGrantScrollTo("");
    setIsGrantAccessToAppsFlyerLearnMoreOpen(true);
  }

  const handleConfirmAccessClick = async () => {
    try {
      await selfServeDataAccessApi({
        selfServeId: applicationDetailsResponse?.selfServeId as string,
        accountName
      }).unwrap();
      startPolling();
    } catch (error: any) {
      dispatch(setToastMessage({
        message: error?.error?.message || "Account Name update failed. Please try again!",
        type: TOAST_TYPES.ERROR
      }));
    }

    fetchApplicationDetails();
  }

  const handleCloseEditSignNDAConfirmation = (cancel: boolean) => {
    if (!cancel) {
      setOpenNDAForm(true);
    }

    setOpenEditSignNDAConfirmation(false);
  }

  return (
    <Box
      padding={"2rem"}
      paddingBottom={'4rem'}
      sx={{
        height: '100%',
        overflow: 'scroll'
      }}
    >
      <GettingStartedHeader />

      <Stack spacing={"1rem"}>
        <CustomAccordion 
          open={!!accordionConfig && accordionConfig[0].open} 
          isSuccess={!!accordionConfig && accordionConfig[0].isSuccess} 
          disableExpand={false}
          title="1. Tell us about your app">
          <Stack gap={"1rem"}>
            {
              isSelfServeDetailsFetching 
              ? 
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}>
                <CircularProgress size={20}/>
              </Box>
              : <>
              <Stack direction={isMobile ? "column" : "row"} gap={"1rem"} sx={{
                flexWrap: 'wrap'
              }}>
                {
                  playStoreApp && 
                  <GameDetails 
                    setIsEditGameOpen={handleEditGameOpen} 
                    category={applicationDetailsResponse?.category} 
                    genre={applicationDetailsResponse?.genre} 
                    gameDetails={playStoreApp} />
                }
                {
                  appStoreApp && 
                  <GameDetails 
                    setIsEditGameOpen={handleEditGameOpen} 
                    category={applicationDetailsResponse?.category} 
                    genre={applicationDetailsResponse?.genre} 
                    gameDetails={appStoreApp} />
                }
              </Stack>

              {
                !(applicationDetailsResponse?.category !== null && applicationDetailsResponse?.genre !== null) &&
                <>
                  <Stack direction={"row"} gap={"1rem"}>
                    <FormControl fullWidth>
                      <InputLabel id="category-label">Category</InputLabel>

                      <Select 
                        value={category}
                        label="Category"
                        onChange={(e) => setCategory(e.target.value)}>
                          {categoryOptions.map((category, index) => (<MenuItem key={index} value={category.name}>{ category.name }</MenuItem>))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="genre-label">Genre</InputLabel>

                      <Select 
                        disabled={genreOptions.length === 0}
                        value={genre}
                        label="Genre"
                        onChange={(e) => setGenre(e.target.value)}>
                        {genreOptions.map((g, index) => (<MenuItem key={index} value={g}>{ g }</MenuItem>))}
                      </Select>
                    </FormControl>
                  </Stack>
                  
                  <Box>
                    <Button
                      disabled={!!!genre}
                      onClick={handleSubmitCategoryAndGenre}
                      variant="contained"
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      sx={{
                        borderRadius: "100px",
                        fontSize: "1rem",
                        lineHeight: "1.25rem",
                        padding: "0.625rem 1.5rem",
                        textTransform: "none",
                        fontWeight: 700,
                        background: "#2F736E",
                        color: "#fff"
                      }}>Submit</Button>
                  </Box>
                </>
              }
              </>
            }
            
          </Stack>
        </CustomAccordion>

        <CustomAccordion 
          open={!!accordionConfig && accordionConfig[1].open}
          isSuccess={!!accordionConfig?.[1].isSuccess} 
          showEdit={!!ndaDetails} 
          disableExpand={!accordionConfig?.[0].isSuccess}
          onEdit={() => setOpenEditSignNDAConfirmation(true)} 
          title="2. Non Disclosure Agreement" >
            <SignNDA 
              isProceedDisalbed={!!!accordionConfig?.[0].isSuccess}
              success={!!accordionConfig?.[1].isSuccess}
              openNDAForm={openNDAForm}
              setOpenNDAForm={(value: boolean) => setOpenNDAForm(value)}
              selfServeId={applicationDetailsResponse?.selfServeId as string}
              ndaDetails={ndaDetails}
              fetchApplicationDetails={fetchApplicationDetails}
              setNDADetails={setNDADetails}/>
        </CustomAccordion>

        <CustomAccordion 
          open={!!accordionConfig && accordionConfig[2].open} 
          disableExpand={!accordionConfig?.[1].isSuccess}
          title="3. Share access to your app data with us">
          <Stack spacing={"1rem"}>
            <Box
              sx={{
                padding: "1rem 0.75rem",
                background: accessData === 1 ? "#2F736E14": "#F0F0F1",
                border: "1px solid #2F736E1F",
                borderRadius: "0.5rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Stack direction={"row"} gap={"0.75rem"}>
                <Box>
                  <Radio
                    checked={accessData === 1} 
                    value={1}
                    onChange={() => setAccessData(1)}
                    name="radio-buttons"
                  />
                </Box>

                <Box>
                  <Stack direction={"row"} gap={"1rem"}>
                    <Box
                      sx={{
                        background: "#fff",
                        border: "1px solid #2F736E1F",
                        borderRadius: "0.5rem",
                        height: "40px",
                        width: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0.5rem 0.5rem"
                      }}
                    >
                      <img height={"100%"} src={appsflyerLogo} alt="appsflyer-logo" />
                    </Box>

                    {accessData === 1 && 
                    (
                      pendingNDASignatureStatus.has(applicationDetailsResponse?.requestStatus || "") ||
                      pendingDataAccessStatus.has(applicationDetailsResponse?.requestStatus || "")
                    )  && 
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      onClick={handleLearnHowClicked}
                      sx={{
                        color: "#51B8B0",
                        textDecoration: "underline",
                        textTransform: "none",
                        fontSize: "0.85rem",
                        lineHeight: "1rem",
                        fontWeight: 700,
                        padding: 0,
                        ":hover": {
                          background: "none",
                        },
                      }}
                    >
                      Learn How
                    </Button>}
                  </Stack>

                  {/* AppsFlyer details */}
                  { accessData === 1 && (
                    (
                      pendingNDASignatureStatus.has(applicationDetailsResponse?.requestStatus || "") ||
                      pendingDataAccessStatus.has(applicationDetailsResponse?.requestStatus || "")
                    ) 
                    ? (
                      <Box marginTop={"0.75rem"}>
                        <Box sx={{
                            width: "min(316px, 80%)"
                          }}>
                          <TextField 
                            id="outlined-basic" 
                            label="Account Name" 
                            placeholder="Account Name on Appsflyer" 
                            variant="outlined" 
                            onChange={(e) => setAccountName(e.target.value)}
                            disabled={isDataAccessUpdating || !accordionConfig?.[1].isSuccess}
                            sx={{
                              width: "100%"
                            }}/>
                        </Box>

                        <Typography
                            marginTop={"1rem"}
                            fontSize={"0.875rem"}
                            lineHeight={"1.25rem"}
                            fontWeight={500}
                            color={"#162936"}
                            maxWidth={"100%"}
                          >
                            Find the Account Name on the top right corner of your
                            {" "}
                            <Typography
                              onClick={() => handleClickData('appsflyer-account-name')}
                              component={"span"}
                              fontSize={"0.875rem"}
                              lineHeight={"1.25rem"}
                              fontWeight={700}
                              color={"#51B8B0"}
                              sx={{
                                "textDecoration": "underline",
                                ":hover": {
                                  cursor: "pointer",
                                  textDecoration: "none"
                                }
                              }}>Appsflyer Account.</Typography>
                          </Typography>

                        <Typography
                          marginTop={"1rem"}
                          fontSize={"0.875rem"}
                          lineHeight={"1.25rem"}
                          fontWeight={500}
                          color={"#162936"}
                          maxWidth={"100%"}
                        >
                          Please provide access to the above Appsflyer account with the Role as "Marketing Lead" to the following email address and confirm. Once access is granted, let us know by clicking on “Confirm Access” button, so we can proceed with the 
                          {" "}
                          <Typography
                            onClick={() => handleClickData("data-we-connect")}
                            component={"span"}
                            fontSize={"0.875rem"}
                            lineHeight={"1.25rem"}
                            fontWeight={700}
                            color={"#51B8B0"}
                            sx={{
                              "textDecoration": "underline",
                              ":hover": {
                                cursor: "pointer",
                                textDecoration: "none"
                              }
                            }}>data evaluation</Typography>.
                        </Typography>

                        <Typography
                        marginTop={"1.75rem"}
                          fontSize={"0.875rem"}
                          lineHeight={"1.25rem"}
                          fontWeight={500}
                          color={"#162936"}>
                          <Typography
                            component={"span"}
                            fontSize={"0.875rem"}
                            lineHeight={"1.25rem"}
                            fontWeight={700}
                            color={"#162936"}>
                              Note:
                          </Typography>
                          {" "}
                          We do not collect any PII data such as device id, GAID or IDFA for the purpose of our analysis.
                        </Typography>

                        {/* Email to be copied */}
                        <Box
                          sx={{
                            background: "#2F736E1F",
                            borderRadius: "0.25rem",
                            padding: "0.5rem",
                            margin: "0.75rem 0",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.75rem",
                            width: "fit-content",
                            maxWidth: "80%"
                          }}
                        >
                          <Typography
                            fontSize={"0.75rem"}
                            fontWeight={700}
                            lineHeight={"1rem"}
                            color={"#162936"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                          >
                            { applicationDetailsResponse?.accessEmail || "" }
                          </Typography>

                          <ContentCopyIcon 
                            onClick={() => handleCopyToClipboard(applicationDetailsResponse?.accessEmail || "")}
                            sx={{
                              ":hover": {
                                cursor: "pointer"
                              }
                            }}></ContentCopyIcon>
                        </Box>

                        <Stack direction={"row"} gap={"1.25rem"} marginTop={"1rem"} flexWrap={"wrap"}>
                            <Button
                              onClick={handleConfirmAccessClick}
                              variant="contained"
                              disabled={accountName.length === 0 || isDataAccessUpdating || !accordionConfig?.[1].isSuccess}
                              disableElevation
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                              sx={{
                                borderRadius: "100px",
                                fontSize: "14px",
                                lineHeight: "1.25rem",
                                padding: "0.625rem 1.5rem",
                                textTransform: "none",
                                fontWeight: 700,
                                background: "#2F736E",
                                color: "#fff",
                              }}
                            >
                              {
                                isDataAccessUpdating ?
                                "Updating..." : "Confirm Access"
                              }
                            </Button>
                        </Stack>
                      </Box>
                    )
                    : (
                      processingDataAccessStatus.has(applicationDetailsResponse?.requestStatus || "") 
                      ? <ProcessingAppsFlyerDataAccess />
                      : <></>
                    )
                  )}
                </Box>
              </Stack>
            </Box>

            <Box
              sx={{
                padding: "1rem 0.75rem",
                background: accessData === 2 ? "#2F736E14": "#F0F0F1",
                border: "1px solid #2F736E1F",
                borderRadius: "0.5rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Stack direction={"row"} gap={"0.75rem"}>
                <Box>
                  <Box>
                    <Radio 
                      checked={accessData === 2}
                      value={2}
                      onChange={() => setAccessData(2)}
                      name="radio-buttons"
                    />
                  </Box>
                </Box>
            
                <Box width={"100%"}>
                  <Stack 
                    direction={isMobile ? "column": "row"} 
                    justifyContent={"space-between"} 
                    gap={"1rem"} 
                    alignItems={isMobile ? "flex-start": "center"}>
                    <Stack direction={"row"} gap={"1rem"} alignItems={"center"} flexWrap={"wrap"}>
                      <Box
                        sx={{
                          background: "#fff",
                          border: "1px solid #2F736E1F",
                          borderRadius: "0.5rem",
                          padding: "0.75rem 0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40px"
                        }}
                      >
                        <img height={"100%"} src={adjustLogo} alt="adjust-logo" />
                      </Box>

                      <Box
                        sx={{
                          background: "#fff",
                          border: "1px solid #2F736E1F",
                          borderRadius: "0.5rem",
                          padding: "0px 0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40px"
                        }}
                      >
                        <img height={"100%"} src={singularLogo} alt="singular-logo" />
                      </Box>

                      <Box
                        sx={{
                          background: "#fff",
                          border: "1px solid #2F736E1F",
                          borderRadius: "0.5rem",
                          padding: "8.5px 0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40px"
                        }}
                      >
                        <img height={"100%"} src={snowFlakeLogo} alt="singular-logo" />
                      </Box>

                      <Box
                        sx={{
                          background: "#fff",
                          border: "1px solid #2F736E1F",
                          borderRadius: "0.5rem",
                          padding: "0 1.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40px"
                        }}
                      >
                        <img height={"100%"} src={bigQueryLogo} alt="singular-logo" />
                      </Box>

                      <Typography fontSize={"0.75rem"} lineHeight={"1rem"} fontWeight={500} color={"#6C6C6C"}>and more...</Typography>
                    </Stack>

                    {accessData === 2 && (
                      <Button
                        href={SCHEDULE_CALL_LINK}
                        target="_blank"
                        variant="contained"
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        disabled={!accordionConfig?.[1].isSuccess}
                        startIcon={<HeadphonesIcon />}
                        sx={{
                          borderRadius: "100px",
                          fontSize: "14px",
                          lineHeight: "1.25rem",
                          padding: "0.625rem 1.5rem",
                          textTransform: "none",
                          fontWeight: 700,
                          background: "#2F736E",
                          color: "#fff",
                          minWidth: "174px"
                        }}
                      >
                        Schedule a call
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </CustomAccordion>

        <Typography fontSize={"0.85rem"} lineHeight={"1rem"} fontWeight={700} color={"#6C6C6C"}>
        Need to add more team members or need help? Contact us at {" "}
          <Link href="mailto:info@pvxpartners.com" color={"#51B8B0"} fontWeight={700}>
            info@pvxpartners.com
          </Link>{" "}
        </Typography>
      </Stack>

      <DrawerComponent
        anchor="right"
        open={isEditGameOpen}
        close={(status: string) => setIsEditGameOpen(false)}
        height="98vh"
        zIndex={1203}
        backgroundColor="#F0F0F1"
      >
        <EditGame 
          close={handleEditGameClose} 
          selfServeId={applicationDetailsResponse?.selfServeId as string} 
          playStoreApp={playStoreApp} appStoreApp={appStoreApp}/>
      </DrawerComponent>

      <DrawerComponent anchor="right" height="98vh" zIndex={1203} backgroundColor="#F0F0F1" open={isGrantAccessToAppsFlyerLearnMoreOpen} close={() => setIsGrantAccessToAppsFlyerLearnMoreOpen(false)}>
        <GrantAccessToAppsFlyer scrollTo={accessGrantScrollTo} close={handleGrantAccessClose} />
      </DrawerComponent>

      <EditSignNDA
        open={openEditSignNDAConfirmation}
        close={handleCloseEditSignNDAConfirmation} />
    </Box>
  );
};

export default GettingStarted;