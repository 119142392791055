import { Chart } from "chart.js";

export const getFundingEligibiltyChartData = (overallScore: number) => {
	const data = {
		labels: ["", ""],
		datasets: [
			{
				data: [overallScore, 3 - overallScore],
				backgroundColor: (context: any) => {
					if (!context.chart.chartArea) {
						return "#FFA726";
					}

					const { ctx } = context.chart;
					const { dataIndex } = context
					if (dataIndex === 0) {
						const gradient = ctx.createLinearGradient(0, 0, 700, 0);
						gradient.addColorStop(0.2, "#FFA726");
						gradient.addColorStop(0.3, "#7FBCAD");
						return gradient;
					} else {
						return "transparent";
					}
				},
				cutout: "0%",
				borderRadius: 20,
				borderWidth: 0,
				spacing: 0,
				order: 1,
				weight: 1
			},
			{
				data: [0, 3],
				backgroundColor: "#162C36",
				cutout: "85%",
				borderRadius: 20,
				borderWidth: 10,
				borderColor: "#162C36",
				spacing: 0,
				order: 2,
				weight: 5
			},
		],
	};

	return data
}

export const getFundingEligibiltyChartOptions = () => (
	{
		rotation: -105,
		circumference: 210,
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				display: false, // Hides the default chart legend
			},
			tooltip: {
				enabled: false, // Disables tooltips to match the design
			},
		},
	}
)

export const getFundingEligibiltyChartPlugins = (
	overallScore: number,
	overallScoreType: string,
	borderlineOffset: number,
	textOffsets: number
) => (
	[
		{
			id: "customBorder",
			afterDraw: (chart: any) => {
				const {
					ctx,
					chartArea: { width, height },
				} = chart;

				// Calculate the radius based on the cutout percentage
				const radius = (Math.min(width, height) / 2) * 0.8 - 5;
				const centerX = width / 2;
				const centerY = height / 2 + borderlineOffset;

				// Adjust the start and end angles based on the chart's rotation (-105 degrees) and circumference (210 degrees)
				const startAngle = (-195 * Math.PI) / 180; // Start angle (-105° in radians)
				const endAngle = startAngle + (210 * Math.PI) / 180; // 210° to match the chart's circumference

				// Drawing the half-circle border
				ctx.save();
				ctx.beginPath();
				ctx.arc(centerX, centerY, radius, startAngle, endAngle);
				ctx.strokeStyle = "#F6F8F926"; // Your desired border color
				ctx.lineWidth = 1; // Adjust thickness if needed
				ctx.stroke();
				ctx.restore();

			},
		},
		{
			id: "overallScoreText",
			afterDatasetsDraw: (chart: Chart) => {
				const { ctx } = chart;
				const xCoor = chart.getDatasetMeta(0).data[0].x;
				const yCoor = chart.getDatasetMeta(0).data[0].y;
				const score = `${overallScore.toFixed(2)}`;

				ctx.save();
				ctx.font = "14px Manrope";
				ctx.fillStyle = "#F6F8F980";
				ctx.textBaseline = "top";
				ctx.textAlign = "left";
				ctx.fillText("Overall Score", xCoor - 40, yCoor - 60);

				ctx.font = "22px Manrope";
				ctx.fillStyle = "#F6F8F9";
				ctx.fillText(score, xCoor - 20, yCoor - 25);

				ctx.font = "20px Manrope";
				ctx.fillStyle = "#F6F8F9";
				const text = overallScoreType.toLowerCase().replace(/^\w/, (c: any) => c.toUpperCase());
				const textWidth = ctx.measureText(text).width;
				ctx.fillText(text, xCoor - textWidth / 2, yCoor + 5);
				ctx.restore();
			},
		},
		{
			id: "gaugeLabels",
			afterDatasetsDraw: (chart: Chart) => {
				const { ctx, chartArea: { width, height } } = chart;

				ctx.save();
				ctx.font = "16px Arial";
				ctx.fillStyle = "#6C6C6C";
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";

				const radius = (Math.min(width, height) / 2) * 0.8 - 5;
				const centerX = width / 2;
				const centerY = height / 2 + 50;
				const labelPositions = [
					{ text: "Fair", x: centerX - (radius * 1.1), y: centerY + (radius * 0.5) },
					{ text: "Good", x: centerX + 100, y: centerY - (radius * textOffsets) + 30 },
					{ text: "Excellent", x: centerX + (radius * 0.95), y: centerY + (radius * 0.5) },
				];

				labelPositions.forEach(({ text, x, y }) => {
					ctx.fillText(text, x, y);
				});

				ctx.restore();
			},
		},
		{
			id: "gaugeMarkers",
			afterDatasetsDraw: (chart: Chart) => {
				const { ctx, chartArea: { width, height } } = chart;

				ctx.save();
				const radius = (Math.min(width, height) / 2) * 0.8 - 5;
				const centerX = width / 2;
				const centerY = height / 2 + 50;
				const startAngle = (-195 * Math.PI) / 180;
				const circumference = (210 * Math.PI) / 180;

				const valuesToMark = [1.8, 2.3];
				valuesToMark.forEach((value) => {
					const angle = startAngle + ((value / 3) * circumference);

					const x1 = centerX + (radius + 2) * Math.cos(angle);
					const y1 = centerY + (radius + 2) * Math.sin(angle);
					const x2 = centerX + (radius + 40) * Math.cos(angle);
					const y2 = centerY + (radius + 40) * Math.sin(angle);

					ctx.setLineDash([3, 3]);
					ctx.beginPath();
					ctx.moveTo(x1, y1);
					ctx.lineTo(x2, y2);
					ctx.strokeStyle = "#FFFFFF";
					ctx.lineWidth = 3;
					ctx.stroke();

					ctx.setLineDash([]);
					ctx.font = "14px Arial";
					ctx.fillStyle = "#FFFFFF99";
					ctx.textAlign = "center";
					ctx.fillText(value.toFixed(1), x2 + 5, y2 - 15);
				});

				ctx.restore();
			},
		}
	] as any
)