/* eslint-disable import/no-anonymous-default-export */
export default {
  userDetails: '/users/me',
  userDetailsbyId: (id: string) => `/users/${id}`,
  userModulesById: (id: string) => `/visualization/users/${id}/details`,
  userModules: '/visualization/modules',
  userSubmodules: (moduleId: string) => `/visualization/modules/${moduleId}/submodules`,
  userSubmoduleFilters: (submoduleId: string) => `/visualization/submodules/${submoduleId}/filters`,
  userSubmoduleFiltersValues: (submoduleId: string, filterId: string) => `/visualization/submodules/${submoduleId}/filters/${filterId}/values`,
  userGuestToken: (submoduleId: string) => `/visualization/submodules/${submoduleId}/token`,
  userSubmoduleGames: (submoduleId: string, gamesParam: string[]) => `/visualization/submodules/${submoduleId}/games${gamesParam?.length ? `?gameIds=${gamesParam.join(',')}` : ''}`,
  users: '/users',
  companies: '/companies',
  fundingCalculator: 'lead-magnet',
  fundingCalculatorDeatils: (id: string) => `/lead-magnet/${id}`,
  charts: (submoduleId: string) => `/visualization/submodules/${submoduleId}/charts`,
  chartById: (submoduleId: string, chartId: string, queryParams?: string) => `/visualization/submodules/${submoduleId}/charts/${chartId}${queryParams || ''}`,
  customDashboardFilters: (submoduleId: string) => `/visualization/submodules/${submoduleId}/filter-values`,
  chartCsvDownload: (submoduleId: string, chartId: string, queryParams?: string) => `/visualization/submodules/${submoduleId}/charts/${chartId}/download${queryParams || ''}`,
  fetchRequestedSubmodules: () => "/visualization/submodule-requests",
  requestSubmodule: (requestId: string) => `/visualization/submodule-requests/${requestId}/request`,
  investmentRequests: '/investments/requests',
  investmentAdminRequests: '/investments/admin/requests',
  investmentsGamesById: (gameId: string) => `/investments/games/${gameId}`,
  applicationDetails: (store: string, appUrl: string) => `/self-serve/verify-game/${store}?appUrl=${encodeURIComponent(appUrl)}`,
  registerUser: '/self-serve/register',
  adminRegisterUser: '/self-serve/admin/register',
  selfServeDetails: '/self-serve/details',
  selfServeCategoryAndGenreOptions: '/self-serve/categories-and-genres',
  selfServeUpdateCategoryAndGenre: (selfServeId: string) => `/self-serve/${selfServeId}/category-genre`,
  selfServeUpdateApplicationDetails: (selfServeId: string) => `/self-serve/${selfServeId}`,
  selfServeDashboardRequests: `/self-serve/admin/requests`,
  selfServeDataAccess: (selfServeId: string) => `/self-serve/${selfServeId}/initiate`,
  selfServeRequestDashboard: (selfServeId: string, dashboardId: string) => `/self-serve/${selfServeId}/dashboards/${dashboardId}/request`,
  selfServeNDADetails: (selfServeId: string) => `/self-serve/${selfServeId}/nda`,
  selfServeRefreshConfig: (gameId: string) => `/self-serve/${gameId}/refresh-config`,
  simulateProjections: (subModuleId: string) => `/visualization/submodules/${subModuleId}/predictions`,
  getPredictions: (submoduleId: string, gameId: string) => `/visualization/submodules/${submoduleId}/predictions?gameId=${gameId}`,
  editProjections: (submoduleId: string, predictionId: string) => `/visualization/submodules/${submoduleId}/predictions/${predictionId}/user-inputs`,
  selfServeAtachments: (selfServeId: string, attachmentId: string) => `/self-serve/requests/${selfServeId}/attachments/${attachmentId}/download`,
};
