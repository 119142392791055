import { FC } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const FundingEligibilityMetricBox: FC<{
  title: string, value: number, status: string, weightage: string, scoreType: string
}> = ({ title, value, status, weightage, scoreType }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        background: 'white',
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        width: "100%",
        padding: isMobile ? "0.75rem" : "1rem",
        borderRadius: '0.75rem',
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)"
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25rem", width: "70%" }}>
        <Typography sx={{ color: "#6C6C6C", fontWeight: 700, fontSize: "0.875rem" }}>{title}</Typography>
        <Typography sx={{ color: "#17191C59", fontWeight: 700, fontSize: "0.75rem", letterSpacing: '-0.35px' }}>
          {weightage.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())} weightage
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25rem", alignItems: "flex-end" }}>
        <Typography sx={{ color: "#6C6C6C", fontWeight: 700, fontSize: "0.75rem", letterSpacing: '-0.35px' }}>
          {scoreType === 'months' ? value.toFixed(0) : `${(value * 100).toFixed(2)}%`}
        </Typography>
        <Typography
          component="span"
          sx={{
            color: status === "FAIR" ? "#FFA726" : "#5E8700",
            fontWeight: 700,
            fontSize: "0.75rem",
            display: "flex",
            alignItems: "center",
            gap: "0.25rem"
          }}
        >
          {status.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
          <Box
            sx={{
              width: "0.8rem",
              height: "0.8rem",
              backgroundColor: status === "FAIR" ? "#FFA726" : "#5E8700",
              borderRadius: "0.25rem"
            }}
          />
        </Typography>
      </Box>
    </Box>
  )
};

export default FundingEligibilityMetricBox;