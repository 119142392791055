import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Box, Modal, Typography } from "@mui/material";
import { RootState } from "@stores/store";
import { fundingEligibilityMetricsTooltips } from "./constants";
import { FundingEligibilityChartResponse, Game, Metric } from "./types";
import FundingEligibilityMetricBox from "./FundingEligibilityMetricBox";
import pvxLogo from "../../../../assets/images/funding-header-logo.png";
import pdfHeader from "../../../../assets/images/pdf-header.svg";
import FundingEligibilityInfo from "./FundingEligibilityInfo";


interface DownloadPDFModalProps {
  isOpen: boolean;
  handleClose: () => void;
  chartData: FundingEligibilityChartResponse | undefined;
  selectedGame: Game | undefined;
}

const DownloadPDFModal: React.FC<DownloadPDFModalProps> = ({
  isOpen,
  handleClose,
  chartData,
  selectedGame,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const userDetails = useSelector((state: RootState) => state?.UserData.user);

  const customAttributes = chartData?.metadata?.customAttributes;

  const getMetricType = (metric: Metric) => {
    if (customAttributes?.[metric.metric])
      return customAttributes?.[metric.metric];
    else return "percentage";
  };

  const downloadPDF = async () => {
    const content = contentRef.current;

    if (!content) return;

    const canvas = await html2canvas(content, {
      scale: 1.5,
      useCORS: true,
      scrollX: 0,
      scrollY: 0,
      windowWidth: document.documentElement.scrollWidth,
      windowHeight: content.scrollHeight,
    });

    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    if (!ctx) return;

    const pdf = new jsPDF("p", "mm", "a4", true);
    const pageWidth = 210;
    const pageHeight = 297;
    const margin = 0;

    let yPos = margin;
    let pageY = 0;
    const pageCanvasHeight =
      (pageHeight - yPos - margin) * (canvas.width / pageWidth);

    while (pageY < canvas.height) {
      const pageCanvas = document.createElement("canvas");
      pageCanvas.width = canvas.width;
      pageCanvas.height = Math.min(pageCanvasHeight, canvas.height - pageY);
      const pageCtx = pageCanvas.getContext("2d", { willReadFrequently: true });

      if (pageCtx) {
        pageCtx.drawImage(
          canvas,
          0,
          pageY,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          canvas.width,
          pageCanvas.height
        );

        const pageImgData = pageCanvas.toDataURL("image/jpg", 0.6);
        pdf.addImage(
          pageImgData,
          "JPG",
          margin,
          yPos,
          pageWidth - 2 * margin,
          (pageCanvas.height * (pageWidth - 2 * margin)) / canvas.width
        );
      }

      pageY += pageCanvasHeight;
      if (pageY < canvas.height) {
        pdf.addPage();
      }
    }

    const fileName = `${chartData?.name}_${selectedGame?.name}.pdf`;
    pdf.save(fileName);
    handleClose();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      downloadPDF();
    }, 700); // Runs after all rendering is done

    return () => clearTimeout(timeout); // Cleanup in case component unmounts
  }, []);

  const filteredMetrics = chartData?.metrics?.filter(
    (metric) => metric !== null
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      hideBackdrop
    >
      <Box
        ref={contentRef}
        sx={{
          position: "absolute",
          top: "-9999px",
          left: "-9999px",
          transform: "translate(-50%, -50%)",
          width: 1200,
          bgcolor: "#F0F0F1",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: filteredMetrics && filteredMetrics?.length > 6 ? "106rem": "auto"
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${pdfHeader})`,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                borderRadius: "1.25rem",
                height: "8.5rem",
                display: "flex",
                alignItems: "center",
                padding: "2.5rem 1rem",
                justifyContent: "space-between",
                marginBottom: "1.125rem",
              }}
            >
              <img src={pvxLogo} alt="PVX" style={{ height: "3.5rem" }} />
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    color: "#F6F8F9",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {userDetails.username}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    color: "#F6F8F9",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {userDetails.email}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "1.25rem",
                backgroundColor: "#FFFFFF",
                borderRadius: "0.75rem",
                marginBottom: "1.125rem",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 700,
                  fontSize: "1.375rem",
                  color: "#1B1C17",
                }}
              >
                Funding Eligibility Report
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 700,
                  fontSize: "1.5rem",
                  color: "#6C6C6C",
                }}
              >
                {selectedGame?.name}
              </Typography>
            </Box>
            <FundingEligibilityInfo chartData={chartData} />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "1rem",
                marginY: "1.125rem",
              }}
            >
              {filteredMetrics?.map((metric: Metric, index: number) => (
                <FundingEligibilityMetricBox
                  key={index}
                  title={metric?.displayName}
                  value={metric?.referenceValue}
                  status={metric?.score}
                  weightage={metric?.weightage}
                  scoreType={getMetricType(metric)}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: '20px',
            }}>
            <Typography sx={{
              color: '#1B1C17',
              fontSize: '1.25rem',
              lineHeight: '1.375rem',
              fontWeight: 700,
              marginBottom: '0.75rem',
            }}>
              Definitions
            </Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.75rem'
            }}>
              {fundingEligibilityMetricsTooltips.map((metric, index) => (
                <Box 
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem'
                }}>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      lineHeight: '1.25rem',
                      fontWeight: 500,
                      color: '#162936'
                    }}>
                    {metric.id}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      lineHeight: '1.25rem',
                      fontWeight: 500,
                      color: '#6C6C6C'
                    }}>
                    {`${metric.content} ${metric.example}`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DownloadPDFModal;
