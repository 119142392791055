import React from "react";
import {
  Box,
  Button,
  Typography,
  FormGroup,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DrawerComponent from "../../../../components/Drawer/Drawer";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import pdfDownload from '../../../../assets/icons/pdf-download.svg';
import downloadIcon from '../../../../assets/icons/download_icon_green.svg';
import { useLazyFetchSelfServeAtachmentsQuery } from "@api/selfServe";

interface DashboardRequestsSidePanelProps {
  showDashboardRequestsSidePanel: boolean;
  setShowDashboardRequestsSidePanel: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  selectedRow: any;
}

export type Dashboard = {
  name: string;
  status: string;
};

const DashboardRequestsSidePanel: React.FC<DashboardRequestsSidePanelProps> = ({
  showDashboardRequestsSidePanel,
  setShowDashboardRequestsSidePanel,
  selectedRow,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [fetchSelfServeAtachments] = useLazyFetchSelfServeAtachmentsQuery();

  const gameMetadata = JSON.parse(selectedRow?.gameMetadata);

  const gameData = gameMetadata.APP_STORE
    ? gameMetadata.APP_STORE
    : gameMetadata.PLAY_STORE;

  const groupedOptions = {
    "User Dashboards": selectedRow.dashboards,
    "Requested Dashboards": selectedRow.requestedDashboards,
  };

  const iconMap: Record<string, string> = {
    BENCHMARKS: "tour_dark.svg",
    UNDERWRITING: "underwriting_dark.svg",
    FUNDING_ELIGIBILITY: "invoice_dark.svg",
    "Financial Projections": "analytics_dark.svg",
  };

  enum DashboardStatus {
    NOT_REQUESTED = "NOT_REQUESTED",
    REQUESTED = "REQUESTED",
    PENDING = "PENDING",
    DATA_FETCH_FAILED = "DATA_FETCH_FAILED",
    READY_TO_PROCESS = "READY_TO_PROCESS",
    PENDING_DATA_PULL = "PENDING_DATA_PULL",
    DATA_PULL_INITIATED = "DATA_PULL_INITIATED",
    FAILED = "FAILED",
    READY_TO_PUBLISH = "READY_TO_PUBLISH",
    PUBLISHED = "PUBLISHED"
  }

  const REQUESTED_DASHBOARD_COLORS = {
    REQUESTABLE: "#FFA726",
    REQUESTED: "#5E8700"
  }

  const STATUS_COLORS: Record<string, string> = {
    NOT_REQUESTED: "#D3D3D3",
    PUBLISHED: "#5E8700",
    DATA_FETCH_FAILED: "#FF0000",
    FAILED: "#FF0000",
    READY_TO_PROCESS: "#FFA726",
    READY_TO_PUBLISH: "#FFA726",
    REQUESTED: "#FFA726",
    PENDING: "#FFA726",
    PENDING_DATA_PULL: "#FFA726",
    DATA_PULL_INITIATED: "#FFA726",
  };

  const formatDashboardName = (name: string) =>
    name
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());


  const handleDownloadAttachment = async () => {
    let downloadLink = "";

    const response: any = await fetchSelfServeAtachments({ selfServeId: selectedRow.id, attachmentId: selectedRow.attachmentId }).unwrap();

    downloadLink = response?.data?.url || '';
    if (!downloadLink) {
      return;
    }
    
    const link = document.createElement("a");
    link.href = downloadLink;
    link.target = "_blank";
    link.download = `PvX Partners - ${selectedRow.companyName} - NDA.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DrawerComponent
      open={showDashboardRequestsSidePanel}
      anchor="right"
      close={() => {
        setShowDashboardRequestsSidePanel(false);
      }}
      height="98vh"
      zIndex={9999}
      backgroundColor="#F0F0F1"
    >
      <Box
        sx={{
          width: isMobile ? "auto" : "500px",
          height: "100%",
          padding: "1.5rem",
          marginBottom: "5rem",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.375rem",
            fontWeight: 700,
            lineHeight: "1.875rem",
            color: "#6C6C6C",
            marginBottom: "1.5rem",
          }}
        >
          Dashboard Permissions
          <Button
            sx={{ padding: 0, minWidth: "fit-content" }}
            onClick={() => {
              setShowDashboardRequestsSidePanel(false);
            }}
          >
            <CloseIcon sx={{ color: "#162C36" }} />
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#FFFFFF",
            padding: "1rem",
            borderRadius: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "0.75rem",
            }}
          >
            <img src={gameData?.icon} alt="icon" style={{ width: "4rem", height: "4rem", borderRadius: "4px" }} />
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 700,
                  fontSize: "1.25rem",
                  lineHeight: "2.5rem",
                }}
              >
                {gameData?.name}
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 600,
                  fontSize: "0.75rem",
                  color: "#6C6C6C",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.25rem",
                }}
              >
                {selectedRow?.companyName}
                <Box
                  sx={{
                    width: "0.35rem",
                    height: "0.35rem",
                    backgroundColor: "#6C6C6C",
                    borderRadius: "1rem",
                  }}
                />
                {selectedRow?.category}
                <Box
                  sx={{
                    width: "0.35rem",
                    height: "0.35rem",
                    backgroundColor: "#6C6C6C",
                    borderRadius: "1rem",
                  }}
                />
                {selectedRow?.genre}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 600,
                  fontSize: "0.75rem",
                  color: "#6C6C6C",
                }}
              >
                Game ID
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 700,
                  fontSize: "1rem",
                  color: "#162936",
                }}
              >
                {selectedRow?.gameId}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 600,
                  fontSize: "0.75rem",
                  color: "#6C6C6C",
                }}
              >
                Email ID
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 700,
                  fontSize: "1rem",
                  color: "#162936",
                }}
              >
                {selectedRow?.email}
              </Typography>
            </Box>
          </Box>
          {selectedRow?.attachmentId && (<Box
            sx={{
              width: "100%",
              display: "flex",
              textAlign: "left",
              cursor: "pointer",
            }}
            onClick={(e) => {
              handleDownloadAttachment();
              e.stopPropagation();
            }}
          >
            <img
              src={pdfDownload}
              alt="action_icon"
              style={{ userSelect: "none", marginRight: "0.2rem", width: "1rem" }}
            />
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 700,
                fontSize: "0.85rem",
                color: "#162936",
                marginRight: "0.2rem"
              }}
            >
              NDA Document
            </Typography>
            <img
              src={downloadIcon}
              alt="action_icon"
              style={{ userSelect: "none", color: "red" }}
            />
          </Box>)}
        </Box>
        {groupedOptions["User Dashboards"].length + groupedOptions["Requested Dashboards"].length > 0 && Object.entries(groupedOptions).map(([title, filteredOptions]) => {
          const isDisabled = filteredOptions.every(
            (row: Dashboard) =>
              row.status === DashboardStatus.NOT_REQUESTED
          );
          
          return (
            (
              (title === "Requested Dashboards") ||
              (title === "User Dashboards" && groupedOptions["User Dashboards"].length > 0)
            ) &&
            <Box
              key={title}
              sx={{
                width: "100%",
                backgroundColor: "#FFFFFF",
                padding: "1rem",
                borderRadius: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                marginBottom: "1rem",
                pointerEvents: isDisabled ? "none" : "auto",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "1rem",
                  fontWeight: 700,
                  color: "#6C6C6C",
                }}
              >
                {title}
              </Typography>
              <FormGroup
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                  opacity: isDisabled ? 0.5 : 1,
                }}
              >
                {filteredOptions.map((row: Dashboard, index: number) =>
                  isDisabled && row.status === DashboardStatus.NOT_REQUESTED ? (
                    <Box
                      key={index}
                      sx={{
                        border: "1px solid #E8EAEB",
                        padding: "0.75rem 1.125rem",
                        borderRadius: "0.875rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        backgroundColor: "#F0F0F1",
                      }}
                    >
                      <img
                        src={iconMap[row.name] ? require(`../../../../assets/icons/${iconMap[row.name]}`) : ""}
                        alt=""
                        style={{ width: "1.5rem" }}
                      />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            fontFamily: "Manrope, sans-serif",
                            fontWeight: 600,
                            fontSize: "0.875rem",
                            color: "#162936",
                          }}
                        >
                          {isDisabled && row.status === DashboardStatus.NOT_REQUESTED
                            ? "No Request"
                            : row.name}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      key={row.name}
                    >
                      {isDisabled && row.status === DashboardStatus.NOT_REQUESTED ? (
                        <Box
                          sx={{
                            border: "1px solid #E8EAEB",
                            padding: "0.75rem 1.125rem",
                            borderRadius: "0.875rem",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            backgroundColor: "#F0F0F1",
                            pointerEvents: isDisabled ? "none" : "auto",
                          }}
                        >
                          <img
                            src={iconMap[row.name] ? require(`../../../../assets/icons/${iconMap[row.name]}`) : ""}
                            alt=""
                            style={{ width: "1.5rem" }}
                          />
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontFamily: "Manrope, sans-serif",
                                fontWeight: 600,
                                fontSize: "0.875rem",
                                color: "#162936",
                              }}
                            >
                              {isDisabled &&
                                row.status === DashboardStatus.NOT_REQUESTED
                                ? DashboardStatus.NOT_REQUESTED
                                : row.name}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            border: "1px solid #E8EAEB",
                            padding: "0.75rem 1.125rem",
                            borderRadius: "0.875rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            opacity:
                              row.status === DashboardStatus.NOT_REQUESTED
                                ? 0.5
                                : 1,
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{
                                width: "0.75rem",
                                height: "0.75rem",
                                borderRadius: "0.25rem",
                                backgroundColor:  (title === "Requested Dashboards" ? 
                                  REQUESTED_DASHBOARD_COLORS[row.status as keyof typeof REQUESTED_DASHBOARD_COLORS]: 
                                  STATUS_COLORS[row.status]) || "#FFA726",
                                marginRight: "0.5rem",
                              }}
                            />
                            <Typography
                              sx={{
                                fontFamily: "Manrope, sans-serif",
                                fontWeight: 600,
                                fontSize: "0.875rem",
                                color: "#162936",
                              }}
                            >
                              { title === "Requested Dashboards" ? (row as any).moduleName : formatDashboardName(row.name) }
                            </Typography>
                          </Box>
                          <img
                            src={iconMap[row.name || (row as any).moduleName] ? require(`../../../../assets/icons/${iconMap[row.name || (row as any).moduleName]}`) : ""}
                            alt=""
                            style={{ width: "1.5rem" }}
                          />
                        </Box>
                      )}
                    </Box>
                  )
                )}

                {filteredOptions.length === 0 && (
                  <Box
                  sx={{
                    border: "1px solid #E8EAEB",
                    padding: "0.75rem 1.125rem",
                    borderRadius: "0.875rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    backgroundColor: "#F0F0F1",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: 600,
                        fontSize: "0.875rem",
                        color: "#162936",
                      }}
                    >
                      No Requests
                    </Typography>
                  </Box>
                </Box>
                )}
              </FormGroup>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          width: isMobile ? "auto" : "500px",
          padding: "1rem 1.5rem",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          position: "sticky",
          bottom: "0",
          borderTop: "1px solid #E8EAEB",
          backgroundColor: "#f0f0f1",
        }}
      >
        <CustomButton
          color="#2F736E"
          name="Close"
          borderColor="#2F736E"
          onClick={() => setShowDashboardRequestsSidePanel(false)}
        />
      </Box>
    </DrawerComponent>
  );
};

export default DashboardRequestsSidePanel;
