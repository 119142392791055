import { createSlice } from "@reduxjs/toolkit";
import { FinancialProjectionData } from "./types/financialProjections";

const createEmptyData = () => ({
  gameId: null,
  columns: [
    {
      key: "",
      label: "",
    },
  ],
  id: "",
  meta: {
    chartType: "",
    columns: [
      {
        id: "",
        name: "",
        type: "",
        currencyFormat: undefined,
        placement: undefined,
        color_code: undefined,
        display: undefined,
      },
    ],
    sort: {
      fields: "",
      order: "ASC" as "ASC" | "DESC",
    },
  },
  rows: [],
  pricingDetails: []

});

// Initial state
const initialState: FinancialProjectionData = {
  financialProjections: {
    chartData: createEmptyData(),
    tableData: createEmptyData(),
    simulationsChartData: createEmptyData(),
    simulationsTableData: createEmptyData(),
  },
};

export const FinancialProjectionsData = createSlice({
  name: 'FinancialProjections',
  initialState,
  reducers: {
    setFinancialProjectionsChartData: (state, action) => {
      state.financialProjections.chartData = action.payload;
    },
    setFinancialProjectionsTableData: (state, action) => {
      state.financialProjections.tableData = action.payload;
    },
    setSimulationsChartData: (state, action) => {
      state.financialProjections.simulationsChartData = action.payload;
    },
    setSimulationsTableData: (state, action) => {
      state.financialProjections.simulationsTableData = action.payload;
    },

    resetFinancialProjectionsChartData: (state) => {
      state.financialProjections.chartData = initialState.financialProjections.chartData;
    },

    resetFinancialProjectionsTableData: (state) => {
      state.financialProjections.tableData = initialState.financialProjections.tableData;
    },

    resetSimulationsChartData: (state) => {
      state.financialProjections.simulationsChartData = initialState.financialProjections.simulationsChartData;
    },

    resetSimulationsTableData: (state) => {
      state.financialProjections.simulationsTableData = initialState.financialProjections.simulationsTableData;
    }
  }
});



export const {
  setFinancialProjectionsChartData,
  setFinancialProjectionsTableData,
  setSimulationsChartData,
  setSimulationsTableData,
  resetFinancialProjectionsChartData,
  resetFinancialProjectionsTableData,
  resetSimulationsChartData,
  resetSimulationsTableData
} = FinancialProjectionsData.actions

export default FinancialProjectionsData.reducer;