import React, { FC, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  TableHead
} from "@mui/material";
import dayjs from "dayjs";
import { ALLOCATION_TYPES, useCreateAllocationRequestMutation, useLazyGetInvestmentRequestAllocationByGameIdQuery, useLazyGetInvestmentRequestInvestorFiltersQuery, useRejectInvestmentRequestMutation } from "@api/investments";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Loader } from "@components";

interface FundingAllocationProps {
  fundingRequestId: string;
  setCloseUpdate: () => void;
  gameId: string;
  refreshData: () => void;
}

const fundingTypeOptions = [
  { label: "Financing (Payments)", value: "FINANCING_PAYMENTS" },
  { label: "Financing (Funding Refund)", value: "FINANCING_FUNDING_REFUND" },
  { label: "Fresh Financing", value: "FRESH_FINANCING" },
  { label: "Re-Financing", value: "RE_FINANCING" },
  { label: "Payments", value: "PAYMENTS" },
  { label: "Adjustments", value: "ADJUSTMENTS" },
  { label: "Financing (Adjustments)", value: "FINANCING_ADJUSTMENTS" },
];

const FundingAllocation: FC<FundingAllocationProps> = (props) => {
  const { fundingRequestId, setCloseUpdate, gameId, refreshData } = props;

  const [games, setGames] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [getInvestmentRequestAllocationByGameId] = useLazyGetInvestmentRequestAllocationByGameIdQuery();
  const [getInvestmentRequestInvestorFilters] = useLazyGetInvestmentRequestInvestorFiltersQuery();
  const [rejectInvestmentRequest] = useRejectInvestmentRequestMutation();
  const [createAllocationRequest] = useCreateAllocationRequestMutation();

  useEffect(() => {
    const getRequests = async () => {
      setLoading(true);
      const games = await getInvestmentRequestInvestorFilters({ gameId: gameId }).unwrap();
      if (games?.data?.length) {
        setGames(games.data.map((g: any) => ({ label: g, value: g })))
      }

      const res = await getInvestmentRequestAllocationByGameId({ requestId: fundingRequestId }).unwrap();
      if (res?.data?.length) {
        const isAdjustment = res.data.some((investment: any) => investment.allocationType === ALLOCATION_TYPES.ADJUSTMENT);
        const data = res.data.map((allocation: any) => ({
          investor: allocation.investor,
          type: allocation.investmentType,
          amount: allocation.amount,
          amountDisplay: formatCurrency(allocation.amount),
          date: dayjs(allocation.settlementDate),
          allocationType: allocation.allocationType,
          disabled: isAdjustment && allocation.allocationType === ALLOCATION_TYPES.ALLOCATION
        }));
        setRows(data);
        setLoading(false);
      }
    };

    if (fundingRequestId) {
      getRequests();
    }
  }, []);

  const handleRowChange = (index: number, field: string, value: any) => {
    const updatedRows = [...rows];
    if (field === "amount") {
      const {amount, amountDisplay} = value;
      updatedRows[index].amount = amount;
      updatedRows[index].amountDisplay = amountDisplay;
    } else {
      (updatedRows[index] as any)[field] = value;
    }
    setRows(updatedRows);
  };

  const handleRejectRequest = () => {
    if (fundingRequestId) {
      rejectInvestmentRequest({ requestId: fundingRequestId });
      setCloseUpdate();
    }
  };

  const handleCreateAllocation = () => {
    if (fundingRequestId && rows.every(obj =>
      obj.investor &&
      obj.type &&
      obj.amount > 0
    )) {
      const isAdjustment = rows.some((investment: any) => investment.allocationType === ALLOCATION_TYPES.ADJUSTMENT);
      const filteredRows = isAdjustment
        ? rows.filter((row: any) => row.allocationType === ALLOCATION_TYPES.ADJUSTMENT)
        : rows;
      const allocations = filteredRows.map((row: any) => ({
        investor: row.investor,
        investmentType: row.type,
        amount: row.amount,
        settlementDate: dayjs(row.date).toISOString().slice(0, 10)
      }));

      const payload = {
        requestId: fundingRequestId,
        allocations: allocations
      }
      createAllocationRequest(payload);
      setCloseUpdate();
    }
  };

  const handleCreateNewRow = () => {
    if (rows.length) {
      const lastRow = rows[rows.length - 1];
      if (!lastRow.investor || !lastRow.type || !lastRow.amount) {
        return;
      }
      const newRow = {
        investor: "",
        type: "",
        amount: 0,
        date: (rows[rows.length - 1] as any).date,
        disabled: false
      }

      setRows([...rows, newRow]);
    }
  };

  const handleDeleteRow = (index: number) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  }

  const formatCurrency = (value: number | string): string => {
    if (!value || value === "0" || value === "0.00") {
      return "";
    }

    let formattedValue = `${value}`.replace(/[^0-9.]/g, "");

    if (formattedValue.includes(".")) {
      const [integerPart, decimalPart] = formattedValue.split(".");
      formattedValue = `${Number(integerPart).toLocaleString(
        "en-US"
      )}.${decimalPart.slice(0, 2)}`;

      // If there is a dot and no decimal values, add two zeros
      if (decimalPart === "") {
        formattedValue = `${Number(integerPart).toLocaleString("en-US")}.`;
      }
    } else {
      formattedValue = `${Number(formattedValue).toLocaleString("en-US")}`;
    }

    return `$${formattedValue}`;
  };

  const handleAmountFieldChange = (value: string) => {
      const cleanedValue = value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1")
        .replace(/(\.\d{2})\d+/g, "$1");
  
      // Check if valid input (allows for partial inputs like "100." or ".5")
      const isValidInput = /^\d*\.?\d{0,2}$/.test(cleanedValue);
  
      if (!isValidInput) {
        return;
      }
  
      const numericValue =
        cleanedValue === "" || cleanedValue === "."
          ? null
          : parseFloat(cleanedValue);
  
      if (numericValue && numericValue > 100000000) {
        return;
      }
  
      return {
        amount: numericValue,
        amountDisplay: formatCurrency(cleanedValue),
      };
    };

  return (
    <TableContainer sx={{ height: "100%", width: "100%", borderRadius: "16px", p: 0, pb: "1rem" }}>
      <Table
        sx={{
          borderSpacing: "0 10px",
          borderCollapse: "separate",
        }}
      >
        <TableHead>
          <TableRow sx={{ borderRadius: "20px" }}>
            <TableCell
              sx={{
                paddingY: "0.75rem",
                paddingLeft: "0.5rem",
                fontSize: "0.75rem",
                fontWeight: 700,
                lineHeight: "1rem",
                color: "#F6F8F9",
                borderLeft: "1px solid #737F86",
                borderBottom: "none",
                background: "#2F736E",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "8px",
                WebkitBorderBottomLeftRadius: "8px",
                textAlign: "left"
              }}
            >
              Settlement Date
            </TableCell>
            <TableCell
              sx={{
                paddingY: "0.75rem",
                paddingLeft: "0.5rem",
                fontSize: "0.75rem",
                fontWeight: 700,
                lineHeight: "1rem",
                color: "#F6F8F9",
                borderLeft: "1px solid #737F86",
                borderBottom: "none",
                background: "#2F736E",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                WebkitBorderBottomLeftRadius: "0px",
                textAlign: "left"
              }}
            >
              Cohort
            </TableCell>
            <TableCell
              sx={{
                paddingY: "0.75rem",
                paddingLeft: "0.5rem",
                fontSize: "0.75rem",
                fontWeight: 700,
                lineHeight: "1rem",
                color: "#F6F8F9",
                borderLeft: "1px solid #737F86",
                borderBottom: "none",
                background: "#2F736E",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                WebkitBorderBottomLeftRadius: "0px",
                textAlign: "left"
              }}
            >
              Investor
            </TableCell>
            <TableCell
              sx={{
                paddingY: "0.75rem",
                paddingLeft: "0.5rem",
                fontSize: "0.75rem",
                fontWeight: 700,
                lineHeight: "1rem",
                color: "#F6F8F9",
                borderLeft: "1px solid #737F86",
                borderBottom: "none",
                background: "#2F736E",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                WebkitBorderBottomLeftRadius: "0px",
                textAlign: "left"
              }}
            >
              Type
            </TableCell>
            <TableCell
              sx={{
                paddingY: "0.75rem",
                paddingLeft: "0.5rem",
                fontSize: "0.75rem",
                fontWeight: 700,
                lineHeight: "1rem",
                color: "#F6F8F9",
                borderLeft: "1px solid #737F86",
                borderBottom: "none",
                background: "#2F736E",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                WebkitBorderBottomLeftRadius: "0px",
                textAlign: "right"
              }}
            >
              Amount
            </TableCell>
            <TableCell
              sx={{
                paddingY: "0.75rem",
                paddingLeft: "0.5rem",
                fontSize: "0.75rem",
                fontWeight: 700,
                lineHeight: "1rem",
                color: "#F6F8F9",
                borderLeft: "1px solid #737F86",
                borderBottom: "none",
                background: "#2F736E",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "0px",
                WebkitBorderBottomLeftRadius: "0px",
                textAlign: "right"
              }}
            >
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow key="table_loader">
              <TableCell colSpan={6} sx={{ borderBottom: "none" }}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                  <Loader customWidth="3rem" />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row: any, index) => (
              <TableRow
                key={index}
                sx={{
                  opacity: row.disabled ? 0.5 : 1,
                  pointerEvents: row.disabled ? "none" : "auto",
                }}
              >
                <TableCell
                  sx={{
                    pt: index === 0 ? "0.25rem" : "0rem",
                    pb: "0.1rem",
                    px: 0,
                    my: 0,
                    borderBottom: "none",
                    borderTopLeftRadius: "1rem",
                    borderBottomLeftRadius: "1rem",
                    background: "#F0F0F1"
                  }}
                >
                  <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <TextField
                      type="text"
                      value={dayjs(row.date).format("YYYY-MM-DD")}
                      onChange={(e) => null}
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "left",
                          fontSize: "0.85rem",
                          fontWeight: 500,
                          lineHeight: "1rem",
                        }
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    pt: index === 0 ? "0.25rem" : "0rem",
                    pb: "0.1rem",
                    px: 0,
                    my: 0,
                    borderBottom: "none",
                    background: "#F0F0F1"
                  }}
                >
                  <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <TextField
                      type="text"
                      value={dayjs(row.cohort).format("YYYY-MM")}
                      onChange={(e) => null}
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "left",
                          fontSize: "0.85rem",
                          fontWeight: 500,
                          lineHeight: "1rem",
                        }
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    pt: index === 0 ? "0.25rem" : "0rem",
                    pb: "0.1rem",
                    px: "0.5rem",
                    my: 0,
                    borderBottom: "none",
                    background: "#F0F0F1"
                  }}
                >
                  <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid #7FBCAD",
                    borderRadius: "0.5rem",
                    background: "#FFFFFF"
                  }}>
                    <Select
                      value={row.investor}
                      onChange={(e) => handleRowChange(index, "investor", e.target.value)}
                      fullWidth
                      sx={{
                        height: "3rem",
                        p: 0,
                        border: "none",
                        fontSize: "0.85rem",
                        fontWeight: 500,
                        lineHeight: "1rem",
                        "& fieldset": {
                          border: "none"
                        }
                      }}
                    >
                      {games.map((g) => (
                        <MenuItem value={g.value}>{g.label}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    pt: index === 0 ? "0.25rem" : "0rem",
                    pb: "0.1rem",
                    px: "0.5rem",
                    my: 0,
                    borderBottom: "none",
                    background: "#F0F0F1"
                  }}
                >
                  <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid #7FBCAD",
                    borderRadius: "0.5rem",
                    background: "#FFFFFF"
                  }}>
                    <Select
                      value={row.type}
                      onChange={(e) => handleRowChange(index, "type", e.target.value)}
                      fullWidth
                      sx={{
                        height: "3rem",
                        p: 0,
                        border: "none",
                        fontSize: "0.85rem",
                        fontWeight: 500,
                        lineHeight: "1rem",
                        "& fieldset": {
                          border: "none"
                        }
                      }}
                    >
                      {fundingTypeOptions.map((type) => (
                        <MenuItem value={type.value}>{type.label}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    pt: index === 0 ? "0.25rem" : "0rem",
                    pb: "0.1rem",
                    px: "0.5rem",
                    my: 0,
                    borderBottom: "none",
                    background: "#F0F0F1"
                  }}
                >
                  <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid #7FBCAD",
                    borderRadius: "0.5rem",
                    background: "#FFFFFF"
                  }}>
                    <TextField
                      type="text"
                      value={row.amountDisplay}
                      onChange={(e) => {
                        // eslint-disable-next-line no-useless-escape
                        const returnVal = handleAmountFieldChange(e.target.value);
                        if (returnVal) {
                          const { amount, amountDisplay } = returnVal;
                          handleRowChange(index, "amount", {amount, amountDisplay});
                        }
                      }}
                      fullWidth
                      inputProps={{
                        style: {
                          textAlign: "right",
                          fontSize: "0.85rem",
                          fontWeight: 500,
                          lineHeight: "1rem",
                        }
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    pt: index === 0 ? "0.25rem" : "0rem",
                    pb: "0.1rem",
                    px: "0.5rem",
                    my: 0,
                    borderBottom: "none",
                    borderTopRightRadius: "1rem",
                    borderBottomRightRadius: "1rem",
                    background: "#F0F0F1"
                  }}
                >
                  <Box sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <DeleteOutlineIcon
                      htmlColor="#2E736E"
                      sx={{
                        height: '1.5rem',
                        width: '1.5rem',
                        cursor: "pointer",
                        backgroundColor: "#F0F0F1",
                      }}
                      onClick={() => handleDeleteRow(index)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
          <TableRow
            key={'add_investor_action_button'}
          >
            <TableCell
              colSpan={6}
              sx={{
                pt: "0.25rem",
                pb: "0.25rem",
                px: 0,
                borderBottom: "none"
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  color: "#2E736E",
                  border: "none",
                  textTransform: "none",
                  p: "0.25rem",
                  fontWeight: 700,
                  '&:hover': {
                    border: "none",
                  }
                }}
                onClick={() => handleCreateNewRow()}
              >
                + Add Investor
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 8,
        }}
      >
        <Box flex={1}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              mr: "1rem",
              borderRadius: "2rem",
              textTransform: "none"
            }}
            onClick={() => {
              handleCreateAllocation();
              refreshData();
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              borderRadius: "2rem",
              textTransform: "none"
            }}
            onClick={() => setCloseUpdate()}
          >
            Cancel
          </Button>
        </Box>
        <Button
          variant="outlined"
          color="error"
          sx={{
            border: "solid 1px",
            borderRadius: "2rem",
            textTransform: "none",
            fontWeight: 600
          }}
          onClick={() => handleRejectRequest()}
        >
          Reject Request
        </Button>
      </div>
    </TableContainer >
  );
};

export default FundingAllocation;
