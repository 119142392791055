import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Snackbar, Typography } from "@mui/material";
import { resetToastMessage } from "@stores/App/slice";
import "./App.css";
import theme from "./theme";
import { AppRoutes } from "./routes";
import { RootState } from "./stores/store";

const App: FC = () => {
  const dispatch = useDispatch();
  const appData = useSelector((state: RootState) => state?.AppData);

  const handleSnackbarExited = () => {
    dispatch(resetToastMessage());
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRoutes />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(appData?.toast?.open)}
        autoHideDuration={2000}
        onClose={handleSnackbarExited}
      >
        {appData.toast.heading.length ? (
          <Box
            borderRadius={"0.25rem"}
            padding={"1rem 0.875rem"}
            sx={{
              backgroundColor: theme.toast[appData?.toast?.type].background,
              boxShadow: "0px 4px 8px 3px #00000026;",
            }}
          >
            <Typography
              fontSize={"0.875rem"}
              lineHeight={"1.25rem"}
              fontWeight={700}
              color={theme.toast[appData?.toast?.type].heading || "#FFF"}
            >
              {appData.toast.heading}
            </Typography>
            <Typography
              fontSize={"0.875rem"}
              lineHeight={"1.25rem"}
              fontWeight={400}
              color={theme.toast[appData?.toast?.type].text || "#FFF"}
            >
              {appData?.toast?.toast_message || ""}
            </Typography>
          </Box>
        ) : (
          <Box
            borderRadius={"0.25rem"}
            padding={"1rem 0.875rem"}
            sx={{
              backgroundColor: theme.toast[appData?.toast?.type].background,
              boxShadow: "0px 4px 8px 3px #00000026;",
            }}
          >
            <Typography
              fontSize={14}
              fontWeight={700}
              lineHeight={"1.25rem"}
              sx={{
                color: theme.toast[appData?.toast?.type].text || "#FFF",
              }}
            >
              {appData?.toast?.toast_message || ""}
            </Typography>
          </Box>
        )}
      </Snackbar>
    </ThemeProvider>
  );
};

export default App;
