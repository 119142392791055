import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";

interface EditSignNDAProps {
  open: boolean;
  close: (cancel: boolean) => void;
}

const EditSignNDA: FC<EditSignNDAProps> = ({ open, close }) => {
  const handleClose = () => {
    close(true);
  };

  const handleConfirm = () => {
    close(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="send-nda-dialog"
      aria-describedby="send-nda-dialog"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "28px",
          width: "min(80%, 430px)",
          padding: "1.5rem",
        },
      }}
    >
      <DialogTitle
        id="send-nda-dialog-title"
        sx={{
          fontSize: "1.5rem",
          fontWeight: 700,
          lineHeight: "2rem",
          color: "#162936",
          padding: 0,
          pb: "1rem",
        }}
      >
        Editing will Restart the NDA Signing Procedure
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
        }}
      >
        <DialogContentText
          fontSize={"0.875rem"}
          fontWeight={500}
          lineHeight={"1.25rem"}
          color={"#6C6C6C"}
          id="send-nda-dialog-description"
        >
            The previously sent document will be invalidated.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: 0,
          pt: "1.5rem",
        }}
      >
        <Button
          onClick={handleConfirm}
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          sx={{
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "0.625rem 1.5rem",
            textTransform: "none",
            fontWeight: 700,
            color: "#51B8B0",
            ":hover": {
              background: "none",
            },
          }}
        >
          Edit NDA
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          sx={{
            borderRadius: "100px",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "0.625rem 1.5rem",
            textTransform: "none",
            fontWeight: 700,
            background: "#2F736E",
            color: "#fff",
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSignNDA;
