import { useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { ArrowForwardIos } from "@mui/icons-material";
import backgroundImage from "../../../../assets/images/header-bg.png";
import cohortAnalytics from "../../../../assets/icons/cohort-analytics-icon.svg";
import fundingAssessment from "../../../../assets/icons/funding-assessment-icon.svg";
import industryBenchmarks from "../../../../assets/icons/industry-benchmarks-icon.svg";
import roasProjections from "../../../../assets/icons/roas-projections-icon.svg";
import { sideNavOptionBorderStyle } from "../../../../components/SideNav/common";
import {
  useFetchSelfServeRefreshConfigQuery,
  useSelfServeRefreshConfigMutation,
} from "@api/selfServe";
import theme from "../../../../theme";

const fundingEligibilityInsights = [
  {
    title: "Advanced Cohort Analytics",
    description:
      "Get a deep dive into ROAS, Retention and payback periods - split by platform, geo and channels",
    icon: cohortAnalytics,
  },
  {
    title: " Funding Assessment",
    description:
      "Receive ongoing insights into how much of your marketing budget can be financed, with feedback on ways to improve eligibility.",
    icon: fundingAssessment,
  },
  {
    title: "Industry Benchmarks",
    description:
      "Stay up to date with how your cohorts compare to industry peers across categories and genres",
    icon: industryBenchmarks,
  },
  {
    title: " ROAS Projections",
    description:
      "Leverage our advanced forecasting models to predict future ROAS and fine-tune your financial planning.",
    icon: roasProjections,
  },
];

const FundingEligibilityInsights: React.FC<{ gameId: string }> = ({
  gameId,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState<string>("");
  const { data: selfServeRefreshConfigData, error: refreshConfigError } =
    useFetchSelfServeRefreshConfigQuery({ gameId });
  const [
    selfServeRefreshConfig,
    {
      isSuccess: selfServeRefreshConfigSuccess,
      isLoading: selfServeRefreshConfigLoading,
    },
  ] = useSelfServeRefreshConfigMutation();
  const [isMonthlyRefreshClicked, setIsMonthlyRefreshClicked] = useState(
    selfServeRefreshConfigData?.data?.refreshEnabled
  );

  useEffect(() => {
    if (selfServeRefreshConfigData?.data?.refreshEnabled !== undefined) {
      setIsMonthlyRefreshClicked(
        selfServeRefreshConfigData?.data?.refreshEnabled
      );
    }
  }, [selfServeRefreshConfigData]);

  useEffect(() => {
    setIsMonthlyRefreshClicked(true);
  }, [selfServeRefreshConfigSuccess]);

  const handleEnableMonthlyRefresh = async () => {
    try {
      await selfServeRefreshConfig({ gameId, refreshEnabled: true });
    } catch (error) {
      console.error("Failed to enable monthly refresh:", error);
      setIsMonthlyRefreshClicked(false);
    }
  };

  const checkScrollPosition = () => {
    if (!scrollRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    const isOverflowing = scrollWidth > clientWidth;

    if (!isOverflowing) {
      setHasOverflow("none");
    } else if (scrollLeft === 0) {
      setHasOverflow("right");
    } else if (scrollLeft + clientWidth >= scrollWidth) {
      setHasOverflow("left");
    } else {
      setHasOverflow("both");
    }
  };

  const scrollToStart = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const scrollToEnd = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollWidth,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", checkScrollPosition);
      checkScrollPosition();
    }

    window.addEventListener("resize", checkScrollPosition);

    return () => {
      scrollRef.current?.removeEventListener("scroll", checkScrollPosition);
      window.removeEventListener("resize", checkScrollPosition);
    };
  }, []);

  useEffect(() => {
    const checkOverflow = () => {
      setHasOverflow("");
      if (scrollRef.current) {
        const scrollLeft = scrollRef.current.scrollLeft;
        const scrollWidth = scrollRef.current.scrollWidth;
        const clientWidth = scrollRef.current.clientWidth;

        const isOverflowing = scrollWidth > clientWidth;

        // Check overflow direction
        if (scrollLeft === 0 && isOverflowing) {
          setHasOverflow("right");
        } else if (scrollLeft + clientWidth === scrollWidth && isOverflowing) {
          setHasOverflow("left");
        }
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow); // Check on resize
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  const renderArrows = () => {
    return (
      <>
        {(hasOverflow === "left" || hasOverflow === "both") && (
          <Button
            onClick={scrollToStart}
            sx={{
              position: "absolute",
              left: 10,
              top: "48%",
              height: "36px",
              width: "36px",
              minWidth: 0,
              background: "#AECBD0",
              borderRadius: "99999px",
              "&:hover": {
                backgroundColor: "#AECBD0",
              },
            }}
          >
            <ArrowForwardIos
              style={{
                color: "#2F736E",
                height: "24px",
                width: "24px",
                rotate: "180deg",
              }}
            />
          </Button>
        )}

        {(hasOverflow === "right" || hasOverflow === "both") && (
          <Button
            onClick={scrollToEnd}
            sx={{
              position: "absolute",
              right: 10,
              top: "48%",
              height: "36px",
              width: "36px",
              minWidth: 0,
              background: "#AECBD0",
              borderRadius: "99999px",
              "&:hover": {
                backgroundColor: "#AECBD0",
              },
            }}
          >
            <ArrowForwardIos
              style={{ color: "#2F736E", height: "24px", width: "24px" }}
            />
          </Button>
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "1.25rem",
        backgroundImage: `url(${backgroundImage}), linear-gradient(#0a1212, #142524, #285a58)`,
        backgroundSize: "cover",
        padding: "1.625rem 1.5rem",
        backgroundPosition: "center bottom",
        display: "flex",
        flexDirection: "column",
        gap: "1.75rem",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          fontSize={"1.375rem"}
          lineHeight={"1.75rem"}
          fontWeight={700}
          color={"#F6F8F9"}
        >
          Unlock Continuous Insights
        </Typography>
        <Typography
          fontSize={"0.875rem"}
          lineHeight={"1.25rem"}
          fontWeight={500}
          color={"#B4B4B4"}
        >
          Keep your Appsflyer data access open, and we’ll refresh your data
          monthly to provide you with:
        </Typography>
      </Box>
      <Box
        ref={scrollRef}
        sx={{
          display: "flex",
          gap: "20px",
          overflowX: "auto",
          scrollbarWidth: "none", // Firefox
          "&::-webkit-scrollbar": {
            display: "none", // Chrome, Safari, Edge
          },
        }}
      >
        {fundingEligibilityInsights.map((insight, index) => (
          <Box
            key={index}
            sx={{
              minWidth: "21rem",
              borderRadius: "1.625rem",
              padding: "1.5rem",
              background: "#5B67681C",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              position: "relative",
              "&::after": sideNavOptionBorderStyle,
            }}
          >
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <img
                src={insight.icon}
                alt="insight"
                style={{ height: "46px", width: "46px" }}
              />
              <Typography
                fontSize={"1.125rem"}
                lineHeight={"1.875rem"}
                fontWeight={700}
                color={"#B4B4B4"}
              >
                {insight.title}
              </Typography>
            </Box>
            <Typography
              fontSize={"0.875rem"}
              lineHeight={"1.5rem"}
              fontWeight={500}
              color={"#B4B4B4"}
            >
              {insight.description}
            </Typography>
          </Box>
        ))}
        {renderArrows()}
      </Box>
      {selfServeRefreshConfigData?.data?.refreshAllowed &&
        refreshConfigError?.error?.message !==
          "User does not have access to this resource" &&
        refreshConfigError?.error?.message !== "Not Found" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              disableElevation
              disableRipple
              sx={{
                minWidth: "12.5rem",
                borderRadius: "2.5rem",
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                padding: "0.625rem 2.25rem",
                letterSpacing: "-0.35px",
                color: isMonthlyRefreshClicked ? "#FFFFFF" : "#162936",
                background: isMonthlyRefreshClicked ? "#162936" : "#FFFFFF",
                "&:hover": {
                  backgroundColor: isMonthlyRefreshClicked
                    ? "#162936"
                    : "#FFFFFF",
                },
                ":disabled": {
                  backgroundColor: "#162936",
                },
                pointerEvents: isMonthlyRefreshClicked ? "none" : "auto",
              }}
              onClick={handleEnableMonthlyRefresh}
            >
              {selfServeRefreshConfigLoading ? (
                <CircularProgress
                  sx={{
                    width: "28px !important",
                    height: "28px !important",
                    color: theme.palette.primary.main,
                  }}
                />
              ) : isMonthlyRefreshClicked ? (
                <>
                  Done <DoneIcon />
                </>
              ) : (
                "Enable Monthly Refresh"
              )}
            </Button>
          </Box>
        )}
    </Box>
  );
};

export default FundingEligibilityInsights;
