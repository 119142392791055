import { FC } from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { CustomDropdown } from "@components";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

interface Filter {
  key: string;
  label: string;
  values: { id: string; label: string }[];
}

interface ROASProjectionsFiltersProps {
  handleFilterChange: Function;
  selections: Record<string, string>;
  filters: Filter[];
  isProjectionSimulation: boolean;
  setShowSimulateProjections?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FinancialProjectionsFilters: FC<ROASProjectionsFiltersProps> = ({
  handleFilterChange,
  selections,
  filters,
  isProjectionSimulation,
  setShowSimulateProjections
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "0px 0px 12px 12px",
        padding: "0.75rem 1rem",
        marginBottom: "1rem",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        alignItems: isMobile ? "" : "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          color: "#162936",
          fontWeight: 700,
          fontSize: "1rem",
          lineHeight: "1.375rem",
          paddingY: "0.5rem",
        }}
      >
        <TuneIcon />
        Controls
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "0.75rem",
        }}
      >
        {filters.map((filter) => (
          <CustomDropdown
            key={filter.key}
            dropdownOptions={filter.values}
            handleChange={(event) =>
              handleFilterChange(filter.key, event.target.value)
            }
            selectedOption={selections[filter.key]}
            wrapperSx={{
              width: isMobile ? "100%" : "fit-content",
            }}
          />
        ))}
        {isProjectionSimulation ?
          <Button
            sx={{
              backgroundColor: "#2F736E",
              borderRadius: "2rem",
              display: "flex",
              gap: "0.5rem",
              paddingY: "0.5rem",
              paddingX: "1.5rem",
              width: isMobile ? "100%" : "fit-content",
              "&:hover": {
                backgroundColor: "#2F736E",
              },
            }}
            onClick={() =>
              setShowSimulateProjections && setShowSimulateProjections(true)
            }
          >
            <AutoGraphIcon sx={{ color: "#F6F8F9" }} />
            <Typography
              sx={{
                textTransform: "none",
                fontFamily: "Manrope, sans-serif",
                fontWeight: 700,
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                color: "#F6F8F9"
              }}
            >
              Edit Simulations
            </Typography>
          </Button>
          : <></>}
      </Box>
    </Box>
  );
};

export default FinancialProjectionsFilters;
