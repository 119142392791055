import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DrawerComponent from "../../../../components/Drawer/Drawer";
import FundingRequestDetailsCard from "./FundingRequestDetailsCard";
import FundingRequestAdjustmentDetailsCard from "./FundingRequestAdjustmentDetailsCard";
import { useLazyGetInvestmentAttachmentsByRequestIdQuery, useLazyDownloadInvestmentAttachmentsByRequestIdQuery } from "@api/investments";

interface FundingRequestAdminSidePanelProps {
  showFundingRequestPanel: boolean;
  setShowFundingRequestPanel: React.Dispatch<React.SetStateAction<boolean>>;
  fundingRequestDetails?: any;
}

const FundingRequestAdminSidePanel: React.FC<FundingRequestAdminSidePanelProps> = ({
  showFundingRequestPanel,
  setShowFundingRequestPanel,
  fundingRequestDetails = {},
}) => {

  const [attachments, setAttachments] = useState([]);
  const [getInvestmentAttachmentsByRequestId] = useLazyGetInvestmentAttachmentsByRequestIdQuery();
  const [downloadInvestmentAttachmentsByRequestId] = useLazyDownloadInvestmentAttachmentsByRequestIdQuery();

  // Hook to get attachments
  useEffect(() => {
    const getAttachments = async () => {
      const res = await getInvestmentAttachmentsByRequestId({ requestId: fundingRequestDetails.id }).unwrap();
      if (!res?.data?.length) setAttachments([]);

      if (res?.data?.length) {
        const attachments = res.data;
        const fundingRequestAttachment = attachments.find((d: any) => d.requestType === "FUNDING_REQUEST");
        const otherAttachments = attachments.filter((d: any) => d.requestType !== "FUNDING_REQUEST");
        const orderedAttachments = fundingRequestAttachment
          ? [fundingRequestAttachment.attachmentId, ...otherAttachments.map((d: any) => d.attachmentId)]
          : otherAttachments.map((d: any) => d.attachmentId);

        setAttachments(orderedAttachments);
      }
    };

    if (fundingRequestDetails.id) {
      getAttachments();
    }
  }, [fundingRequestDetails.id]);

  const downloadAttachment = async (index: number, isAdjustment?: boolean) => {
    if (attachments?.length) {
      const downloadLink = await downloadInvestmentAttachmentsByRequestId({ requestId: fundingRequestDetails.id, attachmentId: attachments[index] }).unwrap();
      if (downloadLink?.data?.url) {
        try {
          const link = document.createElement('a');
          link.href = downloadLink.data.url;
          link.target = '_blank';
          link.download = !isAdjustment ? 'Investment Form.pdf' : 'Adjustment Request Form.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (err) {
          console.error('Download failed', err);
        }
      }
    }
  };

  return (
    <DrawerComponent
      open={showFundingRequestPanel}
      anchor="right"
      close={() => {
        setShowFundingRequestPanel(false);
      }}
      height="98vh"
      zIndex={9}
      backgroundColor="#F0F0F1"
    >
      <Box
        sx={{
          width: "600px",
          height: "100%",
          padding: "1.5rem",
          marginBottom: "5rem",
          overflowY: "auto"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.375rem",
            fontWeight: 700,
            lineHeight: "1.875rem",
            color: "#6C6C6C",
            marginBottom: "1.5rem",
          }}
        >
          Funding Request
          <Button
            sx={{ padding: 0, minWidth: "fit-content" }}
            onClick={() => {
              setShowFundingRequestPanel(false);
            }}
          >
            <CloseIcon sx={{ color: "#162C36" }} />
          </Button>
        </Box>
        <FundingRequestDetailsCard
          fundingRequestDetails={fundingRequestDetails}
          isAdjustment={Boolean(fundingRequestDetails?.fundingAdjustmentReported && fundingRequestDetails?.requestedAdjustment)}
          downloadAttachment={(index: number) => downloadAttachment(index)}
        />
        {
          (fundingRequestDetails?.fundingAdjustmentReported && fundingRequestDetails?.requestedAdjustment)
            ? <FundingRequestAdjustmentDetailsCard
              fundingRequestDetails={fundingRequestDetails}
              downloadAttachment={(index: number) => downloadAttachment(index, true)}
            />
            : <></>
        }
      </Box>
    </DrawerComponent>
  );
};

export default FundingRequestAdminSidePanel;
