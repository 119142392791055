import { useLazyFetchDashboardRequestsQuery } from "@api/selfServe";

const useDashboardRequests = () => {
  const [getDashboardRequests, { isLoading, isError }] =
    useLazyFetchDashboardRequestsQuery();

  const getDashboardRequestsData = async (search?: string, pageNumber?: number, pageSize?: number) => {
    const qps: Array<string> = [];

    if (search !== undefined && search.length !== 0) {
      qps.push(`search=${search}`);
    }

    if (pageNumber !== undefined) {
      qps.push(`pageNumber=${pageNumber}`);
    }

    if (pageSize !== undefined) {
      qps.push(`pageSize=${pageSize}`);
    }

    try {
      const response = await getDashboardRequests(qps.join('&')).unwrap();
      if (response?.data) {
        return response.data;
      }

      return null;
    } catch (err) {
      console.error(err);
    }
  };

  return { getDashboardRequestsData, isLoading, isError };
};

export default useDashboardRequests;
