import React from "react";
import { Box, CircularProgress, useTheme, Typography, Fade } from "@mui/material";

type LoaderProps = {
  customWidth?: string;
  customHeight?: string;
  message?: string;
  fullScreen?: boolean;
};

const Loader: React.FC<LoaderProps> = ({ 
  customWidth = "15%", 
  customHeight, 
  message, 
  fullScreen = false 
}) => {
  const theme = useTheme();

  return (
    <Fade in>
      <Box
        sx={{
          width: fullScreen ? "100vw" : "100%",
          height: fullScreen ? "100vh" : "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: fullScreen ? "fixed" : "relative",
          top: fullScreen ? 0 : "auto",
          left: fullScreen ? 0 : "auto",
          backgroundColor: fullScreen ? "rgba(255, 255, 255, 0.7)" : "transparent",
          zIndex: fullScreen ? 1301 : "auto",
        }}
      >
        <CircularProgress 
          sx={{ 
            width: customWidth, 
            height: customHeight || customWidth, 
            color: theme.palette.primary.main 
          }} 
        />
        {message && (
          <Typography 
            variant="body2" 
            sx={{ marginTop: 2, color: theme.palette.text.primary, opacity: 0.8 }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Fade>
  );
};

export default Loader;
