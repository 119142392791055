export interface Filter {
    key: string;
    label: string;
    values: { id: string; label: string, category: string, genre: string }[];
}

export interface Game {
    id: string,
    name: string
}

export interface Metric {
    displayName: string;
    metric: string;
    referenceValue: number;
    score: string;
    weightage: string;
}

export interface FundingEligibilityChartResponse {
    name: string;
    cumulativeMetrics: { name: string, score: number }[];
    gameId: string;
    overallScore: number;
    overallScoreType: string;
    metadata: { chartType: string, customAttributes: { [key: string]: string } };
    header: string;
    message: string;
    metrics: Metric[];
    pointedFeedback: string[];
    cta: CTAText;
}

export enum CTAText {
    SCHEDULE_A_CALL = 'SCHEDULE_A_CALL',
}