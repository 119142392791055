const ACTIVE_COLOR = "#333";

export const inputFieldStyles = {
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  letterSpacing: "-0.35px",
  fontWeight: 500,
  color: ACTIVE_COLOR
};

export const textFieldStyles = {
  ...inputFieldStyles,
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "& fieldset.Mui-disabled": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    }
  },
};

export const selectStyles = {
  ...inputFieldStyles,
  boxShadow: "none",
  "& .MuiSelect-select": {
    padding: 0,
  },
  ".MuiSvgIcon-root": {
    fill: "#2F736E !important",
  },
  paddingY: "0.375rem",
  paddingLeft: "1rem",
  paddingRight: "0.75rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "48px",
  borderRadius: "8px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#d6d6d6 !important",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#a0a0a0 !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6a6a6a !important",
    },
  },
};


export const menuItemStyles = {
  ...inputFieldStyles,
  display: "flex",
  gap: "4px",
  padding: "8px",
  color: "#162936",
  width: "100%",
};
