import { FC } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import FundingEligibilityMetricBox from "./FundingEligibilityMetricBox";
import { Metric } from "./types";

const FundingEligibilityRemarksField: FC<{
  metrics: Metric[] | undefined,
  metadata: { chartType: string, customAttributes: { [key: string]: string } } | undefined
}> = ({ metrics, metadata }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const customAttributes = metadata?.customAttributes

  const getMetricType = (metric: Metric) => {
    if (customAttributes?.[metric.metric])
      return customAttributes?.[metric.metric]
    else return 'percentage'
  }

  return (
    <>
      <Box
        sx={{
          display: isMobile ? "flex" : "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? '0.75rem' : '1.125rem'
        }}
      >
        {metrics?.map((metric: Metric, index: number) => (
          <FundingEligibilityMetricBox
            key={index}
            title={metric?.displayName}
            value={metric?.referenceValue}
            status={metric?.score}
            weightage={metric?.weightage}
            scoreType={getMetricType(metric)}
          />
        ))}
      </Box>
    </>
  );
};

export default FundingEligibilityRemarksField;