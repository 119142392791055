import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  IconButton,
  Stack,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { GenericTable, Menu, Search } from "../../../components";
import useUserList from "../../../hooks/api/useUserList";
import { RootState } from "../../../stores/store";
import { NAV_ROUTES } from "../../../constants/navRoutes";
import { setToastMessage } from "../../../stores/App/slice";
import useRetryCreateUser from "../../../hooks/api/useRetryCreateUser";
import { titles } from "@utils/translations/en";
import { TOAST_TYPES, USER_STATUS } from "@constants/constants";
import {
  Column,
  Row,
  RowAction,
} from "../../../components/GenericTable/GenericTable";
import { formatDate } from "@utils/common";
import { PaginationConfig } from "@utils/types/PaginationConfig";
import Pagination from "../../Dashboard/components/Pagination";

type User = {
  id: string;
  email: string;
  role: string;
  joiningDate: string;
  status: string;
};

export interface IUserList {
  userList: {
    id: string;
    username: string;
    email: string;
    role: string;
    status: string;
    joiningDate: string;
    companyId: string;
  }[];
}

const UserList: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state: RootState) => state?.UserData);

  const [userList, setUserList] = useState<IUserList>({ userList: [] });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationConfig>({
    pageNumber: 1,
    pageSize: 10,
    total: 0,
  });
  const [sort, setSort] = useState<string>("email");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");

  const { reCreateUser } = useRetryCreateUser();
  const { getUserList, isLoading } = useUserList();

  // Hook to redirect user if not admin
  useEffect(() => {
    if (userDetails?.user?.id && userDetails?.user?.role !== "ADMIN") {
      navigate(NAV_ROUTES.DASHBOARD);
    }
  }, [userDetails]);

  useEffect(() => {
    fetchUserList({});
  }, []);

  const fetchUserList = async (props: {
    pageNumber?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
    sortOrder?: "ASC" | "DESC";
  }) => {
    const defaultProps = {
      pageNumber: 1,
      pageSize: 10,
      sort: sort,
      sortOrder: sortOrder,
      search: search,
    };
    const {
      pageNumber,
      pageSize,
      sort: sortKey,
      sortOrder: sortOrderKey,
      search: searchString,
    } = {
      ...defaultProps,
      ...pagination,
      ...props,
    };
    const res = await getUserList({
      pageNumber: pageNumber - 1,
      pageSize,
      search: searchString,
      sort: sortKey,
      sortOrder: sortOrderKey as "ASC" | "DESC",
    });
    if (!res) {
      setUserList({ userList: [] });
      setPagination({
        ...pagination,
        pageNumber,
        pageSize,
        total: 0,
      });
      return;
    }

    const { totalElements: total, items: users } = res;

    setUserList({
      userList: users.map((item: any) => ({
        id: item.id,
        username: item.name,
        email: item.email,
        role: item.role,
        status: item.status,
        joiningDate: item.joiningDate,
        companyId: item.companyId,
      })),
    });

    setPagination({
      ...pagination,
      pageNumber,
      pageSize,
      total,
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedUserId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = async (type: string, id: string) => {
    if (type === "View User") {
      navigate(`${NAV_ROUTES.USER_MANAGEMENT}/user-details/${id}`);
    } else if (type === "Retry Creation") {
      const res = await reCreateUser({ id });
      dispatch(
        setToastMessage({
          type: TOAST_TYPES.INFO,
          message: `${res?.message}`,
        })
      );
      navigate(NAV_ROUTES.USER_MANAGEMENT);
    } else if (type === "Re-Invite User") {
      // No action required
    } else {
      return null;
    }
  };

  const handleRowClick = (id: string) => {
    for (const user of userList.userList) {
      if (user.id === id && user.status === USER_STATUS.PENDING) {
        // If user status is
        dispatch(
          setToastMessage({
            type: TOAST_TYPES.ERROR,
            message: `Could not create ${user.email}, please retry.`,
          })
        );

        return;
      }
    }
    navigate(`/user-management/user-details/${id}`);
  };

  const handleSort = (sort: string, order: "ASC" | "DESC") => {
    fetchUserList({ sort, sortOrder: order });

    setSort(sort);
    setSortOrder(order);
  };

  const getMenuActions = (selectedId: string) => {
    const isPartialUser =
      userList.userList.find((u: any) => u.id === selectedId)?.status ===
      "PENDING";
    if (isPartialUser) {
      return ["View User", "Retry Creation"];
    } else {
      return ["View User"];
    }
  };

  const columns: Column<any>[] = [
    { key: "email", label: "Email" },
    { key: "role", label: "Role" },
    { key: "joiningDate", label: "Joining Date" },
    { key: "status", label: "Status" },
  ].map((obj) => ({
    ...obj,
    render: (value: User[keyof User], row: User) => {
      if (obj.key === "joiningDate") {
        return (
          <Typography
            fontSize={"0.875rem"}
            fontWeight={550}
            lineHeight={1.5}
            sx={{
              textAlign: "center",
            }}
          >
            {formatDate(value || "")}
          </Typography>
        );
      }

      if (obj.key === "email") {
        const LOGO_SIZE = "2rem";

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.75rem",
            }}
          >
            <Box
              sx={{
                minHeight: LOGO_SIZE,
                minWidth: LOGO_SIZE,
                maxHeight: LOGO_SIZE,
                maxWidth: LOGO_SIZE,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#9ad6d1",
              }}
            >
              <Typography>{value.at(0)?.toUpperCase()}</Typography>
            </Box>

            <Typography
              fontSize={"0.875rem"}
              fontWeight={550}
              lineHeight={1.5}
              sx={{
                textAlign: "left",
              }}
            >
              {value}
            </Typography>
          </Box>
        );
      }

      if (obj.key === "status") {
        return (
          <Typography
            fontSize={"0.875rem"}
            fontWeight={550}
            lineHeight={1.5}
            sx={{
              textAlign: "center",
            }}
          >
            {USER_STATUS[value as keyof typeof USER_STATUS]}
          </Typography>
        );
      }

      if (obj.key === "role") {
        return (
          <Typography
            fontSize={"0.875rem"}
            fontWeight={550}
            lineHeight={1.5}
            sx={{
              textAlign: "center",
            }}
          >
            {value.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}
          </Typography>
        );
      }

      return (
        <Typography
          fontSize={"0.875rem"}
          fontWeight={550}
          lineHeight={1.5}
          sx={{
            textAlign: "center",
          }}
        >
          {value}
        </Typography>
      );
    },
    renderHeader: (column: Column<User>) => {
      return (
        <Stack direction={"row"} alignContent={"center"} gap={"0.5rem"}>
          <Typography
            fontSize={"0.75rem"}
            fontWeight={700}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {column.label}
          </Typography>
          <Stack>
            <ExpandLessIcon
              onClick={() => handleSort(column.key, "ASC")}
              sx={{
                height: "0.875rem",
                color:
                  sort === column.key && sortOrder === "ASC"
                    ? "#fff"
                    : "rgb(136 165 163)",
                ":hover": {
                  cursor: "pointer",
                },
              }}
            />
            <ExpandMoreIcon
              onClick={() => handleSort(column.key, "DESC")}
              sx={{
                height: "0.875rem",
                color:
                  sort === column.key && sortOrder === "DESC"
                    ? "#fff"
                    : "rgb(136 165 163)",
                ":hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Stack>
        </Stack>
      );
    },
  }));

  const actions: RowAction[] = [
    {
      action: (id: string, e: any) => handleMenuOpen(e, id),
      component: (
        <IconButton disableFocusRipple disableRipple disableTouchRipple>
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box
      id="user_list_container"
      sx={{
        height: "100%",
        width: "100%",
        p: 0,
        m: 0,
      }}
    >
      <Box
        id="header_wrapper"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: isMobile ? 0 : "0.5rem",
          mb: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            fontSize: "2rem",
            lineHeight: 1.5,
            fontFamliy: "Manrope, sans-serif",
          }}
        >
          {titles.USERS}
        </Typography>

        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          startIcon={
            <AddIcon
              htmlColor={theme.palette.common.white}
              sx={{
                height: "1rem",
                width: "1rem",
                cursor: "pointer",
                backgroundColor: "transparent",
              }}
            />
          }
          variant="contained"
          onClick={() => navigate("/user-management/user-invite")}
          sx={{
            borderRadius: "100px",
            fontWeight: "700",
            textTransform: "none",
            backgroundColor: theme.palette.primary.main,
            fontFamily: "Manrope, sans-serif",
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
            padding: "10px 24px",
            color: theme.palette.common.white,
            fontSize: "0.875rem",
          }}
        >
          Invite User
        </Button>
      </Box>

      {selectedUserId && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onMenuItemClick={handleClickMenuItem}
          actions={getMenuActions(selectedUserId)}
          value={selectedUserId}
        />
      )}

      <Box
        sx={{
          marginTop: "1.125rem",
          backgroundColor: "white",
          borderRadius: "0.75rem",
          padding: "0.75rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            padding: "0.5rem 0",
          }}
        >
          <Box width={isMobile ? "100%" : "40%"}>
            <Search
              search={search}
              setSearch={setSearch}
              placeholder="Search by Email / Role"
              handleSearch={(search: string) =>
                fetchUserList({ search, pageNumber: 1 })
              }
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {pagination.total > 0 && (
              <>
                <IconButton
                  sx={{
                    p: 0,
                    mr: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    ":hover": {
                      background: "none",
                    },
                  }}
                  onClick={() => fetchUserList({})}
                >
                  <RefreshIcon
                    sx={{
                      mt: isMobile ? "1rem" : 0,
                    }}
                  />
                </IconButton>

                <Pagination
                  page={pagination.pageNumber}
                  size={pagination.pageSize}
                  total={pagination.total}
                  handleChange={(pageNumber) => fetchUserList({ pageNumber })}
                  fileName={""}
                  chartId={""}
                  submoduleId={""}
                  filterSelection={{}}
                />
              </>
            )}
          </Box>
        </Box>
        <GenericTable
          data={
            userList?.userList.map((user) => ({
              ...user,
              id: user.id,
            })) as Row<User>[]
          }
          columns={columns}
          meta={{
            totalCount: pagination.total,
            currentPage: pagination.pageNumber,
            pageSize: pagination.pageSize,
          }}
          actions={actions}
          onViewRow={(row: Row<User>) => handleRowClick(row.id)}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default UserList;
