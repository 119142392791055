import React, { FC, useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import bgImage from "../../../../assets/images/header-bg.png";

const ImageCarousel: FC<{ images: string[] }> = ({ images }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [activeStep, setActiveStep] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        setActiveStep((prev) => (prev < images.length - 1 ? prev + 1 : 0));
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isHovered, images.length]);

  const handleStepChange = (index: number) => {
    setActiveStep(index);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    const endX = e.changedTouches[0].clientX;
    const diff = startX - endX;

    if (diff > 50 && activeStep < images.length - 1) {
      setActiveStep((prev) => prev + 1);
    } else if (diff < -50 && activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleGesture = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.deltaX < -50 && activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    } else if (e.deltaX > 50 && activeStep < images.length - 1) {
      setActiveStep((prev) => prev + 1);
    }
  };

  return (
    <Box
      sx={{ overflow: "hidden", position: "relative" }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onWheel={handleGesture}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          display: "flex",
          transition: "transform 0.5s ease-in-out",
          transform: isMobile
            ? `translateX(-${activeStep * 95}%)`
            : `translateX(-${activeStep * 65}%)`,
          gap: "1.5rem",
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              width: isMobile ? "90%" : "70%",
              maxHeight: "48rem",
              flexShrink: 0,
              border: "0.5rem solid",
              borderImageSource: `url(${bgImage})`,
              borderImageSlice: 30,
              borderRadius: "1rem",
              backgroundImage: "linear-gradient(#0a1111, #264e4c)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#f5f5f5",
                borderRadius: "0.25rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={image}
                alt={`Slide ${index + 1}`}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: -4,
          position: "absolute",
          width: "100%",
        }}
      >
        {images.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: 12,
              height: 12,
              borderRadius: "50%",
              backgroundColor: activeStep === index ? "#000" : "#ccc",
              margin: "0 5px",
              cursor: "pointer",
            }}
            onClick={() => handleStepChange(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ImageCarousel;
