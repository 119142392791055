import { FC, Fragment, useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LOCATION from "./location";

type BreadCrumbType = { label: string; type?: "initial"; location: string };

const UserLayout: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const [breadcrumbs, setBreadCrumbs] = useState<Array<BreadCrumbType>>([]);

  useEffect(() => {
    if (location?.pathname) {
      const eachPath = location.pathname.split("/")?.filter((e) => e) || [];

      if (eachPath.length <= 1) {
        setBreadCrumbs([]);
        return;
      }

      const nextBreadcrumbs = eachPath
        ?.map((e) => {
          if (Object.keys(LOCATION).includes(e)) {
            return LOCATION[e];
          }
          return null;
        })
        .filter((e) => e);
      setBreadCrumbs(nextBreadcrumbs);
    }
  }, [location]);

  return (
    <Box
      id='user_layout_container'
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        background: theme.palette.custom_background,
        padding: "1rem 2rem",
        ...(isMobile && { pb: "3rem" })
      }}
    >
      <Box pb={"1rem"}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            px: isMobile ? 1 : 0,
          }}
        >
          {breadcrumbs?.map(({ label, type, location: locationVal }, index) => (
            <Fragment key={label}>
              <Box
                sx={{
                  fontFamily: 'Manrope, sans-serif',
                  fontSize: '20px',
                  '&:hover': {
                    color: `${locationVal ? "#17191C90" : theme.palette.common.black}`,
                  },
                  color: (type === "initial" && "#17191C59") || "#1B1C17",
                  cursor: `${locationVal ? "pointer" : "cursor"}`,
                  fontWeight: (type === "initial" && "500") || "700"
                }}
                onClick={() => {
                  if (locationVal && location?.pathname !== locationVal) navigate(locationVal);
                }}
              >
                {label}
              </Box>
              {index !== breadcrumbs?.length - 1 && <div style={{fontSize: '20px', color: '#5A5D72'}}>{">"}</div>}
            </Fragment>
          ))}
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default UserLayout;
