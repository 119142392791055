import React, { useEffect } from "react";
import { Box, Button, List, ListItem, ListItemIcon, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { GenericTable } from "@components";
import DrawerComponent from "../../../../components/Drawer/Drawer";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import { Column, Row } from "../../../../components/GenericTable/GenericTable";

const financialData: Row<{ month: string; netRevenue: string; cumulativeRevenue: string; marketingSpend: string; netROAS: string, meta: string }>[] = [
  { id: "1", month: "Month 1 (Jan 2024)", netRevenue: "$40,000", cumulativeRevenue: "$40,000", marketingSpend: "$100,000", netROAS: "0.40", meta: "" },
  { id: "2", month: "Month 2 (Feb 2024)", netRevenue: "$30,000", cumulativeRevenue: "$70,000", marketingSpend: "$100,000", netROAS: "0.70", meta: "" },
  { id: "3", month: "Month 3 (Mar 2024)", netRevenue: "$25,000", cumulativeRevenue: "$95,000", marketingSpend: "$100,000", netROAS: "0.95", meta: "" },
  { id: "4", month: "Month 4 (Apr 2024)", netRevenue: "$20,000", cumulativeRevenue: "$115,000", marketingSpend: "$100,000", netROAS: "1.15", meta: "" },
];

const columns: Column<{ month: string; netRevenue: string; cumulativeRevenue: string; marketingSpend: string; netROAS: string, meta: string }>[] = [
  { key: "month", label: "Elapsed Month" },
  { key: "netRevenue", label: "Net Revenue Generated" },
  { key: "cumulativeRevenue", label: "Cumulative Net Revenue" },
  { key: "marketingSpend", label: "Marketing Spend" },
  { key: "netROAS", label: "Cumulative Net ROAS" },
];

const incrementalNetROAS: Row<{ incrementalNetRoas: string; m1: string; m2: string; m3: string; m4: string; m5: string; m6: string, meta: string }>[] = [
  { id: "1", incrementalNetRoas: "Cohort 1", m1: "42%", m2: "20%", m3: "15%", m4: "12%", m5: "9%", m6: "8%", meta: "" },
  { id: "2", incrementalNetRoas: "Cohort 2", m1: "41%", m2: "22%", m3: "13%", m4: "11%", m5: "8%", m6: "8%", meta: "" },
  { id: "3", incrementalNetRoas: "Cohort 3", m1: "39%", m2: "21%", m3: "11%", m4: "10%", m5: "8%", m6: "7%", meta: "" },
  { id: "4", incrementalNetRoas: "Cohort 4", m1: "37%", m2: "22%", m3: "13%", m4: "10%", m5: "7%", m6: "6%", meta: "" },
  { id: "5", incrementalNetRoas: "Cohort 5", m1: "43%", m2: "23%", m3: "14%", m4: "12%", m5: "8%", m6: "", meta: "" },
  { id: "6", incrementalNetRoas: "Cohort 6", m1: "42%", m2: "27%", m3: "16%", m4: "11%", m5: "", m6: "", meta: "" },
  { id: "7", incrementalNetRoas: "Cohort 7", m1: "40%", m2: "26%", m3: "18%", m4: "", m5: "", m6: "", meta: "" },
  { id: "8", incrementalNetRoas: "Cohort 8", m1: "42%", m2: "28%", m3: "", m4: "", m5: "", m6: "", meta: "" },
  { id: "9", incrementalNetRoas: "Cohort 9", m1: "44%", m2: "", m3: "", m4: "", m5: "", m6: "", meta: "" },
  { id: "10", incrementalNetRoas: "Ref. Value (Incremental Net ROAS)", m1: "42%", m2: "27%", m3: "16%", m4: "11%", m5: "8%", m6: "7%", meta: "" },
  { id: "11", incrementalNetRoas: "Ref. Value (Cumulative Net ROAS)", m1: "42%", m2: "69%", m3: "85%", m4: "96%", m5: "104%", m6: "111%", meta: "" },
];

const referenceValues: Column<{ incrementalNetRoas: string; m1: string; m2: string; m3: string; m4: string; m5: string, m6: string, meta: string }>[] = [
  { key: "incrementalNetRoas", label: "Incremental Net ROAS" },
  { key: "m1", label: "M1" },
  { key: "m2", label: "M2" },
  { key: "m3", label: "M3" },
  { key: "m4", label: "M4" },
  { key: "m5", label: "M5" },
  { key: "m6", label: "M6" },
];

const platforms = [
  "Facebook",
  "Google",
  "Applovin",
  "IronSource",
  "Moloco",
  "Unity",
  "Tiktok"
];

const spendLevels = [
  "<$100K/month",
  "$100K-$250K/month",
  "$250K-$500K/month",
  "$500K-$1M/month",
  ">$1M/month"
];

interface DefinitionsSidePanelProps {
  scrollToDefinitionSidePanel: number;
  hideDefinitionSidePanel: () => void;
}

const DefinitionsSidePanel: React.FC<DefinitionsSidePanelProps> = ({
  scrollToDefinitionSidePanel,
  hideDefinitionSidePanel,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  //Hook to trigger handle scroll
  useEffect(() => {
    if (scrollToDefinitionSidePanel >= 0) {
      setTimeout(() => {
        const element: any = document.getElementById(`scrollId${scrollToDefinitionSidePanel}`);
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 1);
    }
  }, [scrollToDefinitionSidePanel]);

  return (
    <DrawerComponent
      open={Boolean(scrollToDefinitionSidePanel >= 0)}
      anchor="right"
      close={hideDefinitionSidePanel}
      height="98vh"
      zIndex={9999}
      backgroundColor="#F0F0F1"
    >
      <Box
        sx={{
          padding: "1.5rem",
          width: isMobile ? "auto" : "600px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.375rem",
            fontWeight: 700,
            lineHeight: "1.875rem",
            color: "#6C6C6C",
          }}
        >
          Definitions
          <Button
            sx={{ padding: 0, minWidth: "fit-content" }}
            onClick={hideDefinitionSidePanel}
          >
            <CloseIcon sx={{ color: "#162C36" }} />
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "1rem 1.5rem",
          width: isMobile ? "85vw" : "552px",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
          overflowY: "auto"
        }}
      >
        <Box id={"scrollId0"} />
        <Box id={"scrollId1"} />
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <b><i>Net Revenue</i></b> is calculated after deducting platform fees and other direct sales-related costs.
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <b><i>Incremental Net ROAS</i></b> (Return on Ad Spend) measures the net revenue generated by a cohort in a given elapsed month, divided by the marketing spend of that cohort
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <b><i>Cumulative Net ROAS</i></b> (Return on Ad Spend) measures the net revenue generated by a cohort up to a given elapsed month, divided by the marketing spend for that cohort.
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          Note: M1 refers to the month of acquisition
        </Typography>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 700,
            fontSize: "1rem",
          }}
        >
          Example:
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          Let's say, a mobile game studio spends $100,000 on marketing in January 2024, acquiring a cohort of users. The studio tracks the net revenue (after platform fees and other direct costs) generated by this cohort over time.
        </Typography>
        <Box>
          <GenericTable data={financialData} columns={columns} meta={"PIVOT_TABLE"} />
        </Box>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          In this example:
        </Typography>
        <List>
          <ListItem sx={{ display: "flex", alignItems: "flex-start", color: "#6C6C6C", gap: "0.5rem" }}>
            <ListItemIcon sx={{ minWidth: "0.875rem", color: "#555555", paddingTop: "0.25rem" }}>
              <FiberManualRecordIcon sx={{ fontSize: "0.75rem" }} />
            </ListItemIcon>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
              }}
            >
              The game has recovered 25% of its marketing spend through net revenue in month 3. Therefore, M3 Incremental Net ROAS = 25%.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "flex", alignItems: "flex-start", color: "#6C6C6C", gap: "0.5rem" }}>
            <ListItemIcon sx={{ minWidth: "0.875rem", color: "#555555", paddingTop: "0.25rem" }}>
              <FiberManualRecordIcon sx={{ fontSize: "0.75rem" }} />
            </ListItemIcon>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
              }}
            >
              The game has recovered 95% of its marketing spend through net revenue by the end of month 3. Therefore, M3 Cumulative Net ROAS = 95%.
            </Typography>
          </ListItem>
        </List>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 700,
            fontSize: "1rem",
          }}
        >
          App’s Reference Value Calculation:
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <i>Reference value for Cumulative Net ROAS</i> for a given elapsed month is calculated by summing up the last 3 month average Incremental Net ROAS at each elapsed month preceding the given elapsed month
        </Typography>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 700,
            fontSize: "1rem",
          }}
        >
          Example:
        </Typography>
        <Box>
          <GenericTable data={incrementalNetROAS} columns={referenceValues} meta={"PIVOT_TABLE"} />
        </Box>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          In this example:
        </Typography>
        <List>
          <ListItem sx={{ display: "flex", alignItems: "flex-start", color: "#6C6C6C", gap: "0.5rem" }}>
            <ListItemIcon sx={{ minWidth: "0.875rem", color: "#555555", paddingTop: "0.25rem" }}>
              <FiberManualRecordIcon sx={{ fontSize: "0.75rem" }} />
            </ListItemIcon>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
              }}
            >
              Reference value of M3 Cumulative Net ROAS for the game is calculated as sum of reference values of incremental ROAS for each of the elapsed months for M1, M2 and M3, i.e 42% + 27% + 16% = 85%
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "flex", alignItems: "flex-start", color: "#6C6C6C", gap: "0.5rem" }}>
            <ListItemIcon sx={{ minWidth: "0.875rem", color: "#555555", paddingTop: "0.25rem" }}>
              <FiberManualRecordIcon sx={{ fontSize: "0.75rem" }} />
            </ListItemIcon>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
              }}
            >
              Similarly, M6 Cumulative Net ROAS = 42% + 27% + 16% + 11% + 8% + 7% = 111%
            </Typography>
          </ListItem>
        </List>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            paddingBottom: "1rem",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          Note: The reference value can fall outside the minimum and maximum of the data set due to the calculation approach as it takes into account recent improvements and degradation in the incremental ROAS values
        </Typography>
        <Box id={"scrollId2"} />
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 700,
            fontSize: "1rem",
          }}
        >
          Percentile Calculation
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          Percentile Rank is a statistical measure that indicates the relative standing of a value within a dataset. It represents the percentage of values in the dataset that are <b>less than or equal to</b> the given value.
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            paddingBottom: "1rem",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          In this dashboard, for each metric, we calculate the percentile rank by comparing the App’s reference value to the value of that metric that has been calculated for each of the cohorts across each of the apps in our data set for that category or genre.
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <b><i>Cumulative Net ROAS by Marketing Channel</i></b> is defined as the Cumulative Net ROAS (as defined above) attributable to users acquired via that Marketing Channel.
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          The following channels are covered in our analysis based on this mapping. All the others are categorised as “Others”.
        </Typography>
        <List>
          {platforms.map((platform, index) => (
            <ListItem key={index} sx={{ display: "flex", alignItems: "center", color: "#6C6C6C", gap: "0.5rem" }}>
              <ListItemIcon sx={{ minWidth: "0.875rem", color: "#555555" }}>
                <img
                  src={require(`../../../../assets/icons/${platform.toLowerCase()}_logo.svg`)}
                  alt={platform}
                  style={{ width: "1rem", borderRadius: "2px" }}
                />
              </ListItemIcon>
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  lineHeight: "1.25rem",
                }}
              >
                {platform}
              </Typography>
            </ListItem>
          ))}
        </List>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            paddingBottom: "1rem",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          Note: The overall ROAS of you app is not comparable to ROAS by each Marketing Channel since ROAS from Organics cannot be calculated and hence been omitted.
        </Typography>
        <Box id={"scrollId3"} />
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <b><i>Cumulative Net ROAS By UA Spend Level</i></b> is defined as the Cumulative Net ROAS (as defined above) attributable to the cohorts of your app where the UA spend level has been in the given range.
        </Typography>
        <List sx={{ paddingBottom: "1rem", borderBottom: "1px solid #E0E0E0" }}>
          {spendLevels.map((range, index) => (
            <ListItem key={index} sx={{ display: "flex", alignItems: "flex-start", color: "#6C6C6C", gap: "0.5rem" }}>
              <ListItemIcon sx={{ minWidth: "0.875rem", color: "#555555", paddingTop: "0.25rem" }}>
                <FiberManualRecordIcon sx={{ fontSize: "0.75rem" }} />
              </ListItemIcon>
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  lineHeight: "1.25rem",
                }}
              >
                {range}
              </Typography>
            </ListItem>
          ))}
        </List>
        <Box id={"scrollId4"} />
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: 700,
            fontSize: "1rem",
          }}
        >
          Other Metrics
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          Note: M1 refers to the month of acquisition
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <i>M6 Incremental ROAS</i> is defined as the net revenue generated by a cohort in the elapsed Month 6 divided by the marketing spend of that cohort.
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <i>M6 Payer Retention</i> is defined as the number of payers who have generated net revenue (including IAA & IAP) in month 6 divided by the total number of payers who have generated net revenue (including IAA & IAP) from that cohort.
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <i>M6 Revenue Retention</i> is defined as the net revenue generated by the cohorts in the elapsed month 6 divided by the net revenue generated by the cohort in month 1.
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          <i>Payback Period</i> is defined as the number of months of net revenue generation it takes for a cohort to recover the marketing spend of that cohort.
        </Typography>
        <Typography
          sx={{
            color: "#6C6C6C",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }}
        >
          In the above example, the cumulative net ROAS crossed 100% in month 4. So the payback period for this cohort is 4.
        </Typography>
      </Box>
      <Box
        sx={{
          width: isMobile ? "auto" : "600px",
          padding: "1rem 1.5rem",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          position: "sticky",
          bottom: "0",
          borderTop: "1px solid #E8EAEB",
          backgroundColor: "#f0f0f1"
        }}
      >
        <CustomButton
          color="#F6F8F9"
          backgroundColor="#2F736E"
          name="Okay"
          onClick={hideDefinitionSidePanel}
        />
      </Box>
    </DrawerComponent>
  );
};

export default DefinitionsSidePanel;
