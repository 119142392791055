import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Chart, ArcElement } from "chart.js";
import { Box } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { FullPageLoader, Loader } from "@components";
import useCharts from "@hooks/api/useCharts";
import useChartById from "@hooks/api/useChartById";
import { setDashboardFilter } from "@stores/DashboardFilters/slice";
import FundingEligibilityRemarksField from "../components/FundingEligibility/FundingEligibilityRemarksField";
import FundingEligibilityHeader from "../components/FundingEligibility/FundingEligibilityHeader";
import FundingEligibilityInfo from "../components/FundingEligibility/FundingEligibilityInfo";
import {
  FundingEligibilityChartResponse,
  Game,
} from "../components/FundingEligibility/types";
import FundingEligibilityInsights from "../components/FundingEligibility/FundingEligibilityInsights";
import DownloadPDFModal from "../components/FundingEligibility/DownloadPDFModal";

Chart.register(ArcElement);

const FundingEligibility: FC<{ id: string }> = ({ id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));

  const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);
  const [dashboardData, setDashboardData] = useState<
    { id: string; name: string; chartType: string; metadata: string }[]
  >([]);
  const [chartData, setChartData] = useState<FundingEligibilityChartResponse>();
  const [selectedGame, setSelectedGame] = useState<Game | undefined>(undefined);
  const [componentLoader, setComponentLoader] = useState<boolean>(true);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);

  const { getChartData } = useCharts();
  const { getChartDataById } = useChartById();

  const fetchDashboardData = async (filters?: Record<string, string>) => {
    if (id && dashboardData?.length) {
      try {
        await Promise.all(
          dashboardData.map(
            async (chart: {
              id: string;
              name: string;
              chartType: string;
              metadata: string;
            }) => {
              const parsedMetadata = JSON.parse(chart.metadata);
              const response = await getChartDataById(
                id,
                chart.id,
                filters,
                parsedMetadata?.sort?.fields,
                parsedMetadata?.sort?.order
              );

              if (response) {
                setChartData({
                  name: response.name,
                  header: response?.result?.data?.header,
                  message: response?.result?.data?.message,
                  cta: response?.result?.data?.cta,
                  metrics: response?.result?.data?.metrics,
                  pointedFeedback: response?.result?.data?.pointedFeedback,
                  cumulativeMetrics: response?.result?.data?.cumulativeMetrics || [],
                  gameId: response?.result?.data?.gameId || "",
                  overallScore: response?.result?.data?.overallScore || "",
                  overallScoreType: response?.result?.data?.overallScoreType || "",
                  metadata: parsedMetadata
                })
              }
            }
          )
        );
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        setComponentLoader(false);
      }
    }
  };

  useEffect(() => {
    setDashboardLoader(true);
    if (id) {
      getChartData(id).then((response) => {
        if (response) {
          setDashboardData(response);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (selectedGame?.id) {
      setDashboardLoader(false);
      dispatch(setDashboardFilter({ FundingEligibility: selectedGame }));
      fetchDashboardData({ gameId: selectedGame?.id });
    }
  }, [dashboardData, id, selectedGame]);

  return (
    <>
      {dashboardLoader && <FullPageLoader hasSideNav={true} />}
      <div
        id="pvx-funding-eligibility-container"
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "auto",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingTop: "1rem",
          paddingBottom: isSmallDevice ? "5rem" : "2rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.125rem",
        }}
      >
        <FundingEligibilityHeader
          setSelectedGame={setSelectedGame}
          selectedGame={selectedGame}
          submoduleId={id}
          setComponentLoader={setComponentLoader}
          downloadPDF={() => setIsDownloadModalOpen(true)}
          isDownloading={isDownloadModalOpen}
        />
        {componentLoader ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <>
            <FundingEligibilityInfo chartData={chartData} />
            <FundingEligibilityInsights gameId={selectedGame?.id || ""}/>
            <FundingEligibilityRemarksField
              metrics={chartData?.metrics?.filter((metric) => metric !== null)}
              metadata={chartData?.metadata}
            />
          </>
        )}
        {isDownloadModalOpen ?
          <DownloadPDFModal
            isOpen={isDownloadModalOpen}
            handleClose={() => setIsDownloadModalOpen(false)}
            chartData={chartData}
            selectedGame={selectedGame}
          />
          : <></>
        }
      </div>
    </>
  );
};

export default FundingEligibility;
