import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { processRatingString } from "@utils/common";
import {
  APP_STORE_LINK_REGEX,
  PLAY_STORE_LINK_REGEX,
  APPLICATION_STORES,
  EMAIL_REGEX,
  MONTHLY_MARKETTING_SPENDS_OPTIONS,
  ATTRIBUTED_CHANNEL_OPTIONS,
} from "@constants/constants";
import { SignupLayout } from "../../layouts";
import CustomInputField from "../FundingCalculator/components/CustomInputField";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  FetchApplicationDetailsApiMap,
  RegisterUserApiMap,
  RegisterUserPayload,
  useLazyFetchApplicationDetailsQuery,
  useRegisterUserMutation,
} from "@api/register";
import { setToastMessage } from "@stores/App/slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../FundingCalculator/components/CustomSelectField";
import GameDetails from './components/GameDetails';
import useDebounce from "@hooks/useDebounce";
import { TOAST_TYPES } from "@constants/constants";

const SignupPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [fetchApplicationDetailsApi] = useLazyFetchApplicationDetailsQuery();
  const [
    registerUserApi,
    { isLoading: isCreatingUser },
  ] = useRegisterUserMutation();

  // Form State
  const [name, setName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [playstoreLink, setPlaystoreLink] = useState<string>("");
  const [appstoreLink, setAppstoreLink] = useState<string>("");
  const [avgMonthlyMarketingSpend, setAvgMonthlyMarketingSpend] = useState<string>("");
  const [acceptDataAccessTerms, setAcceptDataAccessTerms] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const [attributedChannel, setAttributedChannel] = useState<string>("");
  const [userDomainAlreadyExists, setUserDomainAlreadyExists] = useState(false);

  const [playStoreApp, setPlayStoreApp] = useState<{
    marketPlace: (typeof APPLICATION_STORES)[keyof typeof APPLICATION_STORES];
    appUrl: string;
    name: string;
    icon: string;
    developerName: string;
    rating: number;
    reviews: string;
    downloads: string;
  } | null>(null);

  const [appStoreApp, setAppStoreApp] = useState<{
    marketPlace: (typeof APPLICATION_STORES)[keyof typeof APPLICATION_STORES];
    appUrl: string;
    name: string;
    icon: string;
    developerName: string;
    rating: number;
    reviews: string;
  } | null>(null);

  const [isUserCreated, setIsUserCreated] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Error State
  const [errorState, setErrorState] = useState({
    name: false,
    companyName: false,
    email: false,
    playstoreLink: false,
    appstoreLink: false,
    avgMonthlyMarketingSpend: false,
  });

  const [touched, setTouched] = useState({
    name: false,
    companyName: false,
    email: false,
    playstoreLink: false,
    appstoreLink: false,
    avgMonthlyMarketingSpend: false,
  });

  useEffect(() => {
    validateForm();
  }, [email, playstoreLink, appstoreLink, companyName, name, avgMonthlyMarketingSpend]);

  useEffect(() => {
    if (playStoreApp === null && appStoreApp === null) {
      setCompanyName("");
    } else if (!!appStoreApp) {
      setCompanyName(appStoreApp.developerName);
    } else if (!!playStoreApp) {
      setCompanyName(playStoreApp.developerName);
    }
  }, [playStoreApp, appStoreApp]);


  const getPlaystoreLinkLabel = () =>
    errorState.playstoreLink && playstoreLink
      ? "INVALID PLAY STORE LINK"
      : "PLAY STORE LINK";

  const getAppstoreLinkLabel = () =>
    errorState.appstoreLink && appstoreLink
      ? "INVALID APP STORE LINK"
      : "APP STORE LINK";

  const fetchApplicationDetails = async (
    appUrl: string,
    store: (typeof APPLICATION_STORES)[keyof typeof APPLICATION_STORES]
  ) => {
    if (!appUrl) return;

    try {
      const response: FetchApplicationDetailsApiMap = await fetchApplicationDetailsApi({ store, appUrl }).unwrap();
      const { data } = response;

      if (!data) return;

      if (data) {
        const appDetails = {
          marketPlace: data.marketPlace,
          appUrl: data.appUrl,
          name: data.name,
          icon: data.icon,
          developerName: data.developerName,
          rating: processRatingString(data.rating),
          reviews: data.reviews,
          downloads: data.downloads,
        };

        store === APPLICATION_STORES.PLAY_STORE ? setPlayStoreApp(appDetails) : setAppStoreApp(appDetails);
      }
    } catch (error: any) {
      if (store === APPLICATION_STORES.PLAY_STORE) {
        setPlayStoreApp(null);
      } else if (store === APPLICATION_STORES.APP_STORE) {
        setAppStoreApp(null);
      }

      console.error(error);
      dispatch(setToastMessage({
        type: TOAST_TYPES.ERROR,
        message: error?.error?.message || "Unable to fetch application details."
      }));
    }
  };

  const handleAppStoreLinkChange = (value: string) => {
    setAppstoreLink(value);

    if (value.length === 0) {
      setAppStoreApp(null);
      return;
    }

    const isValid = APP_STORE_LINK_REGEX.test(value);

    if (isValid) {
      handleFetchApplicationDetails(value, APPLICATION_STORES.APP_STORE);
    }
  };

  const handlePlayStoreLinkChange = (value: string) => {
    setPlaystoreLink(value);

    if (value.length === 0) {
      setPlayStoreApp(null);
      return;
    }

    const isValid = PLAY_STORE_LINK_REGEX.test(value);

    if (isValid) {
      handleFetchApplicationDetails(value, APPLICATION_STORES.PLAY_STORE);
    }
  };

  const handleAttributedChannelChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAttributedChannel(event.target.value);
  };

  const isValidLink = (link: string, regex: RegExp) => regex.test(link);

  const validateForm = (): boolean => {
    const isFieldEmpty = (field: string) => field.trim().length === 0;
    const isBothLinksEmpty = isFieldEmpty(playstoreLink) && isFieldEmpty(appstoreLink);
    const isPlaystoreValid = isValidLink(playstoreLink, PLAY_STORE_LINK_REGEX);
    const isAppstoreValid = isValidLink(appstoreLink, APP_STORE_LINK_REGEX);

    const newErrorState = {
      ...errorState,
      name: isFieldEmpty(name),
      companyName: isFieldEmpty(companyName),
      email: isFieldEmpty(email) || !EMAIL_REGEX.test(email),
      playstoreLink: isBothLinksEmpty || (playstoreLink.length > 0 && !isPlaystoreValid),
      appstoreLink: isBothLinksEmpty || (appstoreLink.length > 0 && !isAppstoreValid),
    };

    setErrorState(newErrorState);
    const isValid = !Object.values(newErrorState).some(Boolean);
    setIsFormValid(isValid);

    return isValid;
  };


  const registerUser = async () => {
    const isFormValid = validateForm();
    if (!isFormValid) return;

    const payload: RegisterUserPayload = {
      name: name,
      company: companyName,
      email: email,
      playStoreUrl: playStoreApp?.appUrl || undefined,
      appStoreUrl: appStoreApp?.appUrl || undefined,
      attributedChannel: attributedChannel || "Website", // NOTE: Website is the fallback value.
      monthlyMarketingSpend: avgMonthlyMarketingSpend || undefined
    };

    try {
      const response: RegisterUserApiMap = await registerUserApi(payload).unwrap();

      if (response?.data) {
        setIsUserCreated(true);
      }
    } catch (error: any) {
      const { data } = error;
      if (data) {
        const { code, message } = data;

        switch (code) {
          case 4033:
            setUserDomainAlreadyExists(true);
            break;
          default:
            dispatch(setToastMessage({
              type: TOAST_TYPES.ERROR,
              message: message || "Unable to register user, please try again later."
            }));
            break
        }
      } else {
        dispatch(setToastMessage({
          type: TOAST_TYPES.ERROR,
          message: error?.error?.message || "Unable to register user, please try again later."
        }));
      }
    }
  };

  const handleFetchApplicationDetails = useDebounce(fetchApplicationDetails, 800);

  return (
    <SignupLayout>
      <Box
        sx={{
          paddingTop: "2rem",
          display: "grid",
          gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
          gap: "1rem",
        }}
      >
        <CustomInputField
          label="WORK EMAIL ADDRESS"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => setTouched({ ...touched, email: true })}
          error={errorState.email && touched.email}
          disabled={isUserCreated}
        />

        <CustomInputField
          label="FULL NAME"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() => setTouched({ ...touched, name: true })}
          error={errorState.name && touched.name}
          disabled={isUserCreated}
        />

        <CustomSelect
          label="HOW DID YOU HEAR ABOUT US? (OPTIONAL)"
          value={attributedChannel}
          onChange={handleAttributedChannelChange}
          options={ATTRIBUTED_CHANNEL_OPTIONS}
          error={false}
          disabled={isUserCreated}
        />

        <CustomInputField
          label={getPlaystoreLinkLabel()}
          value={playstoreLink}
          onChange={(e) => handlePlayStoreLinkChange(e.target.value)}
          onBlur={() => setTouched({ ...touched, playstoreLink: true })}
          error={errorState.playstoreLink && touched.playstoreLink}
          disabled={isUserCreated}
        />

        <CustomInputField
          label={getAppstoreLinkLabel()}
          value={appstoreLink}
          onChange={(e) => handleAppStoreLinkChange(e.target.value)}
          onBlur={() => setTouched({ ...touched, appstoreLink: true })}
          error={errorState.appstoreLink && touched.appstoreLink}
          disabled={isUserCreated}
        />

        <CustomInputField
          label="COMPANY NAME"
          value={companyName}
          disabled={true}
          onChange={(e) => setCompanyName(e.target.value)}
          onBlur={() => setTouched({ ...touched, companyName: true })}
          error={errorState.companyName && touched.companyName}
        />

        <CustomSelect
          label="MONTHLY MARKETTING SPENDS (OPTIONAL)"
          value={avgMonthlyMarketingSpend}
          onChange={(e) => setAvgMonthlyMarketingSpend(e.target.value)}
          onBlur={() => setTouched({ ...touched, avgMonthlyMarketingSpend: true })}
          options={MONTHLY_MARKETTING_SPENDS_OPTIONS}
          error={errorState.avgMonthlyMarketingSpend && touched.avgMonthlyMarketingSpend}
          disabled={isUserCreated}
        />
      </Box>

      <Box
        sx={{
          marginTop: "2rem",
          backgroundColor: "#162936",
          padding: "1.5rem",
          borderRadius: "2rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
        }}
      >
        <Stack
          gap={isMobile ? "1.5rem" : "1rem"}
          direction={isMobile ? "column" : "row"}
          sx={{
            flexWrap: "wrap"
          }}
        >
          {playStoreApp && <GameDetails applicationDetails={playStoreApp} />}

          {appStoreApp && <GameDetails applicationDetails={appStoreApp} />}
        </Stack>

        {
          isUserCreated || userDomainAlreadyExists
            ?
            <>
              <Stack direction={'row'} alignItems={'center'} gap={'1.25rem'}>
                <Box borderRadius={"50%"} minHeight={66} minWidth={66} sx={{
                  backgroundColor: "#2E4A4F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  {
                    isUserCreated
                      ?
                      <CheckIcon sx={{ color: "#7FBCAD" }} />
                      :
                      <ErrorOutlineIcon sx={{ color: "#7FBCAD" }} />
                  }
                </Box>

                <Stack>
                  <Typography fontSize={isMobile ? '1.25rem' : '1.375rem'} lineHeight={'1.875rem'} fontWeight={700} color={"#F6F8F9"}>
                    {
                      isUserCreated
                        ?
                        "Account Created Successfully!"
                        :
                        `Hey ${name}, your mail id is already registered with us.`
                    }
                  </Typography>
                  <Typography fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={'1.375rem'} fontWeight={700} color={'#B4B4B4'}>
                    {
                      isUserCreated
                        ?
                        `Invitation sent to ${email}`
                        :
                        `Please sign in using ${email[0]}${Array(email.split('@')[0].length - 1).fill("*").join('')}@${email.split('@')[1]}`
                    }
                  </Typography>
                </Stack>
              </Stack>

              <Button
                variant="contained"
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => navigate("/auth")}
                sx={{
                  width: isMobile ? "100%" : "315px",
                  maxWidth: "315px",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderRadius: "1.5rem",
                  fontSize: "1.375rem",
                  fontWeight: 700,
                  lineHeight: "1.875rem",
                  textTransform: "none",
                }}>
                Sign In
              </Button>
            </>
            : <>
              <Typography
                fontSize={"1rem"}
                fontWeight={700}
                lineHeight={"1.375rem"}
                color={"#F6F8F9"}
              >
                Sign up to get access to PvX Capital and data intelligence platform
              </Typography>

              <Button
                variant="contained"
                onClick={registerUser}
                disabled={
                  isCreatingUser ||
                  (!acceptTermsAndConditions || !acceptDataAccessTerms || !isFormValid)
                }
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                sx={{
                  width: isMobile ? "100%" : "315px",
                  maxWidth: "315px",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderRadius: "1.5rem",
                  fontSize: "1.375rem",
                  fontWeight: 700,
                  lineHeight: "1.875rem",
                  textTransform: "none",
                  ":disabled": {
                    backgroundColor: "#2E4A4F",
                  },
                }}
              >
                {isCreatingUser ? (
                  <>
                    <CircularProgress
                      size={"1.375rem"}
                      sx={{
                        marginRight: "1rem",
                      }}
                    />
                    <Typography
                      fontSize={"1.375rem"}
                      lineHeight={"1.875rem"}
                      fontWeight={700}
                      color={"#F6F8F9"}
                    >
                      Creating Account...
                    </Typography>
                  </>
                ) : (
                  <>Sign Up</>
                )}
              </Button>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "10px",
                  width: "90%",
                  fontSize: "15px",
                  color: false ? "#F6F8F9" : "#F6F8F999",
                  lineHeight: "21px",
                  fontWeight: 550,
                  letterSpacing: "-0.6px",
                }}
              >
                <Checkbox
                  value={acceptDataAccessTerms}
                  onChange={(e: any) => setAcceptDataAccessTerms(e.target.checked)}
                  sx={{
                    padding: "0px",
                    color: "#2F736E",
                    "&.Mui-checked": {
                      color: "#2F736E",
                    },
                  }}
                />
                <Typography
                  fontSize={"0.75rem"}
                  fontWeight={500}
                  lineHeight={"1rem"}
                  color={"#E4E3DBC2"}
                >
                  I hereby represent that I possess the necessary authority to share
                  access to data for the purpose of securing a funding facility from
                  PvX Partners
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "10px",
                  width: "90%",
                  fontSize: "15px",
                  color: false ? "#F6F8F9" : "#F6F8F999",
                  lineHeight: "21px",
                  fontWeight: 550,
                  letterSpacing: "-0.6px",
                }}
              >
                <Checkbox
                  value={acceptTermsAndConditions}
                  onChange={(e: any) => setAcceptTermsAndConditions(e.target.checked)}
                  sx={{
                    padding: "0px",
                    color: "#2F736E",
                    "&.Mui-checked": {
                      color: "#2F736E",
                    },
                  }}
                />
                <Typography
                  fontSize={"0.75rem"}
                  fontWeight={500}
                  lineHeight={"1rem"}
                  color={"#E4E3DBC2"}
                >
                  I agree to the PvX Partners <a
                    href="https://pvxpartners.com/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#E4E3DBC2", textDecoration: "underline" }}
                  >Terms & Conditions</a>
                </Typography>
              </Box>
            </>
        }
      </Box>
    </SignupLayout>
  );
};

export default SignupPage;
