import { FC } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CumulativeMetricCard from "./CumulativeMetricCard";
import { fundingEligibilityMetricsTooltips } from "./constants";
import { CTAText, FundingEligibilityChartResponse } from "./types";
import FundingEligibilityDoughnutChart from "./components/FundingEligibilityDoughnutChart";

export interface FundingEligibilityInfoProps {
  chartData: FundingEligibilityChartResponse | undefined;
}

const FundingEligibilityInfo: FC<FundingEligibilityInfoProps> = ({
  chartData,
}) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumDevice = useMediaQuery(theme.breakpoints.down("lg"));
  const showPointedFeedback = (chartData?.pointedFeedback?.length || 0) > 0;

  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        background:
          "linear-gradient(180.58deg, rgba(127, 188, 173, 0.3) 6.91%, #FFFFFF 120.29%)",
        borderRadius: "1.5rem",
        display: "flex",
        gap: "1.5rem",
        padding: "1.5rem",
        flexDirection: "column",
        alignItems: "start",
        border: "3px solid #E8EAEB",
      }}
    >
      <Box
        sx={{
          width: "100%",
          gap: "2rem",
          display: "flex",
          flexDirection: isMediumDevice ? "column" : "row",
        }}
      >
        <FundingEligibilityDoughnutChart chartData={chartData} />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: isSmallDevice ? "flex" : "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "0.75rem",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            {chartData?.cumulativeMetrics?.map((data: any, index: number) => (
              <CumulativeMetricCard
                key={index}
                cumulativeMetricData={data}
                tooltipData={fundingEligibilityMetricsTooltips[index]}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          fontSize={"1.25rem"}
          fontWeight={700}
          lineHeight={"1.875rem"}
          color={"#1B1C17"}
        >
          {chartData?.header}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>

          <Typography
            fontSize={"0.875rem"}
            lineHeight={"1.25rem"}
            fontWeight={700}
            color={"#6C6C6C"}
            marginTop={chartData?.cta === CTAText.SCHEDULE_A_CALL ? "0rem" : "0.5rem"}
          >
            {chartData?.message}
            <br />
            {showPointedFeedback ?
              'Improving the following metrics while keeping the rest of the metrics constant will increase your likelihood of financing eligibility with PvX:'
              : ''
            }
          </Typography>
          {chartData?.cta === CTAText.SCHEDULE_A_CALL ?
            <Button
              variant="contained"
              disableElevation
              disableRipple
              sx={{
                minWidth: '12.875rem',
                borderRadius: "6.25rem",
                textTransform: "none",
                fontSize: "1.125rem",
                fontWeight: "700",
                padding: "0.625rem 2.25rem",
                color: "#F6F8F9",
              }}
              onClick={() => { }}
              href={"https://pvxpartners.pipedrive.com/scheduler/KkdPWGCK/jeff-cohen-pvx-partners"}
              target="_blank"
              data-html2canvas-ignore="true"
            >
              Schedule a Call
            </Button>
            : <></>
          }
        </Box>
        <ul
          style={{
            margin: 0,
            paddingLeft: "0.5rem",
            listStyleType: "disc",
            marginTop: showPointedFeedback ? "1rem" : "0rem",
            marginBottom: 0
          }}
        >
          {chartData?.pointedFeedback?.map((feedback, index) => (
            <Typography
              key={index}
              component="li"
              fontSize="0.875rem"
              lineHeight="1.25rem"
              fontWeight={500}
              color="#6C6C6C"
            >
              {feedback}
            </Typography>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default FundingEligibilityInfo;
