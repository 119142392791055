export const fundingEligibilityMetricsTooltips = [
	{
		id: 'Cohort Margin of Safety',
		content: 'A measure of a cohort’s ability to repay the user acquisition (”UA”) costs and generate surplus returns over the spends. Higher terminal cumulative ROAS indicates a greater margin of safety for PvX financing.',
		example: 'Example: A game/app which cohorts consistently achieve 150%+ ROAS is preferred over one with cohorts returning only 120%.'
	},
	{
		id: 'Cohort Tail Risk',
		content: 'A measure of how long a cohort continues to generate meaningful revenue. Longer monetization periods reduce tail risk for PvX financing.',
		example: 'Example: A game/app which cohorts still generate >5% incremental ROAS in month 12 is preferred over one where incremental ROAS drops below 5% by month 3.'
	},
	{
		id: 'Payer Retention',
		content: 'A measure of how long an application retains its paying users. Lower payer retention leads to revenue concentration among a small set of users, increasing financing risk.',
		example: 'Example: A game/app with payers consistently returning for 6+ months is less risky than one where high amounts of payers churn within 2 months.'
	},
	{
		id: 'Returns Volatility',
		content: 'A measure of how stable/consistent the cohort ROAS curves have been historically. Lower volatility in returns reduces financing risk for PvX.',
		example: 'Example: A game/app with month-over-month ROAS fluctuations of ±5% is more stable than one where ROAS swings by ±30%.'
	},
	{
		id: 'Scalability',
		content: 'A measure of how much ROAS performance is expected to decline as UA spend scales. Lower ROAS degradation implies lower risk in expanding UA with PvX financing.',
		example: 'Example: A game/app where ROAS drops by only 10% when scaling UA from $100K to $500K per month is more scalable than one where ROAS drops by 40% when UA increases at the same rate.'
	}
]

export const knowMorePanelPoints = [
	{
		title: 'Monthly Refreshed ROAS Benchmarks',
		description: 'Stay up to date with how your cohorts compare to industry peers across categories and genres.'
	},
	{
		title: 'Access to ROAS Projections',
		description: 'Leverage our advanced forecasting models to predict future ROAS and fine-tune your financial planning.'
	},
	{
		title: 'Continuous Funding Eligibility Assessment',
		description: 'Receive ongoing insights into how much of your marketing budget can be financed, with feedback on ways to improve eligibility.'
	},
	{
		title: 'Exclusive ROAS Optimization Partners',
		description: 'Tap into our network of pre-vetted partners who can help improve your ROAS and maximize growth efficiency.'
	},
	{
		title: 'More to Come',
		description: 'We’re constantly adding new features to ensure you always have the best insights and resources at your fingertips.'
	},
]