import { useLazyGetUserModulesByIdQuery } from '../../api/userModules';

export interface UserModulesByIdApiMap {
  code: number
  message: string
  data: Data[]
}

export interface Data {
  id: string
  name: string
  submoduleCatalogs: SubmoduleCatalog[]
}

export interface SubmoduleCatalog {
  id: string
  name: string
  url: string
  submoduleFilters: SubmoduleFilter[]
}

export interface SubmoduleFilter {
  id: string
  name: string
  submoduleFilterRoles: SubmoduleFilterRole[]
}

export interface SubmoduleFilterRole {
  id: string
  filterValue: string
}

const useUserModulesById = () => {
  const [getUserModulesById, { isLoading, isError }] = useLazyGetUserModulesByIdQuery();

  const getUserModuleDetailsById = async (id: string): Promise<void | UserModulesByIdApiMap> => {
    try {
      const response = await getUserModulesById(id).unwrap();

      if (response.data) {
        return response;
      }
    } catch (err) {
      console.error('Error while fetching user module details by id :', err);
    }
  };

  return { getUserModuleDetailsById, isLoading, isError };
};

export default useUserModulesById;
