import {
  useMediaQuery,
  Stack,
  Typography,
  Box,
  useTheme,
  Button,
} from "@mui/material";
import ImageCarousel from "./GettingStartedDashboards/components/ImageCarousel";
import { FC } from "react";
import { useRequestSubModuleMutation } from "@api/userModules";
import { useDispatch } from "react-redux";
import { setToastMessage } from "@stores/App/slice";
import { TOAST_TYPES } from "@constants/constants";
import { resetLoadCount } from "@stores/Modules/slice";
import useUserModules from "@hooks/api/useUserModules";
const imageBucketUrl = `${process.env.REACT_APP_BUCKET_DOMAIN}`;

export interface RequestableDashboardProps {
  title: string;
  subTitle: string;
  body: string;
  requestStatus: "APPROVED" | "REQUESTED" | "REQUESTABLE";
  requestId: string;
  images: string[];
}

const RequestableDashboard: FC<RequestableDashboardProps> = ({
  title,
  subTitle,
  body,
  requestStatus,
  requestId,
  images,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  
  const [requestSubmodule, { isLoading }] = useRequestSubModuleMutation();
  const { getUserModules } = useUserModules();

  const baseUrlAddedImages = images.map(
    (image) => `${imageBucketUrl}/${image}`
  );

  const handleRequestFeature = async () => {
    try {
      const response = await requestSubmodule(requestId).unwrap();

      if (response?.data) {
        dispatch(resetLoadCount());
        await getUserModules();
      }
    } catch (err: any) {
      dispatch(setToastMessage({
        type: TOAST_TYPES.ERROR,
        message: err?.error?.message || "Failed to request for Sub module."
      }))
    }
  };

  return (
    <Stack
      gap={"1.75rem"}
      padding={"2rem"}
      sx={{
        overflowY: "auto",
        maxHeight: "100vh",
      }}
    >
      <Stack>
        <Typography
          fontSize={"2.25rem"}
          lineHeight={"2.75rem"}
          fontWeight={700}
          color={"#162936"}
        >
          {title}
        </Typography>
        <Typography
          fontSize={"2.25rem"}
          lineHeight={"2.75rem"}
          fontWeight={700}
          color={"#B4B4B4"}
        >
          {subTitle}
        </Typography>
        <Typography
          marginTop={"0.75rem"}
          fontSize={"1rem"}
          lineHeight={"1.25rem"}
          fontWeight={500}
          color={"#6C6C6C"}
          sx={{ width: isMobile ? "100%" : "60%" }}
        >
          {body}
        </Typography>
      </Stack>

      <Stack gap={"0.75rem"} direction={"row"}>
        {requestStatus === "REQUESTABLE" && (
          <Button
            variant="contained"
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disabled={isLoading}
            sx={{
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              fontWeight: 700,
              color: "#F6F8F9",
              padding: "0.625rem 1.5rem",
              borderRadius: "100px",
              textTransform: "none",
            }}
            onClick={handleRequestFeature}
          >
            Request Feature
          </Button>
        )}
        {requestStatus === "REQUESTED" && (
          <Button
            variant="text"
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{
              pointerEvents: "none",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              fontWeight: 500,
              color: "#6C6C6C",
              padding: "0.625rem 0",
              borderRadius: "100px",
              textTransform: "none",
              ":hover": {
                background: "none",
              },
            }}
          >
            You have been added to the waitlist!
          </Button>
        )}
      </Stack>

      <Box>
        <ImageCarousel images={baseUrlAddedImages} />
      </Box>
    </Stack>
  );
};

export default RequestableDashboard;
