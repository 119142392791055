import apiRoutes from "@constants/apiRoutes";
import { APPLICATION_STORES } from "@constants/constants";
import baseApi, { noAuthBaseApi } from "@services/api";

export interface FetchApplicationDetailsApiMap {
  code: number;
  message: string;
  data: {
    marketPlace: (typeof APPLICATION_STORES)[keyof typeof APPLICATION_STORES];
    appUrl: string;
    name: string;
    icon: string;
    developerName: string;
    rating: string;
    reviews: string;
    downloads: string;
  };
}

export interface RegisterUserApiMap {
  code: number;
  message: string;
  data: {
    id: string;
    status: string;
  };
}

export interface RegisterUserPayload {
  name: string;
  company: string;
  email: string;
  monthlyMarketingSpend?: string;
  playStoreUrl?: string;
  appStoreUrl?: string;
  attributedChannel?: string;
}

export type AdminRegisterUserPayload = {
  accountName: string;
  category: string;
  genre: string;
  monthlyMarketingSpends: string;
  playStoreUrl: string;
  appStoreUrl: string;
};

const register = noAuthBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchApplicationDetails: builder.query<
      FetchApplicationDetailsApiMap,
      {
        store: (typeof APPLICATION_STORES)[keyof typeof APPLICATION_STORES];
        appUrl: string;
      }
    >({
      query: ({ store, appUrl }) => apiRoutes.applicationDetails(store, appUrl),
    }),

    registerUser: builder.mutation<RegisterUserApiMap, RegisterUserPayload>({
      query: (payload: RegisterUserPayload) => ({
        url: apiRoutes.registerUser,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

const adminRegister = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    adminRegisterUser: builder.mutation<RegisterUserApiMap, AdminRegisterUserPayload>({
      query: (payload: AdminRegisterUserPayload) => ({
        url: apiRoutes.adminRegisterUser,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useLazyFetchApplicationDetailsQuery,
  useRegisterUserMutation,
} = register;

export const {
  useAdminRegisterUserMutation,
} = adminRegister;
