import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, Typography, Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { RootState } from "@stores/store";
import ImageCarousel from "./components/ImageCarousel"
import { useRequestSubModuleMutation } from "@api/userModules";
import { resetLoadCount } from "@stores/Modules/slice";
import useUserModules from "@hooks/api/useUserModules";
import { useDispatch } from "react-redux";
import { TOAST_TYPES } from "@constants/constants";
import { setToastMessage } from "@stores/App/slice";

const imageBucketUrl = `${process.env.REACT_APP_BUCKET_DOMAIN}`;
const images = [
  `${imageBucketUrl}/demo-dashboards/financial_projections_1.png`,
  `${imageBucketUrl}/demo-dashboards/financial_projections_2.png`,
  `${imageBucketUrl}/demo-dashboards/financial_projections_3.png`,
];

const GettingStartedROASProjection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const modulesState = useSelector((state: RootState) => state?.ModulesData);
  const [requestStatus, setRequestStatus] = useState<0 | 1 | 2>(0);
  const [requestId, setRequestId] = useState<string | null>(null);

  const [requestSubmodule, { isLoading }] = useRequestSubModuleMutation();
  const { getUserModules } = useUserModules();

  useEffect(() => {
    if (modulesState.loadCount === 0) return;

    // if modules has length, navigate back to dashboards.
    if (modulesState?.modules.filter((module) => module.requestStatus === "APPROVED").length) {
      navigate('/dashboard');
      return;
    }

    for (const module of modulesState?.modules) {
      if (module.name === "Financial Projections") {
        if (module.requestStatus === "REQUESTABLE") {
          setRequestId(module.requestId as string);
          setRequestStatus(1);
        } else if (module.requestStatus === "REQUESTED") {
          setRequestId(module.requestId as string);
          setRequestStatus(2);
        }
      }
    }

  }, [modulesState]);

  const handleRequestFeature = async () => {
    try {
      const response = await requestSubmodule(requestId as string).unwrap();

      if (response?.data) {
        dispatch(resetLoadCount());
        await getUserModules();
      }
    } catch (err: any) {
      dispatch(setToastMessage({
        type: TOAST_TYPES.ERROR,
        message: err?.error?.message || "Failed to request for Sub module."
      }))
    }
  };

  return (
    <Stack
      gap={"1.75rem"}
      padding={"2rem"}
      sx={{
        overflowY: 'auto',
        maxHeight: '100vh'
      }}>
      <Stack>
        <Typography
          fontSize={"2.25rem"}
          lineHeight={"2.75rem"}
          fontWeight={700}
          color={"#162936"}
        >
          Access ROAS Projections,
        </Typography>
        <Typography
          fontSize={"2.25rem"}
          lineHeight={"2.75rem"}
          fontWeight={700}
          color={"#B4B4B4"}
        >
          for smarter financial planning
        </Typography>
        <Typography
          marginTop={'0.75rem'}
          fontSize={'1rem'}
          lineHeight={'1.25rem'}
          fontWeight={500}
          color={'#6C6C6C'}
          sx={{ width: isMobile ? "100%" : "60%" }}
        >
          Use our projections model trained on your data and fine-tuned with category insights to forecast cohort-level ROAS, helping you plan your cash flow
        </Typography>
      </Stack>

      <Stack gap={"0.75rem"} direction={"row"}>
        {requestStatus === 1 && (<Button
          variant="contained"
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          disabled={[0, 2].includes(requestStatus) || isLoading}
          sx={{
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            fontWeight: 700,
            color: '#F6F8F9',
            padding: '0.625rem 1.5rem',
            borderRadius: '100px',
            textTransform: 'none'
          }}
          onClick={handleRequestFeature}
        >
          Request Feature
        </Button>)}
        {requestStatus === 2 && (<Button
          variant="text"
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          sx={{
            pointerEvents: "none",
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            fontWeight: 500,
            color: '#6C6C6C',
            padding: '0.625rem 0',
            borderRadius: '100px',
            textTransform: 'none',
            ":hover": {
              background: 'none'
            }
          }}
        >
          You have been added to the waitlist!
        </Button>)}
      </Stack>

      <Box>
        <ImageCarousel images={images} />
      </Box>
    </Stack>
  );
};

export default GettingStartedROASProjection;
