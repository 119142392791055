import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Stack, Typography, useMediaQuery, useTheme, Select, MenuItem, FormControl, InputLabel, CircularProgress, Link, TextField, Radio } from "@mui/material";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Player } from '@lottiefiles/react-lottie-player';
import { RootState } from "@stores/store";
import {
  useLazyFetchCategoryAndGenreOptionsQuery,
  useLazyFetchSelfServeDetailsQuery
} from "@api/selfServe";
import { APPLICATION_STORES, SCHEDULE_CALL_LINK, TOAST_TYPES } from "@constants/constants";
import { CustomAccordion } from "../../../components";
import appsflyerLogo from "../../../assets/icons/appsflyer-logo.png";
import adjustLogo from "../../../assets/icons/adjust-logo.png";
import singularLogo from "../../../assets/icons/signular-logo.png";
import snowFlakeLogo from "../../../assets/icons/snowflake-logo.png";
import bigQueryLogo from "../../../assets/icons/big-query-logo.png";
import DrawerComponent from "../../../components/Drawer/Drawer";
import GrantAccessToAppsFlyer from "./components/GrantAccessToAppsFlyer";
import ProcessingAppsFlyerDataAccess from "./components/ProcessingAppsFlyerDataAccess";
import EditGame from "./components/EditGame";
import GameDetails, { IGameDetails } from "./components/GameDetails";
import { useDispatch } from "react-redux";
import { setToastMessage } from "@stores/App/slice";
import { processRatingString } from "@utils/common";
import { useNavigate } from "react-router-dom";
import { resetLoadCount } from "@stores/Modules/slice";
import GettingStartedHeader from "./components/GettingStartedHeader";
import UserAuth from "@services/auth";
import pvxIntelLoader from '../../../assets/images/PvxIntelLoader.json';
import EditSignNDA from "./components/EditSignNDA";
import SignNDA from "./components/SignNDA";

const GettingStarted: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const modulesState = useSelector((state: RootState) => state?.ModulesData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [applicationDetailsResponse, setApplicationDetailsResponse] = useState<{
    category: string | null;
    genre: string | null;
    selfServeId: string | null;
  } | null>(null);
  const [accordionConfig, setAccordionConfig] = useState<{ isSuccess: boolean, open: boolean }[] | null>(null);
  const [playStoreApp, setPlayStoreApp] = useState<null | IGameDetails>(null);
  const [appStoreApp, setAppStoreApp] = useState<null | IGameDetails>(null);
  const [category, setCategory] = useState<string>("");
  const [genre, setGenre] = useState<string>("");
  const [categoryOptions, setCategoryOptions] = useState<{ name: string; genres: string[] }[]>([]);
  const [genreOptions, setGenreOptions] = useState<string[]>([]);
  const [selfServeId, setSelfServeId] = useState<string>("");
  const [isEditGameOpen, setIsEditGameOpen] = useState<boolean>(false);
  const [isGrantAccessToAppsFlyerLearnMoreOpen, setIsGrantAccessToAppsFlyerLearnMoreOpen] = useState<boolean>(false);
  const [accessData, setAccessData] = useState<number>(1);
  const [accessGrantScrollTo, setAccessGrantScrollTo] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [accessDataStatus, setAccessDataStatus] = useState<string | null>(null);
  const [openEditSignNDAConfirmation, setOpenEditSignNDAConfirmation] = useState(false);
  const [ndaDetails, setNDADetails] = useState<any>(null);
  const [successSigningNDA, setSuccessSigningNDA] = useState<boolean>(false);
  const [openNDAForm, setOpenNDAForm] = useState<boolean>(false);

  const [categoryGenreUpdated, setCategoryGenreUpdated] = useState<boolean>(false);
  const [dataAccessUpdated, setDataAccessUpdated] = useState<boolean>(false);

  const [fetchSelfServeDetails, { isLoading: isSelfServeDetailsFetching }] = useLazyFetchSelfServeDetailsQuery();
  const [fetchCategoryAndGenreOptionsApi] = useLazyFetchCategoryAndGenreOptionsQuery();

  useEffect(() => {
    const demoToken = UserAuth.demoToken;

    if (modulesState.loadCount === 0) return;

    // if modules has length, navigate back to dashboards.
    if (modulesState?.modules?.length && demoToken) {
      navigate('/dashboard');
      return;
    }

    // if modules doesn't have length, fetch self serve api.
    fetchApplicationDetails();

    const polling = setInterval(() => {
      fetchDataAccessStatus();
    }, 60000);

    return () => {
      clearInterval(polling);
    }
  }, [modulesState]);

  useEffect(() => {
    fetchCategoryAndGenreOptions();
  }, []);

  useEffect(() => {
    setGenre("");
    if (category === null) return;

    let genres: string[] = [];
    for (const cat of categoryOptions) {
      if (cat.name !== category) continue;

      genres = cat.genres;
      break;
    }

    setGenreOptions(genres);
  }, [category]);

  useEffect(() => {
    if (categoryGenreUpdated) {
      fetchApplicationDetails();
    }
  }, [categoryGenreUpdated])

  useEffect(() => {
    if (successSigningNDA) {
      setAccordionConfig([
        {
          isSuccess: true,
          open: false
        },
        {
          isSuccess: true,
          open: false
        },
        {
          isSuccess: false,
          open: true
        }
      ]);
    }
  }, [successSigningNDA]);

  const fetchDataAccessStatus = async (): Promise<void> => {
    try {
      setTimeout(() => {
        if (UserAuth.demoToken) {
          dispatch(resetLoadCount());

          window.location.reload();
        }
      }, 8000);
    } catch (error: any) {
      console.error(error);
      // NOTE: Error not shown in toast as this is used for polling.
    }
  }

  const fetchApplicationDetails = async (): Promise<void> => {
    try {
      const response = await fetchSelfServeDetails().unwrap();
      const { data } = response;

      if (!data?.id) {
        dispatch(setToastMessage({
          message: "Failed to fetch application details, please try again!",
          type: TOAST_TYPES.ERROR
        }));
        navigate('/demo/dashboard/getting-started');
        return;
      }

      if (data && data.requestStatus && UserAuth.demoToken) {
        dispatch(resetLoadCount());
        navigate('/dashboard');
        return;
      }

      setApplicationDetailsResponse({
        selfServeId: response.data.id,
        category: response.data.category,
        genre: response.data.genre,
      });

      setAccessDataStatus(response.data.requestStatus);

      if (!categoryGenreUpdated) {
        setAccordionConfig([
          {
            isSuccess: false,
            open: true
          },
          {
            isSuccess: false,
            open: false
          },
          {
            isSuccess: false,
            open: false
          }
        ]);
      } else {
        setAccordionConfig([
          {
            isSuccess: true,
            open: false
          },
          {
            isSuccess: false,
            open: true
          },
          {
            isSuccess: false,
            open: false
          }
        ]);
      }

      setSelfServeId(data.id);

      const { metadata } = data;
      const parsedMetadata = JSON.parse(metadata);

      const playStoreApp: IGameDetails = parsedMetadata[APPLICATION_STORES.PLAY_STORE] || null;
      const appStoreApp: IGameDetails = parsedMetadata[APPLICATION_STORES.APP_STORE] || null;

      if (playStoreApp?.rating) {
        playStoreApp["rating"] = processRatingString(playStoreApp["rating"] as string);
      }

      if (appStoreApp?.rating) {
        appStoreApp["rating"] = processRatingString(appStoreApp["rating"] as string);
      }

      setPlayStoreApp(playStoreApp);
      setAppStoreApp(appStoreApp);
    } catch (error: any) {
      dispatch(setToastMessage({
        type: TOAST_TYPES.ERROR,
        message: error?.error?.message || null
      }));
    }
  }

  const fetchCategoryAndGenreOptions = async (): Promise<void> => {
    try {
      const response = await fetchCategoryAndGenreOptionsApi().unwrap();
      const { data } = response;

      setCategoryOptions(data.categories);
    } catch (error: any) {
      dispatch(setToastMessage({
        type: TOAST_TYPES.ERROR,
        message: error?.error?.message || null
      }));
    }
  }

  const handleSubmitCategoryAndGenre = async (): Promise<void> => {
    // Validations done for submitting category and genre submit.
    if (category === null || genre === null) {
      return;
    }
    if (!selfServeId) {
      return;
    }

    setCategoryGenreUpdated(true);
  }

  const handleEditGameClose = useCallback((cancel: boolean = false) => {
    if (!cancel) fetchApplicationDetails();

    setIsEditGameOpen(false);
  }, []);

  const handleGrantAccessClose = useCallback((cancel: boolean = false) => {
    setAccessGrantScrollTo("");
    setIsGrantAccessToAppsFlyerLearnMoreOpen(false)
  }, []);

  const handleEditGameOpen = useCallback(() => setIsEditGameOpen(true), []);

  const handleCopyToClipboard = (text: string) => {
    dispatch(setToastMessage({
      type: TOAST_TYPES.SUCCESS,
      message: "Copied"
    }));
    navigator.clipboard.writeText(text)
  }

  const handleClickData = (sidePanelId: string) => {
    setIsGrantAccessToAppsFlyerLearnMoreOpen(true);
    setAccessGrantScrollTo(sidePanelId);
  }

  const handleConfirmAccessClick = async () => {
    setDataAccessUpdated(true);
    UserAuth.setDemoToken(new Date().toISOString());

    fetchDataAccessStatus();
  }

  const handleLearnHowClicked = () => {
    setAccessGrantScrollTo("");
    setIsGrantAccessToAppsFlyerLearnMoreOpen(true);
  }

  const handleCloseEditSignNDAConfirmation = (cancel: boolean) => {
    if (!cancel) {
      setOpenNDAForm(true);
    }

    setOpenEditSignNDAConfirmation(false);
  }


  return (
    <Box
      padding={"2rem"}
      paddingBottom={'4rem'}
      sx={{
        height: '100%',
        overflow: 'scroll',
        position: "relative"
      }}
    >
      <GettingStartedHeader />

      <Stack spacing={"1rem"}>
        <CustomAccordion
          open={!!accordionConfig && accordionConfig[0].open}
          isSuccess={!!accordionConfig && accordionConfig[0].isSuccess}
          title="1. Tell us about your app">
          <Stack gap={"1rem"}>
            {
              isSelfServeDetailsFetching
                ?
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%'
                }}>
                  <CircularProgress size={20} />
                </Box>
                : <>
                  <Stack direction={isMobile ? "column" : "row"} gap={"1rem"} sx={{
                    flexWrap: 'wrap'
                  }}>
                    {
                      playStoreApp &&
                      <GameDetails
                        setIsEditGameOpen={handleEditGameOpen}
                        category={categoryGenreUpdated ? applicationDetailsResponse?.category : null}
                        genre={categoryGenreUpdated ? applicationDetailsResponse?.genre : null}
                        gameDetails={playStoreApp} />
                    }
                    {
                      appStoreApp &&
                      <GameDetails
                        setIsEditGameOpen={handleEditGameOpen}
                        category={categoryGenreUpdated ? applicationDetailsResponse?.category : null}
                        genre={categoryGenreUpdated ? applicationDetailsResponse?.genre : null}
                        gameDetails={appStoreApp} />
                    }
                  </Stack>

                  {
                    (!categoryGenreUpdated && !UserAuth.demoToken) &&
                    <>
                      <Stack direction={"row"} gap={"1rem"}>
                        <FormControl fullWidth>
                          <InputLabel id="category-label">Category</InputLabel>

                          <Select
                            value={category}
                            label="Category"
                            onChange={(e) => setCategory(e.target.value)}>
                            {categoryOptions.map((category, index) => (<MenuItem key={index} value={category.name}>{category.name}</MenuItem>))}
                          </Select>
                        </FormControl>

                        <FormControl fullWidth>
                          <InputLabel id="genre-label">Genre</InputLabel>

                          <Select
                            disabled={genreOptions.length === 0}
                            value={genre}
                            label="Genre"
                            onChange={(e) => setGenre(e.target.value)}>
                            {genreOptions.map((g, index) => (<MenuItem key={index} value={g}>{g}</MenuItem>))}
                          </Select>
                        </FormControl>
                      </Stack>

                      <Box>
                        <Button
                          disabled={!!!genre}
                          onClick={handleSubmitCategoryAndGenre}
                          variant="contained"
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          sx={{
                            borderRadius: "100px",
                            fontSize: "1rem",
                            lineHeight: "1.25rem",
                            padding: "0.625rem 1.5rem",
                            textTransform: "none",
                            fontWeight: 700,
                            background: "#2F736E",
                            color: "#fff"
                          }}>Submit</Button>
                      </Box>
                    </>
                  }

                </>
            }

          </Stack>
        </CustomAccordion>

        <CustomAccordion
          open={!!accordionConfig && accordionConfig[1].open}
          isSuccess={successSigningNDA}
          showEdit={!!ndaDetails}
          disableExpand={!!!accordionConfig?.[0].isSuccess}
          onEdit={() => setOpenEditSignNDAConfirmation(true)}
          title="2. Non Disclosure Agreement" >
          <SignNDA
            successSigningNDA={successSigningNDA}
            setSuccessSigningNDA={(value: boolean) => setSuccessSigningNDA(value)}
            ndaDetails={ndaDetails}
            setNDADetails={setNDADetails}
            openNDAForm={openNDAForm}
            setOpenNDAForm={(value: boolean) => setOpenNDAForm(value)} />
        </CustomAccordion>

        <CustomAccordion
          open={!!accordionConfig && accordionConfig[2].open}
          disableExpand={!successSigningNDA}
          title="3. Share access to your app data with us">
          <Stack spacing={"1rem"}>
            <Box
              sx={{
                padding: "1rem 0.75rem",
                background: accessData === 1 ? "#2F736E14" : "#F0F0F1",
                border: accessData === 1 ? "1px solid #7FBCAD" : "1px solid #2F736E1F",
                borderRadius: "0.5rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Stack direction={"row"} gap={"0.75rem"}>
                <Box>
                  <Radio
                    checked={accessData === 1}
                    value={1}
                    onChange={() => setAccessData(1)}
                    name="radio-buttons"
                  />
                </Box>

                <Box>
                  <Stack direction={"row"} gap={"1rem"}>
                    <Box
                      sx={{
                        background: "#fff",
                        border: "1px solid #2F736E1F",
                        borderRadius: "0.5rem",
                        height: "40px",
                        width: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0.5rem 0.5rem"
                      }}
                    >
                      <img height={"100%"} src={appsflyerLogo} alt="appsflyer-logo" />
                    </Box>

                    {accessData === 1 && !dataAccessUpdated &&
                      <Button
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        onClick={handleLearnHowClicked}
                        sx={{
                          color: "#51B8B0",
                          textDecoration: "underline",
                          textTransform: "none",
                          fontSize: "0.85rem",
                          lineHeight: "1rem",
                          fontWeight: 700,
                          padding: 0,
                          ":hover": {
                            background: "none",
                          },
                        }}
                      >
                        Learn How
                      </Button>}
                  </Stack>

                  {/* AppsFlyer details */}
                  {(accessData === 1) && (
                    (!dataAccessUpdated)
                      ? (
                        <Box marginTop={"0.75rem"}>
                          <Box sx={{
                            width: "min(316px, 80%)"
                          }}>
                            <TextField
                              id="outlined-basic"
                              label="Account Name"
                              placeholder="Account Name on Appsflyer"
                              variant="outlined"
                              onChange={(e) => setAccountName(e.target.value)}
                              disabled={false}
                              sx={{
                                width: "100%"
                              }} />
                          </Box>

                          <Typography
                            marginTop={"1rem"}
                            fontSize={"0.875rem"}
                            lineHeight={"1.25rem"}
                            fontWeight={500}
                            color={"#162936"}
                            maxWidth={"100%"}
                          >
                            Find the Account Name on the top right corner of your
                            {" "}
                            <Typography
                              onClick={() => handleClickData('appsflyer-account-name')}
                              component={"span"}
                              fontSize={"0.875rem"}
                              lineHeight={"1.25rem"}
                              fontWeight={700}
                              color={"#51B8B0"}
                              sx={{
                                "textDecoration": "underline",
                                ":hover": {
                                  cursor: "pointer",
                                  textDecoration: "none"
                                }
                              }}>Appsflyer Account.</Typography>
                          </Typography>
                          <Typography
                            marginTop={"1rem"}
                            fontSize={"0.875rem"}
                            lineHeight={"1.25rem"}
                            fontWeight={500}
                            color={"#162936"}
                            maxWidth={"100%"}
                          >
                            Please provide access to the above Appsflyer account with the Role as "Marketing Lead" to the following email address and confirm. Once access is granted, let us know by clicking on “Confirm Access” button, so we can proceed with the
                            {" "}
                            <Typography
                              onClick={() => handleClickData("data-we-connect")}
                              component={"span"}
                              fontSize={"0.875rem"}
                              lineHeight={"1.25rem"}
                              fontWeight={700}
                              color={"#51B8B0"}
                              sx={{
                                "textDecoration": "underline",
                                ":hover": {
                                  cursor: "pointer",
                                  textDecoration: "none"
                                }
                              }}>data evaluation</Typography>.
                          </Typography>

                          <Typography
                            marginTop={"1.75rem"}
                            fontSize={"0.875rem"}
                            lineHeight={"1.25rem"}
                            fontWeight={500}
                            color={"#162936"}>
                            <Typography
                              component={"span"}
                              fontSize={"0.875rem"}
                              lineHeight={"1.25rem"}
                              fontWeight={700}
                              color={"#162936"}>
                              Note:
                            </Typography>
                            {" "}
                            We do not collect any PII data such as device id, GAID or IDFA for the purpose of our analysis.
                          </Typography>

                          {/* Email to be copied */}
                          <Box
                            sx={{
                              background: "#2F736E1F",
                              borderRadius: "0.25rem",
                              padding: "0.5rem",
                              margin: "0.75rem 0",
                              display: "flex",
                              alignItems: "center",
                              gap: "0.75rem",
                              width: "fit-content",
                              maxWidth: "80%"
                            }}
                          >
                            <Typography
                              fontSize={"0.75rem"}
                              fontWeight={700}
                              lineHeight={"1rem"}
                              color={"#162936"}
                              textOverflow={"ellipsis"}
                              overflow={"hidden"}
                              whiteSpace={"nowrap"}
                            >
                              underwriting@pvxpartners.com
                            </Typography>

                            <ContentCopyIcon
                              onClick={() => handleCopyToClipboard("underwriting@pvxpartners.com")}
                              sx={{
                                ":hover": {
                                  cursor: "pointer"
                                }
                              }}></ContentCopyIcon>
                          </Box>

                          <Stack direction={"row"} gap={"1.25rem"} marginTop={"1rem"} flexWrap={"wrap"}>
                            <Button
                              onClick={handleConfirmAccessClick}
                              variant="contained"
                              disabled={accountName.length === 0}
                              disableElevation
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                              sx={{
                                borderRadius: "100px",
                                fontSize: "14px",
                                lineHeight: "1.25rem",
                                padding: "0.625rem 1.5rem",
                                textTransform: "none",
                                fontWeight: 700,
                                background: "#2F736E",
                                color: "#fff",
                              }}
                            >
                              Confirm Access
                            </Button>
                          </Stack>
                        </Box>
                      )
                      : (<ProcessingAppsFlyerDataAccess />)
                  )}
                </Box>
              </Stack>
            </Box>

            <Box
              sx={{
                padding: "1rem 0.75rem",
                background: accessData === 2 ? "#2F736E14" : "#F0F0F1",
                border: accessData === 2 ? "1px solid #7FBCAD" : "1px solid #2F736E1F",
                borderRadius: "0.5rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Stack direction={"row"} gap={"0.75rem"}>
                <Box>
                  <Box>
                    <Radio
                      checked={accessData === 2}
                      value={2}
                      onChange={() => setAccessData(2)}
                      name="radio-buttons"
                    />
                  </Box>
                </Box>

                <Box width={"100%"}>
                  <Stack
                    direction={isMobile ? "column" : "row"}
                    justifyContent={"space-between"}
                    gap={"1rem"}
                    alignItems={isMobile ? "flex-start" : "center"}>
                    <Stack direction={"row"} gap={"1rem"} alignItems={"center"} flexWrap={"wrap"}>
                      <Box
                        sx={{
                          background: "#fff",
                          border: "1px solid #2F736E1F",
                          borderRadius: "0.5rem",
                          padding: "0.75rem 0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40px"
                        }}
                      >
                        <img height={"100%"} src={adjustLogo} alt="adjust-logo" />
                      </Box>

                      <Box
                        sx={{
                          background: "#fff",
                          border: "1px solid #2F736E1F",
                          borderRadius: "0.5rem",
                          padding: "0px 0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40px"
                        }}
                      >
                        <img height={"100%"} src={singularLogo} alt="singular-logo" />
                      </Box>

                      <Box
                        sx={{
                          background: "#fff",
                          border: "1px solid #2F736E1F",
                          borderRadius: "0.5rem",
                          padding: "8.5px 0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40px"
                        }}
                      >
                        <img height={"100%"} src={snowFlakeLogo} alt="singular-logo" />
                      </Box>

                      <Box
                        sx={{
                          background: "#fff",
                          border: "1px solid #2F736E1F",
                          borderRadius: "0.5rem",
                          padding: "0 1.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "40px"
                        }}
                      >
                        <img height={"100%"} src={bigQueryLogo} alt="singular-logo" />
                      </Box>

                      <Typography fontSize={"0.75rem"} lineHeight={"1rem"} fontWeight={500} color={"#6C6C6C"}>and more...</Typography>
                    </Stack>

                    {accessData === 2 && (
                      <Button
                        href={SCHEDULE_CALL_LINK}
                        target="_blank"
                        variant="contained"
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        startIcon={<HeadphonesIcon />}
                        sx={{
                          borderRadius: "100px",
                          fontSize: "14px",
                          lineHeight: "1.25rem",
                          padding: "0.625rem 1.5rem",
                          textTransform: "none",
                          fontWeight: 700,
                          background: "#2F736E",
                          color: "#fff",
                          minWidth: "174px"
                        }}
                      >
                        Schedule a call
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </CustomAccordion>

        <Typography fontSize={"0.85rem"} lineHeight={"1rem"} fontWeight={700} color={"#6C6C6C"}>
          Need to add more team members or need help? Contact us at {" "}
          <Link href="mailto:info@pvxpartners.com" color={"#51B8B0"} fontWeight={700}>
            info@pvxpartners.com
          </Link>{" "}
        </Typography>
      </Stack>

      <DrawerComponent
        anchor="right"
        open={isEditGameOpen}
        close={(status: string) => setIsEditGameOpen(false)}
        height="98vh"
        zIndex={1203}
        backgroundColor="#F0F0F1"
      >
        <EditGame close={handleEditGameClose} selfServeId={selfServeId} playStoreApp={playStoreApp} appStoreApp={appStoreApp} />
      </DrawerComponent>

      <DrawerComponent anchor="right" height="98vh" zIndex={1203} backgroundColor="#F0F0F1" open={isGrantAccessToAppsFlyerLearnMoreOpen} close={() => setIsGrantAccessToAppsFlyerLearnMoreOpen(false)}>
        <GrantAccessToAppsFlyer scrollTo={accessGrantScrollTo} close={handleGrantAccessClose} />
      </DrawerComponent>
      <EditSignNDA
        open={openEditSignNDAConfirmation}
        close={handleCloseEditSignNDAConfirmation} />
      {accessData === 1 && dataAccessUpdated ? (
        <Box sx={{ position: "absolute", height: '100%', width: '100%', top: 0, left: 0, background: "#00000050" }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%', width: "100%", py: "0.5rem" }}>
            <Player
              src={pvxIntelLoader}
              className="player"
              loop
              autoplay
              style={{
                width: "17rem"
              }}
            />
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default GettingStarted;