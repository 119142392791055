import { ProjectionValueUpdateType } from 'src/pages/Dashboard/components/FinancialProjections/types';
import apiRoutes from '../constants/apiRoutes';
import baseApi from '../services/api';

interface SimulateProjectionsPayload {
  submoduleId: string;
  body: {
    gameId: string;
    cohortData: { cohortMonth: string, totalCalc: string }[]
  }
}

interface GetPredictionsRequest {
  submoduleId: string;
  gameId: string;
}

interface EditPredictionsRequest {
  submoduleId: string;
  predictionId: string;
  body: ProjectionValueUpdateType[]
}

const projections = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    simulateProjections: builder.mutation<any, SimulateProjectionsPayload>({
      query: (payload) => ({
        url: apiRoutes.simulateProjections(payload.submoduleId),
        method: 'POST',
        body: payload.body
      })
    }),
    getPredictions: builder.query<any, GetPredictionsRequest>({
      query: ({ submoduleId, gameId }) => ({
        url: apiRoutes.getPredictions(submoduleId, gameId)
      })
    }),
    editProjections: builder.mutation<any, EditPredictionsRequest>({
      query: (payload) => ({
        url: apiRoutes.editProjections(payload.submoduleId, payload.predictionId),
        method: 'POST',
        body: payload.body
      })
    })
  })
});

export const {
  useSimulateProjectionsMutation,
  useLazyGetPredictionsQuery,
  useEditProjectionsMutation
} = projections;
