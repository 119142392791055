import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import { FC } from "react";

interface PreviewPDFDialogProps {
  open: boolean;
  handleClose: () => void;
}

const s3Url = `${process.env.REACT_APP_BUCKET_DOMAIN}/demo-dashboards/preview_nda.pdf`;

const PreviewPDFDialog: FC<PreviewPDFDialogProps> = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="send-nda-dialog"
      aria-describedby="send-nda-dialog"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "28px",
          width: "min(80%, 600px)",
          height: "max-content",
          padding: "1.5rem",
        },
      }}
    >
      <DialogContent
        sx={{
          p: 0,
        }}
      >
        <iframe
          title="pvx_nda_prvw"
          src={`${s3Url}#toolbar=0&navpanes=0&scrollbar=0`}
          width="100%"
          height="600px"
          style={{ border: "none" }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          padding: 0,
          pt: "1.5rem",
        }}
      >
        <Button
          onClick={handleClose}
          variant="contained"
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          sx={{
            borderRadius: "100px",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "0.625rem 1.5rem",
            textTransform: "none",
            fontWeight: 700,
            background: "#2F736E",
            color: "#fff",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewPDFDialog;
