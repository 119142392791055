import { FC, useState } from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { ReactComponent as PVXIcon } from "../../../../assets/icons/pvx-icon.svg";
import { RowData, RowDataItem } from "../../CustomDashboards/Benchmarks";
import BenchmarkingAttributesField from "./BenchmarkingAttributesField";
import Pagination from "../Pagination";
import { Loader } from "@components";

const headerWithGenre = [
  {
    text: "Spends",
    borderRadius: "0.5rem 0rem 0rem 0.5rem",
    borderRight: "1px solid #737F86",
    width: "auto",
  },
  {
    text: "vs. Genre",
    borderRadius: "0rem",
    borderRight: "1px solid #737F86",
    width: "50%",
  },
  {
    text: "vs. Category",
    borderRadius: "0rem 0.5rem 0.5rem 0rem",
    borderRight: "none",
    width: "50%",
  },
]

const headerWithoutGenre = [
  {
    text: "Spends",
    borderRadius: "0.5rem 0rem 0rem 0.5rem",
    borderRight: "1px solid #737F86",
    width: "auto",
  },
  {
    text: "vs Category",
    borderRadius: "0rem 0.5rem 0.5rem 0rem",
    width: "100%",
  }
];

const roas = [
  { id: "m1", label: "M1" },
  { id: "m3", label: "M3" },
  { id: "m6", label: "M6" }
]

type BenchmarkingTableProps = {
  rowData: RowData;
  enableToggleRoas: Boolean;
  setSelectedRoas?: React.Dispatch<React.SetStateAction<string>>;
  selectedRoas: string;
  showDefinitionSidePanel: () => void;
  subModuleId: string;
  isLoading: Record<string, boolean>;
  gameId: string;
}

const BenchmarkingTable: FC<BenchmarkingTableProps> = ({ rowData, enableToggleRoas, setSelectedRoas, showDefinitionSidePanel, subModuleId, isLoading, gameId, selectedRoas }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [toggleClick, setToggleClick] = useState<Record<number, boolean>>({ 0: true });

  const handleToggle = (index: number) => {
    setToggleClick((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[Number(key)] = false;
        return acc;
      }, {} as Record<number, boolean>);
      return {
        ...newState,
        [index]: true,
      };
    });
  };

  return (
    <>
      {rowData.data.length > 0 &&
        <Box
          sx={{
            width: "100%",
            height: "fit-content",
            borderRadius: "0.75rem",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "0.75rem",
            position: "relative"
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "0.25rem",
              position: "absolute",
              right: "1.125rem",
              top: isMobile ? "9.6rem" : "7.3rem"
            }}
          >
            <PVXIcon />
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 500,
                fontSize: "0.625rem",
                color: "#8E979D"
              }}
            >
              PvX Partners
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between"
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Manrope, sans-serif",
                fontWeight: 700,
                fontSize: "1.125rem",
                lineHeight: "1.375rem",
                minHeight: "1.875rem"
              }}
            >
              {rowData.header}
              <Button
                sx={{
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                  px: 0
                }}
                onClick={showDefinitionSidePanel}
              >
                <InfoOutlined style={{ height: "1rem", color: "#2F736E", padding: 0 }} />
              </Button>
            </Typography>
            <Box sx={{ display: "flex", alignItems: 'center' }}>
              {enableToggleRoas &&
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    paddingTop: isMobile ? "1rem" : "",
                    paddingRight: "0.8rem"
                  }}
                >
                  {roas.map((row, index) => (
                    <Button
                      key={index}
                      sx={{
                        border: "1px solid #E8EAEB",
                        borderRadius: "0.5rem",
                        backgroundColor: toggleClick[index] ? "#AECBD0" : "",
                        lineHeight: "1rem",
                        color: toggleClick[index] ? "#162936" : "#6C6C6C",
                        display: "flex",
                        gap: "0.25rem"
                      }}
                      onClick={() => { handleToggle(index); setSelectedRoas && setSelectedRoas(row.label); }}
                    >
                      {toggleClick[index] && <CheckIcon sx={{ height: "1rem", width: "1rem" }} />}
                      {row.label}
                    </Button>
                  ))}
                </Box>
              }
              <Pagination
                page={0}
                size={0}
                total={0}
                handleChange={(newPage) => null}
                fileName={rowData.header}
                chartId={rowData.id}
                submoduleId={subModuleId}
                filterSelection={{ gameId: gameId, period: selectedRoas }}
                disablePagination={true}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            {rowData.data.some(item => Array.isArray(item.vsGenre) && item.vsGenre.length > 0) ? headerWithGenre.map((header, index) => (
              <Box
                key={index}
                sx={{
                  height: "2.5rem",
                  borderRadius: header.borderRadius,
                  borderRight: header.borderRight,
                  backgroundColor: "#2F736E",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: index === 0 ? "flex-start" : "center",
                  padding: "1rem",
                  width: header.width
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: 700,
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    color: "#F6F8F9",
                  }}
                >
                  {header.text}
                </Typography>
              </Box>
            )) : (headerWithoutGenre.map((header, index) => (
              <Box
                key={index}
                sx={{
                  height: "2.5rem",
                  borderRadius: header.borderRadius,
                  borderRight: header.borderRight,
                  backgroundColor: "#2F736E",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: index === 0 ? "flex-start" : "center",
                  padding: "1rem",
                  width: header.width
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: 700,
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    color: "#F6F8F9",
                  }}
                >
                  {header.text}
                </Typography>
              </Box>
            )))}
          </Box>
          {isLoading ?
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '440px',
              }}>
              <Loader />
            </Box> :
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem"
              }}
            >
              {rowData.data
                .filter((d: any) => (d?.value || d?.vsCategory?.length > 0 || d?.vsGenre?.length > 0))
                .map((row: RowDataItem) => (
                  <Box key={row.label}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.25rem",
                        alignItems: "center",
                        marginBottom: "0.25rem"
                      }}
                    >
                      {row.label && (() => {
                        try {
                          return <img src={require(`../../../../assets/icons/${row.label.toLowerCase()}_logo.svg`)} alt="" style={{ width: "1rem", borderRadius: "2px" }} />;
                        } catch (error) {
                          return null;
                        }
                      })()}
                      <Typography
                        sx={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: 600,
                          fontSize: "0.875rem",
                          lineHeight: "1rem",
                          color: "#162936",
                        }}
                      >
                        {row.label}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.25rem"
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#F0F0F1",
                          minWidth: "4.79rem",
                          height: "4.375rem",
                          border: "2px solid #E8EAEB",
                          borderRadius: "0.5rem",
                          padding: "1rem 0.5rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Manrope, sans-serif",
                            fontWeight: 700,
                            fontSize: "0.75rem",
                            lineHeight: "1rem",
                            color: "#F6F8F9",
                            backgroundColor: "#2F736E",
                            border: "1px solid #162C36",
                            borderRadius: "0.5rem",
                            padding: "0.5rem 0.75rem",
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          {(row.value === null || parseFloat(row.value) < 0.01) ? "-" : row.value}
                        </Typography>
                      </Box>
                      {rowData.data.some(item => Array.isArray(item.vsGenre) && item.vsGenre.length > 0) && (
                        <BenchmarkingAttributesField boxData={row.vsGenre} title={row.label} minValue={row.minValue.genre} maxValue={row.maxValue.genre} />
                      )}
                      <BenchmarkingAttributesField boxData={row.vsCategory} title={row.label} minValue={row.minValue.category} maxValue={row.maxValue.category} />
                    </Box>
                  </Box>
                ))}
            </Box>}
        </Box>}
    </>
  )
}

export default BenchmarkingTable;