import { CombinedProjectionsResponse } from "../types";
import { Row } from "./FinancialProjectionsTable";

const formatCurrency = (value: any) => {
    const formattedValue = Math.abs(Number(value)).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return value < 0 ? `- $${formattedValue}` : `$${formattedValue}`;
};

export const processCombinedCohortData = (name: string, metadata: any, data: any) => {
    const meta = JSON.parse(metadata)
    let columns = data?.columns?.map((col: any, index: number) => ({ key: index === 0 ? 'rowLabel' : col, label: col }));

    const tableData: Row<{ rowLabel: string }>[] = data?.data?.map((row: CombinedProjectionsResponse) => {
        const children: Row<{ rowLabel: string }>[] = row?.children
            ? Object.entries(row.children).map(([key, value]) => ({
                id: key,
                rowLabel: key,
                ...Object.fromEntries(
                    Object.entries(value.rowData).map(([key, spendsValue]) => [key, formatCurrency(spendsValue)])
                ),
                children: []
            }))
            : [];

        return {
            id: row.type,
            rowLabel: row.key,
            ...Object.fromEntries(
                Object.entries(row?.rowData)?.map(([key, value]) => [key, formatCurrency(value)])
            ),
            children,
        };
    });
    return { mappedData: tableData, mappedColumns: columns, meta }
}