import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import {
  UserListPage,
  DashboardPage,
  UserDetailsPage
} from '../pages';
import { NAV_ROUTES, DEMO_NAV_ROUTES } from '../constants/navRoutes';
import { UserLayout } from '../layouts';
import GettingStarted from '../pages/Dashboard/GettingStartedDashboards/GettingStarted';
import GettingStartedBenchmarks from '../pages/Dashboard/GettingStartedDashboards/GettingStartedBenchmarks';
import GettingStartedDashboards from '../pages/Dashboard/GettingStartedDashboards';
import GettingStartedFinancialProjections from '../pages/Dashboard/GettingStartedDashboards/GettingStartedFinancialProjections';
import GettingStartedUnderwriting from '../pages/Dashboard/GettingStartedDashboards/GettingStartedUnderwriting';
import GettingStartedFundingEligibility from '../pages/Dashboard/GettingStartedDashboards/GettingStartedFundingEligibility';
import DashboardRequest from '../pages/Admin/DashboardRequests/DashboardRequests';
import GettingStartedDemo from '../pages/Demo/GettingStartedDashboards/GettingStarted';
import GettingStartedBenchmarksDemo from '../pages/Demo/GettingStartedDashboards/GettingStartedBenchmarks';
import GettingStartedDashboardsDemo from '../pages/Demo/GettingStartedDashboards';
import GettingStartedFinancialProjectionsDemo from '../pages/Demo/GettingStartedDashboards/GettingStartedFinancialProjections';
import GettingStartedUnderwritingDemo from '../pages/Demo/GettingStartedDashboards/GettingStartedUnderwriting';
import GettingStartedFundingEligibilityDemo from '../pages/Demo/GettingStartedDashboards/GettingStartedFundingEligibility';

const PrivateRoutes: FC = () => (
  <Routes>
    <Route path={NAV_ROUTES.USER_MANAGEMENT} element={<UserLayout />}>
      <Route path={NAV_ROUTES.USER_MANAGEMENT} element={<UserListPage />} />
      <Route path={NAV_ROUTES.USER_MANAGEMENT_INVITE} element={<UserDetailsPage />} />
      <Route path={NAV_ROUTES.USER_MANAGEMENT_DETAILS} element={<UserDetailsPage />} />
    </Route>
    <Route path={NAV_ROUTES.DASHBOARD_REQUESTS} element={<DashboardRequest />} />
    <Route path={DEMO_NAV_ROUTES.GETTING_STARTED} element={<GettingStartedDashboardsDemo />}>
      <Route index element={<GettingStartedDemo />} />
      <Route path={DEMO_NAV_ROUTES.GETTING_STARTED_UNDERWRITING} element={<GettingStartedUnderwritingDemo />} />
      <Route path={DEMO_NAV_ROUTES.GETTING_STARTED_BENCHMARKS} element={<GettingStartedBenchmarksDemo />} />
      <Route path={DEMO_NAV_ROUTES.GETTING_STARTED_FINANCIAL_PROJECTIONS} element={<GettingStartedFinancialProjectionsDemo />} />
      <Route path={DEMO_NAV_ROUTES.GETTING_STARTED_FUNDING_ELIGIBILITY} element={<GettingStartedFundingEligibilityDemo />} />
    </Route>
    <Route path={NAV_ROUTES.GETTING_STARTED} element={<GettingStartedDashboards />}>
      <Route index element={<GettingStarted />} />
      <Route path={NAV_ROUTES.GETTING_STARTED_UNDERWRITING} element={<GettingStartedUnderwriting />} />
      <Route path={NAV_ROUTES.GETTING_STARTED_BENCHMARKS} element={<GettingStartedBenchmarks />} />
      <Route path={NAV_ROUTES.GETTING_STARTED_FINANCIAL_PROJECTIONS} element={<GettingStartedFinancialProjections />} />
      <Route path={NAV_ROUTES.GETTING_STARTED_FUNDING_ELIGIBILITY} element={<GettingStartedFundingEligibility />} />
    </Route>
    <Route path={NAV_ROUTES.DASHBOARD} element={<DashboardPage />} />
    <Route path={NAV_ROUTES.DASHBOARD_MODULE} element={<DashboardPage />} />
    <Route path={NAV_ROUTES.DASHBOARD_MODULE_SUBMODULE} element={<DashboardPage />} />
    <Route path='*' element={<Navigate to={NAV_ROUTES.PAGE_NOT_FOUND} />} />
  </Routes>
);

export default PrivateRoutes;
