import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  APPLICATION_STORES,
  EMAIL_REGEX,
  MONTHLY_MARKETTING_SPENDS_OPTIONS,
  ATTRIBUTED_CHANNEL_OPTIONS,
} from "@constants/constants";
import useDebounce from "@hooks/useDebounce";
import { SignupLayout } from "../../../layouts";
import CustomInputField from "../../FundingCalculator/components/CustomInputField";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../FundingCalculator/components/CustomSelectField";
import GameDetails from './components/GameDetails';

const SignupPage: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Form State
  const [name, setName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [playstoreLink, setPlaystoreLink] = useState<string>("");
  const [appstoreLink, setAppstoreLink] = useState<string>("");
  const [avgMonthlyMarketingSpend, setAvgMonthlyMarketingSpend] = useState<string>("");
  const [acceptDataAccessTerms, setAcceptDataAccessTerms] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const [attributedChannel, setAttributedChannel] = useState<string>("");
  const [isCreatingUser, setCreatingUser] = useState(false);

  const [playStoreApp, setPlayStoreApp] = useState<{
    marketPlace: (typeof APPLICATION_STORES)[keyof typeof APPLICATION_STORES];
    appUrl: string;
    name: string;
    icon: string;
    developerName: string;
    rating: number;
    reviews: string;
    downloads: string;
  } | null>(null);

  const [appStoreApp, setAppStoreApp] = useState<{
    marketPlace: (typeof APPLICATION_STORES)[keyof typeof APPLICATION_STORES];
    appUrl: string;
    name: string;
    icon: string;
    developerName: string;
    rating: number;
    reviews: string;
  } | null>(null);

  const [isUserCreated, setIsUserCreated] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Error State
  const [errorState, setErrorState] = useState({
    name: false,
    companyName: false,
    email: false,
    playstoreLink: false,
    appstoreLink: false,
    avgMonthlyMarketingSpend: false,
  });

  const [touched, setTouched] = useState({
    name: false,
    companyName: false,
    email: false,
    playstoreLink: false,
    appstoreLink: false,
    avgMonthlyMarketingSpend: false,
  });

  useEffect(() => {
    validateForm();
  }, [email, playstoreLink, appstoreLink, companyName, name, avgMonthlyMarketingSpend]);

  useEffect(() => {
    if (playStoreApp === null && appStoreApp === null) {
      setCompanyName("");
    } else if (!!appStoreApp) {
      setCompanyName(appStoreApp.developerName);
    } else if (!!playStoreApp) {
      setCompanyName(playStoreApp.developerName);
    }
  }, [playStoreApp, appStoreApp]);


  const getPlaystoreLinkLabel = () =>
    errorState.playstoreLink && playstoreLink
      ? "INVALID PLAY STORE LINK"
      : "PLAY STORE LINK";

  const getAppstoreLinkLabel = () =>
    errorState.appstoreLink && appstoreLink
      ? "INVALID APP STORE LINK"
      : "APP STORE LINK";

  const fetchApplicationDetails = async (
    appUrl: string,
    store: (typeof APPLICATION_STORES)[keyof typeof APPLICATION_STORES]
  ) => {
    if (!appUrl) return;

    const appstoreDetails = {
      marketPlace: APPLICATION_STORES.APP_STORE,
      appUrl: "https://apps.apple.com/in/app/puzzlerpro/id99309054",
      name: "Puzzler Pro",
      icon: `${process.env.REACT_APP_BUCKET_DOMAIN}/app/icons/PvXPuzzlerIcon.png`,
      developerName: "PvX Puzzler Pro LLC",
      rating: 4.5,
      reviews: "",
      downloads: "1M+",
    };

    setAppStoreApp(appstoreDetails);

    const playstoreDetails = {
      marketPlace: APPLICATION_STORES.PLAY_STORE,
      appUrl: "https://play.google.com/store/apps/details?id=com.pvx.puzzlerpro&hl=en_IN",
      name: "Puzzler Pro",
      icon: `${process.env.REACT_APP_BUCKET_DOMAIN}/app/icons/PvXPuzzlerIcon.png`,
      developerName: "PvX Puzzler Pro LLC",
      rating: 4.5,
      reviews: "",
      downloads: "1M+",
    };
    setPlayStoreApp(playstoreDetails);
  };

  const handleAppStoreLinkChange = (value: string) => {
    setAppstoreLink(value);

    if (value.length === 0) {
      setAppStoreApp(null);
      return;
    }

    const isValid = true;

    if (isValid) {
      handleFetchApplicationDetails(value, APPLICATION_STORES.APP_STORE);
    }
  };

  const handlePlayStoreLinkChange = (value: string) => {
    setPlaystoreLink(value);

    if (value.length === 0) {
      setPlayStoreApp(null);
      return;
    }

    const isValid = true;

    if (isValid) {
      handleFetchApplicationDetails(value, APPLICATION_STORES.PLAY_STORE);
    }
  };

  const handleAttributedChannelChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAttributedChannel(event.target.value);
  };

  const validateForm = (): boolean => {
    const isFieldEmpty = (field: string) => field.trim().length === 0;
    const isBothLinksEmpty = isFieldEmpty(playstoreLink) && isFieldEmpty(appstoreLink);
    const isPlaystoreValid = true;
    const isAppstoreValid = true;

    const newErrorState = {
      ...errorState,
      name: isFieldEmpty(name),
      companyName: isFieldEmpty(companyName),
      email: isFieldEmpty(email) || !EMAIL_REGEX.test(email),
      playstoreLink: isBothLinksEmpty || (playstoreLink.length > 0 && !isPlaystoreValid),
      appstoreLink: isBothLinksEmpty || (appstoreLink.length > 0 && !isAppstoreValid),
    };

    setErrorState(newErrorState);
    const isValid = !Object.values(newErrorState).some(Boolean);
    setIsFormValid(isValid);

    return isValid;
  };


  const registerUser = async () => {
    const isFormValid = validateForm();
    if (!isFormValid) return;

    setCreatingUser(true);

    setTimeout(() => {
      setIsUserCreated(true);
      setCreatingUser(false);
    }, 1000)
  };

  const handleFetchApplicationDetails = useDebounce(fetchApplicationDetails, 800);

  return (
    <SignupLayout>
      <Box
        sx={{
          paddingTop: "2rem",
          display: "grid",
          gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
          gap: "1rem",
        }}
      >
        <CustomInputField
          label="WORK EMAIL ADDRESS"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          onBlur={() => setTouched({ ...touched, email: true })}
          error={errorState.email && touched.email}
          disabled={isUserCreated}
        />

        <CustomInputField
          label="FULL NAME"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          onBlur={() => setTouched({ ...touched, name: true })}
          error={errorState.name && touched.name}
          disabled={isUserCreated}
        />

        <CustomSelect
          label="HOW DID YOU HEAR ABOUT US? (OPTIONAL)"
          value={attributedChannel}
          onChange={handleAttributedChannelChange}
          options={ATTRIBUTED_CHANNEL_OPTIONS}
          error={false}
          disabled={isUserCreated}
        />

        <CustomInputField
          label={getPlaystoreLinkLabel()}
          value={playstoreLink}
          onChange={(e: any) => handlePlayStoreLinkChange(e.target.value)}
          onBlur={() => setTouched({ ...touched, playstoreLink: true })}
          error={errorState.playstoreLink && touched.playstoreLink}
          disabled={isUserCreated}
        />

        <CustomInputField
          label={getAppstoreLinkLabel()}
          value={appstoreLink}
          onChange={(e: any) => handleAppStoreLinkChange(e.target.value)}
          onBlur={() => setTouched({ ...touched, appstoreLink: true })}
          error={errorState.appstoreLink && touched.appstoreLink}
          disabled={isUserCreated}
        />

        <CustomInputField
          label="COMPANY NAME"
          value={companyName ? "PvX Puzzler Pro LLC" : ""}
          disabled={true}
          onChange={(e: any) => setCompanyName(e.target.value)}
          onBlur={() => setTouched({ ...touched, companyName: true })}
          error={errorState.companyName && touched.companyName}
        />

        <CustomSelect
          label="MONTHLY MARKETTING SPENDS (OPTIONAL)"
          value={avgMonthlyMarketingSpend}
          onChange={(e: any) => setAvgMonthlyMarketingSpend(e.target.value)}
          onBlur={() => setTouched({ ...touched, avgMonthlyMarketingSpend: true })}
          options={MONTHLY_MARKETTING_SPENDS_OPTIONS}
          error={errorState.avgMonthlyMarketingSpend && touched.avgMonthlyMarketingSpend}
          disabled={isUserCreated}
        />
      </Box>

      <Box
        sx={{
          marginTop: "2rem",
          backgroundColor: "#162936",
          padding: "1.5rem",
          borderRadius: "2rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
        }}
      >
        <Stack
          gap={isMobile ? "1.5rem" : "1rem"}
          direction={isMobile ? "column" : "row"}
          sx={{
            flexWrap: "wrap"
          }}
        >
          {playstoreLink && playStoreApp && <GameDetails applicationDetails={playStoreApp} />}

          {appstoreLink && appStoreApp && <GameDetails applicationDetails={appStoreApp} />}
        </Stack>

        {
          isUserCreated
            ?
            <>
              <Stack direction={'row'} alignItems={'center'} gap={'1.25rem'}>
                <Box borderRadius={"50%"} minHeight={66} minWidth={66} sx={{
                  backgroundColor: "#2E4A4F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  {
                    isUserCreated
                      ?
                      <CheckIcon sx={{ color: "#7FBCAD" }} />
                      :
                      <ErrorOutlineIcon sx={{ color: "#7FBCAD" }} />
                  }
                </Box>

                <Stack>
                  <Typography fontSize={isMobile ? '1.25rem' : '1.375rem'} lineHeight={'1.875rem'} fontWeight={700} color={"#F6F8F9"}>
                    {
                      isUserCreated
                        ?
                        "Account Created Successfully!"
                        :
                        `Hey ${name}, your mail id is already registered with us.`
                    }
                  </Typography>
                  <Typography fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={'1.375rem'} fontWeight={700} color={'#B4B4B4'}>
                    {
                      isUserCreated
                        ?
                        `Invitation sent to ${email}`
                        :
                        `Please sign in using ${email[0]}${Array(email.split('@')[0].length - 1).fill("*").join('')}@${email.split('@')[1]}`
                    }
                  </Typography>
                </Stack>
              </Stack>

              <Button
                variant="contained"
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => navigate("/auth")}
                sx={{
                  width: isMobile ? "100%" : "315px",
                  maxWidth: "315px",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderRadius: "1.5rem",
                  fontSize: "1.375rem",
                  fontWeight: 700,
                  lineHeight: "1.875rem",
                  textTransform: "none",
                }}>
                Sign In
              </Button>
            </>
            : <>
              <Typography
                fontSize={"1rem"}
                fontWeight={700}
                lineHeight={"1.375rem"}
                color={"#F6F8F9"}
              >
                Sign up to get access to PvX Capital and data intelligence platform
              </Typography>

              <Button
                variant="contained"
                onClick={registerUser}
                disabled={
                  isCreatingUser ||
                  (!acceptTermsAndConditions || !acceptDataAccessTerms || !isFormValid)
                }
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                sx={{
                  width: isMobile ? "100%" : "315px",
                  maxWidth: "315px",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderRadius: "1.5rem",
                  fontSize: "1.375rem",
                  fontWeight: 700,
                  lineHeight: "1.875rem",
                  textTransform: "none",
                  ":disabled": {
                    backgroundColor: "#2E4A4F",
                  },
                }}
              >
                {isCreatingUser ? (
                  <>
                    <CircularProgress
                      size={"1.375rem"}
                      sx={{
                        marginRight: "1rem",
                      }}
                    />
                    <Typography
                      fontSize={"1.375rem"}
                      lineHeight={"1.875rem"}
                      fontWeight={700}
                      color={"#F6F8F9"}
                    >
                      Creating Account...
                    </Typography>
                  </>
                ) : (
                  <>Sign Up</>
                )}
              </Button>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "10px",
                  width: "90%",
                  fontSize: "15px",
                  color: false ? "#F6F8F9" : "#F6F8F999",
                  lineHeight: "21px",
                  fontWeight: 550,
                  letterSpacing: "-0.6px",
                }}
              >
                <Checkbox
                  value={acceptDataAccessTerms}
                  onChange={(e: any) => setAcceptDataAccessTerms(e.target.checked)}
                  sx={{
                    padding: "0px",
                    color: "#2F736E",
                    "&.Mui-checked": {
                      color: "#2F736E",
                    },
                  }}
                />
                <Typography
                  fontSize={"0.75rem"}
                  fontWeight={500}
                  lineHeight={"1rem"}
                  color={"#E4E3DBC2"}
                >
                  I hereby represent that I possess the necessary authority to share
                  access to data for the purpose of securing a funding facility from
                  PvX Partners
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "10px",
                  width: "90%",
                  fontSize: "15px",
                  color: false ? "#F6F8F9" : "#F6F8F999",
                  lineHeight: "21px",
                  fontWeight: 550,
                  letterSpacing: "-0.6px",
                }}
              >
                <Checkbox
                  value={acceptTermsAndConditions}
                  onChange={(e: any) => setAcceptTermsAndConditions(e.target.checked)}
                  sx={{
                    padding: "0px",
                    color: "#2F736E",
                    "&.Mui-checked": {
                      color: "#2F736E",
                    },
                  }}
                />
                <Typography
                  fontSize={"0.75rem"}
                  fontWeight={500}
                  lineHeight={"1rem"}
                  color={"#E4E3DBC2"}
                >
                  I agree to the PvX Partners <a
                    href="https://pvxpartners.com/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#E4E3DBC2", textDecoration: "underline" }}
                  >Terms & Conditions</a>
                </Typography>
              </Box>
            </>
        }
      </Box>
    </SignupLayout>
  );
};

export default SignupPage;
