import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "@stores/store";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TrendingUp } from "@mui/icons-material";
import { Drawer } from "@components";
import { ProjectionDataPoint } from "@stores/FinancialProjections/types/financialProjections";
import { formatCurrency } from "@utils/common";
import FundingRequestField from "../FundingDetails/FundingRequestField";

const headers = [
  { label: "Elapsed Month", align: "start" },
  { label: "Transaction Month", align: "start" },
  { label: "Revenue", align: "end" },
  { label: "ROAS", align: "end" },
];

interface CohortDetailsSidePanelProps {
  showPanel: boolean;
  setShowPanel: React.Dispatch<React.SetStateAction<boolean>>;
  cohortDetails: { cohort: string, marketingSpends: string };
  isSimulation: boolean
}

const CohortDetailsSidePanel: React.FC<CohortDetailsSidePanelProps> = ({
  showPanel,
  setShowPanel,
  cohortDetails,
  isSimulation
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const projectionsChartData = useSelector((state: RootState) =>
    isSimulation ? state?.FinancialProjectionsData?.financialProjections?.simulationsChartData
      : state?.FinancialProjectionsData?.financialProjections?.chartData)

  const filteredData = projectionsChartData?.rows
    ?.filter((item: ProjectionDataPoint) => item.cohort === cohortDetails.cohort)
    ?.map((item: ProjectionDataPoint) => {
      const cohortDate = new Date(item.cohort);
      cohortDate.setMonth(cohortDate.getMonth() + (item.elapsedMonth - 1));
      const transactionMonth = cohortDate.toISOString().slice(0, 7);
      return {
        elapsedMonth: item.elapsedMonth,
        isPredicted: item.isPredicted === 'true',
        transactionMonth,
        revenue: `${formatCurrency(Math.round(item.revenue))}`,
        roas: `${Math.round(item.roas * 100)}%`,
      };
    }) || [];

  filteredData.sort((pointA, pointB) =>
    pointA.elapsedMonth - pointB.elapsedMonth
  )

  return (
    <Drawer
      open={showPanel}
      anchor="right"
      close={() => {
        setShowPanel(false);
      }}
      height="98vh"
      zIndex={9999}
      backgroundColor="#F0F0F1"
    >
      <Box
        sx={{
          padding: "1.5rem",
          width: isMobile ? "100%" : '600px',
          marginBottom: "15px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.375rem",
            fontWeight: 700,
            lineHeight: "1.875rem",
            color: "#6C6C6C",
            marginBottom: "1.875rem",
          }}
        >
          RoAS/Revenue by Elapsed Months
          <Button
            sx={{ padding: 0, minWidth: "fit-content" }}
            onClick={() => setShowPanel(false)}
          >
            <CloseIcon sx={{ color: "#162C36" }} />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            marginBottom: "2rem",
            justifyContent: 'space-between'
          }}
        >
          <FundingRequestField name={"Cohort Month"} value={cohortDetails.cohort} />
          <Box sx={{ textAlign: 'right' }}>
            <FundingRequestField
              name={"UA Spends"}
              value={formatCurrency(Math.round(Number(cohortDetails.marketingSpends)))} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.313rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "0.75rem",
              borderRadius: "0.75rem",
              backgroundColor: "#FFFFFF",
            }}
          >
            {headers.map((header, idx) => (
              <Typography
                key={idx}
                sx={{
                  fontWeight: 700,
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  alignItems: "center",
                  width: "25%",
                  display: "flex",
                  justifyContent: header.align,
                }}
              >
                {header.label}
              </Typography>
            ))}
          </Box>
          {filteredData.map((row: any, index: any) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                padding: "0.5rem 0.75rem",
                borderRadius: "0.75rem",
                backgroundColor: "#FFFFFF",
                ":hover": {
                  boxShadow: "0px 1px 2px 1px #0000004D",
                },
              }}
            >
              {Object.keys(row).map((key, idx) => {
                if (key === "elapsedMonth") {
                  return (
                    <Typography
                      key={idx}
                      sx={{
                        fontWeight: 700,
                        fontSize: "0.875rem",
                        lineHeight: "1.25rem",
                        alignItems: "center",
                        color: "#989CA2",
                        width: "25%",
                        display: "flex",
                        justifyContent: "start",
                        gap: "8px",
                      }}
                    >
                      {`M${row[key as keyof typeof row]}`}
                      {row.isPredicted && (
                        <span
                          style={{
                            marginLeft: "0.5rem",
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <TrendingUp
                            sx={{
                              color: "#AECBD0",
                            }}
                          />
                        </span>
                      )}
                    </Typography>
                  );
                }
                if (key === "isPredicted") {
                  return null;
                }

                return (
                  <Typography
                    key={idx}
                    sx={{
                      fontWeight: 700,
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                      alignItems: "center",
                      color: "#989CA2",
                      width: "25%",
                      display: "flex",
                      justifyContent:
                        key === "revenue" || key === "roas" ? "end" : "start",
                    }}
                  >
                    {row[key as keyof typeof row] || '-'}
                  </Typography>
                );
              })}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          position: "sticky", bottom: 0,
          paddingX: "10px", paddingY: '0px', backgroundColor: "#F0F0F1",
          justifyContent: "flex-end", borderTop: "1px solid #E8EAEB"
        }}
      >
        <Box sx={{
          display: 'flex', alignItems: 'center', borderRadius: '4px', border: '1px solid #D8DBDD',
          color: '#6C6C6C', fontWeight: '500', fontSize: '0.75rem', lineHeight: '1rem', paddingX: '6px', paddingY: '2px',
          width: 'fit-content', margin: "0.625rem 0.875rem 0.5rem 0rem"
        }}>
          <TrendingUp
            sx={{
              color: "#7FBCAD",
            }}
          />
          : Based on our Projections
        </Box>
      </Box>
    </Drawer>
  );
};

export default CohortDetailsSidePanel;
