import { FC, useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PreviewPDFDialog from "./PreviewPDFDialog";
import SignNDAForm from "./SignNDAForm";

const label = {
  companyName: "Company Name",
  signingAuthorityName: "Signing Authority",
  companyAddress: "Company Address",
  signingAuthorityEmail: "Signing Authority's Email",
  signingAuthorityTitle: "Signing Authority's Title",
}

interface SignNDAProps {
  ndaDetails: any;
  setNDADetails: (value: any) => void;
  successSigningNDA: boolean;
  setSuccessSigningNDA: (value: boolean) => void;
  openNDAForm: boolean;
  setOpenNDAForm: (value: boolean) => void;
}

const SignNDA: FC<SignNDAProps> = ({ ndaDetails, setNDADetails, successSigningNDA, setSuccessSigningNDA, openNDAForm, setOpenNDAForm }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [openPreviewPDF, setOpenPreviewPDF] = useState<boolean>(false);

  const handleSaveNDAForm = (payload: any) => {
    setNDADetails(payload);
    setOpenNDAForm(false);
  };

  return (
    <>
      {!!ndaDetails ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              rowGap: "12px",
            }}
          >
            {Object.keys(ndaDetails).map((key: string) => (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  gap: "0.25rem",
                  flexDirection: "column",
                  flexBasis: "50%",
                }}
              >
                <Typography
                  sx={{
                    color: "#6C6C6C",
                    fontWeight: 700,
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                  }}
                >
                  {label[key as keyof typeof label]}
                </Typography>
                <Typography
                  sx={{
                    color: "#6C6C6C",
                    fontWeight: 700,
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                  }}
                >
                  {ndaDetails[key]}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              marginTop: "16px",
              borderRadius: "8px",
              paddingX: "0.75rem",
              paddingY: "1.25rem",
              background: "#2F736E14",
              border: "1px solid #2F736E1F",
              display: "flex",
              alignItems: "center",
            }}
          >
            {successSigningNDA ? (
              <>
                <CheckCircleIcon
                  sx={{
                    color: "#2F736E",
                  }}
                />

                <Typography
                  fontSize={"0.875rem"}
                  lineHeight={"1.25rem"}
                  fontWeight={700}
                  color={"#162936"}
                  ml="0.5rem"
                >
                  NDA Signature Completed
                </Typography>
              </>
            ) : (
              <>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                      letterSpacing: "-0.35px",
                      color: "#162936",
                    }}
                  >
                    Current Status: Pending Signature
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                      letterSpacing: "-0.35px",
                      color: "#6C6C6C",
                    }}
                  >
                    {`NDA has been sent via DocuSign to ${ndaDetails['signingAuthorityEmail']}`}
                  </Typography>
                </Box>
                <Box
                  onClick={() => setSuccessSigningNDA(true)}
                  sx={{
                    width: 32,
                    height: 32,
                    minWidth: 32,
                    borderRadius: "50%",
                    background: "#AECBD0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ml: "1.5rem",
                  }}
                >
                  <ReplayIcon
                    sx={{
                      height: 20,
                      width: 20,
                      color: "#2F736E",
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      ) : (
        <Stack gap={"1rem"}>
          {/* Preview info */}
          <Stack
            sx={{
              pb: "0.875rem",
              borderRadius: "0.5rem",
            }}
          >
            <Stack
              direction={isMobile ? "column" : "row"}
              justifyContent={"space-between"}
              alignItems={isMobile ? "flex-start" : "center"}
              gap={"0.5rem"}
            >
              <Typography
                fontSize={"0.875rem"}
                lineHeight={"1.25rem"}
                fontWeight={500}
                color={"#6C6C6C"}
              >
                Before we accept any kind of sensitive data, we would like an
                NDA to be signed to ensure that we are properly authorised to do
                so.
              </Typography>

              <Button
                onClick={() => setOpenPreviewPDF(true)}
                startIcon={<PictureAsPdfOutlinedIcon />}
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                sx={{
                  fontSize: "0.875rem",
                  lineHeight: "1.25rem",
                  fontWeight: "700",
                  padding: "0.625rem 1rem",
                  textTransform: "none",
                  color: "#51B8B0",
                  pl: isMobile ? "0" : "1rem",
                  ":hover": {
                    background: "none",
                  },
                }}
              >
                Preview
              </Button>
            </Stack>
          </Stack>

          {/* Actions */}
          <Box>
            <Button
              onClick={() => setOpenNDAForm(true)}
              variant="contained"
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              sx={{
                borderRadius: "100px",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                padding: "0.625rem 1.5rem",
                textTransform: "none",
                fontWeight: 700,
                background: "#2F736E",
                color: "#fff",
              }}
            >
              Proceed
            </Button>
          </Box>
        </Stack>
      )}

      {
      openNDAForm && 
      <SignNDAForm
        open={openNDAForm}
        close={() => setOpenNDAForm(false)}
        save={handleSaveNDAForm}
        details={ndaDetails}
      />
      }

      <PreviewPDFDialog
        open={openPreviewPDF}
        handleClose={() => setOpenPreviewPDF(false)}
      />
    </>
  );
};

export default SignNDA;
