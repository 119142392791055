import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@stores/store";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { CustomDropdown } from "@components";
import { useLazyGetUserModuleGamesQuery } from "@api/userModules";
import useCustomDashboardFilters from "@hooks/api/useCustomDashboardFilters";
import { setDashboardFilter } from "@stores/DashboardFilters/slice";

interface Filter {
  key: string;
  label: string;
  values: { id: string; label: string, category: string, genre: string }[];
}

interface BenchmarkingFiltersProps {
  id: string;
  onFilterChange: (selections: Record<string, string>) => void;
  showDefinitionSidePanel: () => void;
}

const BenchmarkingFilters: FC<BenchmarkingFiltersProps> = ({
  id,
  onFilterChange,
  showDefinitionSidePanel,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const dashboardFilters = useSelector(
    (state: RootState) => state?.DashboardFiltersData
  );
  const modules = useSelector((state: RootState) => state?.ModulesData);

  const [filters, setFilters] = useState<Filter[]>([]);
  const [selections, setSelections] = useState<Record<string, string>>({});
  const [gameData, setGameData] = useState<Record<string, string>>({});

  const { getDashboardFilterValues } = useCustomDashboardFilters();
  const [getUserModuleGames] = useLazyGetUserModuleGamesQuery();

  // Hook to fetch dashboard data on load
  useEffect(() => {
    if (id) {
      getDashboardFilterValues(id).then(async (response) => {
        if (response) {
          const gamesParam = response?.game_id?.values || [];
          const deals = await getUserModuleGames({ submoduleId: id, gamesParam }).unwrap();
          const filterData: any = Object.values(response).map((res: any) => ({
            label: res.displayName,
            key: res.name,
            values: res.values.map((v: string) => ({
              id: v,
              label: deals?.data.find((d: any) => d.gameId === v)?.name || v,
              category:
                deals?.data.find((d: any) => d.gameId === v)?.category || v,
              genre:
                deals?.data.find((d: any) => d.gameId === v)?.genre || v,
            })).sort((a: any, b: any) => a.label.localeCompare(b.label)),
          }));

          setFilters(filterData);

          let selectedGameId = "";

          if (
            Object.keys(dashboardFilters?.dashboards).length &&
            dashboardFilters.dashboards["Benchmarks" as any] &&
            Object.keys(dashboardFilters.dashboards["Benchmarks" as any]).length
          ) {
            selectedGameId = dashboardFilters.dashboards["Benchmarks" as any].gameId;
            setSelections(dashboardFilters.dashboards["Benchmarks" as any]);
            onFilterChange(dashboardFilters.dashboards["Benchmarks" as any]);
          } else if (Object.keys(selections).length === 0) {
            const defaultFilterId = modules?.modules?.flatMap((m: any) => m.submodules)?.find((s: any) => s.id === id)?.defaultFilter;
            selectedGameId = defaultFilterId || filterData.find((f: any) => f.key === "gameId")?.values?.[0]?.id;

            setSelections({ gameId: defaultFilterId });
            onFilterChange({ gameId: defaultFilterId });
          }

          setGameData({
            category: filterData.find((f: any) => f.key === "gameId")?.values.find((v: any) => v.id === selectedGameId)?.category,
            genre: filterData.find((f: any) => f.key === "gameId")?.values.find((v: any) => v.id === selectedGameId)?.genre,
          })
        }
      });
    }
  }, [id]);


  // Hook to update dashboard filter values to store
  useEffect(() => {
    if (selections) {
      dispatch(setDashboardFilter({ Benchmarks: selections }));
    }
  }, [selections]);

  // Handle filter selection changes
  const handleFilterChange = (key: string, selectedValue: string) => {
    let updatedSelections = { ...selections, [key]: selectedValue };
    if (key === "gameId") {
      const gameFilter = filters.find((f) => f.key === "gameId");
      const selectedGame = gameFilter?.values.find((v) => v.id === selectedValue);

      if (selectedGame) {
        setGameData({
          category: selectedGame.category,
          genre: selectedGame.genre,
        });
      }
    }
    setSelections(updatedSelections);
    onFilterChange(updatedSelections);
  };

  return (
    <Box
      sx={{
        padding: "0.8rem",
        backgroundColor: "white",
        borderRadius: "0.8rem",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "0.75rem",
          paddingBottom: isMobile ? "0.5rem" : "",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem", px: "0.3rem" }}>
          <Typography
            sx={{
              color: "#162936",
              fontWeight: 600,
              fontSize: "2rem",
            }}
          >
            Benchmarks: {filters[0]?.values.length === 1 ? filters[0]?.values[0].label : ""}
          </Typography>
          {filters.map((filter) => (
            filter.values.length > 1 ? <CustomDropdown
              key={filter.key}
              selectedOption={selections[filter.key]}
              dropdownOptions={filter.values}
              handleChange={(event) =>
                handleFilterChange(filter.key, event.target.value)
              }
              wrapperSx={{
                color: "#162936",
                fontWeight: 600,
                fontSize: "1.5rem",
                display: "flex",
                alignItem: "center",
                paddingTop: "1rem",
              }}
            /> : <></>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: 700,
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              color: "#6C6C6C",
              paddingRight: "0.75rem"
            }}
          >
            $ = USD
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "0.25rem"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", px: "0.4rem" }}>
          {gameData["category"] && (<Typography
            sx={{
              color: "#6C6C6C",
              fontWeight: 600,
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
            }}
          >
            Category: {gameData["category"]}
          </Typography>)}
          <CircleIcon sx={{ height: "0.3rem", width: "0.3rem", mx: "0.3rem", color: "#6C6C6C" }} />
          {gameData["genre"] && (<Typography
            sx={{
              color: "#6C6C6C",
              fontWeight: 600,
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
            }}
          >
            Genre: {gameData["genre"]}
          </Typography>)}
        </Box>
        <Button
          sx={{
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            px: 0,
            paddingRight: "0.75rem"
          }}
          onClick={showDefinitionSidePanel}
        >
          <InfoOutlined style={{ height: "1rem", color: "#2F736E", padding: 0 }} />
          <Typography
            sx={{
              color: "#51B8B0",
              fontFamily: "Manrope, sans-serif",
              fontWeight: 700,
              fontSize: "0.85rem",
              lineHeight: "1rem",
            }}
          >
            Definitions
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default BenchmarkingFilters;
