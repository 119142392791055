import { FC } from "react";
import { APPLICATION_STORES } from "@constants/constants";
import { useTheme, useMediaQuery, Box, Typography } from "@mui/material";
import playStoreIcon from "../../../../assets/icons/play-store.png";
import appStoreIcon from "../../../../assets/icons/app-store.png";
import starIcon from "../../../../assets/icons/star.svg";

interface GameDetailsProps {
    applicationDetails: {
      appUrl: string;
      name: string;
      downloads?: string;
      reviews: string;
      icon: string;
      developerName: string;
      rating: number;
      marketPlace: (typeof APPLICATION_STORES)[keyof typeof APPLICATION_STORES];
    };
}

const GameDetails: FC<GameDetailsProps> = ({ applicationDetails }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: isMobile ? "1rem" : "none",
        justifyContent: "space-between",
        backgroundColor: "#2F736E1F",
        border: "1px solid #3D4C56",
        borderRadius: "0.75rem",
        padding: "0.75rem",
        ...(isMobile ? { width: "100%" } : { flex: 1 })
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            height: "52px",
            minWidth: "52px",
            borderRadius: "0.375rem",
            overflow: "hidden",
          }}
        >
          <img
            alt="app-icon"
            style={{
              height: "100%",
              width: "100%",
            }}
            src={applicationDetails.icon}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.25rem",
            overflow: "hidden",
          }}
        >
          <Typography
            fontSize={"1rem"}
            lineHeight={"1.375rem"}
            fontWeight={700}
            color={"#F6F8F9"}
            minWidth={!isMobile ? 200: 0}
            sx={{
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "2",
              overflow: "hidden",
            }}
          >
            {applicationDetails.name}
          </Typography>
          <Typography
            fontSize={"0.75rem"}
            lineHeight={"1rem"}
            fontWeight={700}
            color={"#B4B4B4"}
          >
            {applicationDetails.developerName}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "1.5rem",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.25rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              ...(!isMobile && {
                justifyContent: "flex-end"
              }),
            }}
          >
            <img
              src={
                applicationDetails.marketPlace === APPLICATION_STORES.PLAY_STORE
                  ? playStoreIcon
                  : appStoreIcon
              }
              alt="play-store-icon"
              height={24}
              width={24}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.25rem",
              }}
            >
              <Typography
                fontSize={"14px"}
                lineHeight={"20px"}
                fontWeight={700}
                color={"#F6F8F9"}
              >
                {applicationDetails.rating}
              </Typography>
              <img height={16} width={16} src={starIcon} alt="rating-star" />
            </Box>
          </Box>
          {applicationDetails.marketPlace === APPLICATION_STORES.PLAY_STORE &&
            applicationDetails.downloads && (
              <Box>
                <Typography
                  fontSize={"0.75rem"}
                  lineHeight={"1rem"}
                  fontWeight={700}
                  color={"#B4B4B4"}
                  textAlign={!isMobile ? "right" : "left"}
                >
                  {applicationDetails.downloads} Downloads
                </Typography>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default GameDetails;