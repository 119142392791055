import { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SignupSideNav from "../pages/SignupPage/components/SignupSideNav";
import underwritingIcon from "../assets/icons/underwriting.svg";
import tourIcon from "../assets/icons/tour.svg";
import invoiceIcon from "../assets/icons/invoice.svg";
import analyticsIcon from "../assets/icons/analytics.svg";

interface SignupLayoutProps {
  children?: ReactNode;
}

const SignupLayout: FC<SignupLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const displayFeatures = [
    {
      description: "Share your aggregated data securely via your MMP",
      icon: underwritingIcon,
    },
    {
      description: "Benchmark your ROAS performance against peers in your category",
      icon: tourIcon,
    },
    {
      description:
        "Check your eligibility for PvX Capital",
      icon: invoiceIcon,
    },
    {
      description:
        "Get access to our ROAS Model to project your revenues into the future",
      icon: analyticsIcon,
    },
  ];

  return (
    <Box
      style={{
        height: "100vh",
        width: "100vw",
        paddingLeft: "1.25rem",
        display: "flex",
        gap: "2.1875rem",
        backgroundColor: "#F9F9F9",
      }}
      component="main"
    >
      {!isMobile && (
        <Box sx={{ paddingBottom: "1.25rem", paddingTop: "1.25rem" }}>
          <SignupSideNav />
        </Box>
      )}

      <Box
        sx={{
          flex: 1,
          overflow: "scroll",
          gap: "2rem",
          paddingBottom: "1.25rem",
          paddingRight: "1.25rem",
          paddingTop: "1.25rem",
        }}
      >
        <Stack sx={{ marginBottom: "2rem" }}>
          <Stack
            direction="row"
            alignItems="start"
            justifyContent="space-between"
          >
            <Typography
              fontSize="2.8125rem"
              fontWeight={700}
              lineHeight="3.875rem"
              color="#162936"
              component="span"
            >
              Start your{" "}
              <Typography
                fontSize="2.8125rem"
                fontWeight={700}
                lineHeight="3.875rem"
                color="#989CA2"
                component="span"
              >
                application
              </Typography>
            </Typography>

            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              endIcon={<CloseIcon />}
              href="https://pvxpartners.com/"
              sx={{
                textTransform: "none",
                padding: 0,
                fontSize: "0.9375rem",
                lineHeight: "1.21875rem",
                fontWeight: 550,
                color: "#989CA2",
                ":hover": { backgroundColor: "rgba(0,0,0,0)" },
              }}
            >
              Close
            </Button>
          </Stack>

          <Stack direction="row" alignItems="center" spacing="0.25rem">
            <Typography
              fontSize="1rem"
              lineHeight="1.375rem"
              fontWeight={500}
              color="#162936"
            >
              Already have an account?
            </Typography>
            <Button
              href="/auth"
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
                fontSize: "1rem",
                lineHeight: "1.375rem",
                fontWeight: 700,
                textDecoration: "underline",
                color: "#51B8B0",
                ":hover": { backgroundColor: "rgba(0,0,0,0)" },
              }}
            >
              Sign In
            </Button>
          </Stack>
        </Stack>

        <Box sx={{ maxWidth: !isMobile ? "52.5rem" : "none" }}>
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: isMobile
                ? "repeat(1, 1fr)"
                : "repeat(2, 1fr)",
              gridTemplateRows: isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
              gap: "0.75rem",
            }}
          >
            {displayFeatures.map((displayFeature, index) => (
              <Box
                style={{
                  background: "#5B67681C",
                  borderRadius: "1rem",
                  padding: "1rem 1.25rem",
                }}
                key={index}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                  <Box
                    sx={{
                      height: "2.875rem",
                      width: "2.875rem",
                      background: "#2F736E",
                      borderRadius: "0.75rem",
                      padding: "0.25rem",
                      border: "1px solid #E8EBFF99",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "0.5rem",
                        position: "relative",
                        "::before": {
                          content: '""',
                          position: "absolute",
                          top: "-1px",
                          left: "-1px",
                          right: "-1px",
                          bottom: "-1px",
                          borderRadius: "0.5625rem",
                          background:
                            "linear-gradient(0deg, #6EDDAF00 0%, #E8EBFF99 60%)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          zIndex: 1,
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "0.5rem",
                          backgroundColor: "#2F736E",
                        }}
                      >
                        <img src={displayFeature.icon} alt="" />
                      </Box>
                    </Box>
                  </Box>

                  <Typography
                    fontSize="0.875rem"
                    lineHeight="1.25rem"
                    fontWeight={700}
                    color="#6C6C6C"
                    sx={{ flex: 1 }}
                  >
                    {displayFeature.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>

          {children || <Outlet />}
        </Box>
      </Box>
    </Box>
  );
};

export default SignupLayout;
