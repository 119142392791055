import { FC, ReactNode, useEffect, useState } from "react";
import { Box, Stack, Typography, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from '@mui/icons-material/Edit';

interface CustomAccordionProps {
  title: string;
  defaultOpen?: boolean;
  isSuccess?: boolean;
  children: ReactNode;
  open?: boolean;
  showEdit?: boolean;
  onEdit?: () => void;
  disableExpand?: boolean;
}

const CustomAccordion: FC<CustomAccordionProps> = ({
  children,
  title,
  defaultOpen,
  isSuccess,
  open,
  showEdit,
  onEdit,
  disableExpand=false
}) => {
  const [isExpanded, setIsExpanded] = useState(!!defaultOpen);

  useEffect(() => {
    if (open !== undefined) {
      setIsExpanded(!!open);
    }
  }, [open]);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "1rem",
        padding: "1.5rem 1.25rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography
            fontSize="1.15rem"
            lineHeight="1.375rem"
            fontWeight={700}
          >
            {title}
          </Typography>

          {isSuccess && (
            <CheckCircleIcon
              sx={{
                color: "#2F736E",
                fontSize: "1.25rem",
              }}
            />
          )}

          {!isSuccess && showEdit && (
            <EditIcon
              sx={{
                color: "#2F736E",
                fontSize: "1.25rem",
                cursor: "pointer",
              }}
              onClick={onEdit}
            />
          )}
        </Stack>

        <ExpandMoreIcon
          onClick={() => !disableExpand && setIsExpanded((prev) => !prev)}
          sx={{
            transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
            cursor: disableExpand ? "not-allowed": "pointer",
          }}
          aria-expanded={isExpanded}
        />
      </Stack>

      <Collapse
        in={isExpanded}
        timeout={200}
        unmountOnExit
        sx={{
          transition: "height 0.3s ease-in-out, padding 0.3s ease-in-out",
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            paddingTop: isExpanded ? "0.75rem" : "0",
            transition: "padding 0.3s ease-in-out",
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CustomAccordion;
