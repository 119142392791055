import { useLazyGetUserByIdQuery } from '../../api/userList';

const useUserById = () => {
  const [getUserById, { isLoading, isError }] = useLazyGetUserByIdQuery();

  const getUserDetailsById = async (id: string) => {
    try {
      const response = await getUserById(id).unwrap();

      if (response.data) {
        const data = response.data;

        return data;
      }
    } catch (err) {
      console.error('Error while fetching user by id :', err);
    }
  };

  return { getUserDetailsById, isLoading, isError };
};

export default useUserById;
