import React from "react"
import { Button } from "@mui/material"
import useChartCsvDownload from "@hooks/api/useChartCsvDownload";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download_icon.svg";

interface DownloadChartProps {
	fileName: string;
	submoduleId: string;
	chartId: string;
	filterSelection: Record<string, string>;
}

const DownloadChart: React.FC<DownloadChartProps> = ({ fileName, submoduleId, chartId, filterSelection }) => {
	const { getChartCsvDownloadById } = useChartCsvDownload();

	const handleDownload = async () => {
		let downloadLink = "";
		let filters = {};
		if (Object.keys(filterSelection).length > 0) {
			filters = filterSelection;
		}
		const response = await getChartCsvDownloadById(submoduleId, chartId, filters);
		downloadLink = response?.url || '';
		if (!downloadLink) {
			return;
		}
		const link = document.createElement("a");
		link.href = downloadLink;
		link.download = `${fileName}.csv`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Button onClick={handleDownload} sx={{ minWidth: '0px', padding: '0px' }}>
			<DownloadIcon />
		</Button>
	)
}

export default DownloadChart