import React from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DownloadChart from "./DownloadChart";

interface PaginationProps {
  page: number;
  size: number;
  total: number;
  handleChange: (page: number) => void;
  fileName: string;
  submoduleId: string;
  chartId: string;
  filterSelection: Record<string, string>;
  disablePagination?: boolean;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const { page, size, total, handleChange, fileName, submoduleId, chartId, filterSelection, disablePagination = false } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const startRow = (page - 1) * size + 1;
  const endRow = Math.min(page * size, total);

  const isFirstPage = page <= 1;
  const isLastPage = page >= Math.ceil(total / size);

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: isMobile ? "1rem" : 0, justifyContent: isMobile ? "flex-start" : "flex-end", gap: '20px', width: isMobile ? '100%' : 'fit-content', }}>
      {submoduleId && (
        <DownloadChart
          fileName={fileName}
          submoduleId={submoduleId}
          chartId={chartId}
          filterSelection={filterSelection}
        />
      )}
      {!disablePagination && (<Box sx={{ display: "flex", alignItems: "center", gap: '20px' }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: "black",
          }}
        >
          {`${startRow}-${endRow} of ${total}`}
        </Typography>
        <Button
          sx={{ color: "#06164B", minWidth: '0px', padding: '0px' }}
          onClick={() => handleChange(page - 1)}
          disabled={isFirstPage}
        >
          <KeyboardArrowLeftIcon />
        </Button>
        <Button
          sx={{ color: "#06164B", minWidth: '0px', padding: '0px' }}
          onClick={() => handleChange(page + 1)}
          disabled={isLastPage}
        >
          <KeyboardArrowRightIcon />
        </Button>
      </Box>)}
    </Box>
  );
};

export default Pagination;
