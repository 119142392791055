import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	Box,
	Button,
	Drawer,
	List,
	ListItem,
	ListItemText,
	Skeleton,
	useTheme
} from "@mui/material";
import {
	ChecklistRtl,
	ChevronRight,
	ExpandLessRounded,
	ExpandMoreRounded,
	LogoutOutlined,
	MenuOpenOutlined,
	MenuOutlined,
	PeopleOutlineOutlined,
	PersonOutlineOutlined,
	Settings,
} from "@mui/icons-material";
import { RootState } from "@stores/store";
import { NAV_ROUTES } from "@constants/navRoutes";
import { titles } from "@utils/translations/en";
import { SideNavOptions } from "./types";
import { sideNavOptionBorderStyle } from "./common";
import sideNavBackgroundImage from "../../assets/images/menu-bg.png";
import PvXLogoExpanded from "../../assets/icons/pvx-logo-full.svg";
import fallbackIcon from '../../assets/icons/module_fallback_icon.png'
import { SUBMODULE_VIEW } from "@constants/constants";


interface DesktopNavBarProps {
	filteredOptions: SideNavOptions[];
	handleModuleClick: (index: number, subOptions?: {
		label: string;
		path: string;
	}[]) => void;
	handleSubModuleClick: (path: string, label: string) => void;
	isCurrentRoute: (path: string | string[]) => boolean;
	openModule: number | null;
	setOpenModule: React.Dispatch<React.SetStateAction<number | null>>
	setSelectedSubmodule: React.Dispatch<React.SetStateAction<string | null>>;
	userName: string;
	isAdmin: boolean;
	onClickLogout?: () => void,
	customWidth: string
}

const DesktopNavBar: React.FC<DesktopNavBarProps> = ({
	filteredOptions,
	handleModuleClick,
	handleSubModuleClick,
	isCurrentRoute,
	openModule,
	setOpenModule,
	setSelectedSubmodule,
	userName,
	isAdmin,
	onClickLogout,
	customWidth
}) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const userList = useSelector((state: RootState) => state?.UserListData);

	const [isCollapsed, setIsCollapsed] = useState(false);
	const [isSettingsCollapsed, setSettingsCollapsed] = useState(true);
	const [scrollDistance, setScrollDistance] = useState(0);
	const [initialPosition, setInitialPosition] = useState(0);

	const dashboardOptions = filteredOptions.filter((option) => option?.subOptions?.[0]?.type !== 'FUNDING_REQUEST_TRACKER');
	const requestTrackerOptionIndex = filteredOptions.findIndex((option) => option?.subOptions?.[0]?.type === 'FUNDING_REQUEST_TRACKER');
	const requestTrackerOption = filteredOptions[requestTrackerOptionIndex]

	const checkIfActive = (option: SideNavOptions): boolean => {
		if (option.label === "Financial Projections") {
			return isCurrentRoute(option.path);
		}

		if (option.subOptions?.length === 1) {
			return isCurrentRoute(option.subOptions.map(sub => sub.path));
		}

		if (!option.subOptions || option.subOptions.length === 0) {
			return isCurrentRoute(option.path);
		}

		return false;
	}

	useEffect(() => {
		const container = document.getElementById("scrollable-container");
		if (!container) return;
		if (!isCollapsed) return

		setInitialPosition(container.scrollTop);
		let scrollTimeout: NodeJS.Timeout | null = null;
		const handleScroll = () => {
			if (scrollTimeout) clearTimeout(scrollTimeout);

			setOpenModule(null);
			scrollTimeout = setTimeout(() => {
				const currentScrollTop = container.scrollTop;
				const distanceMoved = Math.abs(currentScrollTop - initialPosition);
				setScrollDistance(distanceMoved);
			}, 150);
		};

		container.addEventListener("scroll", handleScroll);
		return () => {
			container.removeEventListener("scroll", handleScroll);
		};
	}, [isCollapsed]);

	return (
		<Drawer
			variant="permanent"
			sx={{
				height: "100%",
				width: isCollapsed ? "100px" : customWidth,
				maxWidth: "300px",
				flexShrink: 0,
				display: { xs: "none", md: "block" },
				position: "relative",
				transition: "width 0.3s ease-in-out",
				"& .MuiDrawer-paper": {
					overflowX: "clip",
					width: isCollapsed ? "100px" : customWidth,
					transition: "width 0.3s ease-in-out",
					boxSizing: "border-box",
					background:
						"linear-gradient(180deg, rgba(0, 0, 0, 1) 100%, rgba(82, 184, 176, 1) 100%)",
					backgroundImage: `url(${sideNavBackgroundImage}), linear-gradient(to right, #0e1515 , #1b2525)`,
					backgroundPosition: "center",
					backgroundSize: "cover",
					color: theme.palette.common.white,
					borderTopRightRadius: isCollapsed ? '12px' : '0px',
					borderBottomRightRadius: isCollapsed ? '12px' : '0px'
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					gap: '8px',
					marginTop: isCollapsed ? "32px" : '28px',
					marginBottom: isCollapsed ? "0px" : '16px'
				}}
			>
				{isCollapsed ?
					<Button
						sx={{ minWidth: '0px', paddingTop: '0px' }}
						onClick={() => setIsCollapsed((current) => !current)}
					>
						<MenuOutlined style={{ height: '28px', width: '28px', color: '#2F736E' }} />
					</Button>
					:
					<Box sx={{ display: 'flex', alignItems: 'center', }}>
						<img src={PvXLogoExpanded} alt={titles.PVX_PARTNERS} />
						<Button
							sx={{ minWidth: '0px', paddingLeft: 0 }}
							onClick={() => setIsCollapsed((current) => !current)}
						>
							<MenuOpenOutlined style={{ height: '28px', width: '28px', color: '#2F736E' }} />
						</Button>
					</Box>
				}
			</Box>
			<Box
				id="scrollable-container"
				sx={{
					mx: isCollapsed ? "8px" : "12px",
					height: "100%",
					mb: isCollapsed ? '5rem' : 10,
					overflowY: "auto",
					"&::-webkit-scrollbar": {
						display: 'none'
					},
				}}>
				{dashboardOptions?.length ? (
					<List>
						{dashboardOptions.map((option, index) => (
							<div key={index}>
								<ListItem
									button
									component="a"
									href={option?.subOptions?.length === 1 ? option.subOptions[0].path : undefined}
									rel="noopener noreferrer"
									onClick={(e) => {
										e.preventDefault();
										handleModuleClick(index, option.subOptions);
									}}
									sx={{
										"&:hover": {
											backgroundColor: "#2F736E1F",
										},
										backgroundColor:
											checkIfActive(option)
												? "#2F736E1F"
												: "inherit",
										color: "#F6F8F9",
										borderRadius: isCollapsed ? "8px" : "24px",
										display: "flex",
										alignItems: "center",
										padding: isCollapsed ? "8px 0px" : "16px 16px",
										my: "0.5rem",
										overflow: "hidden",
										...(checkIfActive(option) && {
											"&::after": sideNavOptionBorderStyle,
										}),
									}}
								>
									{isCollapsed && (
										<Box
											sx={{
												display: "flex",
												flexDirection: 'column',
												gap: '6px',
												lineHeight: '1.25rem',
												fontSize: '0.625rem',
												fontWeight: 700,
												alignItems: 'center',
												paddingX: '2px'
											}}>
											<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
												<img src={option.icon as string || fallbackIcon} alt="module-icon" style={{ width: "22px", height: '22px' }} />
												{Boolean(
													option.metadata?.submoduleView !== SUBMODULE_VIEW.TAB &&
													option?.subOptions?.length &&
													option.subOptions.length > 1
												) &&
													<ChevronRight style={{ position: 'absolute', color: '#AECBD0', left: '32px' }} />}
											</Box>
											<Box
												sx={{
													width: '76px',
													display: '-webkit-box',
													WebkitBoxOrient: 'vertical',
													WebkitLineClamp: 2,
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													textAlign: 'center',
													color: '#F6F8F9',
													wordBreak: 'break-word'
												}}>
												{option.label}
											</Box>
										</Box>
									)}
									{!isCollapsed ? (
										<Box
											sx={{
												display: "flex",
												width: "100%",
												alignItems: "center",
												justifyContent: 'space-between'
											}}
										>
											<Box
												sx={{
													display: 'flex',
													gap: '12px',
													alignItems: 'center',
													fontWeight: 700,
													overflow: "hidden",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													color: '#F6F8F9'
												}}>
												<img src={option.icon as string || fallbackIcon} alt="module-icon" style={{ width: "22px", height: '22px' }} />
												{option.label}
											</Box>
											{Boolean(
												option.metadata?.submoduleView !== SUBMODULE_VIEW.TAB &&
												option?.subOptions?.length &&
												option.subOptions.length > 1
											) && (
													<Box
														sx={{
															display: "flex",
															alignItems: "center",
															width: 24,
															height: 24,
															marginRight: 1,
														}}
													>
														{openModule === index ? (
															<ExpandLessRounded />
														) : (
															<ExpandMoreRounded />
														)}
													</Box>
												)}
										</Box>
									) : (
										<></>
									)}
								</ListItem>

								{
									option.metadata?.submoduleView !== SUBMODULE_VIEW.TAB &&
									option.subOptions &&
									option.subOptions.length > 1 &&
									openModule === index && (
										isCollapsed ?
											<Box
												sx={{
													zIndex: 1500,
													position: 'fixed',
													left: '108px',
													transform: `translateY(${-85 - scrollDistance}px)`,
													background: "linear-gradient(90.37deg, #0A1111 -28.36%, #0F1818 31.04%, #1E2828 120.25%)",
													padding: '8px',
													borderRadius: '1rem',
													display: 'flex',
													flexDirection: 'column',
													gap: '8px',
													width: 'fit-content',
													maxWidth: '216px'
												}}>
												{option.subOptions?.map((submodule, subIndex) => (
													<Button
														key={subIndex}
														component="a"
														href={submodule.path}
														rel="noopener noreferrer"
														onClick={(e) => {
															e.preventDefault();
															handleSubModuleClick(`${option.label}/${submodule.path}`, submodule.label);
														}}
														sx={{
															textTransform: 'none',
															'&:hover': {
																backgroundColor: '#2F736E1F',
																color: '#F6F8F9'
															},
															backgroundColor: isCurrentRoute([submodule.path]) ? '#2F736E1F' : 'inherit',
															color: '#F6F8F9',
															borderRadius: '24px',
															display: 'flex',
															alignItems: 'center',
															padding: '8px 16px',
															width: '100%',
															overflow: 'hidden',
															...(isCurrentRoute([submodule.path]) && {
																'&::after': sideNavOptionBorderStyle
															}
															)
														}}>
														{submodule.label}
													</Button>
												))}
											</Box>
											: <List
												component="div"
												disablePadding
												sx={{
													display: "flex",
													flexDirection: "column",
													ml: 2,
												}}
											>
												{option.subOptions.map((submodule, subIndex) => (
													<ListItem
														button
														key={subIndex}
														component="a"
														href={submodule.path}
														rel="noopener noreferrer"
														onClick={(e) => {
															e.preventDefault();
															handleSubModuleClick(`${option.label}/${submodule.path}`, submodule.label);
														}}
														sx={{
															'&:hover': {
																backgroundColor: '#2F736E1F',
																color: '#F6F8F9'
															},
															backgroundColor: isCurrentRoute([submodule.path]) ? '#2F736E1F' : 'inherit',
															color: isCurrentRoute([submodule.path]) ? '#F6F8F9' : '#B4B4B4',
															borderRadius: '24px',
															display: 'flex',
															alignItems: 'center',
															padding: '12px 16px',
															width: '90%',
															my: 1,
															overflow: 'hidden',
															...(isCurrentRoute([submodule.path]) && {
																'&::after': sideNavOptionBorderStyle
															}
															)
														}}
													>
														<ListItemText
															primary={submodule.label}
															primaryTypographyProps={{
																fontWeight: 700,
																overflow: "hidden",
																textOverflow: "ellipsis",
																whiteSpace: "nowrap",
																fontFamily: "Manrope, sans-serif",
															}}
														/>
													</ListItem>
												))}
											</List>
									)
								}
							</div>
						))}
						{isAdmin && dashboardOptions.length > 0 && (
							<>
								<ListItem
									button
									key={'admin_settings'}
									onClick={(e) => setSettingsCollapsed(!isSettingsCollapsed)}
									sx={{
										'&:hover': {
											backgroundColor: '#2F736E1F',
											color: '#F6F8F9'
										},
										color: '#F6F8F9',
										borderRadius: '24px',
										display: 'flex',
										alignItems: 'center',
										padding: '12px 16px',
										my: 1,
										overflow: 'hidden',
										...(
											(isCurrentRoute(NAV_ROUTES.DASHBOARD_REQUESTS) || isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT) || isCurrentRoute([requestTrackerOption?.path]))
											&& isSettingsCollapsed
											&& {
												'&::after': sideNavOptionBorderStyle
											}
										)
									}}
								>
									{isCollapsed ? <Settings /> : <ListItemText
										primary={"Admin"}
										primaryTypographyProps={{
											fontWeight: 700,
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											fontFamily: "Manrope, sans-serif",
										}}
									/>}
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											width: 24,
											height: 24,
											marginRight: 1,
										}}
									>
										{isSettingsCollapsed ? (
											<ExpandMoreRounded />
										) : (
											<ExpandLessRounded />
										)}
									</Box>
								</ListItem>
								{!isSettingsCollapsed && requestTrackerOption && (
									<>
										<ListItem
											button
											key={'admin_settings_request_tracker'}
											onClick={(e) => {
												e.preventDefault();
												handleModuleClick(requestTrackerOptionIndex, requestTrackerOption.subOptions);
											}}
											sx={{
												'&:hover': {
													backgroundColor: '#2F736E1F',
													color: '#F6F8F9'
												},
												backgroundColor: checkIfActive(requestTrackerOption) ? '#2F736E1F' : 'inherit',
												color: checkIfActive(requestTrackerOption) ? '#F6F8F9' : '#B4B4B4',
												borderRadius: '24px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												padding: '12px 16px',
												width: '90%',
												my: 1,
												overflow: 'hidden',
												...(isCurrentRoute([requestTrackerOption.path]) && {
													'&::after': sideNavOptionBorderStyle
												}
												),
												marginLeft: isCollapsed ? "0rem" : '1rem'
											}}
										>
											{!isCollapsed ? (
												<ListItemText
													primary={requestTrackerOption?.label}
													primaryTypographyProps={{
														fontWeight: 700,
														overflow: "hidden",
														textOverflow: "ellipsis",
														whiteSpace: "nowrap",
														fontFamily: "Manrope, sans-serif",
													}}
												/>
											) :
												<img src={requestTrackerOption.icon as string || fallbackIcon} alt="module-icon" style={{ width: "24px", height: '24px' }} />
											}
										</ListItem>
									</>
								)}
								{!isSettingsCollapsed && (
									<>
										<ListItem
											button
											key={'admin_settings_dashboard_requests'}
											onClick={(e) => {
												navigate(NAV_ROUTES.DASHBOARD_REQUESTS);
												setOpenModule(null);
												setSelectedSubmodule(null)
											}}
											sx={{
												'&:hover': {
													backgroundColor: '#2F736E1F',
													color: '#F6F8F9'
												},
												backgroundColor: isCurrentRoute(NAV_ROUTES.DASHBOARD_REQUESTS) ? '#2F736E1F' : 'inherit',
												color: isCurrentRoute(NAV_ROUTES.DASHBOARD_REQUESTS) ? '#F6F8F9' : '#B4B4B4',
												borderRadius: '24px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												padding: '12px 16px',
												width: '90%',
												my: 1,
												overflow: 'hidden',
												...(isCurrentRoute(NAV_ROUTES.DASHBOARD_REQUESTS) && {
													'&::after': sideNavOptionBorderStyle
												}
												),
												marginLeft: isCollapsed ? "0rem" : '1rem'
											}}
										>
											{!isCollapsed ? (
												<ListItemText
													primary={"Self Serve Requests"}
													primaryTypographyProps={{
														fontWeight: 700,
														overflow: "hidden",
														textOverflow: "ellipsis",
														whiteSpace: "nowrap",
														fontFamily: "Manrope, sans-serif",
													}}
												/>
											) : <ChecklistRtl />}
										</ListItem>
										<ListItem
											button
											key={'admin_settings_users'}
											onClick={() => {
												navigate(NAV_ROUTES.USER_MANAGEMENT);
												setOpenModule(null);
												setSelectedSubmodule(null)
											}}
											sx={{
												"&:hover": { backgroundColor: "#2F736E1F" },
												backgroundColor: isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT)
													? "#2F736E1F"
													: "transparent",
												color: isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT) ? '#F6F8F9' : '#B4B4B4',
												borderRadius: "24px",
												display: "flex",
												alignItems: "center",
												justifyContent: isCollapsed ? "center" : "",
												padding: "12px 16px",
												overflow: "hidden",
												...(isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT) && {
													"&::after": sideNavOptionBorderStyle,
												}),
												marginLeft: isCollapsed ? "0rem " : '1rem',
												width: '90%'
											}}
										>
											{!isCollapsed ? (
												<ListItemText
													primaryTypographyProps={{
														fontFamily: "Manrope, sans-serif",
														fontWeight: 600,
														fontSize: "1rem",
													}}
													primary={titles.USERS}
												/>
											) : (
												<></>
											)}
											{userList.allUserCount > 0 && (
												isCollapsed ? <PeopleOutlineOutlined /> :
													<ListItemText
														primary={userList.allUserCount}
														primaryTypographyProps={{
															fontSize: "1rem",
															fontFamily: "Manrope, sans-serif",
															marginRight: 2,
															textAlign: "end",
															fontWeight: 600,
														}}
													/>
											)}
										</ListItem>
									</>
								)}
							</>
						)}
					</List>
				) : (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							height: "100%",
							boxSizing: "border-box",
						}}
					>
						{(isAdmin && dashboardOptions.length > 0) ? (
							isSettingsCollapsed ?
								(
									<Box
										sx={{
											zIndex: 1500,
											position: 'fixed',
											left: '108px',
											transform: 'translateY(-85px)',
											background: "linear-gradient(90.37deg, #0A1111 -28.36%, #0F1818 31.04%, #1E2828 120.25%)",
											padding: '8px',
											borderRadius: '1rem',
											display: 'flex',
											flexDirection: 'column',
											gap: '8px',
											width: 'fit-content',
											maxWidth: '216px'
										}}
									>
										<Button
											key={'admin_settings'}
											onClick={(e) => setSettingsCollapsed(!isSettingsCollapsed)}
											sx={{
												textTransform: 'none',
												'&:hover': {
													backgroundColor: '#2F736E1F',
													color: '#F6F8F9'
												},
												backgroundColor: isSettingsCollapsed ? '#2F736E1F' : 'inherit',
												color: '#F6F8F9',
												borderRadius: '24px',
												display: 'flex',
												alignItems: 'center',
												padding: '8px 16px',
												width: '100%',
												overflow: 'hidden',
												...(isSettingsCollapsed && {
													'&::after': sideNavOptionBorderStyle
												}
												)
											}}
										>
											Admin
										</Button>
									</Box>
								) : (
									<List
										component="div"
										disablePadding
										sx={{
											display: "flex",
											flexDirection: "column",
											ml: 2,
										}}
									>
										<ListItem
											button
											key={'admin_settings_dashboard_requests'}
											onClick={(e) => {
												navigate(NAV_ROUTES.DASHBOARD_REQUESTS);
												setOpenModule(null);
												setSelectedSubmodule(null)
											}}
											sx={{
												'&:hover': {
													backgroundColor: '#2F736E1F',
													color: '#F6F8F9'
												},
												backgroundColor: isCurrentRoute(NAV_ROUTES.DASHBOARD_REQUESTS) ? '#2F736E1F' : 'inherit',
												color: isCurrentRoute(NAV_ROUTES.DASHBOARD_REQUESTS) ? '#F6F8F9' : '#B4B4B4',
												borderRadius: '24px',
												display: 'flex',
												alignItems: 'center',
												padding: '12px 16px',
												width: '90%',
												my: 1,
												overflow: 'hidden',
												...(isCurrentRoute(NAV_ROUTES.DASHBOARD_REQUESTS) && {
													'&::after': sideNavOptionBorderStyle
												}
												)
											}}
										>
											<ListItemText
												primary={"Self Serve Requests"}
												primaryTypographyProps={{
													fontWeight: 700,
													overflow: "hidden",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													fontFamily: "Manrope, sans-serif",
												}}
											/>
										</ListItem>
										<ListItem
											button
											onClick={() => {
												navigate(NAV_ROUTES.USER_MANAGEMENT);
												setOpenModule(null);
												setSelectedSubmodule(null)
											}}
											sx={{
												"&:hover": { backgroundColor: "#2F736E1F" },
												backgroundColor: isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT)
													? "#2F736E1F"
													: "transparent",
												color: "#F6F8F9",
												borderRadius: "24px",
												display: "flex",
												alignItems: "center",
												justifyContent: isCollapsed ? "center" : "",
												padding: "12px 16px",
												overflow: "hidden",
												...(isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT) && {
													"&::after": sideNavOptionBorderStyle,
												}),
											}}
										>
											{!isCollapsed ? (
												<ListItemText
													primaryTypographyProps={{
														fontFamily: "Manrope, sans-serif",
														fontWeight: 600,
														fontSize: "1rem",
													}}
													primary={titles.USERS}
												/>
											) : (
												<></>
											)}
											{userList.allUserCount > 0 && (
												isCollapsed ? <PeopleOutlineOutlined /> :
													<ListItemText
														primary={userList.allUserCount}
														primaryTypographyProps={{
															fontSize: "1rem",
															fontFamily: "Manrope, sans-serif",
															marginRight: 2,
															textAlign: "end",
															fontWeight: 600,
														}}
													/>
											)}
										</ListItem>
									</List>
								)
						) : (
							Array.from({ length: 7 }).map((_, index) => (
								<Skeleton
									key={index}
									variant="rectangular"
									width="90%"
									height="4%"
									sx={{ marginTop: 3, borderRadius: 1 }}
								/>
							))
						)}
					</Box>
				)}
			</Box>
			<Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
				{userName && (
					<>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								padding: "20px 16px",
								background: "#30434C",
							}}
						>
							{!isCollapsed ? (
								<>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											width: 24,
											height: 24,
											marginRight: 1,
										}}
									>
										<PersonOutlineOutlined />
									</Box>
									<ListItemText
										primary={userName}
										primaryTypographyProps={{
											fontFamily: "Manrope, sans-serif",
											fontWeight: 600,
											color: "#F6F8F9",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											maxWidth: "140px",
										}}
									/>
								</>
							) : (
								<></>
							)}
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									width: 24,
									height: 24,
									cursor: "pointer",
								}}
								onClick={onClickLogout}
							>
								<LogoutOutlined />
							</Box>
						</Box>
					</>
				)}
			</Box>
		</Drawer >
	)
}

export default DesktopNavBar