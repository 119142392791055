import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const ProcessingAppsFlyerDataAccess = () => {
  return (
    <Stack marginTop={"0.75rem"} gap={"0.75rem"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"0.5rem"}
        padding={"0.5rem 0"}
      >
        <Stack alignItems={"center"}>
          <CheckCircleIcon
            style={{
              color: "#2F736E",
            }}
          />
        </Stack>
        <Stack>
          <Typography
            fontSize={"0.875rem"}
            lineHeight={"1.25rem"}
            fontWeight={700}
            color={"#162936"}
          >
            Access Granted
          </Typography>
          <Typography
            fontSize={"0.875rem"}
            lineHeight={"1rem"}
            fontWeight={700}
            color={"#6C6C6C"}
          >
            Data Processing in Progress
          </Typography>
        </Stack>
      </Stack>

      <Typography 
        fontSize={"0.875rem"}
        lineHeight={"1.25rem"}
        fontWeight={500}
        color={"#162936"}>
        Your dashboards will typically be ready within 1 hour. In rare cases, processing may take up to 2 business days. You will receive an email notification as soon as your dashboards are available.
      </Typography>
    </Stack>
  );
};

export default ProcessingAppsFlyerDataAccess;
