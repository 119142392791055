import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@stores/store";
import useChartById from "@hooks/api/useChartById";
import { useLazyGetPredictionsQuery } from "@api/projections";
import { setDashboardFilter } from "@stores/DashboardFilters/slice";
import { resetSimulationsChartData, resetSimulationsTableData, setSimulationsChartData, setSimulationsTableData } from "@stores/FinancialProjections/slices";
import CohortDetailsSidePanel from "./CohortDetailsSidePanel";
import FinancialProjectionsFilters from "./FinancialProjectionsFilters";
import ProjectionsChart from "./ProjectionsChart";
import ProjectionsTable from "./ProjectionsTable";
import SimulateProjectionsSidePanel from "./SimulateProjectionsSidePanel";
import { Filter, FinancialProjectionChartTypes, PredictionDetails } from "./types";
import { processRowDataWithMetadata } from "../../../../components/GenericTable/tableDataUtil";

interface FutureCohortProjectionsProps {
  submoduleId: string;
  selectedGame: { id: string, label: string };
  setDashboardLoader: React.Dispatch<React.SetStateAction<boolean>>
  dashboardCharts: { id: string; name: string; chartType: string, metadata: string }[]
}

const projectionType: Filter[] = [
  {
    key: "category",
    label: "Category",
    values: [
      { id: "roasProjections", label: "ROAS Projections" },
      { id: "revenueProjections", label: "Revenue Projections" },
    ],
  },
];

const FutureCohortsProjections: React.FC<FutureCohortProjectionsProps> = ({
  submoduleId,
  selectedGame,
  setDashboardLoader,
  dashboardCharts
}) => {
  const dispatch = useDispatch();

  const [selections, setSelections] = useState<Record<string, string>>({ category: projectionType[0].values[0].id, });
  const [showRevenueByElapsedMonthsPanel, setShowRevenueByElapsedMonthsPanel] =
    useState(false);
  const [cohortDetails, setCohortDetails] = useState<{ cohort: string, marketingSpends: string }>({ cohort: '', marketingSpends: '' });
  const [showSimulateProjections, setShowSimulateProjections] = useState(false);
  const [predictionDetails, setPredictionDetails] = useState<PredictionDetails | undefined>(undefined)
  const [componentLoader, setComponentLoader] = useState<boolean>(true)
  const [chartData, setChartData] = useState<
    { id: string; name: string; columns: any[]; rows: any[]; pricingDetails: string[]; meta: any }[]
  >([]);
  const [isSkippable, setIsSkippable] = useState<boolean>(false)

  const { getChartDataById } = useChartById()
  const [getPredictions, { isSuccess: isPredictionsFetchSuccess }] = useLazyGetPredictionsQuery();

  const simulationsTableData = useSelector((state: RootState) => state?.FinancialProjectionsData?.financialProjections?.simulationsTableData)
  const simulationsChartData = useSelector((state: RootState) => state?.FinancialProjectionsData?.financialProjections?.simulationsChartData)

  const chartsList = [FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_M1, FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_M1_TABLE]
  const tabsChartsList = dashboardCharts.filter((chart: any) => chartsList.includes(chart.chartType))

  const fetchDashboardData = async (filters?: Record<string, string>) => {
    if (submoduleId) {
      const chartsById: any[] = [];
      try {
        await Promise.all(
          tabsChartsList.map(
            async (chart: {
              id: string;
              name: string;
              chartType: string;
              metadata: string;
            }) => {
              const parsedMetadata = JSON.parse(chart.metadata);
              const response = await getChartDataById(submoduleId, chart.id, filters, parsedMetadata?.sort?.fields, parsedMetadata?.sort?.order);
              if (response) {
                const { rows, columns, pricingDetails, metadata } = formatChartResponseData(response);
                chartsById.push({
                  columns,
                  rows,
                  pricingDetails,
                  id: chart.id,
                  name: response.name,
                  meta: metadata,
                });
              }
              else {
                if (chart.chartType === FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_M1_TABLE) {
                  dispatch(resetSimulationsTableData());
                } else if (chart.chartType === FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_M1) {
                  dispatch(resetSimulationsChartData())
                }
              }
            }
          )
        );

        setChartData(chartsById);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        setDashboardLoader(false);
        setComponentLoader(false);
      }
    }
  };

  const formatChartResponseData = (response: any) => {
    let columns = [];
    let rows = [];
    let pricingDetails = response.result.pricingDetails || []
    const metadata = JSON.parse(response.metadata);
    const { mappedColumns, mappedData } = processRowDataWithMetadata(
      response.name,
      metadata,
      response.result.data
    );
    columns = mappedColumns;
    rows = mappedData

    return { rows, columns, pricingDetails, metadata }
  }


  const fetchPredictionsData = async () => {
    if (selectedGame?.id)
      await getPredictions({ gameId: selectedGame?.id, submoduleId: submoduleId }).then((response) => {
        if (response)
          setPredictionDetails(response?.data?.data[0])
      })
  }

  useEffect(() => {
    setComponentLoader(true);
    fetchPredictionsData();
    dispatch(setDashboardFilter({ "FinancialProjections": selectedGame }));
  }, [selectedGame]);

  useEffect(() => {
    if (isSkippable) {
      setDashboardLoader(false);
      setComponentLoader(false);
      return;
    }
    setComponentLoader(true)

    if (selectedGame?.id && predictionDetails?.requestId && isPredictionsFetchSuccess) {
      fetchDashboardData({
        game_id: selectedGame?.id,
        predictionRequestId: predictionDetails?.requestId || '',
      });
    }
  }, [predictionDetails]);

  useEffect(() => {
    if (chartData.length) {
      chartData.forEach((chart) => {
        if (chart.name === FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_M1_TABLE) {
          dispatch(setSimulationsTableData({ gameId: selectedGame?.id, ...chart }));
        } else {
          dispatch(setSimulationsChartData({ gameId: selectedGame?.id, ...chart }));
        }
      })
    }
  }, [chartData])

  useEffect(() => {
    setIsSkippable(simulationsTableData?.gameId === selectedGame?.id)
  }, [selectedGame?.id])

  const handleFilterChange = (key: string, selectedValue: string) => {
    const updatedSelections = { ...selections, [key]: selectedValue };
    setSelections(updatedSelections);
  };

  const handleCohortDetailsSidebarClick = (selectedCohortDetails: { cohort: string, marketingSpends: string }) => {
    setCohortDetails(selectedCohortDetails);
    setShowRevenueByElapsedMonthsPanel(!showRevenueByElapsedMonthsPanel);
  }

  return (
    <>
      <FinancialProjectionsFilters
        handleFilterChange={handleFilterChange}
        selections={selections}
        filters={projectionType}
        isProjectionSimulation
        setShowSimulateProjections={setShowSimulateProjections}
      />
      <ProjectionsChart
        selectedFilter={selections.category}
        isLoading={componentLoader}
        isSimulation
        submoduleId={submoduleId}
        chartId={simulationsChartData?.id || ''}
        downloadFilename={simulationsChartData?.meta?.chartType || FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_M1}
        filterSelection={{ game_id: selectedGame?.id, predictionRequestId: predictionDetails?.requestId || '' }}
      />
      <ProjectionsTable
        selectedGameId={selectedGame?.id}
        handleRowClick={handleCohortDetailsSidebarClick}
        subModuleId={submoduleId}
        chartId={simulationsTableData?.id || ''}
        chartName={simulationsTableData?.meta?.chartType || FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_M1_TABLE}
        filterSelection={{ game_id: selectedGame?.id, predictionRequestId: predictionDetails?.requestId || '' }}
        isLoading={componentLoader}
        isSimulation
      />
      <CohortDetailsSidePanel
        showPanel={showRevenueByElapsedMonthsPanel}
        setShowPanel={setShowRevenueByElapsedMonthsPanel}
        cohortDetails={cohortDetails}
        isSimulation
      />
      <SimulateProjectionsSidePanel
        showPanel={showSimulateProjections}
        setShowPanel={setShowSimulateProjections}
        submoduleId={submoduleId}
        gameId={selectedGame?.id}
        refetchChartsCallback={() => {
          fetchPredictionsData()
          setIsSkippable(false)
        }}
        predictionData={predictionDetails}
      />
    </>
  )
}

export default FutureCohortsProjections;

