import { Stack, Typography, Box, useMediaQuery, useTheme } from "@mui/material"
import ImageCarousel from "./components/ImageCarousel"

const imageBucketUrl = `${process.env.REACT_APP_BUCKET_DOMAIN}`;
const images = [
  `${imageBucketUrl}/demo-dashboards/funding_eligibility.png`,
];

const GettingStartedFundingEligibility = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      gap={"1.75rem"}
      padding={"2rem"}
      sx={{
        overflowY: 'auto',
        maxHeight: '100vh'
      }}>
      <Stack>
        <Typography
          fontSize={"2.25rem"}
          lineHeight={"2.75rem"}
          fontWeight={700}
          color={"#162936"}
        >
          Understand your cohort strength,
        </Typography>
        <Typography
          fontSize={"2.25rem"}
          lineHeight={"2.75rem"}
          fontWeight={700}
          color={"#B4B4B4"}
        >
          and unlock smarter financing
        </Typography>
        <Typography
          marginTop={"0.75rem"}
          fontSize={"1rem"}
          lineHeight={"1.25rem"}
          fontWeight={500}
          color={"#6C6C6C"}
          sx={{ width: isMobile ? "100%" : "60%" }}
        >
          Get a funding score based on your ROAS, retention, and
          scalability—ensuring you’re ready to scale with confidence.
        </Typography>
      </Stack>

      <Box>
        <ImageCarousel images={images} />
      </Box>
    </Stack>
  )
}

export default GettingStartedFundingEligibility