import React, { ReactNode, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ModeOutlined } from "@mui/icons-material";
import { Loader } from "@components";
import { formatCurrency } from "@utils/common";
import { ProjectionValueUpdateType } from "../types";
import CustomButton from "../../../../../components/CustomButton/CustomButton";

export type Column<T> = {
  key: keyof T;
  label: string;
  render?: (value: T[keyof T], row: T, level?: number) => ReactNode;
  renderHeader?: (column: Column<T>) => ReactNode;
};

export type Row<T> = T & {
  id: string;
  children?: Row<T>[];
  getChildren?: (rowId: string) => Row<T>[];
};

export type RowAction = {
  label?: string;
  icon?: string;
  renderUpdateComponent?: any;
  condition?: (rowId: string) => boolean;
  action?: (rowId: string) => void;
};

export type GenericTableProps<T> = {
  data: Row<T>[];
  columns: Column<T>[];
  meta: any;
  actions?: RowAction[];
  onViewRow?: (row: Row<T>) => void;
  isLoading?: boolean;
  depth?: number | null;
  showTotal?: boolean;
  handleFieldSave: (updatedValues: ProjectionValueUpdateType[]) => void;
};

function FinancialProjectionsTable<T>({
  data,
  columns,
  meta,
  actions,
  onViewRow,
  isLoading,
  depth,
  showTotal = false,
  handleFieldSave
}: GenericTableProps<T>) {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const [editedValues, setEditedValues] = useState<Record<string, any>>({});
  const [toUpdateValues, setToUpdateValues] = useState<ProjectionValueUpdateType[]>([])

  const handleEditClick = (row: any) => {
    setEditingRowId(row.id);
    setEditedValues({ ...row });
  };

  const handleSaveClick = (rowId: string) => {
    handleFieldSave(toUpdateValues);
    setEditingRowId(null);
    setToUpdateValues([])
  };

  const handleCancelClick = () => {
    setEditingRowId(null);
    setToUpdateValues([])
  };

  const columnMetadata = meta?.columns?.length
    ? meta.columns.reduce((obj: any, item: any) => {
      obj[item.id] = item;
      return obj;
    }, {})
    : [];

  const editableRows = ['OTHER_REVENUE', 'OPERATING_COSTS', 'START_CASH_BALANCE']
  const highlightedRows = ['TOTAL_REVENUE', 'TOTAL_COST', 'PROFIT_OR_LOSS', 'END_CASH_BALANCE']

  const handleExpandRowClick = (id: string) => {
    setExpandedRows((prev) => {
      const isExpanded = !prev[id];
      return { ...prev, [id]: isExpanded };
    });
  };

  const checkCellValueType = (type: string, value: any) => {
    switch (type) {
      case "string":
        return value;
      case "percentage":
        return Number(value) === 0
          ? "-"
          : `${(Number(value) * 100).toFixed(2)}%`;
      case "currency":
        return Number(value) === 0 ? "-" : formatCurrency(value);
      case "date":
        return value;
      default:
        return null;
    }
  };

  const getCellValueAllignment = (type: string) => {
    switch (type) {
      case "percentage":
      case "currency":
        return "right";
      case "string":
      case "date":
        return "left";
      default:
        return "center";
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string, row: any) => {
    const inputValue = e.target.value;
    const numericValue = parseFloat(inputValue.replace(/[^0-9]/g, '')) || 0;
    setEditedValues((prev) => ({ ...prev, [key]: numericValue.toString() }))
    setToUpdateValues((prevValues) => {
      const existingIndex = prevValues.findIndex(
        (item) => item.transactionPeriod === key && item.type === row.id
      );

      if (existingIndex !== -1) {
        // Update existing item
        const updatedValues = [...prevValues];
        updatedValues[existingIndex] = { ...updatedValues[existingIndex], value: numericValue };
        return updatedValues;
      } else {
        // Add new item
        return [...prevValues, { transactionPeriod: key, value: numericValue, type: row.id }];
      }
    });
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement | HTMLInputElement>, startKey: string, row: any) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData("text");
    const rows = pastedData
      .trim()
      .split(/\r?\n/)
      .map(row => row.split(/\t/));

    const values = rows.flat().map(value => parseFloat(value.replace(/[^0-9.]/g, "")) || 0);
    const fieldKeys = Object.keys(editedValues);
    const startIndex = fieldKeys.indexOf(startKey);

    setEditedValues((prev) => {
      const updatedValues = { ...prev };
      values.forEach((value, i) => {
        const targetKey = fieldKeys[startIndex + i];
        if (targetKey) {
          updatedValues[targetKey] = value.toString();
        }
      });

      return updatedValues;
    });
    setToUpdateValues((prevValues) => {
      const newValues = [...prevValues];
      values.forEach((value, i) => {
        const targetKey = fieldKeys[startIndex + i];
        if (targetKey) {
          const existingIndex = newValues.findIndex(
            (item) => item.transactionPeriod === targetKey && item.type === row.id
          );
          if (existingIndex !== -1) {
            newValues[existingIndex] = { ...newValues[existingIndex], value };
          } else {
            newValues.push({ transactionPeriod: targetKey, value, type: row.id });
          }
        }
      });
      return newValues;
    });
  };

  const renderCellContent = (row: any, key: any, colindex: any): ReactNode => {
    const value = row[key];
    const columnType = columnMetadata[key]?.type;
    const isEditing = editingRowId === row.id && colindex !== 0;
    const isStartCashBalance = row.id === "START_CASH_BALANCE";
    const isDisabled = colindex !== 1;
    const icons = columnMetadata[key]?.icons;
    const leftIcon = icons?.left || null;
    const rightIcon = icons?.right || null;
    const roundedCells = colindex === 0 || colindex === columns.length - 1

    // Handle null values
    if (value === "null") {
      return (
        <span
          style={{
            width: "100%",
            minWidth: "3rem",
            display: "flex",
            justifyContent: getCellValueAllignment(columnType),
          }}
        >
          N/A
        </span>
      );
    }

    // Handle valid cell values
    if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
      const renderValue = checkCellValueType(columnType, value);

      if (isEditing) {
        if (isStartCashBalance) {
          return (
            <TextField
              disabled={isDisabled}
              sx={{
                width: "100%",
                minWidth: columnType === "currency" ? "6rem" : "3rem",
                display: "flex",
                justifyContent: "flex-end",
                margin: roundedCells ? "0 1rem 0 0" : "0",
                backgroundColor: isDisabled ? "#2F736E1F" : "#FFFFFF",
                borderRadius: !isDisabled || colindex === columns.length - 1 ? "0.5rem" : "0rem",
              }}
              value={
                isDisabled
                  ? "-"
                  : formatCurrency(editedValues[key]?.replace(/[^0-9]/g, "")) || "$0"
              }
              onChange={(e) => handleTextChange(e, key, row)}
              InputProps={{
                sx: {
                  fontSize: "14px",
                  fontWeight: 500,
                  pointerEvents: isDisabled ? "none" : "auto",
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 8px",
                    textAlign: "right",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderTop: "2px solid #9AD6D1",
                    borderRight: colindex === 0 ? "2px solid #9AD6D1" : "1px solid #2F736E1F",
                    borderBottom: "2px solid #9AD6D1",
                    borderLeft: colindex === 1 ? "2px solid #9AD6D1" : "",
                    borderRadius: "initial",
                    borderTopLeftRadius: colindex === 1 ? "0.5rem" : "0rem",
                    borderBottomLeftRadius: colindex === 1 ? "0.5rem" : "0rem",
                    borderTopRightRadius: roundedCells ? "0.5rem" : "0rem",
                    borderBottomRightRadius: roundedCells ? "0.5rem" : "0rem",
                    transition: "border-color 0.3s ease-in-out",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9AD6D1",
                    borderWidth: "2px",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9AD6D1",
                    borderWidth: "2px",
                  },
                },
              }}
            />
          );
        } else {
          return (
            <TextField
              sx={{
                width: "100%",
                minWidth: columnType === "currency" ? "6rem" : "3rem",
                display: "flex",
                justifyContent: "flex-end",
                margin: roundedCells ? "0 1rem 0 0" : "0",
                backgroundColor: "#FFFFFF",
                borderRadius: colindex === 1 || colindex === columns.length - 1 ? "0.5rem" : "0rem",
              }}
              value={formatCurrency(editedValues[key]?.replace(/[^0-9]/g, "")) || "$0"}
              onChange={(e) => handleTextChange(e, key, row)}
              onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => handlePaste(e, key, row)}
              InputProps={{
                sx: {
                  fontSize: "14px",
                  fontWeight: 500,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 8px",
                    textAlign: "right",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderTop: "2px solid #9AD6D1",
                    borderRight: roundedCells ? "2px solid #9AD6D1" : "1px solid #2F736E1F",
                    borderBottom: "2px solid #9AD6D1",
                    borderLeft: colindex === 1 ? "2px solid #9AD6D1" : "",
                    borderRadius: "initial",
                    borderTopLeftRadius: colindex === 1 ? "0.5rem" : "0rem",
                    borderBottomLeftRadius: colindex === 1 ? "0.5rem" : "0rem",
                    borderTopRightRadius: roundedCells ? "0.5rem" : "0rem",
                    borderBottomRightRadius: roundedCells ? "0.5rem" : "0rem",
                    transition: "border-color 0.3s ease-in-out",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9AD6D1",
                    borderWidth: "2px",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9AD6D1",
                    borderWidth: "2px",
                  },
                },
              }}
            />
          );
        }
      }

      return (
        <span
          style={{
            width: "100%",
            minWidth: columnType === "currency" ? "6rem" : "3rem",
            display: "flex",
            justifyContent: colindex === 0 ? "flex-start" : "flex-end",
            marginLeft: editingRowId === row.id ? "1rem" : "0.75rem",
          }}
        >
          {leftIcon}
          {renderValue ?? value}
          {rightIcon}
        </span>
      );
    }

    return (
      <span
        style={{
          width: "100%",
          minWidth: "3rem",
          display: "flex",
          justifyContent: getCellValueAllignment(columnType),
        }}
      >
        -
      </span>
    );
  };



  const renderRows = (rows: Row<T>[], level = 0): ReactNode => {
    return rows.map((row, rowIndex) => {
      const isExpanded = expandedRows[row.id];

      return (
        <React.Fragment key={`${row.id}_${rowIndex}`}>
          <TableRow
            style={{
              backgroundColor:
                rowIndex === rows.length - 1 && showTotal
                  ? "#2F736E1F"
                  : "#FFFFFF",
              cursor: onViewRow ? "pointer" : "default",
            }}
            onClick={() => {
              if (onViewRow) onViewRow(row);
            }}
          >
            {columns.map((column, colindex) => (
              <TableCell
                key={String(column.key)}
                sx={{
                  position: colindex === 0 ? 'sticky' : 'static',
                  zIndex: colindex === 0 ? "10" : "1",
                  left: '0',
                  paddingY: "0.75rem",
                  paddingLeft: "0.5rem",
                  padding: editingRowId === row.id ? "1rem 0rem 1rem 0rem !important" : "",
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "0.875rem",
                  fontWeight:
                    level === 0 ? 600 : 500,
                  lineHeight: "1rem",
                  color:
                    rowIndex === rows.length - 1 && showTotal
                      ? "#2F736E"
                      : "#1B1C17",
                  borderTop: (rowIndex === 0 && editingRowId !== row?.id) ? "1px solid #2F736E1F" : "",
                  borderBottom: editingRowId === row.id ? "none" : "1px solid #D3D3D3",
                  borderLeft:
                    colindex === 0 || level === depth || editingRowId === row.id
                      ? "none"
                      : colindex === 1
                        ? "1px solid #DDDDDD"
                        : "1px solid #2F736E1F",
                  borderRight: colindex === columns.length - 1 ? "1px solid #2F736E1F" : "none",
                  background:
                    editingRowId === row.id
                      ? "#E5EEED" : colindex === 0 || highlightedRows.includes(row.id as string)
                        ? "#E5EEED" : "#FFFFFF",
                  borderTopLeftRadius: colindex === 0 && rowIndex === 0 && level === 0 ? "0.5rem" : 0,
                  borderBottomLeftRadius: colindex === 0 && rowIndex === rows.length - 1 && level === 0 && editingRowId !== row.id ? "0.5rem" : 0,
                  borderTopRightRadius: colindex === columns.length - 1 && rowIndex === 0 && level === 0 ? "0.5rem" : 0,
                  borderBottomRightRadius: colindex === columns.length - 1 && rowIndex === rows.length - 1 && level === 0 && editingRowId !== row.id ? "0.5rem" : 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent:
                      colindex === 0
                        ? "flex-start"
                        : "flex-end",
                    color: level === 0 ? "#1B1C17" : "#6C6C6C",
                  }}
                >
                  {colindex === 0 &&
                    row.children?.length !== 0 && (
                      <button
                        onClick={() => handleExpandRowClick(row.id)}
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                          padding: 0,
                        }}
                      >
                        <KeyboardArrowDownIcon
                          style={{
                            width: "1rem",
                            color: "#162C36",
                            rotate: isExpanded ? "0deg" : "-90deg",
                            marginLeft: editingRowId === row.id ? "0.75rem" : "0",
                          }}
                        />
                      </button>
                    )}
                  {column.render
                    ? column.render(row[column.key], row, level)
                    : renderCellContent(row, column.key, colindex)
                  }
                  {colindex === 0 && editableRows.includes(row.id as string) &&
                    editingRowId !== row.id && (
                      <Button onClick={() => handleEditClick(row)} sx={{ padding: 0, minWidth: 0 }} disableRipple>
                        <ModeOutlined style={{ color: '#2F736E', height: '1rem', width: '1rem' }} />
                      </Button>
                    )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
          <TableRow sx={{ position: "relative" }}>
            {columns.map(
              (column, index) =>
                editingRowId === row.id && (
                  <TableCell key={String(column.key)} sx={{ backgroundColor: "#E5EEED", }}>
                    {index === columns?.length - 1 ? (
                      editingRowId === row.id && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "0rem 0.5rem 0rem 0",
                            gap: "0.5rem",
                            position: "absolute",
                            top: "-5px",
                            right: 0
                          }}
                        >
                          <CustomButton
                            color="#F6F8F9"
                            backgroundColor="#2F736E"
                            name="Confirm"
                            onClick={() => handleSaveClick(row.id)}
                            disabled={toUpdateValues.length === 0}
                          />
                          <CustomButton
                            color="#6C6C6C"
                            borderColor="#2E4A4F"
                            name="Cancel"
                            onClick={handleCancelClick}
                          />
                        </Box>
                      )
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "0.5rem 0.5rem 0.5rem 0",
                        }}
                      >

                      </Box>
                    )}
                  </TableCell>
                )
            )}
          </TableRow>
          {isExpanded && row.children && renderRows(row.children, level + 1)}
        </React.Fragment>
      );
    });
  };

  if (!data?.length) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            height: "10rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#FFFFFF",
          }}
        >
          {isLoading ? <Loader customWidth={"3rem"} /> : <p>No data</p>}
        </div>
      </div>
    );
  }

  return (
    <TableContainer
      style={{
        width: "100%",
        height: "100%",
        marginBottom: "0.75rem",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "0.5rem",
          height: "0.5rem",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#AECBD0",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#FFFFFF",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#9CBEC4",
        },
      }}
    >
      <Table
        sx={{
          borderCollapse: "separate",
          borderSpacing: "0 0",
        }}
      >
        <TableHead>
          <TableRow
            sx={{ borderRadius: "20px", borderBottom: "1px solid #d3d3d3" }}
          >
            {columns.map((column, i) => (
              <TableCell
                key={String(column.key)}
                sx={{
                  position: i === 0 ? 'sticky' : 'static',
                  left: '0',
                  paddingY: "0.75rem",
                  paddingLeft: "0.5rem",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  lineHeight: "1rem",
                  color: "#F6F8F9",
                  borderLeft: "1px solid #737F86",
                  border: "1px solid #737F86",
                  background: "#2F736E",
                  borderTopRightRadius:
                    i === columns.length - 1 ? "8px" : "0px",
                  borderBottomRightRadius:
                    i === columns.length - 1 ? "8px" : "0px",
                  borderTopLeftRadius: i === 0 ? "8px" : "0px",
                  WebkitBorderBottomLeftRadius: i === 0 ? "8px" : "0px",
                  textAlign: getCellValueAllignment(
                    columnMetadata[column.key]?.type
                  ),
                }}
              >
                {column.renderHeader
                  ? column.renderHeader(column)
                  : column.label}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell
                key={String(column.key)}
                sx={{
                  paddingY: "0.375rem",
                  paddingLeft: "0.5rem",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  color: "#F6F8F9",
                  background: "#FFFFFF",
                  borderBottom: "none"
                }}
              >
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{renderRows(data)}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default FinancialProjectionsTable;
