import { FC, useEffect, useState } from "react";
import { Box, Button, CircularProgress, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Drawer } from "@components";
import { useSimulateProjectionsMutation } from "@api/projections";
import { formatCurrency } from "@utils/common";
import { PredictionDetails } from "./types";
import CustomButton from "../../../../components/CustomButton/CustomButton";


interface SimulateProjectionsSidePanelProps {
  showPanel: boolean;
  setShowPanel: React.Dispatch<React.SetStateAction<boolean>>;
  submoduleId: string;
  gameId: string;
  refetchChartsCallback: () => void
  predictionData: PredictionDetails | undefined
}

const SimulateProjectionsSidePanel: FC<SimulateProjectionsSidePanelProps> = ({
  showPanel,
  setShowPanel,
  submoduleId,
  gameId,
  refetchChartsCallback,
  predictionData
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [cohortSpendDetails, setCohortSpendDetails] = useState<{
    cohortMonth: string;
    totalCalc: string
  }[]>([]);

  const [simulateProjections, { isSuccess: isSimulationSuccess, isLoading: loadingSimulation }]
    = useSimulateProjectionsMutation()

  const cohortDetails = predictionData?.cohortDetails

  const getYearMonthList = () => {
    if (!cohortDetails || cohortDetails?.length === 0) return []

    const firstCohort = cohortDetails?.reduce((earliest: any, item: any) => {
      return item.cohort < earliest ? item.cohort : earliest;
    }, cohortDetails?.[0].cohort);

    const [startYear, startMonth] = firstCohort.split("-").map(Number);
    const months = [];

    for (let i = 0; i < 12; i++) {
      const newYear = startYear + Math.floor((startMonth - 1 + i) / 12);
      const newMonth = ((startMonth - 1 + i) % 12) + 1;
      months.push(`${newYear}-${String(newMonth).padStart(2, "0")}`);
    }

    return months;
  };

  const yearMonthList = getYearMonthList();
  const initialCohortDetailsValue = yearMonthList.map((month) => {
    // Find the matching cohort in cohortDetails
    const matchingCohort = cohortDetails?.find((item: any) => item.cohort === month);

    return {
      cohortMonth: month,
      totalCalc: matchingCohort ? matchingCohort.marketingSpend : "0"
    };
  });

  useEffect(() => {
    setCohortSpendDetails(initialCohortDetailsValue)
  }, [cohortDetails])

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>, startIndex: number) => {
    event.preventDefault();

    const pastedData = event.clipboardData.getData("text");

    const pastedValues = pastedData
      .split(/[\n\t]+/)
      .map((val) => val.trim().replace(/[^0-9.]/g, ""))
      .filter((val) => val !== "");

    setCohortSpendDetails((prevValues) => {
      return prevValues.map((item, index) => {
        if (index >= startIndex && index < startIndex + pastedValues.length) {
          return { ...item, totalCalc: pastedValues[index - startIndex] };
        }
        return item;
      });
    });
  };

  const handleSimulationsSave = async () => {
    simulateProjections({
      submoduleId,
      body: {
        gameId,
        cohortData: cohortSpendDetails
      }
    });
  }

  useEffect(() => {
    if (isSimulationSuccess) {
      setShowPanel(false);
      refetchChartsCallback()
    }
  }, [isSimulationSuccess])


  return (
    <Drawer
      open={showPanel}
      anchor="right"
      close={() => {
        setShowPanel(false);
      }}
      height="98vh"
      zIndex={9999}
      backgroundColor="#F0F0F1"
    >
      <Box
        sx={{
          padding: "1.5rem",
          width: isMobile ? "100%" : '400px',
          height: "100%",
          marginBottom: "5rem",
          overflowY: "auto"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.375rem",
            fontWeight: 700,
            lineHeight: "1.875rem",
            color: "#6C6C6C",
            marginBottom: "1.875rem",
          }}
        >
          Simulate Projections
          <Button
            sx={{ padding: 0, minWidth: "fit-content" }}
            onClick={() => setShowPanel(false)}
          >
            <CloseIcon sx={{ color: "#162C36" }} />
          </Button>
        </Box>
        <Box
          sx={{
            backgroundColor: "#2F736E",
            borderRadius: "0.5rem",
            padding: "0.75rem",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.75rem"
          }}
        >
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: 700,
              fontSize: "0.75rem",
              lineHeight: "1rem",
              color: "#F6F8F9"
            }}
          >
            Cohort
          </Typography>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: 700,
              fontSize: "0.75rem",
              lineHeight: "1rem",
              color: "#F6F8F9"
            }}
          >
            Marketing Spends(USD)
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            width: isMobile ? '85vw' : 'auto'
          }}
        >
          {cohortSpendDetails.map((cohortDetails, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.375rem",
                  color: "#6C6C6C",
                }}
              >
                {cohortDetails.cohortMonth}
              </Typography>
              <TextField
                value={formatCurrency(cohortDetails.totalCalc) || '$0'}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = parseFloat(inputValue.replace(/[^0-9]/g, '')) || 0;
                  setCohortSpendDetails((current) =>
                    current.map((item) =>
                      item.cohortMonth === cohortDetails.cohortMonth
                        ? { ...item, totalCalc: numericValue.toString() }
                        : item
                    )
                  );
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "2px solid #9AD6D1",
                      borderRadius: '8px'
                    },
                    "&:hover fieldset": {
                      borderColor: "#2F736E",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    height: "0.5rem",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    color: "#162936",
                    textAlign: "right",
                  },
                  width: "10rem",
                  backgroundColor: "#FFFFFF",
                  borderRadius: '8px'
                }}
                onPaste={(e) => handlePaste(e, index)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          position: "absolute",
          bottom: "0rem",
          width: isMobile ? "295px" : '400px',
          borderTop: "1px solid #E8EAEB",
          borderRadius: "0rem 0rem 1rem 1rem",
          padding: "1rem 1.5rem",
          backgroundColor: "#F0F0F1",
        }}
      >
        <Button
          onClick={handleSimulationsSave}
          disableRipple
          sx={{
            width: '6.5rem',
            color: '#F6F8F9',
            backgroundColor: '#2F736E',
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: '0.1px',
            borderRadius: '100px',
            padding: '10px 24px',
            textTransform: 'none',
            "&:hover": {
              backgroundColor: "#2F736E",
            },
            ":disabled": {
              pointerEvents: 'none',
              color: '#F6F8F980',
              backgroundColor: '#2F736E80'
            }
          }}
          disabled={!cohortSpendDetails.some((element) => element.totalCalc !== '0')}
        >
          {loadingSimulation ?
            <CircularProgress style={{ width: "1rem", height: "1rem", color: '#F6F8F9' }} />
            : 'Confirm'
          }
        </Button>
        <CustomButton
          borderColor="#2E4A4F"
          color="#6C6C6C"
          name="Cancel"
          onClick={() => {
            setShowPanel(false);
            setCohortSpendDetails(initialCohortDetailsValue);
          }}
        />
      </Box>
    </Drawer>
  )
}

export default SimulateProjectionsSidePanel;