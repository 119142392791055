import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import ImageCarousel from "./components/ImageCarousel"

const imageBucketUrl = `${process.env.REACT_APP_BUCKET_DOMAIN}`;
const images = [
  `${imageBucketUrl}/demo-dashboards/benchmarks_1.png`,
  `${imageBucketUrl}/demo-dashboards/benchmarks_2.png`,
  `${imageBucketUrl}/demo-dashboards/benchmarks_3.png`,
  `${imageBucketUrl}/demo-dashboards/benchmarks_4.png`,
];

const GettingStartedBenchmarks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      gap={"1.75rem"}
      padding={"2rem"}
      sx={{
        overflowY: 'auto',
        maxHeight: '100vh'
      }}>
      <Stack>
        <Typography
          fontSize={"2.25rem"}
          lineHeight={"2.75rem"}
          fontWeight={700}
          color={"#162936"}
        >
          ROAS Benchmarks,
        </Typography>
        <Typography
          fontSize={"2.25rem"}
          lineHeight={"2.75rem"}
          fontWeight={700}
          color={"#B4B4B4"}
        >
          Refresh monthly with constantly expanding data set
        </Typography>
        <Typography
          marginTop={"0.75rem"}
          fontSize={"1rem"}
          lineHeight={"1.25rem"}
          fontWeight={500}
          color={"#6C6C6C"}
          sx={{ width: isMobile ? "100%" : "60%" }}
        >
          Stay up to date with how your cohorts compare to industry peers across categories and genres.
        </Typography>
      </Stack>

      <Box>
        <ImageCarousel images={images} />
      </Box>
    </Stack>
  )
}

export default GettingStartedBenchmarks