import { createSlice } from '@reduxjs/toolkit';
import { DashboardFilterState, Dashboard } from './types/dashboardFilters';

const initialState: DashboardFilterState = {
  dashboards: []
};

export const DashboardFilterData = createSlice({
  name: 'DashboardFilters',
  initialState,
  reducers: {
    setDashboardFilter: (state: DashboardFilterState, { payload }: { payload: Dashboard }) => {
      return { ...state, dashboards: { ...state.dashboards, ...payload } };
    },
    resetDashboardFilter: () => {
      return initialState;
    }
  }
});

export const {
  setDashboardFilter,
  resetDashboardFilter,
} = DashboardFilterData.actions;

export default DashboardFilterData.reducer;
