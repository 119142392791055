import { FC, useEffect, useState } from "react";
import { Box, Typography, Tabs, Tab, useMediaQuery, useTheme, Button } from "@mui/material";
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import useCharts from "@hooks/api/useCharts";
import useChartById from "@hooks/api/useChartById";
import { FUNDING_TYPES } from "@api/investments";
import { FullPageLoader, GenericTable } from "@components";
import {
    calculatePivotTotals,
    processNestedRowDataWithMetadata,
    processPivotDataWithMetadata,
    processRowDataWithMetadata,
} from "../../../components/GenericTable/tableDataUtil";
import Pagination from "../components/Pagination";
import GamePerformanceFilters from "../components/GamePerformance/GamePerformanceFilters";
import TransactionTypesSidePanel from "../components/GamePerformance/TransactionTypesSidePanel";

enum GAME_PERFORMANCE_CHART_NAMES {
    GameRevenueDetails = "Revenue Details",
    Ledger = "Ledger",
    RevenueTrend = "Revenue Trend",
    IRRTrend = "IRR Trend",
    MOICTrend = "MOIC Trend",
    ExposureTrend = "Exposure Trend",
    CollectionTrend = "Collection Trend",
    FinancingTrend = "Financing Trend"
}

const PAGE_SIZE = 50;

interface FundingData {
    amount: number;
    cohort: string;
    gameId: string;
    id: string;
    investor: string
    settlementDate: string;
    type: string;
}

const GamePerformance: FC<{ id: string }> = ({ id }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);
    const [dashboardData, setDashboardData] = useState<
        { id: string; name: string; chartType: string, metadata: string }[]
    >([]);
    const [chartData, setChartData] = useState<
        { id: string; name: string; columns: any[]; rows: any[]; meta: any }[]
    >([]);
    const [selectedTab, setSelectedTab] = useState<string>(
        GAME_PERFORMANCE_CHART_NAMES.CollectionTrend
    );
    const [pageState, setPageState] = useState<Record<string, number>>({});
    const [filterSelection, setFilterSelection] = useState<Record<string, string>>({});
    const [showTransactionTypesSidePanel, setShowTransactionTypesSidePanel] = useState(false);

    const { getChartData } = useCharts();
    const { getChartDataById } = useChartById();

    // Hook to fetch dashboard data on load
    useEffect(() => {
        if (id) {
            getChartData(id).then((response) => {
                if (response) {
                    setDashboardData(response);
                }
            });
        }
    }, [id]);

    // Hook to fetch all chart on filter update
    useEffect(() => {
        if (Object.keys(filterSelection).length > 0) {
            fetchDashboardData(filterSelection);
        }
    }, [filterSelection]);

    const fetchDashboardData = async (filters?: Record<string, string>) => {
        if (id && dashboardData?.length) {
            const chartsById: any[] = [];
            try {
                await Promise.all(
                    dashboardData.map(
                        async (chart: {
                            id: string;
                            name: string;
                            chartType: string;
                            metadata: string;
                        }) => {
                            const parsedMetadata = JSON.parse(chart.metadata);
                            const response = await getChartDataById(id, chart.id, filters, parsedMetadata?.sort?.fields, parsedMetadata?.sort?.order);
                            if (response) {
                                const { rows, columns, metadata } = formatChartResponseData(response);
                                chartsById.push({
                                    columns,
                                    rows,
                                    id: chart.id,
                                    name: response.name,
                                    meta: metadata,
                                });
                            }
                        }
                    )
                );

                setChartData(chartsById);
            } catch (error) {
                console.error("Error fetching chart data:", error);
            } finally {
                if (chartsById.length) {
                    chartsById.forEach((chart) => {
                        handlePageChange(chart.name, 1);
                    })
                }

                setDashboardLoader(false);
            }
        }
    };

    const formatChartResponseData = (response: any) => {
        let columns = [];
        let rows = [];
        const metadata = JSON.parse(response.metadata);
        if (metadata.chartType === "MULTI_LEVEL_TABLE") {
            const { mappedColumns, mappedData } =
                processNestedRowDataWithMetadata(
                    response.name,
                    metadata,
                    response.result.data,
                    [["fundingMonth", "cohort"]]
                );
            columns = mappedColumns;
            rows = mappedData;
        } else if (metadata.chartType === "PIVOT_TABLE") {
            const { mappedColumns, mappedData } =
                processPivotDataWithMetadata(
                    response.name,
                    metadata,
                    response.result.columns,
                    response.result.data,
                );
            columns = mappedColumns;
            rows = mappedData;
        } else {
            const { mappedColumns, mappedData } =
                processRowDataWithMetadata(
                    response.name,
                    metadata,
                    response.result.data
                );
            columns = mappedColumns;
            rows = mappedData.map((data: FundingData) => ({
                ...data,
                type: FUNDING_TYPES[data.type] || data.type,
            }))
        }
        return { rows, columns, metadata };
    };

    const handlePageChange = (chartName: string, newPage: number) => {
        setPageState((prev) => ({ ...prev, [chartName]: newPage }));
    };

    const getChartByName = (name: string) => {
        const chart = chartData.find((chart) => chart.name === name);

        const currentPage = pageState[name] || 1;
        const paginatedRows = chart?.rows?.slice(
            (currentPage - 1) * PAGE_SIZE,
            currentPage * PAGE_SIZE
        ) || [];

        if (chart) {
            const isTabbedChart = [
                GAME_PERFORMANCE_CHART_NAMES.RevenueTrend,
                GAME_PERFORMANCE_CHART_NAMES.IRRTrend,
                GAME_PERFORMANCE_CHART_NAMES.MOICTrend,
                GAME_PERFORMANCE_CHART_NAMES.ExposureTrend,
                GAME_PERFORMANCE_CHART_NAMES.CollectionTrend,
                GAME_PERFORMANCE_CHART_NAMES.FinancingTrend
            ].includes(name as GAME_PERFORMANCE_CHART_NAMES);

            const conditionallyMappedColumns = chart.columns.map((col: any) => ({
                ...col,
                ...(col.key === "fundingMonth/cohort" && {
                    render: (key: string, row: any, level?: number) => {
                        if (level === 1) {
                            return (
                                <Box
                                    sx={{
                                        width: "100%",
                                        justifyContent: "left",
                                        display: "flex"
                                    }}>
                                    <Typography
                                        fontSize={"0.875rem"}
                                        fontWeight={550}
                                    >
                                        {`${row["fundingMonth/cohort"] || "-"}`}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#51B8B0",
                                            fontFamily: "Manrope, sans-serif",
                                            fontWeight: 500,
                                            fontSize: "0.875rem",
                                            lineHeight: "1.25rem",
                                            pl: "0.25rem"
                                        }}
                                    >
                                        c
                                    </Typography>
                                </Box>
                            );
                        }

                        return (
                            <Box
                                sx={{
                                    width: "100%",
                                    textAlign: "left"
                                }}>
                                <Typography
                                    fontSize={"0.875rem"}
                                    fontWeight={550}
                                >
                                    {`${row["fundingMonth/cohort"] || "-"}`}
                                </Typography>
                            </Box>
                        );
                    },
                    renderHeader: (column: any) => {
                        return (
                            <Box
                                sx={{
                                    width: "100%",
                                    textAlign: 'left',
                                }}>
                                {column.label}
                            </Box>
                        )
                    }
                }),
                ...(col.key === "game" && {
                    render: (key: string, row: any, level?: number) => {
                        return (
                            <Box
                                sx={{
                                    width: "100%",
                                    justifyContent: "left",
                                    display: "flex"
                                }}>
                                <Typography
                                    fontSize={"0.875rem"}
                                    fontWeight={550}
                                >
                                    {`${row["game"] === "-" ? "All" : row["game"]}`}
                                </Typography>
                            </Box>
                        );
                    },
                    renderHeader: (column: any) => {
                        return (
                            <Box
                                sx={{
                                    width: "100%",
                                    textAlign: 'left',
                                }}>
                                {column.label}
                            </Box>
                        )
                    }
                })
            }));

            return (
                <Box
                    id={chart.name}
                    key={chart.name}
                    sx={{
                        width: "100%",
                        height: "fit-content",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        padding: "0.75rem",
                        marginY: "1rem",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                lineHeight: "1.375rem",
                                fontWeight: 700,
                                color: "#162936",
                            }}
                        >
                            {isTabbedChart ? "Trend" : chart.name}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex"
                            }}
                        >
                            {chart.name === "Ledger" &&
                                <Button
                                    sx={{
                                        textTransform: "none",
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: isMobile ? "1rem" : ""
                                    }}
                                    onClick={() => { setShowTransactionTypesSidePanel(true) }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#51B8B0",
                                            fontFamily: "Manrope, sans-serif",
                                            fontWeight: 700,
                                            fontSize: "0.75rem",
                                            lineHeight: "1rem"
                                        }}
                                    >
                                        Transaction Types
                                    </Typography>
                                    <KeyboardArrowRightOutlinedIcon
                                        sx={{
                                            color: "#51B8B0",
                                            fontWeight: 700,
                                            lineHeight: "1rem",
                                            fontSize: "1rem"
                                        }}
                                    />
                                </Button>
                            }
                            <Pagination
                                page={currentPage}
                                size={PAGE_SIZE}
                                total={chart.rows.length}
                                handleChange={(newPage) => handlePageChange(name, newPage)}
                                fileName={chart.name}
                                chartId={chart.id}
                                submoduleId={id}
                                filterSelection={filterSelection}
                                disablePagination={isTabbedChart}
                            />
                        </Box>
                    </Box>
                    {isTabbedChart ? (
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                            sx={{
                                flexDirection: "column",
                                alignItems: "flex-start",
                                ".MuiTabs-indicator": {
                                    backgroundColor: "#51B8B0",
                                    height: "3px",
                                    borderRadius: "5px",
                                    overflowX: "auto"
                                },
                                ".MuiTabs-scroller": {
                                    overflowX: "auto !important",
                                    scrollbarWidth: "thin"
                                }
                            }}
                        >
                            <Tab
                                label="Collection"
                                value={GAME_PERFORMANCE_CHART_NAMES.CollectionTrend}
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    color: "#6C6C6C",
                                    "&.Mui-selected": {
                                        color: "#51B8B0",
                                    },
                                    alignItems: "center",
                                }}
                            />
                            <Tab
                                label="Financing"
                                value={GAME_PERFORMANCE_CHART_NAMES.FinancingTrend}
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    color: "#6C6C6C",
                                    "&.Mui-selected": {
                                        color: "#51B8B0",
                                    },
                                    alignItems: "center",
                                }}
                            />
                            <Tab
                                label="Recoup"
                                value={GAME_PERFORMANCE_CHART_NAMES.RevenueTrend}
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    color: "#6C6C6C",
                                    "&.Mui-selected": {
                                        color: "#51B8B0",
                                    },
                                    alignItems: "center",
                                }}
                            />
                            <Tab
                                label="IRR"
                                value={GAME_PERFORMANCE_CHART_NAMES.IRRTrend}
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    color: "#6C6C6C",
                                    "&.Mui-selected": {
                                        color: "#51B8B0",
                                    },
                                    alignItems: "center",
                                }}
                            />
                            <Tab
                                label="MOIC"
                                value={GAME_PERFORMANCE_CHART_NAMES.MOICTrend}
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    color: "#6C6C6C",
                                    "&.Mui-selected": {
                                        color: "#51B8B0",
                                    },
                                    alignItems: "center",
                                }}
                            />
                            <Tab
                                label="Exposure"
                                value={GAME_PERFORMANCE_CHART_NAMES.ExposureTrend}
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: 700,
                                    textTransform: "none",
                                    color: "#6C6C6C",
                                    "&.Mui-selected": {
                                        color: "#51B8B0",
                                    },
                                    alignItems: "center",
                                }}
                            />
                        </Tabs>
                    ) : null}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {isTabbedChart && paginatedRows.length > 0 &&
                            <Box
                                sx={{
                                    border: "0.44px solid #E8EAEB",
                                    padding: "0.243rem 0.609rem",
                                    borderRadius: "1.772rem",
                                    width: "fit-content",
                                    marginTop: "1rem",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Manrope, sans-serif",
                                        fontWeight: 700,
                                        fontSize: "0.8rem",
                                        lineHeight: "1rem",
                                        color: "#162936",
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    Settlement Month
                                    <ArrowRightAltOutlinedIcon />
                                </Typography>
                            </Box>}
                    </Box>
                    <GenericTable
                        data={
                            (isTabbedChart && ![GAME_PERFORMANCE_CHART_NAMES.MOICTrend, GAME_PERFORMANCE_CHART_NAMES.IRRTrend].includes(chart.name as GAME_PERFORMANCE_CHART_NAMES))
                                ? ([...chart?.rows, calculatePivotTotals(conditionallyMappedColumns[0].key, chart?.rows)] || [])
                                : paginatedRows
                        }
                        columns={conditionallyMappedColumns}
                        meta={chart.meta}
                        depth={chart.name === "Revenue Details" ? 1 : null}
                        showTotal={(isTabbedChart && ![GAME_PERFORMANCE_CHART_NAMES.MOICTrend, GAME_PERFORMANCE_CHART_NAMES.IRRTrend].includes(chart.name as GAME_PERFORMANCE_CHART_NAMES))}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        {chart.name === "Revenue Details" &&
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    border: "1px solid #D8DBDD",
                                    borderRadius: "0.25rem",
                                    padding: "0.25rem 0.5rem",
                                    width: "fit-content",
                                    gap: "0.25rem"
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#51B8B0",
                                        fontFamily: "Manrope, sans-serif",
                                        fontWeight: 500,
                                        fontSize: "0.875rem",
                                        lineHeight: "1.25rem"
                                    }}
                                >
                                    c
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#6C6C6C",
                                        fontFamily: "Manrope, sans-serif",
                                        fontWeight: 500,
                                        fontSize: "0.75rem",
                                        lineHeight: "1rem"
                                    }}
                                >
                                    : Cohort Month
                                </Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            );
        } else if (chart && !paginatedRows.length) {
            return (
                <Box
                    id={'no-data-chart'}
                    key={'no-data-chart'}
                    sx={{
                        width: "100%",
                        height: "fit-content",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        padding: "0.75rem",
                        marginY: "1rem",
                    }}
                >
                    <GenericTable data={[]} columns={[]} meta={{}} />
                </Box>
            );
        } else {
            return null;
        }
    };

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            {dashboardLoader && <FullPageLoader hasSideNav={true} />}
            <div
                id="pvx-dashboard-container"
                style={{
                    width: "100%",
                    height: "100vh",
                    border: "none",
                    overflow: "auto",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                }}
            >
                <Typography
                    fontSize={"2rem"}
                    fontWeight={600}
                    sx={{ color: "#1B1C17", mt: "0.5rem", mb: "1.5rem" }}
                >
                    Deal Performance
                </Typography>
                <GamePerformanceFilters id={id} onFilterChange={(selections) => setFilterSelection(selections)} />
                <TransactionTypesSidePanel
                    showTransactionTypesSidePanel={showTransactionTypesSidePanel}
                    setShowTransactionTypesSidePanel={setShowTransactionTypesSidePanel}
                />

                {getChartByName(GAME_PERFORMANCE_CHART_NAMES.GameRevenueDetails)}
                {getChartByName(selectedTab)}
                {getChartByName(GAME_PERFORMANCE_CHART_NAMES.Ledger)}
            </div >
        </>
    );
};

export default GamePerformance;
