import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Circle } from "@mui/icons-material";
import { CustomDropdown, FullPageLoader } from "@components";
import { RootState } from "@stores/store";
import useCharts from "@hooks/api/useCharts";
import { useLazyGetUserModuleGamesQuery } from "@api/userModules";
import { FinancialProjectionTabs } from "../components/FinancialProjections/types";
import CurrentCohortProjections from "../components/FinancialProjections/CurrentCohortsProjections";
import FutureCohortsProjections from "../components/FinancialProjections/FutureCohortsProjections";
import CombinedCohortsProjections from "../components/FinancialProjections/CombinedCohortsProjections";

const FinancialProjections: React.FC<{ id: string }> = ({ id }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const modules = useSelector((state: RootState) => state?.ModulesData);
  const dashboardFilters = useSelector((state: RootState) => state?.DashboardFiltersData);
  const financialProjectionsSelections = dashboardFilters.dashboards["FinancialProjections" as any];

  const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);
  const [gamesList, setGamesList] = useState<any[]>([])
  const [selectedGame, setSelectedGame] = useState<{ id: string, label: string }>({ id: "", label: "" });
  const [selectedTab, setSelectedTab] = useState<FinancialProjectionTabs>();
  const [projectionsTabs, setProjectionsTabs] = useState<{ id: string; label: string }[]>([]);
  const [dashboardData, setDashboardData] = useState<
    { id: string; name: string; chartType: string, metadata: string }[]
  >([]);
  const [subModuleIdMapping, setSubModuleIdMapping] = useState<{ [key: string]: string }>({});
  const [subModuleId, setSubModuleId] = useState<string>(id);

  const [getUserModuleGames] = useLazyGetUserModuleGamesQuery();
  const { getChartData } = useCharts()

  useEffect(() => {
    if (modules.loadCount === 0) return;

    let financialProjection = null;
    for (const module of modules.modules) {
      if (module.name === "Financial Projections") {
        financialProjection = module;
        break;
      }
    }

    if (!financialProjection) return;

    const tabs: { id: string; label: string }[] = [];
    const idMapping: Record<string, string> = {};
    for (const submodule of financialProjection.submodules || []) {
      if (submodule.submoduleType) {
        tabs.push({ id: submodule.submoduleType, label: submodule.name });
        idMapping[submodule.submoduleType] = submodule.id;
      }
    }

    setSelectedTab(tabs[0].id as FinancialProjectionTabs);
    setProjectionsTabs(tabs);
    setSubModuleIdMapping(idMapping);
  }, [modules]);

  useEffect(() => {
    const fetchGames = async () => {
      const res = await getUserModuleGames({ submoduleId: subModuleId, gamesParam: [] });
      if (res.data) {
        const gamesList = res.data.data;
        const sortedGamesList = [...gamesList].sort((a: any, b: any) => a.name.localeCompare(b.name));

        const selectedGame = sortedGamesList[0]
        setGamesList(sortedGamesList);

        if (financialProjectionsSelections && Object.keys(financialProjectionsSelections).length) {
          setSelectedGame({ id: financialProjectionsSelections?.id || '', label: financialProjectionsSelections?.label || '' })
        } else {
          setSelectedGame({ id: selectedGame?.gameId || '', label: selectedGame?.name || '' })
        }
      }
    };

    if (!gamesList.length && subModuleId) {
      fetchGames();
    }
  }, []);

  useEffect(() => {
    if (subModuleId) {
      getChartData(subModuleId).then((response) => {
        if (response) {
          setDashboardData(response);
        }
      });
    }
  }, [subModuleId]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: FinancialProjectionTabs) => {
    setSubModuleId(subModuleIdMapping[newValue]);
    setSelectedTab(newValue);
  }

  const projectionsMap = {
    [FinancialProjectionTabs.CURRENT_COHORTS]: CurrentCohortProjections,
    [FinancialProjectionTabs.FUTURE_COHORTS]: FutureCohortsProjections,
    [FinancialProjectionTabs.COMBINED_COHORTS]: CombinedCohortsProjections,
  };

  const SelectedProjection = selectedTab ? projectionsMap[selectedTab] : null;

  return (
    <>
      {dashboardLoader && <FullPageLoader hasSideNav={true} />}
      <div
        id="pvx-roas-projection-container"
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "auto",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: isMobile ? "column" : "row",
            mt: "0.5rem",
            mb: "1.5rem"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "0.5rem",
            }}
          >
            <Typography
              fontSize={isMobile ? "1.5rem" : "2rem"}
              fontWeight={600}
              sx={{ color: "#1B1C17", }}
            >
              Financial Projections: {gamesList?.length === 1 ? gamesList[0]?.name : ""}
            </Typography>
            {(gamesList?.length || 0) > 1 ? (
              <CustomDropdown
                dropdownOptions={gamesList.map((game) => ({
                  id: game.gameId,
                  label: game.name
                }))}
                handleChange={(e) => {
                  const currentGame = gamesList.find((item) => item.gameId === e.target.value)
                  setSelectedGame({ id: currentGame.gameId, label: currentGame.name })
                }}
                selectedOption={selectedGame?.id?.toString() || ""}
                wrapperSx={{
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  color: "#1B1C17",
                  lineHeight: "none",
                  border: "1px solid #2F736E46",
                }}
              />
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: isMobile ? "0.6rem" : "1rem",
              right: "0rem"
            }}
          >
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 700,
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                color: "#6C6C6C"
              }}
            >
              $ = USD
            </Typography>
          </Box>
        </Box>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{
            flexDirection: "column",
            alignItems: "flex-start",
            backgroundColor: 'white',
            borderRadius: '12px 12px 0px 0px',
            borderBottom: '1px solid #F0F0F1',
            ".MuiTabs-indicator": {
              backgroundColor: "#51B8B0",
              height: "2px",
              borderRadius: "5px",
              overflowX: "auto"
            },
            ".MuiTabs-scroller": {
              overflowX: "auto !important",
              scrollbarWidth: "thin"
            }
          }}
        >
          {projectionsTabs.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              value={tab.id}
              icon={<Circle style={{ height: '12px' }} />}
              iconPosition="start"
              sx={{
                paddingY: '0px',
                height: '48px',
                minHeight: '48px',
                fontSize: "0.9rem",
                fontWeight: 700,
                textTransform: "none",
                color: "#6C6C6C",
                "&.Mui-selected": {
                  color: "#51B8B0",
                },
                alignItems: "center",
              }}
            />
          ))}
        </Tabs>
        {subModuleId && SelectedProjection && (
          <SelectedProjection
            submoduleId={subModuleId}
            selectedGame={selectedGame}
            setDashboardLoader={setDashboardLoader}
            dashboardCharts={dashboardData}
          />
        )}
      </div>
    </>
  );
}

export default FinancialProjections;