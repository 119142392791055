import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { setToastMessage } from "@stores/App/slice";
import { CustomTooltip } from "@components";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import DrawerComponent from "../../../../components/Drawer/Drawer";
import CustomTextField from "../../../../components/CustomTextField/CustomTextField";
import FundingRequestDetailsCard from "./FundingRequestDetailsCard";
import {
  INVESTMENT_STATUS,
  useCreateAdjustmentRequestMutation,
  useCreateInvestmentRequestMutation,
  useLazyDownloadInvestmentAttachmentsByRequestIdQuery,
  useLazyGetGameDealStructureByIdQuery,
  useLazyGetInvestmentAttachmentsByRequestIdQuery,
  useModifyAdjustmentRequestMutation,
  useModifyInvestmentRequestMutation,
} from "@api/investments";
import FundingRequestAdjustmentDetailsCard from "./FundingRequestAdjustmentDetailsCard";
import { TOAST_TYPES } from "@constants/constants";

interface FundingRequestSidePanelProps {
  showFundingRequestPanel: boolean;
  setShowFundingRequestPanel: React.Dispatch<React.SetStateAction<boolean>>;
  fundingRequestId?: string;
  gameId?: string;
  fundingRequestDetails?: any;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface AmountInputState {
  value: number | null;
  displayValue: string;
}

const FundingRequestSidePanel: React.FC<FundingRequestSidePanelProps> = ({
  showFundingRequestPanel,
  setShowFundingRequestPanel,
  fundingRequestId = "",
  gameId = "",
  fundingRequestDetails = {},
  setShowModal,
}) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setLoading] = useState<boolean>(false);
  const [cohortMonth, setCohortMonth] = useState<string>("");

  const [netSpend, setNetSpend] = useState<AmountInputState>({
    value: null,
    displayValue: "",
  });
  const [growthSpend, setGrowthSpend] = useState<AmountInputState>({
    value: null,
    displayValue: "",
  });
  const [investmentAmount, setInvestmentAmount] = useState<AmountInputState>({
    value: null,
    displayValue: "",
  });
  const [experimentalMarketingSpend, setExperimentalMarketingSpend] =
    useState<AmountInputState>({ value: null, displayValue: "" });
  const [nextMonthExpectedSpend, setNextMonthExpectedSpend] =
    useState<AmountInputState>({ value: null, displayValue: "" });
  const [actualSpend, setActualSpend] = useState<AmountInputState>({
    value: null,
    displayValue: "",
  });

  const [allowedCohort, setAllowedCohort] = useState<string | null>(null);
  const [attachments, setAttachments] = useState([]);
  const [showAdjustmentRequest, setShowAdjustmentRequest] = useState(false);
  const [showEditRequest, setShowEditRequest] = useState(false);

  const [createInvestmentRequest] = useCreateInvestmentRequestMutation();
  const [modifyInvestmentRequest] = useModifyInvestmentRequestMutation();
  const [createAdjustmentRequest] = useCreateAdjustmentRequestMutation();
  const [modifyAdjustmentRequest] = useModifyAdjustmentRequestMutation();
  const [getInvestmentAttachmentsByRequestId] =
    useLazyGetInvestmentAttachmentsByRequestIdQuery();
  const [getGameDealStructureById] = useLazyGetGameDealStructureByIdQuery();
  const [downloadInvestmentAttachmentsByRequestId] =
    useLazyDownloadInvestmentAttachmentsByRequestIdQuery();

  // Hook to reset fields
  useEffect(() => {
    if (!showFundingRequestPanel) {
      setCohortMonth("");
      setNetSpend({ value: null, displayValue: "" });
      setGrowthSpend({ value: null, displayValue: "" });
      setInvestmentAmount({ value: null, displayValue: "" });
      setExperimentalMarketingSpend({ value: null, displayValue: "" });
      setNextMonthExpectedSpend({ value: null, displayValue: "" });
      setActualSpend({ value: null, displayValue: "" });
    }
  }, [showFundingRequestPanel]);

  // Hook to fetch allowed cohort
  useEffect(() => {
    const getAllowedCohorts = async () => {
      const res = await getGameDealStructureById({ gameId }).unwrap();
      if (res?.data?.allowedFundingCohort) {
        setAllowedCohort(res.data.allowedFundingCohort);
      }
    };

    if (!allowedCohort && gameId) {
      getAllowedCohorts();
    }
  }, [allowedCohort, gameId]);

  // Hook to get attachments
  useEffect(() => {
    const getAttachments = async () => {
      const res = await getInvestmentAttachmentsByRequestId({
        requestId: fundingRequestId,
      }).unwrap();
      if (res?.data?.length) {
        const attachments = res.data;
        const fundingRequestAttachment = attachments.find(
          (d: any) => d.requestType === "FUNDING_REQUEST"
        );
        const otherAttachments = attachments.filter(
          (d: any) => d.requestType !== "FUNDING_REQUEST"
        );
        const orderedAttachments = fundingRequestAttachment
          ? [
              fundingRequestAttachment.attachmentId,
              ...otherAttachments.map((d: any) => d.attachmentId),
            ]
          : otherAttachments.map((d: any) => d.attachmentId);

        setAttachments(orderedAttachments);
      }
    };

    if (fundingRequestId) {
      getAttachments();
    }
  }, [fundingRequestId]);

  useEffect(() => {
    setCohortMonth(generateCohortOptions()[0].value);
  }, [showFundingRequestPanel]);

  useEffect(() => {
    if (showEditRequest) {
      const totalSpend =
        (fundingRequestDetails?.expectedSpend || 0) +
        (fundingRequestDetails?.experimentalSpend || 0);
      setNetSpend({
        value: totalSpend,
        displayValue: formatCurrency(totalSpend.toString()),
      });
      setGrowthSpend({
        value: fundingRequestDetails?.expectedSpend || 0,
        displayValue: formatCurrency(
          (fundingRequestDetails?.expectedSpend || 0).toString()
        ),
      });
      setInvestmentAmount({
        value: fundingRequestDetails?.requestedFund || 0,
        displayValue: formatCurrency(
          (fundingRequestDetails?.requestedFund || 0).toString()
        ),
      });
      setExperimentalMarketingSpend({
        value: fundingRequestDetails?.experimentalSpend || 0,
        displayValue: formatCurrency(
          (fundingRequestDetails?.experimentalSpend || 0).toString()
        ),
      });
      setNextMonthExpectedSpend({
        value: fundingRequestDetails?.nextExpectedSpend || 0,
        displayValue: formatCurrency(
          (fundingRequestDetails?.nextExpectedSpend || 0).toString()
        ),
      });
    }

    if (showAdjustmentRequest) {
      setActualSpend({
        value: fundingRequestDetails?.actualSpend || 0,
        displayValue: formatCurrency(
          (fundingRequestDetails?.actualSpend || 0).toString()
        ),
      });
    }
  }, [showEditRequest, showAdjustmentRequest]);

  useEffect(() => {
    // Set Growth Spend
    if (netSpend.value !== null) {
      const expValue = experimentalMarketingSpend.value || 0;
      const newGrowthValue = netSpend.value - expValue;
      setGrowthSpend({
        value: newGrowthValue,
        displayValue: formatCurrency(newGrowthValue.toString()),
      });

      // Adjust Investment amount
      if (
        experimentalMarketingSpend.value !== null &&
        investmentAmount.value !== null &&
        Number((netSpend.value - experimentalMarketingSpend.value) * 0.8) <
          investmentAmount.value
      ) {
        const newInvestmentValue = Number(
          (netSpend.value - experimentalMarketingSpend.value) * 0.8
        );
        setInvestmentAmount({
          value: newInvestmentValue,
          displayValue: formatCurrency(newInvestmentValue.toString()),
        });
      }
    } else {
      growthSpend.value &&
        setGrowthSpend({
          value: null,
          displayValue: "",
        });
      investmentAmount.value &&
        setInvestmentAmount({
          value: null,
          displayValue: "",
        });
      experimentalMarketingSpend.value &&
        setExperimentalMarketingSpend({
          value: null,
          displayValue: "",
        });
    }
  }, [netSpend, investmentAmount, experimentalMarketingSpend]);

  const formatCurrency = (value: number | string): string => {
    if (!value || value === "0" || value === "0.00") {
      return "";
    }

    let formattedValue = `${value}`.replace(/[^0-9.]/g, "");

    if (formattedValue.includes(".")) {
      const [integerPart, decimalPart] = formattedValue.split(".");
      formattedValue = `$${Number(integerPart).toLocaleString(
        "en-US"
      )}.${decimalPart.slice(0, 2)}`;

      // If there is a dot and no decimal values, add two zeros
      if (decimalPart === "") {
        formattedValue = `$${Number(integerPart).toLocaleString("en-US")}.`;
      }
    } else {
      formattedValue = `$${Number(formattedValue).toLocaleString("en-US")}`;
    }

    return formattedValue;
  };

  const downloadAttachment = async (index: number, isAdjustment?: boolean) => {
    if (attachments?.length) {
      const downloadLink = await downloadInvestmentAttachmentsByRequestId({
        requestId: fundingRequestDetails.id,
        attachmentId: attachments[index],
      }).unwrap();
      if (downloadLink?.data?.url) {
        try {
          const link = document.createElement("a");
          link.href = downloadLink.data.url;
          link.target = "_blank";
          link.download = !isAdjustment
            ? "Investment Form.pdf"
            : "Adjustment Request Form.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (err) {
          console.error("Download failed", err);
        }
      }
    }
  };

  const handleSubmitRequest = async () => {
    if (gameId) {
      let result = null;
      try {
        if (!fundingRequestId) {
          // Create investment request
          const payload = {
            gameId: gameId,
            cohort: allowedCohort ? allowedCohort : cohortMonth.slice(0, 7),
            expectedSpend: growthSpend.value,
            requestedFund: investmentAmount.value,
            nextExpectedSpend: nextMonthExpectedSpend.value,
            experimentalSpend: experimentalMarketingSpend.value,
          };

          result = await createInvestmentRequest(payload).unwrap();
        } else if (
          fundingRequestId &&
          INVESTMENT_STATUS.APPROVED !== fundingRequestDetails.status &&
          !fundingRequestDetails.fundingAdjustmentReported
        ) {
          // Edit investment request
          const payload = {
            requestId: fundingRequestDetails.id,
            cohort: allowedCohort
              ? allowedCohort
              : cohortMonth.slice(0, 7) || fundingRequestDetails.cohort,
            expectedSpend:
              growthSpend.value || fundingRequestDetails.expectedSpend,
            requestedFund:
              investmentAmount.value || fundingRequestDetails.requestedFund,
            nextExpectedSpend:
              nextMonthExpectedSpend.value ||
              fundingRequestDetails.nextExpectedSpend,
            experimentalSpend:
              experimentalMarketingSpend.value ||
              fundingRequestDetails.experimentalSpend,
          };

          result = await modifyInvestmentRequest(payload).unwrap();
        } else if (
          fundingRequestId &&
          !fundingRequestDetails.fundingAdjustmentReported &&
          INVESTMENT_STATUS.APPROVED === fundingRequestDetails.status
        ) {
          // Create adjustment request
          const payload = {
            requestId: fundingRequestId,
            actualSpend: actualSpend.value || fundingRequestDetails.actualSpend,
          };

          result = await createAdjustmentRequest(payload).unwrap();
        } else if (
          fundingRequestId &&
          fundingRequestDetails.fundingAdjustmentReported &&
          INVESTMENT_STATUS.APPROVED !== fundingRequestDetails.status
        ) {
          // Edit adjustment request
          const payload = {
            requestId: fundingRequestId,
            actualSpend: actualSpend.value || fundingRequestDetails.actualSpend,
          };

          result = await modifyAdjustmentRequest(payload).unwrap();
        } else {
          return;
        }

        if (result?.data) {
          setShowFundingRequestPanel(false);
          setShowModal && setShowModal(true);
        }
      } catch (error: any) {
        dispatch(
          setToastMessage({
            type: TOAST_TYPES.ERROR,
            message:
              error?.error?.message ||
              `Could not submit request, please retry.`,
          })
        );
      }
    }
  };

  const handleAmountFieldChange = (
    field: string,
    value: string,
    stateSetter: React.Dispatch<React.SetStateAction<AmountInputState>>
  ) => {
    const cleanedValue = value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1")
      .replace(/(\.\d{2})\d+/g, "$1");

    // Check if valid input (allows for partial inputs like "100." or ".5")
    const isValidInput = /^\d*\.?\d{0,2}$/.test(cleanedValue);

    if (!isValidInput) {
      return;
    }

    const numericValue =
      cleanedValue === "" || cleanedValue === "."
        ? null
        : parseFloat(cleanedValue);

    if (numericValue && numericValue > 100000000) {
      return;
    }

    if (
      (field === "experimental-marketing-spend" &&
        netSpend.value !== null &&
        numericValue !== null &&
        numericValue >= netSpend.value) ||
      (field === "investment-amount" && !netSpend.value)
    ) {
      return;
    }

    let finalValue = numericValue;
    let finalDisplayValue = cleanedValue;

    if (
      field === "investment-amount" &&
      growthSpend.value !== null &&
      numericValue !== null &&
      numericValue > growthSpend.value * 0.8
    ) {
      finalValue = growthSpend.value * 0.8;
      finalDisplayValue = finalValue.toString();
    }

    stateSetter({
      value: finalValue,
      displayValue: formatCurrency(finalDisplayValue),
    });
  };

  const getTotalGrowthSpend = () => {
    const growth =
      growthSpend.value !== null
        ? growthSpend.value
        : fundingRequestDetails?.expectedSpend || 0;

    const uaSpend =
      experimentalMarketingSpend.value !== null
        ? experimentalMarketingSpend.value
        : fundingRequestDetails?.experimentalSpend || 0;

    return Number(growth + uaSpend);
  };

  const getActualGrowthSpend = () => {
    const totalSpend = netSpend.value !== null ? netSpend.value : 0;
    const uaSpend =
      experimentalMarketingSpend.value !== null
        ? experimentalMarketingSpend.value
        : 0;

    return Number(totalSpend - uaSpend);
  };

  const getFundingPercentage = (isAdjustment?: boolean) => {
    let val = 0;
    if (
      !isAdjustment &&
      investmentAmount.value !== null &&
      growthSpend.value !== null
    ) {
      val = Math.round(
        (Number(investmentAmount.value) / Number(growthSpend.value)) * 100
      );
    } else {
      if (growthSpend.value !== null || investmentAmount.value !== null) {
        const requestedInvestment =
          investmentAmount.value !== null
            ? investmentAmount.value
            : fundingRequestDetails?.requestedFund;
        const reportingSpend =
          growthSpend.value !== null
            ? growthSpend.value
            : fundingRequestDetails?.expectedSpend;
        val = Math.round(
          (Number(requestedInvestment) / Number(reportingSpend)) * 100
        );
      } else {
        val = Math.round(fundingRequestDetails?.fundingPercentage * 100);
      }
    }
    return val ? `${val}%` : "-";
  };

  const generateCohortOptions = () => {
    const options = [];
    for (let i = 0; i < 4; i++) {
      const date = dayjs().subtract(i, "month");
      options.push({
        value: date.format("YYYY-MM"),
        label: date.format("YYYY-MM"),
      });
    }
    return fundingRequestId
      ? [
          {
            value: fundingRequestDetails?.cohort,
            label: fundingRequestDetails?.cohort,
          },
          ...options,
        ]
      : options;
  };

  const getCohortMonth = () => {
    if (fundingRequestId || cohortMonth) {
      return cohortMonth || fundingRequestDetails.cohort;
    } else {
      const options = generateCohortOptions();
      return options[0].value;
    }
  };

  const getSubmitButtonText = () => {
    if (!fundingRequestId) {
      return "Confirm Request";
    } else if (showAdjustmentRequest || showEditRequest) {
      return "Confirm";
    } else if (
      [INVESTMENT_STATUS.APPROVED].includes(fundingRequestDetails?.status) &&
      !fundingRequestDetails?.fundingAdjustmentReported
    ) {
      return "Adjustment Request";
    } else if (
      ![INVESTMENT_STATUS.APPROVED].includes(fundingRequestDetails?.status) &&
      fundingRequestDetails?.fundingAdjustmentReported
    ) {
      return "Edit Adjustment Request";
    } else {
      return "Edit Request";
    }
  };

  const getAdjustmentState = () => {
    return (
      <>
        <Typography
          sx={{
            marginTop: "30px",
            color: "#162936",
            fontWeight: 700,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            marginBottom: "1.25rem",
          }}
        >
          Fill Details
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <Box>
            <CustomTextField
              id="growth-spend"
              label="Growth Spend"
              value={
                actualSpend.displayValue !== ""
                  ? actualSpend.displayValue
                  : formatCurrency(fundingRequestDetails?.actualSpend || 0) ||
                    ""
              }
              onFieldChange={(e) => {
                handleAmountFieldChange(
                  "growth-spend",
                  e.target.value,
                  setActualSpend
                );
              }}
              customStyle={{ width: "100%" }}
            />
            <Typography
              sx={{
                color: "#6C6C6C",
                fontWeight: 500,
                fontSize: "0.75rem",
                lineHeight: "1rem",
                marginTop: "0.25rem",
                marginLeft: "0.25rem",
              }}
            >
              Actual Growth Spend (excluding any Experimental Marketing spend)
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                width: "50%",
                paddingX: "1.5rem",
                paddingY: "0.5rem",
                background:
                  "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                borderRadius: "10px",
                border: "1px solid #2F736E1F",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.375rem",
                  fontSize: "0.625rem",
                  color: "#6C6C6C",
                  lineHeight: "1rem",
                  fontWeight: 700,
                  alignItems: "center",
                }}
              >
                New PvX Investment Amount
              </Box>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.375rem",
                }}
              >
                {formatCurrency(
                  fundingRequestDetails?.fundingPercentage *
                    (actualSpend.value || fundingRequestDetails?.actualSpend) ||
                    0
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                paddingX: "1.5rem",
                paddingY: "0.5rem",
                background:
                  "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                borderRadius: "10px",
                border: "1px solid #2F736E1F",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.375rem",
                  fontSize: "0.625rem",
                  color: "#6C6C6C",
                  lineHeight: "1rem",
                  fontWeight: 700,
                  alignItems: "center",
                }}
              >
                Funding Percentage
                <CustomTooltip
                  content={
                    <>
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          color: "#6C6C6C",
                          marginBottom: "0.25rem",
                        }}
                      >
                        <b>Funding Percentage</b>
                      </div>
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          color: "#6C6C6C",
                        }}
                      >
                        Investment Amount divided by <br /> Actual Growth Spend
                      </div>
                    </>
                  }
                  customStyle={{
                    "& .MuiTooltip-tooltip": {
                      borderRadius: "0.75rem",
                      bgcolor: "#F0F0F1",
                      backdropFilter: "blur(5px)",
                      boxShadow: 2,
                      paddingX: "1rem",
                      paddingY: "0.75rem",
                      position: "relative",
                      left: "-110px",
                      top: "-10px",
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{ height: "20px", color: "#2F736E" }}
                  />
                </CustomTooltip>
              </Box>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.375rem",
                }}
              >
                {getFundingPercentage(true)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const getEditableState = () => {
    return (
      <>
        <Typography
          sx={{
            marginTop: "30px",
            color: "#162936",
            fontWeight: 700,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            marginBottom: "1.25rem",
          }}
        >
          Fill Details
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            {allowedCohort ? (
              <Box
                sx={{
                  width: "48.5%",
                  paddingX: "1.5rem",
                  paddingY: "0.5rem",
                  background:
                    "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                  borderRadius: "10px",
                  border: "1px solid #2F736E1F",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.375rem",
                    fontSize: "0.625rem",
                    color: "#6C6C6C",
                    lineHeight: "0.875rem",
                    fontWeight: 700,
                    alignItems: "center",
                  }}
                >
                  Cohort Month
                </Box>
                <Typography
                  sx={{
                    color: "#162936",
                    fontWeight: 700,
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                  }}
                >
                  {allowedCohort}
                </Typography>
              </Box>
            ) : (
              <TextField
                select
                sx={{
                  width: "48.5%",
                }}
                label="Cohort Month"
                value={getCohortMonth()}
                onChange={handleCohortChange}
                placeholder="Cohort Month"
              >
                {generateCohortOptions().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <Box
              sx={{
                width: "48.5%",
                paddingX: "1.5rem",
                paddingY: "0.5rem",
                background:
                  "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                borderRadius: "10px",
                border: "1px solid #2F736E1F",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.375rem",
                  fontSize: "0.625rem",
                  color: "#6C6C6C",
                  lineHeight: "0.875rem",
                  fontWeight: 700,
                  alignItems: "center",
                }}
              >
                Disbursement Date
              </Box>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                }}
              >
                {fundingRequestDetails?.settlementDate ||
                  (dayjs().date() < 25
                    ? dayjs().date(25).format("YYYY-MM-DD")
                    : dayjs().add(1, "month").date(25).format("YYYY-MM-DD"))}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <CustomTextField
                id="net-spend"
                label="Total UA Spend (USD)"
                value={netSpend.displayValue}
                onFieldChange={(e) => {
                  handleAmountFieldChange(
                    "net-spend",
                    e.target.value,
                    setNetSpend
                  );
                }}
                customStyle={{ width: "50%" }}
              />
              <CustomTextField
                id="experimental-marketing-spend"
                label="Experimental UA Spend (USD)"
                value={experimentalMarketingSpend.displayValue}
                onFieldChange={(e) => {
                  handleAmountFieldChange(
                    "experimental-marketing-spend",
                    e.target.value,
                    setExperimentalMarketingSpend
                  );
                }}
                customStyle={{ width: "50%" }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                paddingX: "1.5rem",
                paddingY: "0.85rem",
                background:
                  "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                borderRadius: "10px",
                border: "1px solid #2F736E1F",
                mt: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.375rem",
                  fontSize: "0.625rem",
                  color: "#6C6C6C",
                  lineHeight: "0.875rem",
                  fontWeight: 700,
                  alignItems: "center",
                }}
              >
                Actual Growth Spend (Total UA Spend - Experimental UA Spend)
              </Box>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                }}
              >
                {getActualGrowthSpend()
                  ? formatCurrency(getActualGrowthSpend())
                  : "-"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <CustomTextField
              id="investment-amount"
              label="PvX Investment Amount (USD)"
              value={investmentAmount.displayValue}
              onFieldChange={(e) => {
                handleAmountFieldChange(
                  "investment-amount",
                  e.target.value,
                  setInvestmentAmount
                );
              }}
              customStyle={{ width: "50%" }}
            />
            <Box
              sx={{
                width: "50%",
                paddingX: "1.5rem",
                paddingY: "0.5rem",
                background:
                  "linear-gradient(180deg, rgba(127, 188, 173, 0.45) -15.61%, rgba(245, 247, 248, 0.8) 100%)",
                borderRadius: "10px",
                border: "1px solid #2F736E1F",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "0.375rem",
                  fontSize: "0.625rem",
                  color: "#6C6C6C",
                  lineHeight: "1rem",
                  fontWeight: 700,
                  alignItems: "center",
                }}
              >
                Funding Percentage
                <CustomTooltip
                  content={
                    <>
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          color: "#6C6C6C",
                          marginBottom: "0.25rem",
                        }}
                      >
                        <b>Funding Percentage</b>
                      </div>
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          color: "#6C6C6C",
                        }}
                      >
                        Investment Amount divided by <br /> Actual Growth Spend
                      </div>
                    </>
                  }
                  customStyle={{
                    "& .MuiTooltip-tooltip": {
                      borderRadius: "0.75rem",
                      bgcolor: "#F0F0F1",
                      backdropFilter: "blur(5px)",
                      boxShadow: 2,
                      paddingX: "1rem",
                      paddingY: "0.75rem",
                      position: "relative",
                      left: "-110px",
                      top: "-10px",
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{ height: "20px", color: "#2F736E" }}
                  />
                </CustomTooltip>
              </Box>
              <Typography
                sx={{
                  color: "#162936",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1.375rem",
                }}
              >
                {getFundingPercentage()}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <CustomTextField
              id="next-month-marketing-spend"
              label="Next Month Expected Spend (USD)"
              value={nextMonthExpectedSpend.displayValue}
              onFieldChange={(e) => {
                handleAmountFieldChange(
                  "next-month-marketing-spend",
                  e.target.value,
                  setNextMonthExpectedSpend
                );
              }}
              customStyle={{ width: "50%" }}
            />
          </Box>
        </Box>
      </>
    );
  };

  const getSavedState = (isAdjustment?: boolean) => {
    return (
      <FundingRequestDetailsCard
        fundingRequestDetails={fundingRequestDetails}
        isAdjustment={isAdjustment}
        downloadAttachment={(index: number) => downloadAttachment(index)}
      />
    );
  };

  const getSavedAdjustmentState = () => {
    return (
      <FundingRequestAdjustmentDetailsCard
        fundingRequestDetails={fundingRequestDetails}
        downloadAttachment={(index: number) => downloadAttachment(index, true)}
      />
    );
  };

  const handleCohortChange = (event: any) => {
    setCohortMonth(event.target.value);
  };

  const handleClickSubmitButton = () => {
    if (!fundingRequestId || showAdjustmentRequest || showEditRequest) {
      // Create or edit view submit
      handleSubmitRequest();

      setShowEditRequest(false);
      setShowAdjustmentRequest(false);
    } else {
      if (
        ![INVESTMENT_STATUS.APPROVED].includes(fundingRequestDetails?.status) &&
        !fundingRequestDetails?.fundingAdjustmentReported &&
        !showEditRequest
      ) {
        // Show edit request view
        setShowEditRequest(true);
      } else {
        // Show edit adjustment request view
        setShowAdjustmentRequest(true);
      }
    }
  };

  return (
    <>
      <DrawerComponent
        open={showFundingRequestPanel}
        anchor="right"
        close={() => {
          setShowFundingRequestPanel(false);
          setShowAdjustmentRequest(false);
          setShowEditRequest(false);
        }}
        height="98vh"
        zIndex={9}
        backgroundColor="#F0F0F1"
      >
        <Box
          sx={{
            width: "600px",
            height: "100%",
            padding: "1.5rem",
            marginBottom: "5rem",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "1.375rem",
              fontWeight: 700,
              lineHeight: "1.875rem",
              color: "#6C6C6C",
              marginBottom: "1.5rem",
            }}
          >
            Funding Request
            <Button
              sx={{ padding: 0, minWidth: "fit-content" }}
              onClick={() => {
                setShowFundingRequestPanel(false);
                setShowAdjustmentRequest(false);
                setShowEditRequest(false);
              }}
            >
              <CloseIcon sx={{ color: "#162C36" }} />
            </Button>
          </Box>
          {(!fundingRequestId || showEditRequest) &&
          !fundingRequestDetails?.fundingAdjustmentReported
            ? getEditableState()
            : getSavedState(fundingRequestDetails?.fundingAdjustmentReported)}
          {showAdjustmentRequest ? getAdjustmentState() : <></>}
          {fundingRequestDetails?.fundingAdjustmentReported &&
          !showAdjustmentRequest ? (
            getSavedAdjustmentState()
          ) : (
            <></>
          )}
        </Box>
        <Box
          sx={{
            width: "600px",
            padding: "1rem 1.5rem",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            position: "absolute",
            bottom: "0",
            borderTop: "1px solid #E8EAEB",
          }}
        >
          {!(
            [INVESTMENT_STATUS.APPROVED].includes(
              fundingRequestDetails?.status
            ) && fundingRequestDetails?.fundingAdjustmentReported
          ) && (
            <CustomButton
              backgroundColor="#2F736E"
              color="#F6F8F9"
              name={getSubmitButtonText()}
              onClick={handleClickSubmitButton}
              disabled={isLoading}
            />
          )}
          {(!fundingRequestId || getSubmitButtonText() === "Confirm") && (
            <CustomButton
              borderColor="#2E4A4F"
              color="#6C6C6C"
              name="Cancel"
              onClick={() => {
                setShowAdjustmentRequest(false);
                setShowEditRequest(false);
                if (!fundingRequestId) {
                  setShowFundingRequestPanel(false);
                }
              }}
              disabled={isLoading}
            />
          )}
        </Box>
      </DrawerComponent>
    </>
  );
};

export default FundingRequestSidePanel;
