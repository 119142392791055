import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PLAY_STORE_LINK_REGEX, APP_STORE_LINK_REGEX, APP_STORE_LINK_REGEX_2, MONTHLY_MARKETTING_SPENDS_OPTIONS, TOAST_TYPES } from "@constants/constants";
import { setToastMessage } from "@stores/App/slice";
import { useLazyFetchCategoryAndGenreOptionsQuery } from "@api/selfServe";
import { AdminRegisterUserPayload } from "@api/register";
import DrawerComponent from "../../../../components/Drawer/Drawer";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import CustomInputField from "../../../FundingCalculator/components/CustomInputField";
import CustomSelect from "../../../../pages/FundingCalculator/components/CustomSelectField";

interface NewGameSidePanelProps {
  showNewGameSidePanel: boolean;
  setShowNewGameSidePanel: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  createNewGameAction: (data: AdminRegisterUserPayload) => void;
}

const NewGameSidePanel: React.FC<NewGameSidePanelProps> = ({
  showNewGameSidePanel,
  setShowNewGameSidePanel,
  createNewGameAction,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const [categoryOptions, setCategoryOptions] = useState<{ name: string; genres: string[] }[]>([]);
  const [genreOptions, setGenreOptions] = useState<string[]>([]);
  const [enableCreateGameButton, setEnableCreateGameButton] = useState(false);
  const [newGameData, setNewGameData] = useState<AdminRegisterUserPayload>({
    accountName: "",
    category: "",
    genre: "",
    monthlyMarketingSpends: "",
    playStoreUrl: "",
    appStoreUrl: "",
  });

  const [fetchCategoryAndGenreOptionsApi] = useLazyFetchCategoryAndGenreOptionsQuery();

  // Hook for fetching the category and genre options.
  useEffect(() => {
    fetchCategoryAndGenreOptions();
  }, []);

  // Hook for updating genre and genre options when category is changed.
  useEffect(() => {
    if (!newGameData?.category) return;

    let genres: string[] = [];
    for (const cat of categoryOptions) {
      if (cat.name !== newGameData.category) continue;

      genres = cat.genres;
      break;
    }

    setGenreOptions(genres);
  }, [newGameData?.category]);

  const fetchCategoryAndGenreOptions = async (): Promise<void> => {
    try {
      const response = await fetchCategoryAndGenreOptionsApi().unwrap();
      const { data } = response;

      setCategoryOptions(data.categories);
    } catch (error: any) {
      dispatch(setToastMessage({
        type: TOAST_TYPES.ERROR,
        message: error?.error?.message || null
      }));
    }
  }

  const validateGameForm = (newGameToUpdate: AdminRegisterUserPayload) => {
    const { accountName, category, genre, monthlyMarketingSpends, playStoreUrl, appStoreUrl } = newGameToUpdate;

    const isPlayStoreLinkValid = PLAY_STORE_LINK_REGEX.test(playStoreUrl);
    const isAppStoreLinkValid = APP_STORE_LINK_REGEX.test(appStoreUrl) || APP_STORE_LINK_REGEX_2.test(appStoreUrl);

    const isInvalid =
      !accountName ||
      !category ||
      !genre ||
      !monthlyMarketingSpends ||
      !(playStoreUrl || appStoreUrl) ||
      (playStoreUrl && !isPlayStoreLinkValid) ||
      (appStoreUrl && !isAppStoreLinkValid);

    setEnableCreateGameButton(!isInvalid);
  };

  const handleUpdateNewGameData = (key: string, value: string) => {
    const newGameToUpdate = {
      ...newGameData,
      [key]: value,
    };

    validateGameForm(newGameToUpdate);
    setNewGameData(newGameToUpdate);
  };

  return (
    <DrawerComponent
      open={showNewGameSidePanel}
      anchor="right"
      close={() => {
        setShowNewGameSidePanel(false);
      }}
      height="98vh"
      zIndex={1300}
      backgroundColor="#F0F0F1"
    >
      <Box
        sx={{
          width: isMobile ? "auto" : "500px",
          height: "100%",
          padding: "1.5rem",
          marginBottom: "5rem",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.375rem",
            fontWeight: 700,
            lineHeight: "1.875rem",
            color: "#6C6C6C",
            marginBottom: "1.5rem",
          }}
        >
          New Game
          <Button
            sx={{ padding: 0, minWidth: "fit-content" }}
            onClick={() => {
              setShowNewGameSidePanel(false);
            }}
          >
            <CloseIcon sx={{ color: "#162C36" }} />
          </Button>
        </Box>
        <Box sx={{ my: "1rem" }}>
          <CustomInputField
            label="ACCOUNT NAME"
            value={newGameData.accountName}
            error={false}
            onChange={(
              e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleUpdateNewGameData("accountName", e.target.value)}
          ></CustomInputField>
        </Box>
        <Box sx={{ my: "1rem" }}>
          <CustomSelect
            label="CATEGORY"
            value={newGameData.category}
            onChange={(e: any) => handleUpdateNewGameData("category", e.target.value)}
            options={categoryOptions.map((category, index) => ({ label: category.name, value: category.name }))}
            error={false}
          />
        </Box>
        <Box sx={{ my: "1rem" }}>
          <CustomSelect
            label="GENRE"
            value={newGameData.genre}
            onChange={(e: any) => handleUpdateNewGameData("genre", e.target.value)}
            options={genreOptions.map((genre, index) => ({ label: genre, value: genre }))}
            disabled={!newGameData.category}
            error={false}
          />
        </Box>
        <Box sx={{ my: "1rem" }}>
          <CustomSelect
            label="MONTHLY MARKETTING SPENDS"
            value={newGameData.monthlyMarketingSpends}
            onChange={(e: any) => handleUpdateNewGameData("monthlyMarketingSpends", e.target.value)}
            options={MONTHLY_MARKETTING_SPENDS_OPTIONS.map((spends: any, index) => ({ label: spends.label, value: spends.value }))}
            error={false}
          />
        </Box>
        <Box sx={{ my: "1rem" }}>
          <CustomInputField
            label="LINK TO PLAY STORE"
            value={newGameData.playStoreUrl}
            error={false}
            onChange={(
              e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleUpdateNewGameData("playStoreUrl", e.target.value)}
          ></CustomInputField>
        </Box>
        <Box sx={{ my: "1rem" }}>
          <CustomInputField
            label="LINK TO APP STORE"
            value={newGameData.appStoreUrl}
            error={false}
            onChange={(
              e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleUpdateNewGameData("appStoreUrl", e.target.value)}
          ></CustomInputField>
        </Box>
      </Box>
      <Box
        sx={{
          width: isMobile ? "auto" : "500px",
          padding: "1rem 1.5rem",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          position: "sticky",
          bottom: "0",
          borderTop: "1px solid #E8EAEB",
          backgroundColor: "#f0f0f1",
        }}
      >
        <CustomButton
          color="#FFFFFF"
          name="Create"
          backgroundColor="#2F736E"
          borderColor="#2F736E"
          onClick={() => createNewGameAction(newGameData)}
          disabled={!enableCreateGameButton}
        />
        <CustomButton
          color="#2F736E"
          name="Close"
          borderColor="#2F736E"
          onClick={() => setShowNewGameSidePanel(false)}
        />
      </Box>
    </DrawerComponent>
  );
};

export default NewGameSidePanel;
