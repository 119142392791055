
export type ProjectionDataPoint = {
  cohort: string;
  [key: string]: any;
};

export enum FinancialProjectionChartTypes {
  FINANCIAL_PROJECTIONS_TABLE = "FINANCIAL_PROJECTIONS_TABLE",
  FINANCIAL_PROJECTIONS = 'FINANCIAL_PROJECTIONS',
  FINANCIAL_PROJECTIONS_M1_TABLE = 'FINANCIAL_PROJECTIONS_TABLE_M1',
  FINANCIAL_PROJECTIONS_M1 = 'FINANCIAL_PROJECTIONS_M1',
  FINANCIAL_PROJECTIONS_COMBINED_VIEW = 'FINANCIAL_PROJECTIONS_COMBINED_VIEW'
}

export interface PredictionDetails {
  gameId: string;
  requestId: string;
  createdBy: string;
  cohortDetails: {
    cohort: string;
    marketingSpend: string;
  }[];
}

export enum FinancialProjectionTabs {
  CURRENT_COHORTS = 'FINANCIAL_PROJECTIONS',
  FUTURE_COHORTS = 'FINANCIAL_PROJECTIONS_V2',
  COMBINED_COHORTS = 'FINANCIAL_PROJECTIONS_V3'
}

export interface Filter {
  key: string;
  label: string;
  values: { id: string; label: string }[];
}

export interface CombinedProjectionsResponse {
  type: string;
  key: string;
  rowData: Record<string, number>;
  children: Record<string, CombinedProjectionsResponse> | null;
}

export interface ProjectionValueUpdateType {
  transactionPeriod: string,
  value: number,
  type: string
}