import { Chart } from "chart.js";
import { ProjectionDataPoint } from "./types";
import { formatCurrency } from "@utils/common";

const getOrCreateTooltip = (chart: Chart) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector("div");

  const container = document.getElementById("pvx-roas-projection-container");

  if (container) {
    container.addEventListener("scroll", () => {
      if (tooltipEl) {
        tooltipEl.style.opacity = "0"; // Hides tooltip during scroll
      }
    });
  }

  // If tooltip doesn't exist, create it
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.width = "200px";
    tooltipEl.style.background = "#FFFFFF";
    tooltipEl.style.borderRadius = "8px";
    tooltipEl.style.color = "#6C6C6C";
    tooltipEl.style.opacity = "1";
    tooltipEl.style.border = "1px solid #737F86";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (
  context: { chart: any; tooltip: any },
  isRoasProjection: boolean,
  isMobile: boolean
) => {
  if (isMobile)
    return;
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLine = tooltip.dataPoints[0].dataset.label;

    const tableHead = document.createElement("thead");

    const tr = document.createElement("tr");
    tr.style.borderWidth = "0";
    tr.style.display = "flex";
    tr.style.gap = "6px";
    tr.style.alignItems = "center";

    const colorBlock = document.createElement("th");
    colorBlock.style.width = "8px";
    colorBlock.style.height = "8px";
    colorBlock.style.borderRadius = "9999px";
    colorBlock.style.background = tooltip.dataPoints[0].dataset.borderColor;

    const th = document.createElement("th");
    th.style.borderWidth = "0";
    th.style.color = "#162936";
    th.style.fontFamily = "Manrope, sans-serif";
    th.style.fontSize = "16px";
    th.style.fontWeight = "700";
    const text = document.createTextNode(titleLine);

    th.appendChild(text);
    tr.appendChild(colorBlock);
    tr.appendChild(th);
    tableHead.appendChild(tr);

    const tableBody = document.createElement("tbody");
    tableBody.style.display = "flex";
    tableBody.style.gap = "4px";
    tableBody.style.flexDirection = "column";
    tableBody.style.marginTop = "6px";


    const hoveredIndex = tooltip.dataPoints[0].dataIndex;
    const hoveredValue = Math.round(tooltip.dataPoints[0].dataset.data[hoveredIndex]);
    const displayValue = isRoasProjection ? `${hoveredValue}%` : formatCurrency(hoveredValue)
    const tooltipData = [
      { label: 'Elapsed Month', value: tooltip.title },
      { label: isRoasProjection ? 'Cumulative ROAS' : 'Cumulative Revenue', value: displayValue }
    ]

    tooltipData.forEach((body) => {
      const tr = document.createElement("tr");
      tr.style.width = "180px";
      tr.style.borderWidth = "0";
      tr.style.display = "flex";
      tr.style.justifyContent = "space-between";

      const tdLabel = document.createElement("td");
      tdLabel.style.borderWidth = "0";
      tdLabel.style.fontSize = "12px";
      tdLabel.style.lineHeight = "15px";
      tdLabel.style.fontWeight = "700";

      const tdValue = document.createElement("td");
      tdValue.style.borderWidth = "0";
      tdValue.style.fontSize = "12px";
      tdValue.style.lineHeight = "15px";
      tdValue.style.fontWeight = "700";

      const label = document.createTextNode(body.label);
      const value = document.createTextNode(body.value);

      tdLabel.appendChild(label);
      tdValue.appendChild(value);
      tr.appendChild(tdLabel);
      tr.appendChild(tdValue);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot?.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  const container = document.getElementById("pvx-roas-projection-container");
  const containerScrollTop = container?.scrollTop || 0;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = "1";
  const tooltipOffsetX = 96; // Adjust for horizontal spacing
  const tooltipOffsetY = 8; // Adjust for vertical spacing

  tooltipEl.style.left = positionX + tooltip.caretX + tooltipOffsetX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + tooltipOffsetY - containerScrollTop + "px";

  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

export const getProjectionsChartOptions = (
  isRoasProjection: boolean,
  isMobile: boolean
) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (ctx: { chart: any; tooltip: any }) =>
          externalTooltipHandler(ctx, isRoasProjection, isMobile),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: '#E8EAEB',
          tickWidth: 0
        },
        border: {
          color: "#2C2C34",
          dash: [5, 5],
        },
        ticks: {
          callback: (value: number | string) => {
            return isRoasProjection ? `${value}%` : formatCurrency(value, true, 1, 0);
          },
          color: "#898C8D",
          font: {
            family: "Manrope",
            weight: 700,
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        border: {
          color: "#2C2C34",
        },
        display: true,
        title: {
          text: "",
          display: true,
        },
        ticks: {
          color: "#898C8D",
          font: {
            family: "Manrope",
            weight: 700,
          },
        },
      },
    },
  };

  return options;
};

export const parseColorCodeConditions = (colorCodeConditions: any) => {
  return colorCodeConditions?.map(
    (conditionObj: { condition: any; value: any }) => {
      const conditionString = conditionObj.condition;

      const match = conditionString.match(/>\s*(\d+)/);
      if (match) {
        const threshold = parseInt(match[1], 10); // Extract the number as an integer
        const color = conditionObj.value; // Get the associated color
        return { threshold, color };
      }

      throw new Error(`Invalid condition format: ${conditionString}`);
    }
  );
};

export const getBorderColor = (
  point: ProjectionDataPoint,
  colorCodeConditions: any
) => {
  const conditions = parseColorCodeConditions(colorCodeConditions);
  const roasPercentage = point.roas * 100;

  // Find the first matching condition
  for (const condition of conditions) {
    if (roasPercentage > condition.threshold) {
      return condition.color;
    }
  }

  // Default color if no condition matches
  return "#8D7C4A";
};

const setDashedLines = (
  context: any,
  index: number,
  value: number[],
  renderedDatasetsArray: ProjectionDataPoint[][]
) => {
  const projectionPlotArray = renderedDatasetsArray.map((dataset) =>
    dataset.findIndex((point) => point.isPredicted === 'true')
  );

  const projectedDataPointIndex = projectionPlotArray[index];
  if (context.p1DataIndex > projectedDataPointIndex - 1)
    return value;
  else return [6, 0];
};

export const getProjectionsData = (
  renderedDatasetsArray: ProjectionDataPoint[][],
  isRoasProjection: boolean,
  metadata?: any,
) => {

  const metadataColorCode = metadata?.columns?.find((column: any) => {
    return isRoasProjection ? column.id === 'roas' : column.id === 'revenue';
  });

  const dataset = renderedDatasetsArray.map((dataset) => {
    const chartDatasetData = {
      label: dataset[0].cohort,
      data: dataset.map((point) => {
        if (isRoasProjection) return point.roas * 100
        else return point.revenue
      }),
      borderColor: metadataColorCode?.color_code ? getBorderColor(
        dataset[dataset.length - 1],
        metadataColorCode.color_code
      ) : '#8D7C4A',
      tension: 0.4,
      segment: {
        borderDash: (context: any) =>
          setDashedLines(
            context,
            renderedDatasetsArray
              .map((item: any) => item[0].cohort)
              .indexOf(dataset[0].cohort),
            [6, 4],
            renderedDatasetsArray
          ) || [6, 0],
      },
      pointRadius: (context: any) => {
        const pointsLength = dataset.length - 1;
        const index = context.dataIndex;

        return index === pointsLength ? 4 : 0;
      },
      pointHoverRadius: 4,
      pointHitRadius: 4,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      pointBackgroundColor: '#FFF',
      pointHoverBackgroundColor: '#FFF'
    };
    return chartDatasetData;
  });

  return dataset;
};

export const isProjectedPayback = (cohortDate: string, paybackPeriod: string) => {
  if (!paybackPeriod) return false;
  // Parse the startDate (format: YYYY-MM)
  const [year, month] = cohortDate?.split('-').map(Number);

  // Extract the number of months from the duration string (e.g., "14 Months")
  const monthsToAdd = parseInt(paybackPeriod?.match(/\d+/)?.[0] || '0', 10);

  // Calculate the new month and year
  const totalMonths = month + monthsToAdd - 1; // Subtract 1 to make it 0-indexed
  const newYear = year + Math.floor(totalMonths / 12);
  const newMonth = (totalMonths % 12) + 1; // Add 1 to make it 1-indexed

  // Create a Date object for the resultant date
  const resultDate = new Date(newYear, newMonth - 1); // Month is 0-indexed in Date

  // Get today's date (start of the day)
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to midnight

  // Compare the resultant date with today's date
  return resultDate > today;
}
