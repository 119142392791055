import { REQUESTABLE_SUBMODULES_STATUS } from '@utils/types/RequestedDashboards';
import apiRoutes from '../constants/apiRoutes';
import baseApi from '../services/api';

export interface UserSubModulesFilterValueApiMap {
  id: string;
  filterValue: string;
}

export interface UserSubModulesFilterApiMap {
  id: string;
  name: string;
  submoduleFilterRoles: UserSubModulesFilterValueApiMap[];
}

export interface UserSubModulesApiMap {
  id: string;
  name: string;
  url: string;
  embedId: string;
  submoduleType: string;
  submoduleFilters: UserSubModulesFilterApiMap[];
}

export interface NestedUserModulesApiMap {
  code: number
  message: string
  data: Data
}

export interface Data {
  modules: Module[]
}

export interface Module {
  id: string
  name: string
  sortOrder: string
  metadata?: string
  submoduleCatalogs: SubmoduleCatalog[]
}

export interface SubmoduleCatalog {
  id: string
  name: string
  url: string
  embedId?: string
  defaultFilter?: string
  nativeFilterId: any
  submoduleType?: string
}

export interface RequestedSubModule {
  status: REQUESTABLE_SUBMODULES_STATUS;
  requestId: string;
  submoduleName: string;
  submoduleCatalogId: string;
  moduleName: string;
  moduleMetadata: string;
}

export interface RequestedSubModulesApiMap {
  code: number;
  message: string;
  data: RequestedSubModule[];
}

export interface RequestSubModuleApiMap {
  code: number;
  message: string,
  data: RequestedSubModule[];
}

const modules = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getModules: builder.query<NestedUserModulesApiMap, void>({
      query: () => `${apiRoutes.userModules}`
    }),
    getSubModules: builder.query<any, string>({
      query: (moduleId) => apiRoutes.userSubmodules(moduleId)
    }),
    getSubModuleFilters: builder.query<any, string>({
      query: (submoduleId) => apiRoutes.userSubmoduleFilters(submoduleId)
    }),
    getUserModulesById: builder.query<any, string>({
      query: (id) => apiRoutes.userModulesById(id)
    }),
    getGuestToken: builder.query<any, string>({
      query: (submoduleId) => apiRoutes.userGuestToken(submoduleId)
    }),
    addFilterValues: builder.mutation<any, any>({
      query: (payload: { moduleId: string, submoduleId: string, filterId: string, values: string[] }) => ({
        url: apiRoutes.userSubmoduleFiltersValues(payload.submoduleId, payload.filterId),
        method: 'POST',
        body: {
          values: payload.values
        }
      }),
    }),
    getCustomDashboardFilters: builder.query<any, string>({
      query: (submoduleId) => apiRoutes.customDashboardFilters(submoduleId)
    }),
    getUserModuleGames: builder.query<any, any>({
      query: (payload: { submoduleId: string, gamesParam: string[] }) => apiRoutes.userSubmoduleGames(payload.submoduleId, payload.gamesParam)
    }),
    getRequestedSubModules: builder.query<RequestedSubModulesApiMap, void>({
      query: () => apiRoutes.fetchRequestedSubmodules()
    }),
    requestSubModule: builder.mutation<RequestSubModuleApiMap, string>({
      query: (requestId) => ({
        url: apiRoutes.requestSubmodule(requestId),
        method: 'PUT'
      })
    })
  })
});

export const {
  useLazyGetModulesQuery,
  useLazyGetSubModulesQuery,
  useLazyGetSubModuleFiltersQuery,
  useAddFilterValuesMutation,
  useLazyGetUserModulesByIdQuery,
  useLazyGetGuestTokenQuery,
  useLazyGetCustomDashboardFiltersQuery,
  useLazyGetUserModuleGamesQuery,
  useLazyGetRequestedSubModulesQuery,
  useRequestSubModuleMutation
} = modules;
