import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@stores/store";
import { Player } from '@lottiefiles/react-lottie-player';
import { Box, Button, IconButton, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { formatCurrency } from "@utils/common";
import { FullPageLoader, CustomDropdown, GenericTable } from "@components";
import {
  INVESTMENT_STATUS,
  useLazyDownloadInvestmentAttachmentsByRequestIdQuery,
  useLazyGetInvestmentAttachmentsByRequestIdQuery,
  useLazyGetInvestmentRequestByGameIdQuery,
  useLazyGetInvestmentRequestGameFiltersQuery
} from "@api/investments";
import FundingDetailsActions from "../components/FundingDetails/FundingDetailsActions";
import FundingRequestSidePanel from "../components/FundingDetails/FundingRequestSidePanel";
import pdfDownload from '../../../assets/icons/pdf-download.svg';
import loader from '../../../assets/images/Loader.json';

const metadata = {
  "chartType": "TABLE",
  "columns": [
    {
      "id": "cohort",
      "name": "Cohort",
      "type": "string"
    },
    {
      "id": "expectedSpend",
      "name": "Growth Spend",
      "type": "currency",
      "currencyFormat": {
        "currency": "dollar",
        "scale": 2
      }
    },
    {
      "id": "requestedFund",
      "name": "Investment Amt.",
      "type": "currency",
      "currencyFormat": {
        "currency": "dollar",
        "scale": 2
      }
    },
    {
      "id": "allocatedFund",
      "name": "Allocation",
      "type": "currency",
      "currencyFormat": {
        "currency": "dollar",
        "scale": 2
      }
    },
    {
      "id": "executedPdf",
      "name": "Executed PDF",
      "type": "string"
    },
    {
      "id": "fundingPercentage",
      "name": "Funding %",
      "type": "percentage"
    },
    {
      "id": "settlementDate",
      "name": "Settlement Date",
      "type": "string"
    }
  ]
};

const mappedColumns = [
  {
    "key": "cohort",
    "label": "Cohort"
  },
  {
    "key": "expectedSpend",
    "label": "Growth Spend"
  },
  {
    "key": "requestedFund",
    "label": "Investment Amt."
  },
  {
    "key": "allocatedFund",
    "label": "Allocation"
  },
  {
    "key": "fundingPercentage",
    "label": "Funding %"
  },
  {
    "key": "settlementDate",
    "label": "Settlement Date"
  },
  {
    "key": "executedPdf",
    "label": "Request Status"
  }
];

const FundingDetails: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [dashboardLoader, setDashboardLoader] = useState<boolean>(true);
  const [showFundingRequestPanel, setShowFundingRequestPanel] = useState(false);
  const [fundingRequestDetails, setFundingRequestDetails] = useState(null);
  const [gamesList, setGamesList] = useState<{ id: number, label: string, value: string }[]>([]);
  const [selectedGame, setSelectedGame] = useState<{ id: number, label: string, value: string }>({ id: -1, label: "", value: "" });
  const [investmentRequests, setInvestmentRequests] = useState([]);
  const [renderTable, setRenderTable] = useState(false);

  const [getInvestmentRequestsGameFilters] = useLazyGetInvestmentRequestGameFiltersQuery();
  const [getInvestmentRequestByGameId] = useLazyGetInvestmentRequestByGameIdQuery();
  const [getInvestmentAttachmentsByRequestId] = useLazyGetInvestmentAttachmentsByRequestIdQuery();
  const [downloadInvestmentAttachmentsByRequestId] = useLazyDownloadInvestmentAttachmentsByRequestIdQuery();
  const [showModal, setShowModal] = useState(false);

  // Hook to fetch games on page load
  useEffect(() => {
    const fetchGames = async () => {
      const res = await getInvestmentRequestsGameFilters({}).unwrap();
      if (res.data.length) {
        const gamesList = res.data.map((game: { gameId: string, gameName: string }, index: number) => ({ id: index + 1, label: game.gameName, value: game.gameId }));
        setGamesList(gamesList);
        setSelectedGame({ id: 1, label: gamesList[0].label, value: gamesList[0].value });
      }
    };

    if (!gamesList.length) {
      fetchGames();
    }
  }, []);

  // Hook to fetch investment requests for seleted game
  useEffect(() => {
    if (selectedGame.value) {
      fetchInvestmentRequests(selectedGame.value);
    }
    if (!showFundingRequestPanel) {
      setFundingRequestDetails(null)
    }
  }, [selectedGame.value, showFundingRequestPanel]);

  useEffect(() => {
    setRenderTable(!renderTable);
  }, [showFundingRequestPanel])

  const userDetails = useSelector((state: RootState) => state?.UserData);

  const fetchInvestmentRequests = async (selectedGame: string) => {
    const queryParams = "?sort=cohort&sortOrder=DESC";
    const res = await getInvestmentRequestByGameId({ gameId: selectedGame, queryParams }).unwrap();
    setDashboardLoader(false);
    setInvestmentRequests(res?.data?.items || []);
  };

  const downloadAttachments = async (id: string) => {
    const res = await getInvestmentAttachmentsByRequestId({ requestId: id }).unwrap();
    if (res?.data?.length) {
      const attachments = res.data.map((d: any) => d.attachmentId);
      for (const [index, attachmentId] of attachments.entries()) {
        const downloadLink = await downloadInvestmentAttachmentsByRequestId({ requestId: id, attachmentId }).unwrap();
        if (downloadLink?.data?.url) {
          try {
            const link = document.createElement('a');
            link.href = downloadLink.data.url;
            link.target = '_blank';
            link.download = index === 0 ? 'Investment Form.pdf' : 'Adjustment Request Form.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (err) {
            console.error('Download failed', err);
          }
        }
      }
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case INVESTMENT_STATUS.REJECTED:
      case INVESTMENT_STATUS.REJECTED_ADJUSTMENT:
        return "Rejected";
      case INVESTMENT_STATUS.CLOSED:
        return "Closed";
      case INVESTMENT_STATUS.PENDING_SIGNATURE:
        return "Pending Signature";
      case INVESTMENT_STATUS.PENDING_ALLOCATION:
      case INVESTMENT_STATUS.PENDING_APPROVAL:
        return "Pending with PvX";
      case INVESTMENT_STATUS.APPROVED:
        return "Approved";
      default:
        return "Pending";
    }
  };

  const conditionallyMappedColumns = mappedColumns.map((col: any) => ({
    ...col,
    ...(col.key === 'executedPdf' && {
      render: (key: string, row: any) => {
        if (row.executedPdf) {
          return (
            <Box
              sx={{
                width: "100%",
                textAlign: "right"
              }}
              onClick={(e) => {
                downloadAttachments(row.id);
                e.stopPropagation();
              }}
            >
              <img
                src={pdfDownload}
                alt="action_icon"
                style={{ userSelect: "none" }}
              />
            </Box>
          );
        }
        return (
          <Box
            sx={{
              width: "100%",
              textAlign: "left"
            }}>
            <Typography
              fontSize={"0.875rem"}
              fontWeight={550}
            >
              {`${getStatus(row.status)}`}
            </Typography>
          </Box>
        );
      }
    }),
    ...(col.key === 'expectedSpend' && {
      render: (key: string, row: any) => {
        const val = row.actualSpend || row.expectedSpend || 0;

        return (
          <Box
            sx={{
              width: "100%",
              textAlign: "right"
            }}>
            <Typography
              fontSize={"0.875rem"}
              fontWeight={550}
            >
              {`${val ? formatCurrency(val) : "-"}`}
            </Typography>
          </Box>
        );
      }
    }),
    ...(col.key === 'requestedFund' && {
      render: (key: string, row: any) => {
        const val = (row?.requestedAdjustment ? (row.requestedFund + row.requestedAdjustment) : row.requestedFund) || 0;

        return (
          <Box
            sx={{
              width: "100%",
              textAlign: "right"
            }}>
            <Typography
              fontSize={"0.875rem"}
              fontWeight={550}
            >
              {`${val ? formatCurrency(val) : "-"}`}
            </Typography>
          </Box>
        );
      }
    }),
    ...(col.key === 'allocatedFund' && {
      render: (key: string, row: any) => {
        const val = (row?.allocatedAdjustment ? (row.allocatedFund + row.allocatedAdjustment) : row.allocatedFund) || 0;

        return (
          <Box
            sx={{
              width: "100%",
              textAlign: "right"
            }}>
            <Typography
              fontSize={"0.875rem"}
              fontWeight={550}
            >
              {`${val ? formatCurrency(val) : "-"}`}
            </Typography>
          </Box>
        );
      }
    }),
  }));

  const mappedData = investmentRequests.map((data: any) => ({
    ...data,
    executedPdf: ""
    // executedPdf: data.status === "APPROVED" ? "download" : "" -> to enable download icon in cell
  }));

  return (
    <>
      {dashboardLoader && <FullPageLoader hasSideNav={true} />}
      <div
        id="pvx-funding-details-container"
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "auto",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          position: "relative",
        }}
      >
        <Box
          sx={{ display: "flex", position: "relative", mt: "0.5rem", mb: "1.5rem", flexDirection: isMobile ? "column" : "row" }}
        >
          <Box
            sx={{ display: "flex", gap: "0.5rem", flexDirection: isMobile ? "column" : "row" }}
          >
            <Typography
              fontSize={"2rem"}
              fontWeight={600}
              sx={{ color: "#1B1C17" }}
            >
              Funding Details: {gamesList.length === 1 ? `${gamesList[0].label} (${gamesList[0].value})` : ""}
            </Typography>
            {gamesList.length > 1 ? (
              <CustomDropdown
                dropdownOptions={gamesList.map((g) => ({ ...g, label: `${g.label} (${g.value})` }))}
                handleChange={(e) => {
                  setSelectedGame(gamesList[parseInt(e.target.value) - 1])
                }}
                selectedOption={selectedGame?.id?.toString() || ""}
                wrapperSx={{
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  color: "#1B1C17",
                  lineHeight: "none",
                  border: "1px solid #2F736E46",
                }}
              />
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "1rem",
              right: "0rem"
            }}
          >
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: 700,
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                color: "#6C6C6C"
              }}
            >
              $ = USD
            </Typography>
          </Box>
        </Box>
        <FundingDetailsActions
          handleSidePanelOpen={() => {
            setShowFundingRequestPanel(true);
          }}
        />
        <Box
          sx={{
            marginTop: "1.125rem",
            backgroundColor: "white",
            borderRadius: "0.75rem",
            padding: "0.75rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                lineHeight: "1.375rem",
                fontWeight: 700,
                color: "#162936",
              }}
            >
              Funding Requests
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <IconButton onClick={(e: any) => fetchInvestmentRequests(selectedGame.value)} sx={{ color: "#2E736E" }}>
                <RefreshIcon />
              </IconButton>
              {/* <Pagination
                                        page={currentPage}
                                        size={PAGE_SIZE}
                                        total={chart.rows.length}
                                        handleChange={(newPage) => handlePageChange(name, newPage)}
                                        fileName={chart.name}
                                        chartId={chart.id}
                                        submoduleId={id}
                                        filterSelection={filterSelection}
                                    /> */}
            </Box>
          </Box>
          <GenericTable
            data={mappedData}
            columns={conditionallyMappedColumns}
            meta={metadata}
            onViewRow={(row: any) => {
              setFundingRequestDetails(row);
              setShowFundingRequestPanel(true);
            }}
          />
        </Box>
        <FundingRequestSidePanel
          showFundingRequestPanel={showFundingRequestPanel}
          setShowFundingRequestPanel={setShowFundingRequestPanel}
          fundingRequestId={(fundingRequestDetails as any)?.id || ""}
          gameId={selectedGame.value}
          fundingRequestDetails={fundingRequestDetails}
          setShowModal={setShowModal}
        />
      </div>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "28px",
            padding: "1.5rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: 700,
              fontSize: "1rem",
              lineHeight: "1.375rem",
              color: "#162936",
              marginBottom: "1rem"
            }}
          >
            You will shortly receive a signature request via Docusign from info@pvxpartners.com
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%", py: "0.5rem" }}>
            <Player
              src={loader}
              className="player"
              loop
              autoplay
              style={{
                width: "5rem"
              }}
            />
          </Box>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: 500,
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              color: "#6C6C6C"
            }}
          >
            Signing authority: {userDetails.user.username}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 3, borderRadius: "2rem", textTransform: "none" }}
              onClick={() => setShowModal(false)}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FundingDetails;
