import { CustomTooltip } from "@components";
import { Box, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";


interface CumulativeMetricCardProps {
	cumulativeMetricData: { name: string, score: number },
	tooltipData: { content: string, example: string }
}

const CumulativeMetricCard: React.FC<CumulativeMetricCardProps> = ({ cumulativeMetricData, tooltipData }) => {
	const getFillStyle = (value: number, index: number) => {
		if (value >= index + 1) {
			return { background: "#2F736E" };
		} else if (value > index && value < index + 1) {
			return {
				background: `linear-gradient(to right, #2F736E ${(value - index) * 100}%, transparent 0%)`,
				border: "2px solid #2F736E"
			};
		} else {
			return { background: "transparent", border: "2px solid #2F736E" };
		}
	};

	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: 'center',
				gap: "0.375rem",
				backgroundColor: "white",
				border: "1px solid #E8EAEB",
				padding: "0.75rem",
				borderRadius: "0.75rem",
				boxShadow: "0px 1px 3px rgba(47, 115, 110, 0.2)"
			}}
		>
			<Box sx={{ display: "flex", justifyContent: 'space-between' }}>
				<Typography sx={{ color: "#6C6C6C", fontWeight: 700, fontSize: "0.875rem" }}>{cumulativeMetricData.name}</Typography>
				<CustomTooltip
					customStyle={{
						"& .MuiTooltip-tooltip": {
							borderRadius: "0.75rem",
							bgcolor: "#F0F0F1",
							backdropFilter: "blur(5px)",
							boxShadow: 2,
							paddingX: "1rem",
							paddingY: "0.75rem",
							position: "relative",
							left: "-80px",
							top: '-10px'
						},
					}}
					content={
						<>
							<div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 500, fontSize: "0.875rem", lineHeight: '1.25rem', color: '#6C6C6C' }}>
								<p style={{ marginBlockStart: '0px', marginBlockEnd: '8px' }}>{tooltipData.content}</p>
								{tooltipData.example}
							</div>
						</>
					}
				>
					<InfoOutlined
						sx={{ color: "#2F736E", width: "1.25rem" }}
						data-html2canvas-ignore="true"
					/>
				</CustomTooltip>
			</Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: 'space-between'
				}}
			>
				<Typography sx={{ color: "#162936", fontWeight: 700, fontSize: "1.25rem" }}>
					{cumulativeMetricData.score ?
						cumulativeMetricData.score.toFixed(2)
						: 'N/A'
					}
				</Typography>
				<Box sx={{ display: "flex", gap: "0.391rem" }}>
					<Box sx={{ display: "flex", gap: "0.391rem" }}>
						{[0, 1, 2].map((index) => (
							<Box
								key={index}
								sx={{
									width: "20px",
									height: "20px",
									borderRadius: "50%",
									...getFillStyle(cumulativeMetricData.score, index)
								}}
							/>
						))}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default CumulativeMetricCard;