import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@stores/store";
import { setDashboardFilter } from "@stores/DashboardFilters/slice";
import { resetFinancialProjectionsChartData, resetFinancialProjectionsTableData, setFinancialProjectionsChartData, setFinancialProjectionsTableData } from "@stores/FinancialProjections/slices";
import useChartById from "@hooks/api/useChartById";
import CohortDetailsSidePanel from "./CohortDetailsSidePanel";
import FinancialProjectionsFilters from "./FinancialProjectionsFilters";
import ProjectionsChart from "./ProjectionsChart";
import ProjectionsTable from "./ProjectionsTable";
import { Filter, FinancialProjectionChartTypes } from "./types";
import { processRowDataWithMetadata } from "../../../../components/GenericTable/tableDataUtil";

interface CurrentCohortProjectionsProps {
	submoduleId: string;
	selectedGame: { id: string, label: string };
	setDashboardLoader: React.Dispatch<React.SetStateAction<boolean>>
	dashboardCharts: { id: string; name: string; chartType: string, metadata: string }[]
}

const projectionType: Filter[] = [
	{
		key: "category",
		label: "Category",
		values: [
			{ id: "roasProjections", label: "ROAS Projections" },
			{ id: "revenueProjections", label: "Revenue Projections" },
		],
	},
];

const CurrentCohortProjections: React.FC<CurrentCohortProjectionsProps> = ({
	submoduleId,
	selectedGame,
	setDashboardLoader,
	dashboardCharts
}) => {
	const dispatch = useDispatch();

	const [cohortDetails, setCohortDetails] = useState<{ cohort: string, marketingSpends: string }>({ cohort: '', marketingSpends: '' });
	const [selections, setSelections] = useState<Record<string, string>>({ category: projectionType[0].values[0].id, });
	const [showRevenueByElapsedMonthsPanel, setShowRevenueByElapsedMonthsPanel] =
		useState(false);
	const [chartData, setChartData] = useState<
		{ id: string; name: string; columns: any[]; rows: any[]; pricingDetails: string[]; meta: any }[]
	>([]);
	const [componentLoader, setComponentLoader] = useState<boolean>(true)

	const { getChartDataById } = useChartById()

	const projectionsTableData = useSelector((state: RootState) => state?.FinancialProjectionsData?.financialProjections?.tableData)
	const projectionsChartData = useSelector((state: RootState) => state?.FinancialProjectionsData?.financialProjections?.chartData)

	const isSkippable = projectionsTableData?.gameId === selectedGame?.id
	const chartsList = [FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS, FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_TABLE]
	const tabChartsList = dashboardCharts.filter((chart: any) => chartsList.includes(chart.chartType))

	const fetchDashboardData = async (filters?: Record<string, string>) => {
		if (submoduleId) {
			const chartsById: any[] = [];
			setComponentLoader(true)
			try {
				await Promise.all(
					tabChartsList.map(
						async (chart: {
							id: string;
							name: string;
							chartType: string;
							metadata: string;
						}) => {
							const parsedMetadata = JSON.parse(chart.metadata);
							const response = await getChartDataById(submoduleId, chart.id, filters, parsedMetadata?.sort?.fields, parsedMetadata?.sort?.order);
							if (response) {
								const { rows, columns, pricingDetails, metadata } = formatChartResponseData(response);
								chartsById.push({
									columns,
									rows,
									pricingDetails,
									id: chart.id,
									name: response.name,
									meta: metadata,
								});
							}
							else {
								if (chart.chartType === FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_TABLE) {
									dispatch(resetFinancialProjectionsTableData());
								} else if (chart.chartType === FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS) {
									dispatch(resetFinancialProjectionsChartData())
								}
							}
						}
					)
				);

				setChartData(chartsById);
			} catch (error) {
				console.error("Error fetching chart data:", error);
			} finally {
				setDashboardLoader(false);
				setComponentLoader(false);
			}
		}
	};

	const formatChartResponseData = (response: any) => {
		let columns = [];
		let rows = [];
		let pricingDetails = response.result.pricingDetails || []
		const metadata = JSON.parse(response.metadata);
		const { mappedColumns, mappedData } = processRowDataWithMetadata(
			response.name,
			metadata,
			response.result.data
		);
		columns = mappedColumns;
		rows = mappedData

		return { rows, columns, pricingDetails, metadata }
	}

	useEffect(() => {
		if (chartData.length) {
			chartData.forEach((chart) => {
				if (chart.meta.chartType === FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_TABLE) {
					dispatch(setFinancialProjectionsTableData({ gameId: selectedGame?.id, ...chart }));
				} else {
					dispatch(setFinancialProjectionsChartData({ gameId: selectedGame?.id, ...chart }));
				}
			})
		}
	}, [chartData])

	useEffect(() => {
		dispatch(setDashboardFilter({ "FinancialProjections": selectedGame }));
		if (isSkippable) {
			setDashboardLoader(false);
			setComponentLoader(false);
			return
		};

		if (selectedGame?.id) {
			fetchDashboardData({ gameId: selectedGame?.id });
		}
	}, [submoduleId, selectedGame, isSkippable, dashboardCharts]);



	const handleFilterChange = (key: string, selectedValue: string) => {
		const updatedSelections = { ...selections, [key]: selectedValue };
		setSelections(updatedSelections);
	};

	const handleCohortDetailsSidebarClick = (selectedCohortDetails: { cohort: string, marketingSpends: string }) => {
		setCohortDetails(selectedCohortDetails);
		setShowRevenueByElapsedMonthsPanel(!showRevenueByElapsedMonthsPanel);
	}

	return (
		<>
			<FinancialProjectionsFilters
				handleFilterChange={handleFilterChange}
				selections={selections}
				filters={projectionType}
				isProjectionSimulation={false}
			/>
			<ProjectionsChart
				selectedFilter={selections.category}
				isLoading={componentLoader}
				isSimulation={false}
				submoduleId={submoduleId}
				chartId={projectionsChartData?.id || ''}
				downloadFilename={projectionsChartData?.meta?.chartType || FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS}
				filterSelection={{ gameId: selectedGame?.id }}
			/>
			<ProjectionsTable
				selectedGameId={selectedGame?.id}
				handleRowClick={handleCohortDetailsSidebarClick}
				subModuleId={submoduleId}
				chartId={projectionsTableData?.id || ''}
				chartName={projectionsTableData?.meta?.chartType || FinancialProjectionChartTypes.FINANCIAL_PROJECTIONS_TABLE}
				filterSelection={{ gameId: selectedGame?.id }}
				isLoading={componentLoader}
				isSimulation={false}
			/>
			<CohortDetailsSidePanel
				showPanel={showRevenueByElapsedMonthsPanel}
				setShowPanel={setShowRevenueByElapsedMonthsPanel}
				cohortDetails={cohortDetails}
				isSimulation={false}
			/>
		</>
	)
}

export default CurrentCohortProjections;